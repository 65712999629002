import { createAction } from 'redux-actions';
import { map } from 'lodash';
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { showError, showSuccess, createRequestSaga } from '@weave/alert-system';
import { getErrorMessage } from '../../axios';
import { updateJobTitles } from './user-job-titles.action';
import { updateUserRoles } from './user-roles.action';
import { UserModel, WeaveUserModel } from '../../../models/user.model';
import { usersApi } from './users.api';
import { ResolvedPromise } from '../../store/store-utils';

/* ~~~~~~~~~~~~ TYPES ~~~~~~~~~~~~ */

type SaveUserProfilePayload = {
  addingNewUser: boolean;
  user: UserModel;
};
type UpdateWeaveUserRolesPayload = {
  addingNewUser: boolean;
  user: UserModel;
};

type SaveUserProfileAction = {
  type: string;
  payload: SaveUserProfilePayload;
};

type UpdateWeaveUserRolesAction = {
  type: string;
  payload: UpdateWeaveUserRolesPayload;
};

type GetUserAction = {
  type: 'GET_USER';
  payload: string;
};

/* ~~~~~~~~~~~~ ACTION CREATORS ~~~~~~~~~~~~ */

export const getUser = createAction<string>('GET_USER');
export const getUsers = createAction('GET_USERS');
export const getUsersSuccess = createAction('GET_USERS_SUCCESS');
export const setLoading = createAction('SET_USERS_LOADING');
export const addNewUser = createAction('ADD_NEW_USER');
export const addNewUserSuccess = createAction('ADD_NEW_USER_SUCCESS');
export const saveUserProfile = createAction<SaveUserProfilePayload>('SAVE_USER_PROFILE');
export const updateWeaveUserRoles = createAction<UpdateWeaveUserRolesPayload>(
  'UPDATE_WEAVE_USER_ROLES'
);
export const saveUserProfileSuccess = createAction('SAVE_USER_PROFILE_SUCCESS');
export const sendPasswordReset = createAction('SEND_PASSWORD_RESET');
export const sendPasswordResetSuccess = createAction('SEND_PASSWORD_RESET_SUCCESS');
export const deleteUser = createAction('DELETE_USER');
export const deleteUserSuccess = createAction('DELETE_USER_SUCCESS');

/* ~~~~~~~~~~~~ GENERATOR FUNCTIONS ~~~~~~~~~~~~ */

export const handleGetUser = function* (action: GetUserAction) {
  const userId = action.payload;
  try {
    const user: ResolvedPromise<ReturnType<typeof usersApi.getUser>> = yield call(
      usersApi.getUser,
      userId
    );
    yield put(addNewUserSuccess(user));
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
  }
};

export const handleGetUsers = function* (action) {
  const { limit } = action.payload;
  try {
    const users: ResolvedPromise<ReturnType<typeof usersApi.getUsers>> = yield call(
      usersApi.getUsers,
      limit
    );
    yield put(getUsersSuccess(users || []));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    yield put(showError(getErrorMessage(error)));
  }
};

export const handleAddNewUser = function* (action) {
  const user = action.payload;
  const userDetails: any = {
    Username: user.Username,
    FirstName: user.FirstName,
    LastName: user.LastName,
    TicketID: user.TicketID,
    Roles: map(user.Roles, 'ID'),
    Password: user.Password,
    JobTitles: map(user.JobTitles, 'ID'),
    UserID: '',
  };
  try {
    const newUser: ResolvedPromise<ReturnType<typeof usersApi.addUser>> = yield call(
      usersApi.addUser,
      userDetails
    );
    userDetails.UserID = newUser.UserID;
    userDetails.LocationID = user.LocationID;
    yield put(updateJobTitles(userDetails));
    yield put(showSuccess('New User Added Successfully'));
    if (!user.LocationID) {
      yield put(getUsers({ limit: 25 }));
    }
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    yield put(showError(getErrorMessage(error)));
  }
};

export const handleSaveUserProfile = createRequestSaga<SaveUserProfileAction>({
  key: saveUserProfile.toString(),
  onError: () => 'Failed to submit user.',
  displayErrors: true,
  saga: function* (action) {
    const { user, addingNewUser } = action.payload;
    yield call(usersApi.updateUser, user);
    const userUpdates = { ...user, JobTitles: map(user.JobTitles, 'ID') };
    const shouldUpdateRoles = user.Roles[0].hasOwnProperty('value');

    yield all([
      put(updateJobTitles(userUpdates)),
      put(saveUserProfileSuccess(userUpdates)),
      put(showSuccess('Save Successful')),
    ]);

    if (shouldUpdateRoles) {
      yield put(updateUserRoles(user));
    } else if (addingNewUser) {
      yield put(getUsers({ limit: 25 }));
    }
  },
});

export const handleUpdateWeaveUserRoles = createRequestSaga<UpdateWeaveUserRolesAction>({
  key: updateWeaveUserRoles.toString(),
  onError: () => 'Failed to update user roles.',
  displayErrors: true,
  saga: function* (action) {
    const { user, addingNewUser } = action.payload;
    const weaveUser: WeaveUserModel = {
      UserID: user?.UserID,
      Username: user?.Username,
      FirstName: user?.FirstName,
      LastName: user?.LastName,
      TicketID: user?.TicketID,
      Roles: user?.Roles.map((role) => role.ID),
    };
    try {
      yield call(usersApi.updateWeaveUser, weaveUser);
      const shouldUpdateRoles = user.Roles[0].hasOwnProperty('value');
      yield all([
        put(saveUserProfileSuccess({ ...user })),
        put(showSuccess('Save Successful')),
      ]);

      if (shouldUpdateRoles) {
        yield put(updateUserRoles(user));
      } else if (addingNewUser) {
        yield put(getUsers({ limit: 25 }));
      }
    } catch (error: any) {
      yield put(setLoading(false));
      yield put(showError(getErrorMessage(error)));
    }
  },
});

export const handleDeleteUser = function* (action) {
  const user = action.payload;
  try {
    yield call(usersApi.deleteUser, user.UserID);
    yield put(showSuccess('Delete Successful'));
    yield put(deleteUserSuccess(user));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    yield put(showError(getErrorMessage(error)));
  }
};

export const handleSendPasswordReset = function* (action) {
  const user = action.payload;
  const userDetails = {
    Email: user.Username,
    FirstName: user.FirstName,
    Roles: map(user.Roles, 'ID'),
  };
  try {
    yield call(usersApi.passwordReset, userDetails);
    yield put(showSuccess('Password Email Sent Successful'));
    yield put(sendPasswordResetSuccess(user));
  } catch (error: any) {
    yield put(setLoading(false));
    yield put(showError(getErrorMessage(error)));
  }
};

/* ~~~~~~~~~~~~ SAGAS ~~~~~~~~~~~~ */

export const addNewUserSaga = function* () {
  yield takeEvery(addNewUser.toString(), handleAddNewUser);
};

export const getUsersSaga = function* () {
  yield takeEvery(getUsers.toString(), handleGetUsers);
};

export const saveUserProfileSaga = function* () {
  yield takeEvery(saveUserProfile.toString(), handleSaveUserProfile);
};
export const updateWeaveUserRolesSaga = function* () {
  yield takeEvery(updateWeaveUserRoles.toString(), handleUpdateWeaveUserRoles);
};

export const deleteUserSaga = function* () {
  yield takeEvery(deleteUser.toString(), handleDeleteUser);
};

export const sendPasswordResetSaga = function* () {
  yield takeEvery(sendPasswordReset.toString(), handleSendPasswordReset);
  yield takeEvery(getUser.toString(), handleGetUser);
};
