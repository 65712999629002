import copy from 'clipboard-copy';
import { useState, useCallback } from 'react';

export const useClipboard = () => {
  const [copied, setCopied] = useState(false);
  const [copyError, setCopyError] = useState();

  const execCopy = useCallback((text: string) => {
    copy(text)
      .then(() => setCopied(true))
      .catch((error) => setCopyError(error));
  }, []);

  return { copy: execCopy, copied, copyError };
};
