import { css } from 'emotion';
import { weaveTheme } from '@weave/theme-original/dist/weaveTheme';

export const portingCardStyles: any = {};

portingCardStyles.typeaheadStyles = css`
  .rbt-input-hint {
    display: none;
  }
  input {
    height: 40px;
    width: 100%;
    border-radius: 5px;
    padding-left: 15px;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-size: 14px;
      color: #ced4d6;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      font-size: 14px;
      color: #ced4d6;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      font-size: 14px;
      color: #ced4d6;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      font-size: 14px;
      color: #ced4d6;
    }
  }
  .dropdown-item {
    font-size: 14px;
    padding: 5px 15px;
  }
`;

portingCardStyles.lnpText = css`
  padding-bottom: 5px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #8c9496;
  font-weight: 300;
`;

portingCardStyles.portingHubText = css`
  padding-bottom: 5px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #8c9496;
  font-weight: 300;
`;
portingCardStyles.loading = css`
  display: flex;
  justify-content: center;
`;
portingCardStyles.backButtonStyle = css`
  margin-bottom: 10px;
  font-size: 14px;
  color: ${weaveTheme.colors.weaveBlue};
  &:hover {
    cursor: pointer;
  }
`;
portingCardStyles.portOrderTitle = css`
  color: #333b3d;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 7px;
`;

portingCardStyles.errorMessage = css`
  color: #ff6b59;
`;
portingCardStyles.container = css`
  margin-right: 30px;
  border-radius: 10px;
  border: 1px solid #dee2e3;
  margin-bottom: 20px;
`;
portingCardStyles.backgroundStyle = css`
  background-color: #f6f7f7;
  padding: 15px 25px;
  border-bottom: 1px solid #dee2e3;
`;
portingCardStyles.flexCenter = css`
  display: flex;
  align-content: center;
  align-items: center;
`;
portingCardStyles.flexContainer = css`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
`;

portingCardStyles.portOrderType = css`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #272b2c;
`;
portingCardStyles.downloadLoaButton = css`
  margin-bottom: 10px;
  font-size: 14px;
  margin: 20px;
  color: ${weaveTheme.colors.weaveBlue};
  &:hover {
    cursor: pointer;
  }
`;
portingCardStyles.downloadBillButton = css`
  margin-bottom: 10px;
  font-size: 14px;
  margin: 20px;
  color: ${weaveTheme.colors.weaveBlue};
  &:hover {
    cursor: pointer;
  }
`;

portingCardStyles.gray = css`
  background: #f3f3f3;
  padding: 10px;
`;

portingCardStyles.white = css`
  background: white;
  padding: 10px;
`;

portingCardStyles.portingResultsTitle = css`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
`;

portingCardStyles.uploadLoaButtonSplit = css`
  text-align: center;
  font-size: 14px;
  width: 100px;
  color: ${weaveTheme.colors.weaveBlue};
  &:hover {
    cursor: pointer;
  }
`;

portingCardStyles.uploadLoaButtonSplitFail = css`
  text-align: center;
  font-size: 14px;
  width: 300px;
  color: ${weaveTheme.colors.weaveBlue};
  &:hover {
    cursor: pointer;
  }
`;

portingCardStyles.uploadLoaButton = css`
  text-align: center;
  font-size: 14px;
  margin: 5px auto;
  margin-bottom: 30px;
  color: ${weaveTheme.colors.weaveBlue};
  &:hover {
    cursor: pointer;
  }
`;
portingCardStyles.modalStyle = css`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
`;
portingCardStyles.portingInfoContainer = css`
  display: flex;
  justify-content: space-between;
  margin-left: 3px;
  margin-right: 9px;
`;
portingCardStyles.title = css`
  font-weight: bold;
  font-size: 16px;
`;

portingCardStyles.requestedPortDate = css`
  border: 1px solid #b9c0c2;
  border-radius: 10px;
  padding: 25px;
  margin-top: 5px;
  margin-bottom: 30px;
  font-size: 16px;
  color: #272b2c;
  display: grid;
  grid-template-columns: max-content auto;
  row-gap: 8px;
  column-gap: 16px;
`;

portingCardStyles.gotItButton = css`
  width: 250px;
  font-size: 16px;
  height: 48px;
  margin: 10px auto;
`;

portingCardStyles.splitError = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

portingCardStyles.splitSuccess = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

portingCardStyles.successSubmit = css`
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 18px;
  text-align: center;
`;

portingCardStyles.errorOkayButton = css`
  width: 250px;
  font-size: 16px;
  height: 48px;
  margin: 10px auto;
`;

portingCardStyles.lnpButton = css`
  font-size: 16px;
  height: 48px;
  margin: 10px;
  width: 200px;
`;

portingCardStyles.fixErrorTitle = css`
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 15px;
`;

portingCardStyles.fixErrorReason = css`
  font-weight: bold;
  margin-bottom: 15px;
`;

portingCardStyles.submitButtonStyle = css`
  width: 250px;
  font-size: 16px;
  height: 48px;
  margin: 10px auto;
`;

portingCardStyles.buttonContainers = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

portingCardStyles.buttonContainersDownload = css`
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

portingCardStyles.errorText = css`
  color: #ff6b59;
  text-align: center;
`;

portingCardStyles.downloadLoaButtonStyle = css`
  width: 250px;
  font-size: 16px;
  height: 48px;
  margin: 10px auto;
`;

portingCardStyles.flexCenterText = css`
  display: flex;
  justify-content: center;
`;
portingCardStyles.whatisnext = css`
  border: 1px solid #b9c0c2;
  border-radius: 10px;
  padding: 25px;
  margin-top: 5px;
  margin-bottom: 30px;
  font-size: 16px;
  color: #272b2c;
  line-height: 32px;
`;

portingCardStyles.nextStepContainer = css`
  display: flex;
  justify-content: space-between;
  margin-left: 3px;
  margin-right: 9px;
`;

portingCardStyles.saveOffice = css`
  width: 120px;
  font-size: 16px;
  height: 48px;
`;

portingCardStyles.saveOfficeCont = css`
  width: 200px;
  margin: 10px auto;
`;

portingCardStyles.typeaheadCont = css`
  color: #8c9496;
  display: flex;
  width: 500px;
  justify-content: space-between;
  margin-bottom: 20px;
`;

portingCardStyles.streetContainer = css`
  color: #8c9496;
  display: flex;
  width: 500px;
  justify-content: space-between;
  margin-bottom: 20px;
`;

portingCardStyles.officeCont = css`
  border: 1px solid #b9c0c2;
  border-radius: 10px;
  padding: 25px;
  margin-top: 5px;
  margin-bottom: 30px;
  font-size: 16px;
  color: #272b2c;
`;

portingCardStyles.phoneCont = css`
  color: #8c9496;
  display: flex;
  width: 400px;
  justify-content: space-between;
  margin-bottom: 10px;
`;

portingCardStyles.dids = css`
  border: 1px solid #b9c0c2;
  border-radius: 10px;
  padding: 25px;
  margin-top: 5px;
  margin-bottom: 30px;
  font-size: 16px;
  color: #272b2c;
`;

portingCardStyles.savePortCont = css`
  width: 120px;
  font-size: 16px;
  height: 48px;
`;
