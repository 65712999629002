import React from 'react';
import { EditIcon, NakedButton } from '@weave/design-system';
import { css } from '@emotion/core';
import { cx } from 'emotion';

import * as styles from './auto-reminder-advanced-options.styles';

interface ConsolidatedOptionInfo {
  excludeOptions: boolean;
  values: string[];
}

interface Props {
  key: string;
  info: ConsolidatedOptionInfo;
  index: number;
  toggleOptionTypeList: Function;
  viewOptionList: string;
  setRef: (key: string, refElem: HTMLDivElement | null) => void;
  optionKey: string;
}

export const AutoScrollDiv = (props: Props) => {
  const { optionKey, info, index, toggleOptionTypeList, viewOptionList, setRef } = props;
  return (
    <div>
      <h4
        css={css`
          align-items: center;
          display: flex;
        `}
      >
        <NakedButton
          className={styles.editIcon}
          onClick={toggleOptionTypeList(optionKey)}
        >
          <EditIcon
            color="default"
            css={css`
              --size: 16px;
              height: var(--size);
              width: var(--size);
            `}
          />
        </NakedButton>{' '}
        {optionKey}{' '}
      </h4>
      <div
        className={cx(
          styles.optionValues,
          viewOptionList === optionKey ? '' : styles.hide
        )}
        ref={(refElem) => setRef(optionKey, refElem)}
      >
        {info &&
          !!info.values.length &&
          info.values.map((option, key) => (
            <div
              key={`${optionKey}-${index}-${key}-${option}`}
              className={info!['excludeOptions'] ? styles.exclude : styles.include}
            >
              <span>{info!['excludeOptions'] ? '-' : '+'}</span> {option}
            </div>
          ))}
      </div>
    </div>
  );
};
