import { css } from 'emotion';
import { theme } from '@weave/theme-original';

export const modalHeader = css`
  background-color: ${theme.colors.weaveBlue};
  height: 70px;
  padding: 10px 10px 0;
`;

export const title = css`
  align-items: flex-start;
  color: ${theme.colors.white};
  display: flex;
  font-size: 1.3rem;
  font-weight: bold;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  svg {
    --size: 16px;
    align-self: flex-end;
    height: var(--size);
    width: var(--size);
  }
`;

export const modalHeaderOptions = css`
  background-color: ${theme.colors.weaveBlue};
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: 1fr 5fr 1fr;
  grid-template-rows: 28px;
`;

export const editIcon = css`
  position: relative;
  display: inline;
  top: 3px;
`;

export const titleInput = css`
  padding: 0;
`;

export const modalFooter = css`
  background-color: ${theme.colors.weaveBlue};
`;
export const modalStyle = css`
  width: 600px !important;
  height: 600px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  transition: all 1s;

  & .weave-modal-close {
    position: relative;
    bottom: 10px;
    font-size: 85%;
  }
`;

export const modalActionButtons = css`
  width: 300px;
  margin: auto;
  display: flex;
  position: absolute;
  top: 47px;
  left: 0;
  right: 0;
  justify-content: space-around;

  svg {
    --size: 20px;
    cursor: pointer;
    height: var(--size);
    width: var(--size);

    &:hover {
      transform: scale(1.2);
    }

    path {
      fill: white;
    }
  }
`;

export const toggle = css`
  label: toggle;

  & > div {
    align-items: center;
    display: flex;
  }

  & > div > .toggle-switch {
    top: 0;
  }

  & > div > span {
    color: #37cff1;
    font-size: 120%;
  }
`;
