import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';
import { useAlert } from '@weave/alert-system';
import {
  TextField,
  DropdownField,
  FormRow,
  PrimaryButton,
  Modal,
  useModalControl,
  useFormField,
  ContentLoader,
} from '@weave/design-system';
import { FieldWrapper } from '@weave/design-system/dist/components/forms/layouts/field-wrapper.component';

import { selectWeaveUserAcls } from '../../redux/actions/auth/auth.selectors';
import { insertNewRelease } from './api';
import { ReleaseTarget, OSFilters } from './models';
import { webFilters, windowsFilters, macFilters } from './constants';
import { CoreACLs } from '../../redux/actions/auth/auth.types';

interface Props {
  refresh: () => void;
}

const createReleaseJson = (
  target: string,
  version: string,
  signature: string,
  filename: string
) => {
  const versionNumbers = version.split('.');
  const id = `${version}-${target}`;

  let osFilters: OSFilters = webFilters;
  if (target === ReleaseTarget.windows) {
    osFilters = windowsFilters;
  } else if (target === ReleaseTarget.osx) {
    osFilters = macFilters;
  }

  return {
    ID: id,
    Disabled: true,
    Information: {
      name: 'weave_client',
      os: osFilters.os,
      filename: `${id}/${filename}`,
      signature: signature,
    },
    Version: {
      release_channel: 0,
      major: Number(versionNumbers[0]),
      minor: Number(versionNumbers[1]),
      maintenance: Number(versionNumbers[2]),
    },
    Meta: {
      required: 'false',
    },
    Filters: {
      opsys: osFilters.opsys,
      shell: ['0.44.6'],
      '-osver': osFilters.excludedVersions,
    },
  };
};

const versionValidator = ({ value = '' }) => {
  if (value?.match(/^\d+\.\d+\.\d+$/)) {
    return '';
  }
  return 'Version must only contain digits and match this style: "x.x.x"';
};

export const CreateRelease = ({ refresh }: Props) => {
  const hasReleaseAcl =
    useSelector(selectWeaveUserAcls).indexOf(CoreACLs.APPRELEASEWRITE) >= 0;

  const { modalProps, triggerProps } = useModalControl();
  const alerts = useAlert();

  const [loading, setLoading] = useState(false);
  const targetFieldProps = useFormField({ type: 'text' });
  const versionFieldProps = useFormField({ type: 'text', validator: versionValidator });
  const signatureFieldProps = useFormField({ type: 'text' });
  const fileInput = useRef<HTMLInputElement>(null);

  const handleSubmit = () => {
    if (!canSubmit()) {
      alerts.error('All fields are required');
      return;
    }

    const selectedFile = fileInput?.current?.files?.[0];
    if (!selectedFile) {
      alerts.error('Must select a valid file');
      return;
    }

    const json = createReleaseJson(
      targetFieldProps.value,
      versionFieldProps.value,
      signatureFieldProps.value,
      selectedFile.name
    );

    setLoading(true);
    insertNewRelease(json, selectedFile).then(
      () => {
        refresh();
        modalProps.onClose();
        setLoading(false);
        clearFields();
      },
      (error) => {
        console.error(error);
        setLoading(false);
        alerts.error('Failed to create release. Check console');
      }
    );
  };

  const clearFields = () => {
    targetFieldProps.onChange({ name: 'target', value: '' });
    versionFieldProps.onChange({ name: 'version', value: '' });
    signatureFieldProps.onChange({ name: 'signature', value: '' });
  };

  // This part would be simpler with useForm, but that was tricky becaue we don't currently support File types
  const canSubmit = () => {
    return (
      targetFieldProps.value &&
      versionFieldProps.value &&
      signatureFieldProps.value &&
      fileInput?.current?.files?.[0] &&
      !targetFieldProps.error &&
      !versionFieldProps.error &&
      !signatureFieldProps.error
    );
  };

  if (!hasReleaseAcl) {
    return null;
  }

  return (
    <>
      <PrimaryButton size="tiny" {...triggerProps}>
        Create new Release
      </PrimaryButton>
      <Modal {...modalProps}>
        <Modal.Header>Create New Release</Modal.Header>
        <Modal.Body
          css={css`
            padding-top: 8px;
          `}
        >
          <FormRow>
            <DropdownField {...targetFieldProps} label="Release Type" name="target">
              <DropdownField.Option value={ReleaseTarget.web}>Web</DropdownField.Option>
              <DropdownField.Option value={ReleaseTarget.windows}>
                Windows
              </DropdownField.Option>
              <DropdownField.Option value={ReleaseTarget.osx}>osx</DropdownField.Option>
            </DropdownField>
          </FormRow>
          <FormRow>
            <TextField
              {...versionFieldProps}
              label="Version Number"
              placeholder="x.x.x"
              name="version"
            />
          </FormRow>
          <FormRow>
            <TextField {...signatureFieldProps} label="File Signature" name="signature" />
          </FormRow>
          <FormRow>
            <FieldWrapper>
              <input
                type="file"
                name="file"
                id="file"
                accept=".zip,.exe,.dmg"
                required={true}
                ref={fileInput}
              />
            </FieldWrapper>
          </FormRow>
        </Modal.Body>
        <Modal.Actions
          primaryLabel="Submit"
          onPrimaryClick={handleSubmit}
          onSecondaryClick={modalProps.onClose}
        />
        <ContentLoader show={loading} />
      </Modal>
    </>
  );
};
