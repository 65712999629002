import React from 'react';

import { contentWrapper, title } from '../../labeler-container/labeler/labeler.component';
import { css, cx } from 'emotion';
import { Loader } from '../../../shared/loader/loader.component';

export interface Leader {
  Rank: number;
  Score: number;
  Employee: {
    ID: string;
    FirstName: string;
    LastName: string;
  };
}

const leaderHeader = css`
  font-weight: bold;

  label: leaderHeader;
`;

const leaderItem = css`
  display: block;

  span {
    width: 50px;
    margin: 2px 10px;
    display: inline-block;
    &:last-child {
      width: 150px;
    }
  }

  label: leaderItem;
`;

interface Props {
  leaders: Leader[];
  loading: boolean;
  error: string;
  getLeaders: () => void;
}

const body = css`
  padding: 0 10px;
  scroll: auto;
  max-height: 400px;

  label: body;
`;

const listItem = css`
  color: #505050;
  border-bottom: 1px solid #ecece4;
  label: listItem;
`;

const bodyContainer = css`
  height: 125px;
  overflow: auto;
  label: bodyContainer;
`;

export class Leaderboard extends React.Component<Props, {}> {
  componentDidMount() {
    if (typeof this.props.getLeaders === 'function') {
      this.props.getLeaders();
    }
  }

  render() {
    const { loading, leaders = [] } = this.props;
    return (
      <div className={cx('leaderboard', contentWrapper)}>
        <h4 className={title}>Leaderboard</h4>
        <div className={body}>
          <div className={cx(leaderItem, leaderHeader)}>
            <span>Rank</span>
            <span>Score</span>
            <span>Name</span>
          </div>
          <div className={bodyContainer}>
            {loading ? (
              <Loader />
            ) : (
              leaders.map((leader) => {
                return (
                  <div key={leader.Employee.ID} className={cx(leaderItem, listItem)}>
                    <span>{leader.Rank}</span>
                    <span>{leader.Score}</span>
                    <span>
                      {leader.Employee.FirstName} {leader.Employee.LastName}
                    </span>
                  </div>
                );
              })
            )}
          </div>
          {this.props.error && <div>Error Fetching Leaderboard</div>}
        </div>
      </div>
    );
  }
}
