import { getDay } from 'date-fns';
import {
  statusColorsMap,
  friendlyStatuses,
  friendlyStatusMap,
} from './map-friendly-statuses';

interface Dataset {
  label: string;
  backgroundColor: string;
  barThickness: number;
  data: number[];
}

type Datasets = Dataset[];

const emptyDays = {
  Sunday: {},
  Monday: {},
  Tuesday: {},
  Wednesday: {},
  Thursday: {},
  Friday: {},
  Saturday: {},
};

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const todayIndex = new Date().getDay();
const daysFromToday = days.slice(0, todayIndex);
export const labels = days.concat(daysFromToday).slice(-7);

const getDaysObject = (data) => {
  return data.reduce(
    (previous, item) => {
      const dayIndex = getDay(item.Date);
      const day = days[dayIndex];
      const friendlyStatus = friendlyStatusMap[item.Status];
      const currentCount = previous?.[day]?.[friendlyStatus] ?? 0;
      return {
        ...previous,
        [day]: {
          ...previous[day],
          [friendlyStatus]: currentCount + item.Count,
        },
      };
    },
    { ...emptyDays }
  );
};

export const formatBarChart = (data): Datasets => {
  const daysObject = getDaysObject(data);
  const sortedDays = labels.map((day) => ({ ...daysObject[day] }));
  return Object.values(friendlyStatuses).map((status) => {
    const data = sortedDays.map((item) => item?.[status] ?? 0);
    return {
      label: status,
      backgroundColor: statusColorsMap[status],
      barThickness: 12,
      data,
    };
  }, []);
};

export const formatSummaryTotals = (data) => {
  return data.reduce(
    (previous, item) => {
      const friendlyStatus = friendlyStatusMap[item.Status];
      const currentCount = previous?.[friendlyStatus] ?? 0;
      return {
        ...previous,
        [friendlyStatus]: currentCount + item.Count,
      };
    },
    {
      Delivered: 0,
      'Appointment Moved': 0,
      'Opted Out': 0,
      'Missing Contact Info': 0,
      Pending: 0,
      Sending: 0,
      Error: 0,
    }
  );
};
