import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/core';
import {
  PrimaryButton,
  SecondaryButton,
  Tabs,
  Text,
  useModalControl,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';

import { LocationFeature, FeatureStates } from '../../../models/location-feature.model';
import { LocationModel } from '../../../redux/actions/location';
import {
  MultiStateToggle,
  ToggleState,
} from '../../shared/multi-state-toggle/multi-state-toggle.component';
import { LegacyCustomizationPackage } from '../legacy-customization-package/legacy-customization-package.component';
import {
  addons,
  legacyPackages,
  Package,
  PackageNames,
  packages,
  SubPackage,
} from '../customization.constant';
import { Page } from '../../shared';
import { CustomizationPackage } from '../customization-package/customization-package.component';
import { VerticalEnum } from '../../../models/vertical-enum';
import { useWeaveVerifyPermittedACL } from '../../weave-verify/weave-verify-utils.hooks';
import {
  ModalData,
  WeaveVerifyCustomizationFlagModal,
} from '../../weave-verify/weave-verify-customization-flag-modal';

const LocationFeatureStates: ToggleState[] = [
  { key: FeatureStates.Active, label: 'Show', color: 'success' },
  { key: FeatureStates.Hide, label: 'Hide', color: 'error' },
  { key: FeatureStates.Promote, label: 'Promote', color: 'light' },
];

interface Props {
  loading: boolean;
  canEdit: boolean;
  features: LocationFeature[];
  location: LocationModel;
  verticalId: VerticalEnum;
  getLocationFeatures: (locationId: string) => void;
  setLocationFeatures: (locationId: string, features: LocationFeature[]) => void;
  updateFeature: (feature: LocationFeature, newState: ToggleState) => void;
  setFeatures: (features: LocationFeature[]) => void;
  showError: (message: string) => void;
}

interface State {
  selectedPackageName: string;
  selectedSubPkgIndices: number[];
  activeTabIndex: number;
  selectedNewPackages: Package[];
}

export const CustomizationPage = (props: Props) => {
  const hasWeaveVerifyACL = useWeaveVerifyPermittedACL();
  const modalData = useRef<ModalData>({} as ModalData);
  const { modalProps, openModal, closeModal } = useModalControl();
  const [customizationPageState, setCustomizationPageState] = useState<State>({
    selectedPackageName: '',
    selectedSubPkgIndices: [],
    activeTabIndex: 1,
    selectedNewPackages: [],
  });

  useEffect(() => {
    loadData();
  }, [props.location?.LocationID]);

  const loadData = () => {
    if (props.location?.LocationID) {
      props.getLocationFeatures(props.location.LocationID);
    }
  };

  const cancel = () => {
    loadData();
  };

  const getCurrentState = (feature: LocationFeature) => {
    return (
      LocationFeatureStates.find((state) => state.key === feature.state) ||
      LocationFeatureStates[0]
    );
  };

  const save = () => {
    if (props.canEdit) {
      props.setLocationFeatures(props.location.LocationID, props.features);
    }
  };

  const handleWeaveVerifyShowStatus = (
    featureToToggle: LocationFeature,
    newState: ToggleState
  ) => {
    const verifyFlags = ['Weave Verify Trial', 'Weave Verify'];
    const isSelfSign =
      props.location.Type === 'Regular' &&
      verifyFlags.includes(featureToToggle?.name) &&
      newState.label !== 'Hide';
    if (isSelfSign) {
      props.showError('Verify Users must self sign up');
    }
    return isSelfSign;
  };

  const handleWeaveVerifyHideStatus = (
    featureToToggle: LocationFeature,
    newState: ToggleState
  ) => {
    const isRegularType = props.location.Type === 'Regular';
    const isWeaveVerifyFeature =
      featureToToggle?.name === 'Weave Verify' ||
      featureToToggle?.name === 'Weave Verify Trial';

    const isHideLabel = newState.label !== 'Show' && newState.label !== 'Promote';

    const isHideWeaveVerifyCustomizationFlag =
      isRegularType && isWeaveVerifyFeature && isHideLabel;

    if (isHideWeaveVerifyCustomizationFlag && hasWeaveVerifyACL) {
      modalData.current = {
        featureToToggle,
        newState,
      };
      openModal();
    } else if (isHideWeaveVerifyCustomizationFlag) {
      props.showError(
        'Action not allowed! Only Level 2 Support and Multi office admin are allowed to perform the action'
      );
    }
    return isHideWeaveVerifyCustomizationFlag;
  };

  // Cycle to the next state in the list of possible states
  const toggleFeature = (featureToToggle: LocationFeature, newState: ToggleState) => {
    if (!props.canEdit) {
      return;
    }

    // For Regular accounts, users must self sign up with for weave verify and weave verify beta
    if (
      handleWeaveVerifyShowStatus(featureToToggle, newState) ||
      handleWeaveVerifyHideStatus(featureToToggle, newState)
    ) {
      return;
    }
    props.updateFeature(featureToToggle, newState);
  };

  const updateSelectedFeature = (
    featureToToggle: LocationFeature,
    newState: ToggleState
  ) => {
    props.updateFeature(featureToToggle, newState);
  };

  const setMultipleFeaturesByName = (
    features,
    featuresToSet: string[],
    featureState: FeatureStates
  ) => {
    featuresToSet.forEach((featureToSet) => {
      features.forEach((feature) => {
        if (feature.name === featureToSet) {
          feature.state = featureState;
        }
      });
    });

    return features;
  };

  const setAllFeaturesToHide = () => {
    return props.features.map((feature) => {
      return {
        ...feature,
        state: FeatureStates.Hide,
      };
    });
  };

  const handleLegacyPackageClicked = (pkg: Package, selectedSubPkgIndices: number[]) => {
    // Clean up any of the new packages that may have been previously selected.
    setCustomizationPageState({ ...customizationPageState, selectedNewPackages: [] });

    // Hide all features
    let features = setAllFeaturesToHide();

    // Set all of the package's base features as Active
    features = setMultipleFeaturesByName(
      features,
      pkg.baseFeatures,
      FeatureStates.Active
    );

    if (selectedSubPkgIndices.length && pkg.subPackages?.length) {
      // Separate any selected sub packages by package effect. This is so that packages
      // that subtract features can be applied after those that add features since
      // subtracting takes precedence over adding.
      const initial: { add: SubPackage[]; subtract: SubPackage[] } = {
        add: [],
        subtract: [],
      };
      const subPackagesToUse = selectedSubPkgIndices.reduce((acc, currIndex) => {
        const subPkg = pkg.subPackages![currIndex];

        if (subPkg.packageEffect === 'subtract') {
          return { add: [...acc.add], subtract: [...acc.subtract, subPkg] };
        } else {
          return { add: [...acc.add, subPkg], subtract: [...acc.subtract] };
        }
      }, initial);

      // First add any sub package features
      subPackagesToUse.add.forEach((subPackage) => {
        features = setMultipleFeaturesByName(
          features,
          subPackage.features,
          FeatureStates.Active
        );
      });

      // Then subtract ("Hide") any sub package features
      subPackagesToUse.subtract.forEach((subPackage) => {
        features = setMultipleFeaturesByName(
          features,
          subPackage.features,
          FeatureStates.Hide
        );
      });
    }

    props.setFeatures(features);

    setCustomizationPageState({
      ...customizationPageState,
      selectedPackageName: pkg.name,
      selectedSubPkgIndices,
    });
  };

  const handleNewPackageClicked = (pkg: Package) => {
    // clean up any legacy packages that may have been previously selected.
    setCustomizationPageState({
      ...customizationPageState,
      selectedSubPkgIndices: [],
      selectedPackageName: '',
    });

    // Handle special case for Optometry locations.
    if (
      props.verticalId === VerticalEnum.Optometry &&
      pkg.name === PackageNames.weaveEngage
    ) {
      // Weave Engage package should also include "Eyewear Ready" feature if this is an
      // Optometry location.
      pkg = { ...pkg, baseFeatures: [...pkg.baseFeatures, 'Eyewear Ready'] };
    }

    let selectedPackages = [...customizationPageState.selectedNewPackages];

    if (newPackageIsCurrentlySelected(pkg)) {
      selectedPackages = selectedPackages.filter(
        (currPackage) => currPackage.label !== pkg.label
      );
    } else {
      selectedPackages.push(pkg);
    }

    // Hide all features
    let features = setAllFeaturesToHide();

    // Set all appropriate features according to all selected packages.
    selectedPackages.forEach((currPackage) => {
      features = setMultipleFeaturesByName(
        features,
        currPackage.baseFeatures,
        FeatureStates.Active
      );
    });

    props.setFeatures(features);

    setCustomizationPageState({
      ...customizationPageState,
      selectedNewPackages: selectedPackages,
    });
  };

  const handleTabChanged = (activeTabIndex) => {
    setCustomizationPageState({ ...customizationPageState, activeTabIndex });
  };

  const newPackageIsCurrentlySelected = (pkg: Package) => {
    return customizationPageState.selectedNewPackages.some(
      (currPackage) => pkg.name === currPackage.name
    );
  };

  return (
    <Page
      title="Customizations"
      loading={props.loading}
      css={css`
        display: flex;
        flex-wrap: wrap;
      `}
      headerActions={
        props.canEdit && (
          <div
            css={css`
              display: flex;
            `}
          >
            <SecondaryButton onClick={cancel}>Cancel</SecondaryButton>
            <PrimaryButton
              css={css`
                margin-left: ${theme.spacing(2)};
              `}
              onClick={save}
            >
              Save
            </PrimaryButton>
          </div>
        )
      }
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <div>
          <h4
            css={css`
              margin-bottom: 10px;
            `}
          >
            Packages
          </h4>
          <Tabs
            initialTab={customizationPageState.activeTabIndex ? 'new' : 'label'}
            onChange={handleTabChanged}
          >
            <Tabs.Bar>
              <Tabs.Tab
                id="label"
                controls="label-panel"
                css={css`
                  width: 250px;
                `}
              >
                Label
              </Tabs.Tab>
              <Tabs.Tab
                id="new"
                controls="new-panel"
                css={css`
                  width: 250px;
                `}
              >
                New
              </Tabs.Tab>
            </Tabs.Bar>
            <Tabs.Panel id="label-panel" controller="label">
              <div
                css={css`
                  margin-top: 5%;
                `}
              >
                {legacyPackages.map((pkg) => (
                  <LegacyCustomizationPackage
                    key={pkg.name}
                    package={pkg}
                    isSelectedPackage={
                      pkg.name === customizationPageState.selectedPackageName
                    }
                    selectedSubPkgIndices={customizationPageState.selectedSubPkgIndices}
                    onPackageClicked={handleLegacyPackageClicked}
                  />
                ))}
              </div>
            </Tabs.Panel>
            <Tabs.Panel id="new-panel" controller="new">
              <div
                css={css`
                  margin-top: 5%;
                `}
              >
                <Text size="large" weight="bold">
                  Packages
                </Text>
                {packages.map((pkg) => (
                  <CustomizationPackage
                    key={pkg.name}
                    package={pkg}
                    isSelectedPackage={newPackageIsCurrentlySelected(pkg)}
                    onPackageClicked={handleNewPackageClicked}
                  />
                ))}

                <Text size="large" weight="bold">
                  Addons
                </Text>
                {addons.map((pkg) => (
                  <CustomizationPackage
                    key={pkg.name}
                    package={pkg}
                    isSelectedPackage={newPackageIsCurrentlySelected(pkg)}
                    onPackageClicked={handleNewPackageClicked}
                  />
                ))}
              </div>
            </Tabs.Panel>
          </Tabs>
        </div>
        <div>
          <h4>Features</h4>
          {props.features.map((feature: LocationFeature) => (
            <MultiStateToggle
              key={feature.id}
              states={LocationFeatureStates}
              currentState={getCurrentState(feature)}
              label={feature.name}
              onChange={(newState: ToggleState) => {
                toggleFeature(feature, newState);
              }}
            />
          ))}
        </div>
      </div>
      {hasWeaveVerifyACL && (
        <WeaveVerifyCustomizationFlagModal
          closeModal={closeModal}
          onAccept={updateSelectedFeature}
          modalData={modalData.current}
          {...modalProps}
        />
      )}
    </Page>
  );
};
