import { connect } from 'react-redux';

import { SyncAppCard } from './sync-app-card.component';
import {
  addToTerminalText,
  clearTerminalText,
  getSyncAppInfo,
  getMultipleSyncAppInfo,
} from '../../../redux/actions/sync-app';
import { selectHasWeaveAcl } from '../../../redux/actions/auth/auth.selectors';
import { CoreACLs } from '../../../redux/actions/auth/auth.types';

export const SyncAppCardContainer = connect(
  (state: any) => ({
    syncAppsInfo: state.syncApp.syncAppsInfo,
    managePermission: selectHasWeaveAcl(state, CoreACLs.SYNCAPPMANAGE),
    manageAdvancedPermission: selectHasWeaveAcl(state, CoreACLs.SYNCAPPMANAGEADVANCED),
    installAdvancedPermission: selectHasWeaveAcl(state, CoreACLs.SYNCAPPINSTALLADVANCED),
  }),
  {
    getSyncAppInfo,
    getMultipleSyncAppInfo,
    addToTerminalText,
    clearTerminalText,
  }
)(SyncAppCard);
