/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { CustomAxios } from '../../redux/axios';
import { useQueryWithSetter } from '../helpers/useQueryWithSetter';
import { stringify } from 'query-string';
import { UseQueryOptions } from 'react-query';
import { OmitByValue } from 'utility-types';
// These TS Utils can be used for any routes related to schedule API.
// Should another team wish to do something similar you would need to generate the TS using https://www.npmjs.com/package/openapi-typescript

type AnyObject = Record<string | number | symbol, any>;

/**
 * Returns the Response Body type for the given path's method
 */
type ProtoResponseBody<
  Paths,
  Path extends keyof Paths,
  Method extends keyof Paths[Path]
> = 'responses' extends keyof Paths[Path][Method]
  ? 200 extends keyof Paths[Path][Method]['responses']
    ? 'application/json' extends keyof Paths[Path][Method]['responses'][200]
      ? Paths[Path][Method]['responses'][200]['application/json']
      : never
    : never
  : never;

/**
 * Permissive version of ProtoResponseBody meaning you can pass in a possibly incorrect path and method.
 * Typescript will allow it, but you'll receive a 'never' type if there is no value for the given path > method
 */
type ProtoResponseBodyPermissive<
  Paths,
  Path extends string | number | symbol,
  Method extends string
> = Paths extends AnyObject
  ? Paths[Path] extends AnyObject
    ? Paths[Path][Method] extends AnyObject
      ? ProtoResponseBody<Paths, Path, Method>
      : never
    : never
  : never;

/**
 * Returns the Request Body type for the given Path > MEthod
 */
type ProtoRequestBody<Paths, Path extends keyof Paths, Method extends keyof Paths[Path]> =
  'requestBody' extends keyof Paths[Path][Method]
    ? 'application/json' extends keyof Paths[Path][Method]['requestBody']
      ? Paths[Path][Method]['requestBody']['application/json']
      : never
    : never;

/**
 * Permissive version of ProtoRequestBody meaning you can pass in a possibly incorrect path and method.
 * Typescript will allow it, but you'll receive a 'never' type if there is no value for the given path > method
 */
type ProtoRequestBodyPermissive<
  Paths,
  Path extends string | number | symbol,
  Method extends string
> = Paths extends AnyObject
  ? Paths[Path] extends AnyObject
    ? Paths[Path][Method] extends AnyObject
      ? ProtoRequestBody<Paths, Path, Method>
      : never
    : never
  : never;

/**
 * Returns the Request Query Params type for the given Path > Method
 */
type ProtoRequestQueryParams<
  Paths,
  Path extends keyof Paths,
  Method extends keyof Paths[Path]
> = 'parameters' extends keyof Paths[Path][Method]
  ? 'query' extends keyof Paths[Path][Method]['parameters']
    ? Paths[Path][Method]['parameters']['query']
    : {}
  : never;

/**
 * Returns the structure of the Route Params for a given Path Method
 */
type ProtoRequestRouteParams<
  Paths,
  Path extends keyof Paths,
  Method extends keyof Paths[Path]
> = 'parameters' extends keyof Paths[Path][Method]
  ? 'path' extends keyof Paths[Path][Method]['parameters']
    ? Paths[Path][Method]['parameters']['path']
    : {}
  : {};

/**
 * Returns an object with all possible axios methods, but nonexistent methods resolve to 'never'.
 * This is only really useful when used with OmitByValue<ProtoAxiosMethodsAll, never>
 * So that you end up with an object with only valid fns
 * */
type ProtoAxiosMethodsAll<Paths, Path extends keyof Paths> = {
  get: 'get' extends keyof Paths[Path]
    ? (
        body?: ProtoRequestBodyPermissive<Paths, Path, 'get'>,
        config?: AxiosRequestConfig
      ) => Promise<ProtoResponseBody<Paths, Path, 'get'>>
    : never;
  post: 'post' extends keyof Paths[Path]
    ? (
        body?: ProtoRequestBodyPermissive<Paths, Path, 'post'>,
        config?: AxiosRequestConfig
      ) => Promise<ProtoResponseBody<Paths, Path, 'post'>>
    : never;
  put: 'put' extends keyof Paths[Path]
    ? (
        body?: ProtoRequestBodyPermissive<Paths, Path, 'put'>,
        config?: AxiosRequestConfig
      ) => Promise<ProtoResponseBody<Paths, Path, 'put'>>
    : never;
  delete: 'delete' extends keyof Paths[Path]
    ? (
        body?: ProtoRequestBodyPermissive<Paths, Path, 'delete'>,
        config?: AxiosRequestConfig
      ) => Promise<ProtoResponseBody<Paths, Path, 'delete'>>
    : never;
  patch: 'patch' extends keyof Paths[Path]
    ? (
        body?: ProtoRequestBodyPermissive<Paths, Path, 'patch'>,
        config?: AxiosRequestConfig
      ) => Promise<ProtoResponseBody<Paths, Path, 'patch'>>
    : never;

  // I wanted to use this, but it doesn't get enough context from the method, it still considers Method a union of all methods
  // [Method in keyof Paths[Path]] :
  //   <Method extends keyof Paths[Path], Params extends ProtoRequestRouteParams<Paths, Path, Method> > (
  //     body?: ProtoRequestBody<Paths, Path, Method>,
  //     config?: AxiosRequestConfig
  //   ) => Promise<ProtoResponseBody<Paths, Path, Method>>
};

/**
 * This type gives you an object with each method on the endpoint
 * with its expected request body and route params
 */
type ProtoDestructuredAxiosMethods<Paths, Path extends keyof Paths> = OmitByValue<
  ProtoAxiosMethodsAll<Paths, Path>,
  never
>;

/**
 * Extracts route params (not query params) from url string into an array of param names
 */
type ExtractRouteParams<S extends string | number | symbol> = S extends number | symbol
  ? []
  : string extends S
  ? string[]
  : S extends ''
  ? []
  : S extends `${infer Left}{${infer ParamKey}}${infer Right}`
  ? [ParamKey, ...ExtractRouteParams<Right>]
  : [S];

/**
 * Converts the string array of param names into keys of an object with string values
 */
export type ProtoRouteParams<Path extends string | number | symbol> =
  | Record<ExtractRouteParams<Path>[number], string>
  | undefined;

/**
 * Utility Method for replacing the route param placeholders with their values in a url string
 */
export const replaceRouteParams = (
  path: string,
  params: Record<string | number, string> | undefined
) => {
  if (params) {
    for (const [key, value] of Object.entries(params)) {
      path = path.replace(`{${key}}`, value as string);
    }
  }
  return path;
};

/**
 * This fn is curried to help with typescript autocompletion
 * prefix is the portion of the api that comes before the path in the proto. like `support/v1`, or `support/gateway`
 * path is the url for the endpoint
 * method is the HTTP method (get, post, etc)
 */
const axiosMethodFn =
  <Paths extends AnyObject>(prefix?: string) =>
  <Path extends keyof Paths>(path: Path) =>
  <Method extends keyof Paths[Path]>(method: Method) =>
  <Path extends string>(routeParams?: ProtoRouteParams<Path> | undefined) =>
  (body?: ProtoRequestBody<Paths, Path, Method>, config?: AxiosRequestConfig) => {
    return CustomAxios.request({
      ...(body && { data: body }),
      url: `${prefix}${replaceRouteParams(path as string, routeParams ?? {})}`,
      method: method as AxiosRequestConfig['method'],
      ...config,
    }) as Promise<AxiosResponse<ProtoResponseBody<Paths, Path, Method>>>;
  };

/**
 * An optimistic implementation of all methods for each route (even methods that aren't defined on the route)
 * Then we type the response to only the methods that actually are on the type
 * //ts-ignores allow us to trick typescript into allowing us to specify the type at fn invocation type
 */
export const protosApiFactory =
  <Paths>(prefix?: string) =>
  <Path extends keyof Paths>(path: Path) =>
  <Method extends keyof Paths[Path]>(routeParams?: ProtoRouteParams<Path>) =>
    ({
      get: axiosMethodFn<Paths>(prefix)(path)('get' as Method)(routeParams),
      put: axiosMethodFn<Paths>(prefix)(path)('put' as Method)(routeParams),
      post: axiosMethodFn<Paths>(prefix)(path)('post' as Method)(routeParams),
      delete: axiosMethodFn<Paths>(prefix)(path)('delete' as Method)(routeParams),
      patch: axiosMethodFn<Paths>(prefix)(path)('patch' as Method)(routeParams),
      options: axiosMethodFn<Paths>(prefix)(path)('options' as Method)(routeParams),
      head: axiosMethodFn<Paths>(prefix)(path)('head' as Method)(routeParams),
    } as unknown as ProtoDestructuredAxiosMethods<Paths, Path>);

/**
 * This fn curried to help with typescript autocompletion
 * prefix is the portion of the api that comes before the path in the proto. like `support/v1`, or `support/gateway`
 * path is the url for the endpoint
 * method is the http method (get, post, etc)
 */
export const protosQueryFactory =
  <Paths extends AnyObject>(prefix?: string) =>
  <Path extends keyof Paths>(path: Path) =>
  (
    routeParams?: ProtoRequestRouteParams<Paths, Path, 'get'>,
    axiosConfig?: AxiosRequestConfig,
    useQueryOptions?: UseQueryOptions<
      AxiosResponse<ProtoResponseBody<Paths, Path, 'get'>>
    >
  ) => {
    const getter = axiosMethodFn<Paths>(prefix)(path)('get')(routeParams);
    const cacheKey = `${prefix}${replaceRouteParams(path as string, routeParams)}${
      axiosConfig?.params ? '?' + stringify(axiosConfig?.params) : ''
    }`;
    return useQueryWithSetter(
      cacheKey,
      () => getter(undefined, axiosConfig),
      useQueryOptions
    );
  };

/*******************************************************
 * Helper Method for when directly accessing schema types
 */

/**
 * Returns the Response Body for the 200 GET request
 */
export type ProtoResponseBodyGET<Paths, Path extends keyof Paths> =
  ProtoResponseBodyPermissive<Paths, Path, 'get'>;

/**
 * Returns the Response Body for the 200 POST request
 */
export type ProtoResponseBodyPOST<Paths, Path extends keyof Paths> =
  ProtoResponseBodyPermissive<Paths, Path, 'post'>;

/**
 * Returns the Response Body for the 200 POST request
 */
export type ProtoResponseBodyPUT<Paths, Path extends keyof Paths> =
  ProtoResponseBodyPermissive<Paths, Path, 'put'>;

/**
 * Returns the Response Body for the 200 POST request
 */
export type ProtoResponseBodyPATCH<Paths, Path extends keyof Paths> =
  ProtoResponseBodyPermissive<Paths, Path, 'patch'>;

/**
 * Returns the Response Body for the 200 POST request
 */
export type ProtoResponseBodyDELETE<Paths, Path extends keyof Paths> =
  ProtoResponseBodyPermissive<Paths, Path, 'delete'>;

/**
 * Returns the Response Body for the 200 GET request
 */
export type ProtoRequestBodyGET<Paths, Path extends keyof Paths> =
  ProtoRequestBodyPermissive<Paths, Path, 'get'>;

/**
 * Returns the Response Body for the 200 POST request
 */
export type ProtoRequestBodyPOST<Paths, Path extends keyof Paths> =
  ProtoRequestBodyPermissive<Paths, Path, 'post'>;

/**
 * Returns the Response Body for the 200 POST request
 */
export type ProtoRequestBodyPUT<Paths, Path extends keyof Paths> =
  ProtoRequestBodyPermissive<Paths, Path, 'put'>;

/**
 * Returns the Response Body for the 200 POST request
 */
export type ProtoRequestBodyPATCH<Paths, Path extends keyof Paths> =
  ProtoRequestBodyPermissive<Paths, Path, 'patch'>;

/**
 * Returns the Response Body for the 200 POST request
 */
export type ProtoRequestBodyDELETE<Paths, Path extends keyof Paths> =
  ProtoRequestBodyPermissive<Paths, Path, 'delete'>;
