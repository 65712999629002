import React from 'react';
import { useStatusMapping } from './status-mapping.hook';
import { Select, MenuItem } from '@material-ui/core';
import * as emotion from 'emotion';
import { css } from '@emotion/core';
import {
  HomeIcon,
  SelectAllIcon,
  CircleDeselectIconSmall,
  MissedCallIconSmall,
  CheckIcon,
  Text,
} from '@weave/design-system';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';
import { selectCurrentLocationParentId } from '../../../../redux/actions/location';

const itemContainer = (theme) => css`
  display: flex;
  margin: ${theme.spacing(3, 0)};
  p {
    margin: auto ${theme.spacing(1)};
    width: 100%;
  }
  & .iconSpan {
    width: 60px;
    height: 60px;
    padding: 0;
    margin: auto 0;
    svg {
      padding: 0;
      margin: auto;
    }
  }
`;

const selectStyle = emotion.css`
  label: selectStyle;
  width: 324px;
  height: 40px;
  padding-top: 8px;
  font-size: 1rem;
  input { font-size: 1rem; }
  div {
    width: 224px;
    height: 26px;
    div {
      height: 0;
      padding: 0;
    }

  }
`;

const mapIcons = {
  Attempted: <HomeIcon />,
  Canceled: <MissedCallIconSmall />,
  Completed: <CheckIcon />,
  Confirmed: <SelectAllIcon />,
  'In Office': <HomeIcon />,
  Unconfirmed: <CircleDeselectIconSmall />,
};

export const StatusMapping = ({ type, sourceID, onChange = noop }) => {
  const { mapUsage, externalValues, mapping, updateMapping } = useStatusMapping(
    type,
    sourceID
  );

  const parentID = useSelector(selectCurrentLocationParentId);
  const isWriteback = type === 'writeback';
  const listValues = isWriteback ? ['', ...externalValues] : externalValues;

  const onUpdate = (evt, key) => {
    onChange(evt, key);
    updateMapping(evt, key);
  };

  return (
    <div>
      {mapping &&
        Object.entries(mapping)?.map(([key, value]) => (
          <div key={key} css={itemContainer}>
            {!isWriteback && key in mapIcons && (
              <div className="iconSpan">{mapIcons[key]}</div>
            )}
            <Text>{key}</Text>
            <Select
              variant="outlined"
              id="status-mapping"
              name={`${key}-status-mapping-options`}
              multiple={!isWriteback}
              className={selectStyle}
              disabled={!!parentID}
              onChange={(evt) => onUpdate(evt, key)}
              value={value}
            >
              {listValues?.map((item, idx) => {
                const disabled =
                  (!value.includes(item) && mapUsage.includes(item)) || !!parentID;
                const display = item === '' ? 'None' : item;
                return (
                  <MenuItem key={idx + item} value={item} disabled={disabled}>
                    {display}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        ))}
    </div>
  );
};
