import { Store } from '../../store/store.model';
export const selectOnBoardingStatusModules = (store: Store) =>
  store?.onBoardingStatus?.modules ?? null;

export const selectOnBoardingStatusTasks = (store: Store) =>
  store?.onBoardingStatus?.tasks ?? null;

export const selectOnBoardingStatus = (store: Store) => store.onBoardingStatus ?? null;

export const selectOnBoardingStatusLoading = (store: Store) =>
  store.onBoardingStatus?.isLoading ?? false;

export const selectOnBoardingStatusError = (store: Store) =>
  store.onBoardingStatus?.error ?? null;

export const selectOnBoardingStatusDataSyncData = (store: Store) =>
  store.onBoardingStatus?.tasks?.find((ele) => ele.name === 'data-sync-app-v1') ?? null;
