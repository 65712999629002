import React, { useContext, useReducer } from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { IconButton, PreviewIcon, SpinningLoader, Text } from '@weave/design-system';
import { startCase } from 'lodash/fp';
import { ExtendedBillingInvoice } from '../billing.types';

import { billingApi } from '../billing.api';
import { formatNumber } from '../billing.helpers';
import { history } from '../../../../redux/store/history';
import {
  rowsStatesReducer,
  setRowErred,
  toggleRowExpanded,
  updateRowLoading,
  clearState,
} from './rows-states-reducer';
import { InvoicesDetailsContext } from '../billing-page';
import { useEffect } from 'react';
import { CarrotIconButton } from '../shared/carrot-icon-button';

type Props = {
  invoices: ExtendedBillingInvoice[];
  locationId: string;
};

export const InvoiceHistoryTable = ({ locationId, invoices }: Props) => {
  const { invoiceDetailsMap, addInvoiceDetails } = useContext(InvoicesDetailsContext);
  const [rowsStates, dispatch] = useReducer(rowsStatesReducer, {});

  useEffect(() => {
    if (locationId) {
      dispatch(clearState());
    }
  }, [locationId]);

  const fetchInvoiceDetails = async (index: number, invoiceId: string) => {
    // Don't fetch if already in fetch process.
    const isLoading = rowsStates[index]?.loading;
    if (isLoading) return;

    try {
      dispatch(updateRowLoading(index, true));

      const data = await billingApi.getInvoiceDetails(invoiceId);
      addInvoiceDetails(invoiceId, data);

      dispatch(updateRowLoading(index, false));
    } catch (error: any) {
      console.error(error);

      // Set the corresponding row's error state.
      dispatch(setRowErred(index));
    } finally {
      // Set the corresponding row's loading state to false.
      dispatch(updateRowLoading(index, false));
    }
  };

  const handleRowClicked = async (index: number, invoiceId: string) => {
    // Toggle the corresponding row's expanded state.
    dispatch(toggleRowExpanded(index));

    const dataDoesNotExist = !invoiceDetailsMap[invoiceId];
    if (dataDoesNotExist) {
      fetchInvoiceDetails(index, invoiceId);
    }
  };

  return (
    <table css={[sharedTableStyles, mainTableStyles]}>
      <thead>
        <tr>
          <th>Invoice Date</th>
          <th>Invoice Name</th>
          <th>Status</th>
          <th>Billed Amount</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {(invoices ?? []).map((invoice, index) => (
          <React.Fragment key={invoice.id + '-fragment'}>
            <tr
              key={invoice.id}
              onClick={() => handleRowClicked(index, invoice.id)}
              css={mainTableRow}
            >
              <td>{invoice.dueDate}</td>
              <td>{invoice.id}</td>
              <td>
                <p css={invoiceStatusStyles(invoice.status)}>
                  {startCase(invoice.status)}
                </p>
              </td>
              <td>{formatNumber(invoice.amount, invoice.currency)}</td>
              <td>
                <div css={invoiceActionsContainerStyles}>
                  <CarrotIconButton
                    showLabelOnHover={true}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleRowClicked(index, invoice.id);
                    }}
                    label={`${rowsStates[index]?.expanded ? 'Close Row' : 'Expand Row'}`}
                    isCarrotUpState={rowsStates[index]?.expanded}
                  />

                  <IconButton
                    onClick={() =>
                      history.push(
                        `/location/${locationId}/settings/billing/invoice/${invoice.id}`
                      )
                    }
                    label="View Invoice Details"
                    showLabelOnHover={true}
                  >
                    <PreviewIcon />
                  </IconButton>
                </div>
              </td>
            </tr>
            {rowsStates[index]?.expanded && (
              <tr key={`${invoice.id}-line-items`} css={nestedTableContainer}>
                <td colSpan={5}>
                  {rowsStates[index]?.loading ? (
                    <div css={spinnerContainer}>
                      <SpinningLoader size="small" />
                    </div>
                  ) : rowsStates[index]?.error ? (
                    <Text color="error" textAlign="center">
                      Error retrieving invoice details.
                    </Text>
                  ) : (
                    <table css={[sharedTableStyles, nestedTableStyles]}>
                      <thead>
                        <tr>
                          <th>Invoice Code</th>
                          <th>Description</th>
                          <th>Qty</th>
                          <th>Unit Price</th>
                          <th>Extended Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(invoiceDetailsMap[invoice.id]?.lineItems ?? []).map(
                          (lineItem) => {
                            const currency =
                              invoiceDetailsMap[invoice.id]?.invoice?.currency ?? 'USD';
                            return (
                              <tr key={lineItem.id}>
                                <td>{lineItem.code}</td>
                                <td>{lineItem.description}</td>
                                <td>{lineItem.quantity}</td>
                                <td>{formatNumber(+lineItem.unitPrice, currency)}</td>
                                <td>{formatNumber(+lineItem.amount, currency)}</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  )}
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

const sharedTableStyles = css`
  width: 100%;

  th,
  td {
    text-align: left;
    padding: ${theme.spacing(2, 0)};
  }
`;

const horizontalLineStyles = css`
  content: '';
  border-bottom: 1px solid ${theme.colors.gray300};
  position: absolute;
  display: block;
  transform: translateX(-100%);
  bottom: 0;
  width: calc(100% - 36px);
  left: calc(100% - 18px);
`;

const mainTableStyles = css`
  & thead {
    position: relative;
  }
  & > thead::after {
    ${horizontalLineStyles}
  }

  & > thead th {
    font-size: ${theme.fontSize(14)};
    color: ${theme.colors.gray500};
    font-weight: initial;
  }

  --billing-table-side-padding: ${theme.spacing(3)};
  & > thead th:first-child {
    padding-left: var(--billing-table-side-padding);
  }
  & > thead th:last-child {
    padding-right: var(--billing-table-side-padding);
    width: 10%;
  }

  & > thead td:last-child {
    width: 10%;
  }
`;

const mainTableRow = css`
  position: relative;
  cursor: pointer;
  &:hover {
    background: ${theme.colors.gray300};
  }

  td:first-child {
    padding-left: var(--billing-table-side-padding);
  }
  td:last-child {
    padding-right: var(--billing-table-side-padding);
  }

  &::after {
    ${horizontalLineStyles};
  }
`;

const invoiceStatusStyles = (status: ExtendedBillingInvoice['status']) => css`
  background: ${status === 'paid' ? '#EDF9F7' : '#fdf2ed'};
  color: ${status === 'paid' ? theme.colors.success : theme.colors.error};
  border-radius: ${theme.borderRadius.small};
  display: inline;
  padding: ${theme.spacing(0.5, 1)};
  font-weight: bold;
`;

const nestedTableContainer = css`
  background-color: #32373e;

  max-height: 0;
  @keyframes expandRow {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }

  transition: transform 0.15s ease-in-out;
  animation: expandRow 0.15s;
`;

const nestedTableStyles = css`
  thead {
    border-bottom: 1px solid #5f6771;
  }

  td {
    padding: ${theme.spacing(2)};
    color: ${theme.colors.white};
  }

  th {
    font-size: ${theme.fontSize(14)};
    color: #a1a8b0;
    font-weight: initial;
  }

  --billing-nested-table-side-padding: ${theme.spacing(6)};
  th:first-child,
  td:first-child {
    padding-left: var(--billing-nested-table-side-padding);
  }
  th:last-child,
  td:last-child {
    padding-right: var(--billing-nested-table-side-padding);
  }

  tbody tr {
    position: relative;
  }
  tbody tr:not(:last-child)::after {
    content: '';
    border-bottom: 1px dashed #5f6771;
    position: absolute;
    display: block;
    bottom: 0;
    transform: translateX(-100%);
    width: calc(100% - 100px);
    left: calc(100% - 50px);
  }
`;

const invoiceActionsContainerStyles = css`
  display: flex;
`;

const spinnerContainer = css`
  text-align: center;
`;
