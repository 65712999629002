import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getLocationFeatures } from '../../../redux/actions/customization/customization.action';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';

export const EnsureLocationFeature = ({ flag, children }) => {
  const { customizations, LocationID } = useSelector((state: any) => ({
    customizations: state.customization.features,
    LocationID: selectCurrentLocationId(state),
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    if (LocationID) {
      dispatch(getLocationFeatures(LocationID));
    }
  }, [LocationID]);
  return customizations.some(
    (customization) => customization.name === flag && customization.state !== 'hide'
  )
    ? children
    : null;
};
