import React, { useEffect, useState } from 'react';
import { css } from 'emotion';
import FeatureFlagModel from '../../settings/feature-flags-container/feature-flag.model';
import { DayOfWeekRemindersModel } from '../../../redux/actions/day-of-week-reminders/day-of-week-reminders.action';
import { Checkbox } from '@weave/platform-ui-components';
import { useDeepEffect } from '../hooks';

const listStyle = css`
  display: flex;
  & div {
    text-align: center;
    margin: 0 5px;
  }
`;

type DayOfWeekReminders = DayOfWeekRemindersModel | null;
interface Props {
  updateDayOfWeekReminders: (payload: DayOfWeekRemindersModel | {}) => void;
  getDayOfWeekReminders: () => void;
  dayOfWeekRemindersReset: () => void;
  loading: boolean;
  locationId: string;
  dayOfWeekReminderSettings: DayOfWeekReminders;
  features: FeatureFlagModel[];
}

const DaySetting = ({ day, isSet, click }) => {
  return (
    <span onClick={click}>
      {' '}
      <div>{day.slice(0, 3)}</div>{' '}
      <Checkbox
        className="horizontal"
        defaultChecked={isSet}
        onChange={function () {}}
        name={day}
        value={day}
      />{' '}
    </span>
  );
};

export const DayOfWeekSettings = ({
  locationId,
  features,
  dayOfWeekReminderSettings,
  dayOfWeekRemindersReset,
  getDayOfWeekReminders,
  updateDayOfWeekReminders,
}: Props) => {
  const [showSettings, setShowSettings] = useState<boolean>(false);

  useEffect(() => {
    dayOfWeekRemindersReset();
  }, [locationId]);

  useDeepEffect(() => {
    features.forEach((feature) => {
      if (feature.Name === 'day-of-week-settings' && feature.Value === true) {
        getDayOfWeekReminders();
        setShowSettings(feature.Value);
        return;
      }
    });
  }, [features]);

  const saveDOW = (day, isSet) => {
    const dayOfWeek = dayOfWeekReminderSettings;
    if (dayOfWeek) {
      dayOfWeek[day] = !isSet;
      updateDayOfWeekReminders(dayOfWeek);
    }
  };

  const dayOfWeekSettings = dayOfWeekReminderSettings || {};
  if (showSettings) {
    return (
      <div className={listStyle}>
        {Object.entries(dayOfWeekSettings).map(([day, isSet]) => (
          <DaySetting
            key={day}
            day={day}
            isSet={isSet}
            click={() => saveDOW(day, isSet)}
          />
        ))}
      </div>
    );
  }
  return null;
};
