import React, { useState, useEffect } from 'react';
import { DateTimePicker } from 'react-widgets';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { upperFirst } from 'lodash';
import { Heading, Text, PrimaryButton, TextField, useForm } from '@weave/design-system';
import { css } from 'emotion';
import ServerDataTableComponent from '../../../../shared/server-data-table/server-data-table.component';
import { dateFormat, dateTimeFormat } from '../../../../../helpers/utils';
import { Endpoint } from '../../../../../redux/actions/history/endpoints';
import {
  FaxHistoryActions,
  FaxHistoryState,
} from '../../../../../redux/actions/fax-history';

type Props = {
  locationId: string;
  faxHistory: FaxHistoryState;
  loading: boolean;
  fetchFaxHistory: (searchParams: any) => FaxHistoryActions;
  title: Endpoint;
};

const formatDate = (seconds: string | number): string => {
  if (!seconds) {
    return '';
  }

  return moment(seconds, ['X', 'YYYY-MM-DDTHH:mm:ssZ']).format(dateTimeFormat);
};

const columns = [
  {
    Header: 'Direction',
    accessor: 'Direction',
  },
  {
    Header: 'From DID',
    accessor: 'FromDID',
  },
  {
    Header: 'To DID',
    accessor: 'ToDID',
  },
  {
    Header: 'Viewed Count',
    accessor: 'ViewedCount',
  },
  {
    Header: 'Status',
    accessor: 'Status',
  },
  {
    Header: 'Error Description',
    accessor: 'ErrDesc',
  },
  {
    id: 'CreatedAt',
    Header: 'Created At',
    accessor: (data: any) => formatDate(data.CreatedAt),
  },
  {
    Header: 'Fax ID',
    accessor: 'ID',
  },
  {
    Header: 'Location ID',
    accessor: 'LocationID',
  },
  {
    Header: 'Media ID',
    accessor: 'MediaID',
  },
];

const formatTitle = (text: string): string => {
  try {
    return text.replace(/[_-]/g, ' ').split(' ').map(upperFirst).join(' ');
  } catch {
    return text;
  }
};

export const FaxHistory = ({
  locationId,
  faxHistory,
  loading,
  title,
  fetchFaxHistory,
}: Props) => {
  moment.locale('en');
  momentLocalizer();
  const [refreshing, setRefreshing] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'week').toDate());
  const [endDate, setEndDate] = useState(new Date());
  const defaultPageSize = 25;

  const { getFieldProps, reset, values } = useForm({
    fields: {
      searchFromText: { type: 'text', value: '' },
      searchToText: { type: 'text', value: '' },
    },
  });

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    loadData();
    if (refreshing) setRefreshing(false);
  }, [locationId, refreshing]);

  const loadData = (
    skip = 0,
    limit: number = defaultPageSize + 1,
    sortColumn = '',
    isSortDesc = false
  ) => {
    if (locationId) {
      const params = {
        start: moment(startDate).startOf('day').toISOString(),
        end: moment(endDate).endOf('day').toISOString(),
        fromdid: values.searchFromText!.trim(),
        todid: values.searchToText!.trim(),
      };
      fetchFaxHistory(params);
    }
  };

  const filter = (e) => {
    e.preventDefault();
    setRefreshing(true);
    loadData();
  };

  return (
    <>
      <Heading
        className={css`
          margin-top: 24px;
        `}
      >
        {formatTitle(title)}
      </Heading>
      <hr />
      <form
        className={css`
          margin: 24px 0;
          display: flex;
          align-items: center;
        `}
        onSubmit={filter}
      >
        <TextField
          className={css`
            display: inline-flex;
            width: 150px;
            margin-right: 24px !important;
          `}
          label="From"
          placeholder="From Did"
          {...getFieldProps('searchFromText')}
        />
        <TextField
          className={css`
            display: inline-flex;
            width: 150px;
          `}
          label="To"
          placeholder="To DID"
          {...getFieldProps('searchToText')}
        />
        <label
          className={css`
            margin: 0 8px;
          `}
        >
          <Text
            className={css`
              margin: 0;
            `}
          >
            Between
          </Text>
        </label>
        <DateTimePicker
          className={css`
            display: inline-block;
          `}
          format={dateFormat}
          time={false}
          value={startDate}
          onChange={(value: Date) => setStartDate(value)}
        />
        <label
          className={css`
            margin: 0 8px;
          `}
        >
          <Text
            className={css`
              margin: 0;
            `}
          >
            And
          </Text>
        </label>
        <DateTimePicker
          className={css`
            display: inline-block;
          `}
          format={dateFormat}
          time={false}
          value={endDate}
          onChange={(value: Date) => setEndDate(value)}
        />
        <PrimaryButton
          size="tiny"
          type="submit"
          className={css`
            display: inline-block !important;
            margin-left: 8px;
          `}
        >
          Filter
        </PrimaryButton>
      </form>
      <ServerDataTableComponent
        data={faxHistory}
        columns={columns}
        sortable={true}
        loading={loading}
        loadData={loadData}
        resetPage={refreshing}
      />
    </>
  );
};
