import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  handleSentimentTrainingClassifying,
  sentimentTrainingClassifying,
} from './get-sentiment-training-classifying/get-sentiment-training-classifying.action';

import {
  handleSentimentTrainingLeaderboard,
  sentimentTrainingLeaderboard,
} from './get-sentiment-training-leaderboard/get-sentiment-training-leaderboard.action';

import {
  handleSentimentTrainingRank,
  sentimentTrainingRank,
} from './get-sentiment-training-rank/get-sentiment-training-rank.action';

import {
  handlePostSentimentTrainingClassifying,
  postSentimentTrainingClassifying,
} from './post-sentiment-training-classifying/post-sentiment-training-classifying.action';

export const sentimentTrainingSaga = function* () {
  yield all([
    takeEvery(
      sentimentTrainingClassifying.toString(),
      handleSentimentTrainingClassifying
    ),
    takeEvery(
      sentimentTrainingLeaderboard.toString(),
      handleSentimentTrainingLeaderboard
    ),
    takeLatest(sentimentTrainingRank.toString(), handleSentimentTrainingRank),
    takeLatest(
      postSentimentTrainingClassifying.toString(),
      handlePostSentimentTrainingClassifying
    ),
  ]);
};
