import { all, call, put, takeEvery } from 'redux-saga/effects';
import startCase from 'lodash/startCase';
import { CustomAxios } from '../../axios';
import { createRequestSaga, showError, showSuccess } from '@weave/alert-system';
import { parseIfJson } from '../../../helpers/utils';
import {
  SyncAppActions,
  getSyncAppHealthSuccess,
  getSyncAppHealthFailure,
  createSyncAppSuccess,
  createSyncAppFailure,
  getSyncAppInfoSuccess,
  getSyncAppInfoFailure,
  getMultipleSyncAppInfoFailure,
  addToTerminalText,
  ResetDOVStatus,
  resetSyncAppDOVStatusSuccess,
} from './sync-app.actions';
import {
  handleGetSyncAppsDOVStatus,
  handleGetSyncAppReports,
} from './sync-app-reports.sagas';

const handleGetSyncAppHealth = function* () {
  try {
    const response = yield call(CustomAxios.get, 'support/v1/syncapp/health');
    const data = response?.data?.data ?? {};
    yield put(getSyncAppHealthSuccess(data));
    const syncApps = data?.SyncApps ?? [];
    if (syncApps.length > 0) {
      yield call(handleGetSyncAppReports, syncApps);
      yield call(handleGetSyncAppsDOVStatus);
    }
  } catch {
    yield put(getSyncAppHealthFailure());
    yield put(showError('Failed to get sync app health'));
  }
};

const handleCreateSyncApp = function* (action) {
  try {
    const response = yield call(CustomAxios.post, 'support/v1/syncapp/create', {});
    yield put(createSyncAppSuccess(response.data));
    yield put(showSuccess('Sync App Created Successfully'));

    const getResponse = yield call(CustomAxios.get, 'support/v1/syncapp/health');
    yield put(getSyncAppHealthSuccess(getResponse.data.data));
  } catch (error: any) {
    yield put(createSyncAppFailure(error));
    yield put(showError('Failed to create sync app'));
  }
};

export const getTerminalText = (method: string, data?: any) => {
  let message = '';

  switch (method) {
    case 'RPCAdapter.Ping':
      message = 'Ping result: Ping was successful';
      break;
    case 'RPCAdapter.SyncPatients':
      message = 'Patient sync complete';
      break;
    case 'RPCAdapter.SyncAppointments':
      message = 'Appointments sync complete';
      break;
    case 'RPCAdapter.Restart':
      message = 'Restart will take place over the next 5 minutes';
      break;
    case 'RPCAdapter.ReloadCloudSettings':
      message = `Result: ${data}`;
      break;
    case 'RPCAdapter.Update':
      message = data;
      break;
    case 'RPCAdapter.GetRecallTypes':
      message = `Recall Types: ${data ? Object.values(data).join(', ') : data}`;
      break;
    case 'RPCAdapter.AdapterInformation':
      if (data) {
        message = `Integration Info: \n ${JSON.stringify(data, null, 4)}`;
      }
      break;
    case 'RPCAdapter.GetSettingsFile':
      if (data) {
        message = `Settings File: \n ${JSON.stringify(data, null, 4)}`;
      }
      break;
    case 'RPCAdapter.CompatibleAdapters':
      if (data) {
        let stringOutput = '';
        if (data.length) {
          const isMulti = data.length > 1;
          const result: string[] = [];
          data.forEach((item) => {
            const trimmedItem = item.trim();
            const itemFirstCharacter = trimmedItem.substring(0, 1);
            if (itemFirstCharacter !== '-') {
              result.push(trimmedItem);
            } else if (itemFirstCharacter === '-' && !isMulti) {
              result.push(trimmedItem.substring(1, trimmedItem.length));
            }
          });
          stringOutput = result.join(', ');
        }
        message = `Compatible Integrations: ${stringOutput}`;
      }
      break;
    default: {
      const rpcMethodName = method.split('.');
      const messageTitle = startCase(rpcMethodName[rpcMethodName.length - 1]);
      if (data && typeof data === 'object') {
        message = `${messageTitle} Result: \n ${JSON.stringify(data, null, 4)}`;
      } else if (data && typeof data === 'string' && data.trim().length > 0) {
        message = `${messageTitle} Result: ${data.trim()}`;
      } else if (typeof data === 'boolean') {
        message = `${messageTitle} Result: ${data}`;
      } else {
        message = `${messageTitle} action complete`;
      }
    }
  }
  return message;
};

const handleGetSyncAppInfo = function* (action) {
  try {
    const { syncAppInfoKey, ...requestPayload } = action.payload;
    const result = yield call(
      CustomAxios.post,
      'support/v1/syncapp/executerpc',
      requestPayload
    );
    const response = result?.data?.data?.Response ?? '';
    if (response) {
      const decodedResponse = atob(response);
      const syncAppData = parseIfJson(decodedResponse);
      if (syncAppInfoKey) {
        yield put(
          getSyncAppInfoSuccess({
            sourceId: requestPayload.sourceId,
            data: syncAppData,
            syncAppInfoKey,
          })
        );
      } else {
        yield put(
          addToTerminalText({
            sourceId: requestPayload.sourceId,
            terminalText: getTerminalText(requestPayload.rpcMethod, syncAppData),
          })
        );
      }
    }
  } catch (error: any) {
    const { sourceId } = action.payload;
    yield put(getSyncAppInfoFailure({ error, sourceId }));
  }
};

const handleGetMultipleSyncAppInfo = function* (action) {
  try {
    // run parallel
    const responses = yield all(
      action.payload.map((payload) => {
        try {
          const { syncAppInfoKey, ...requestPayload } = payload;
          return call(CustomAxios.post, 'support/v1/syncapp/executerpc', requestPayload);
        } catch {
          return null;
        }
      })
    );

    yield all(
      responses.map((result, index) => {
        const { syncAppInfoKey, sourceId } = action.payload[index];
        const response = result?.data?.data?.Response ?? '';
        if (response) {
          const decodedResponse = atob(response);
          const syncAppData = parseIfJson(decodedResponse);
          return put(
            getSyncAppInfoSuccess({
              sourceId,
              data: syncAppData,
              syncAppInfoKey,
            })
          );
        }
        return null;
      })
    );
  } catch (error: any) {
    yield put(getMultipleSyncAppInfoFailure(error));
  }
};

export const handleResetDOVStatus = createRequestSaga<ResetDOVStatus>({
  key: SyncAppActions.RequestResetDOVStatus,
  displayErrors: true,
  onError: (error, { payload }) => {
    payload.onFinish();
    return 'Request has failed. Please try again.';
  },
  saga: function* ({ payload }) {
    const { sourceId, onFinish } = payload;
    yield call(
      CustomAxios.get,
      `support/v1/syncapp/dov/unverifysource?sourceID=${sourceId}`
    );
    yield put(resetSyncAppDOVStatusSuccess(sourceId));
    onFinish();
    yield put(showSuccess('Verification status successfully reset!'));
  },
});

export const syncAppSaga = function* () {
  yield all([
    yield takeEvery(SyncAppActions.GetHealth, handleGetSyncAppHealth),
    yield takeEvery(SyncAppActions.Create, handleCreateSyncApp),
    yield takeEvery(SyncAppActions.GetInfo, handleGetSyncAppInfo),
    yield takeEvery(SyncAppActions.GetMultipleInfo, handleGetMultipleSyncAppInfo),
    yield takeEvery(SyncAppActions.RequestResetDOVStatus, handleResetDOVStatus),
  ]);
};
