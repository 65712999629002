import React from 'react';
import { findIndex } from 'lodash';

import { css } from '@emotion/core';
import { Text } from '@weave/design-system';
import { WeaveTheme, theme } from '@weave/theme-original';

export interface ToggleState {
  color?: 'success' | 'error' | 'light';
  label: string;
  key: string;
}

interface Props {
  label: string;
  states: ToggleState[];
  currentState: ToggleState;
  onChange: (newState: ToggleState) => void;
}

export const MultiStateToggle = (props: Props) => {
  // Cycle to the next state in the list of possible states
  const toggleState = () => {
    let index = findIndex(props.states, (state) => state.key === props.currentState.key);
    index = index < props.states.length - 1 ? index + 1 : 0;
    props.onChange(props.states[index]);
  };

  const getTextColor = (theme: WeaveTheme) => {
    const stateColor = props.currentState.color;

    switch (stateColor) {
      case 'success':
        return css`
          color: ${theme.colors.success};
        `;
      case 'error':
        return css`
          color: ${theme.colors.error};
        `;
      default:
        return css`
          color: ${theme.colors.gray500};
        `;
    }
  };

  const getBackgroundColor = () => {
    const stateColor = props.currentState.color;

    switch (stateColor) {
      case 'success':
        return css`
          background-color: rgba(43, 213, 61, 0.1);
        `;
      case 'error':
        return css`
          background-color: rgba(255, 107, 89, 0.1);
        `;
      default:
        return css`
          background-color: rgba(141, 145, 155, 0.2);
        `;
    }
  };

  return (
    <div id={`feature-${props.label}`} css={cardStyles} onClick={toggleState}>
      <Text size="large" css={textStyles}>
        {props.label}
      </Text>
      <div css={[stateStyles, getBackgroundColor()]}>
        <Text
          size="large"
          weight="bold"
          css={(theme: WeaveTheme) => [textStyles, getTextColor(theme)]}
          color={props.currentState.color}
        >
          {props.currentState.label}
        </Text>
      </div>
    </div>
  );
};

const cardStyles = css`
  width: 509px;
  height: 46px;
  padding: 0;
  margin: 16px 4px;
  display: flex;
  border-radius: ${theme.borderRadius.small};
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0 4px 10px 0 rgba(49, 53, 110, 0.13);
`;
const stateStyles = css`
  width: 136px;
  background: rgba(43, 213, 61, 0.1);
  text-align: center;
`;
const textStyles = css`
  margin: 12px 16px;
`;
