import React, {
  KeyboardEventHandler,
  RefObject,
  useCallback,
  useState,
  useEffect,
  createRef,
  useRef,
} from 'react';
import { useControlledField, TextField, KeyNames } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { debounce } from 'lodash';
import { css } from '@emotion/core';

import { addressToString } from './utils';
import { Address } from './use-address-form';
import { CustomAxios, getResponseData } from '../../../redux/axios';
import { highlightLetters } from '../../../helpers/highlight-letters';

export type SmartyStreetsAddress = {
  city: string;
  entries: number;
  secondary: string;
  state: string;
  street_line: string;
  zipcode: string;
};

type Props = {
  onSelectAddress: (address: Address) => void;
  handleStreetChange: (streetLine: string) => void;
};

export const AddressAutoComplete = ({ onSelectAddress, handleStreetChange }: Props) => {
  const [suggestions, setSuggestions] = useState<SmartyStreetsAddress[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionsRefs, setSuggestionsRefs] = useState<RefObject<HTMLLIElement>[]>([]);
  const [value, setValue] = useState('');
  const [focusedSuggestion, setFocusedSuggestion] = useState<null | number>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const addressFieldProps = useControlledField({
    type: 'text',
    onChange: (value: string) => {
      setValue(value);
      handleStreetChange(value);
      getSuggestions(value);
      if (value) {
        setShowSuggestions(true);
      } else {
        setShowSuggestions(false);
      }
    },
    required: true,
    value: value,
  });

  useEffect(() => {
    setSuggestionsRefs(suggestions.map((_) => createRef<HTMLLIElement>()));
  }, [suggestions.length, showSuggestions]);

  useEffect(() => {
    if (showSuggestions) {
      focusSuggestion(0);
    }
  }, [showSuggestions]);

  const fetchSuggestions = async (value) => {
    if (!value) {
      return setSuggestions([]);
    }

    try {
      const response = await CustomAxios.get(
        `https://api.weaveconnect.com/address-regulation/autocomplete`,
        {
          params: {
            search: value,
          },
        }
      ).then<{ suggestions: SmartyStreetsAddress[] }>(getResponseData);
      setSuggestions(response.suggestions?.slice(0, 4) ?? []);
    } catch (error) {
      console.error('Failed to fetch Smarty Streets address suggestions: ', error);
      setSuggestions([]);
    }
  };

  const getSuggestions = useCallback(debounce(fetchSuggestions, 300), []);

  const focusSuggestion = (suggestionIndex: number | null) => {
    setFocusedSuggestion(suggestionIndex);
  };

  const getPreviousOptionIndex = (
    currentIndex: number | null,
    suggestions: Array<SmartyStreetsAddress>
  ) => {
    if (currentIndex === null) {
      return 0;
    }
    if (currentIndex === 0) {
      return suggestions.length - 1;
    }

    return currentIndex - 1;
  };

  const getNextOptionIndex = (
    currentIndex: number | null,
    suggestions: Array<SmartyStreetsAddress>
  ) => {
    if (currentIndex === null) {
      return 0;
    }
    if (currentIndex === suggestions.length - 1) {
      return 0;
    }

    return currentIndex + 1;
  };

  const handleAddressSelected = (address: SmartyStreetsAddress) => {
    setValue(address.street_line);
    handleStreetChange(address.street_line);
    setShowSuggestions(false);
    setSuggestions([]);
    onSelectAddress({
      address1: address.street_line,
      address2: address.secondary,
      city: address.city,
      state: address.state,
      postal: address.zipcode,
    });
  };

  const onSuggestionKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === KeyNames.Enter) {
      e.preventDefault();
    }
    if (showSuggestions) {
      if (e.key === KeyNames.Up) {
        e.preventDefault();
        focusSuggestion(getPreviousOptionIndex(focusedSuggestion, suggestions));
      }

      if (e.key === KeyNames.Tab && focusedSuggestion !== null) {
        handleAddressSelected(suggestions[focusedSuggestion]);
        setFocusedSuggestion(null);
      }

      if (e.key === KeyNames.Down) {
        e.preventDefault();
        focusSuggestion(getNextOptionIndex(focusedSuggestion, suggestions));
      }
      if (e.key === KeyNames.Enter && focusedSuggestion !== null) {
        e.preventDefault();
        handleAddressSelected(suggestions[focusedSuggestion]);
        setFocusedSuggestion(null);
      }
      if (e.key === KeyNames.Escape) {
        e.preventDefault();
        setShowSuggestions(false);
        setFocusedSuggestion(null);
      }
    }
  };

  return (
    <div ref={scrollRef} css={addressAutocompleteContainer}>
      <TextField
        {...addressFieldProps}
        aria-controls="addresses-list"
        aria-owns="addresses-list"
        aria-autocomplete="list"
        aria-expanded={showSuggestions}
        label="Street"
        name="Street"
        autoComplete="Don't show"
        onKeyDown={onSuggestionKeyDown}
        onFocus={(e) => {
          scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
          addressFieldProps.onFocus(e);
        }}
        onBlur={(e) => {
          setTimeout(() => {
            setShowSuggestions(false);
            setSuggestions([]);
            setFocusedSuggestion(null);
            addressFieldProps.onBlur(e);
          }, 200);
        }}
      />
      {showSuggestions ? (
        <ul id="addresses-list" role="listbox" css={dropDownStyles}>
          {suggestions.map((address, idx) => {
            const ref = suggestionsRefs[idx];
            return (
              <li
                ref={ref}
                aria-posinset={idx + 1}
                key={`${address.street_line} -- ${idx}`}
                css={dropDownItem(focusedSuggestion === idx)}
                onClick={() => handleAddressSelected(address)}
                dangerouslySetInnerHTML={{
                  __html: highlightLetters(addressToString(address), value),
                }}
              ></li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

const addressAutocompleteContainer = css`
  position: relative;
  margin: ${theme.spacing(2)} 0;
  text-align: left;
`;

const dropDownStyles = css`
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 0 0 ${theme.borderRadius.small} ${theme.borderRadius.small};
  z-index: ${theme.zIndex.highest};
  border-radius: ${theme.borderRadius.medium};
  box-shadow: ${theme.shadows.hover};
`;

const dropDownItem = (isFocused: boolean) => css`
  background-color: ${isFocused ? theme.colors.gray300 : theme.colors.white};
  cursor: pointer;
  padding: ${theme.spacing(1, 2)};
  :hover {
    background-color: ${theme.colors.gray300};
  }
`;
