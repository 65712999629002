import React from 'react';
import { css } from 'emotion';
import { Label, Radio, RadioGroup } from '@weave/platform-ui-components';

import { Template } from '../../../../redux/actions/notifications-settings/notification-settings.types';

const row = css`
  display: flex;
  flex-direction: row;
`;

const center = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0 !important;
  margin-left: 5px;
  position: relative;
  bottom: 5px;
`;

const RadioOption = ({ type, selectedType, toggleType }) => (
  <div>
    <Radio
      name={`include-${type}`}
      id="include"
      value="include"
      onChange={toggleType(type)}
      checked={type === selectedType}
    />
    <Label htmlFor="include">{type}</Label>
  </div>
);

interface Props {
  template: Template;
  updateSendTo: (template: Template) => void;
}

export class EditReminderSendToType extends React.Component<Props> {
  toggleType = (type) => () => {
    const template = { ...this.props.template };
    template.type = type;
    this.props.updateSendTo(template);
  };
  render() {
    return (
      <React.Fragment>
        <div className={row}>
          <span> Send to : </span>
          <RadioGroup horizontal className={center}>
            {['confirmed', 'unconfirmed', 'all'].map((type) => (
              <RadioOption
                key={`send-to-type-${type}`}
                type={type}
                selectedType={this.props.template.type}
                toggleType={this.toggleType}
              />
            ))}
          </RadioGroup>
        </div>
      </React.Fragment>
    );
  }
}
