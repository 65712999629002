import React, { useEffect, useState } from 'react';

import { EnsureLocationFeature } from '../../shared/ensure-location-feature/ensure-location-feature.component';
import { WeaveTheme } from '@weave/theme-original';
import { NakedButton, Text } from '@weave/design-system';
import { AppointmentMapping } from './status-mapping-components/appointment-mapping';
import { WritebackMapping } from './status-mapping-components/writeback-mapping';
import { OrderMapping } from './status-mapping-components/order-mapping';
import { MarkAsRead } from './status-mapping-components/mark-as-read';
import { Page } from '../../shared/page/page.component';
import { css } from '@emotion/core';
import { useResource } from '../../shared/hooks/use-resource.hook';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';

const wrapperStyle = css`
  margin: 10px;
`;

const sourceButton = (theme: WeaveTheme, isSelected) => css`
  text-align: left;
  ${isSelected
    ? `background-color: ${theme.colors.weaveBlue};`
    : `background-color: ${theme.colors.white};`}
  outline: none;
  border-radius: 4px;
  border: 2px solid ${theme.colors.weaveBlue};
  margin: 8px;
  padding: 8px;
  p {
    ${isSelected ? `color: ${theme.colors.white};` : `color: ${theme.colors.weaveBlue};`}
    margin: 0;
    padding: 0;
  }
`;

type SyncAppType = {
  SourceID: string;
  PracticeManagementSystem: string;
  SourceName: any;
};

type SyncAppsDataType = {
  SyncApps: SyncAppType[];
};

export const StatusMappingPage = () => {
  const locationID = useSelector(selectCurrentLocationId);
  const [sourceIDs, setSourceIDs] = useState<SyncAppType[]>([]);
  const [sourceID, setSourceID] = useState('');
  const { data } = useResource<SyncAppsDataType>({ url: `support/v1/syncapp/health` });

  useEffect(() => {
    if (data?.SyncApps?.length) {
      const findLocationIndex = data.SyncApps.findIndex(
        (item) => item.SourceID === locationID
      );
      const locationIndex = findLocationIndex === -1 ? 0 : findLocationIndex;
      setSourceID(data.SyncApps[locationIndex].SourceID);
      const sources = data.SyncApps.sort((a, b) =>
        ('' + a.SourceName).localeCompare(b.SourceName)
      );
      setSourceIDs(sources);
    }
  }, [data?.SyncApps]);

  return (
    <Page title="Status Mapping">
      <div
        css={css`
          display: flex;
        `}
      >
        {sourceIDs.map((item) => {
          const isSelected = sourceID === item.SourceID;
          return (
            <NakedButton
              css={(theme) => sourceButton(theme, isSelected)}
              onClick={() => setSourceID(item.SourceID)}
              key={item.SourceID}
            >
              <Text>{item?.SourceName}</Text>
              <Text>{item?.PracticeManagementSystem}</Text>
            </NakedButton>
          );
        })}
      </div>
      <div css={wrapperStyle}>
        <WritebackMapping sourceID={sourceID} />
        <MarkAsRead />
        <AppointmentMapping sourceID={sourceID} />
        <EnsureLocationFeature flag="Eyewear Ready">
          <OrderMapping sourceID={sourceID} />
        </EnsureLocationFeature>
      </div>
    </Page>
  );
};
