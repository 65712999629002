import React from 'react';
import { FormRow, TextField, DropdownField, PostalCodeField } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { stateOptions } from './state-codes';
import { useAddressForm } from './use-address-form';
import { css } from '@emotion/core';

type Props = {
  values: ReturnType<typeof useAddressForm>['addressFormProps']['values'];
  getFieldProps: ReturnType<typeof useAddressForm>['addressFormProps']['getFieldProps'];
};

export const AddressForm = ({ getFieldProps }: Props) => {
  return (
    <>
      <FormRow css={formRowStyling}>
        <TextField {...getFieldProps('city')} label="City" />
      </FormRow>
      <FormRow>
        <DropdownField {...getFieldProps('state')} label="State">
          {stateOptions['US'].map((state) => (
            <DropdownField.Option key={state.value} value={state.value}>
              {state.label}
            </DropdownField.Option>
          ))}
        </DropdownField>
        <PostalCodeField {...getFieldProps('postal')} label="Postal" />
      </FormRow>
    </>
  );
};

const formRowStyling = css`
  margin: ${theme.spacing(2)} 0;
`;
