import axios, {
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  AxiosInstance,
} from 'axios';
import jwtDecode from 'jwt-decode';
import { getLocationUUID } from '../../helpers/utils';
import { REACT_APP_API_URL } from '../../config/app';
import { AuthStorage, DecodedAccessToken, JwtPayload } from '../actions/auth/auth.types';
import { handleLocalStorage } from '../actions/auth/auth.helpers';

type CustomAxiosInstance = AxiosInstance & {
  __wvaTokens?: {
    weave_token: JwtPayload;
    encoded_weave_token: string;
    okta_access_token?: DecodedAccessToken;
  };
};

export const axiosInstance: CustomAxiosInstance = axios.create({
  baseURL: REACT_APP_API_URL,
});
export class CustomAxios {
  static setUpAuth = (
    weaveToken: string,
    oktaAccessToken?: string | DecodedAccessToken
  ) => {
    axiosInstance.__wvaTokens = {
      okta_access_token:
        oktaAccessToken && typeof oktaAccessToken === 'string'
          ? jwtDecode(oktaAccessToken)
          : oktaAccessToken,
      weave_token: jwtDecode(weaveToken),
      encoded_weave_token: weaveToken,
    };
    if (oktaAccessToken && typeof oktaAccessToken === 'string') {
      handleLocalStorage.create(AuthStorage.okta_access_token, oktaAccessToken);
    }
    CustomAxios.setHeader('Authorization', `Bearer ${weaveToken}`);
    handleLocalStorage.create(AuthStorage.weave_token, weaveToken);
    handleLocalStorage.delete(AuthStorage.redirect_path);
  };

  /* If screen was refreshed */
  static resetAuth = (
    weaveToken: string,
    oktaAccessToken?: DecodedAccessToken,
    decodedWeaveToken?: JwtPayload
  ) => {
    axiosInstance.__wvaTokens = {
      okta_access_token: oktaAccessToken,
      weave_token: decodedWeaveToken || jwtDecode(weaveToken),
      encoded_weave_token: weaveToken,
    };
    CustomAxios.setHeader('Authorization', `Bearer ${weaveToken}`);
    if (!decodedWeaveToken) {
      handleLocalStorage.delete(AuthStorage.redirect_path);
    }
  };

  static clearAuth = () => {
    delete axiosInstance.__wvaTokens;
    CustomAxios.deleteHeader('Authorization');
  };

  static getBaseUrl = () => axiosInstance.defaults.baseURL;

  static setHeader = (key: string, value?: string) => {
    axiosInstance.defaults.headers.common[key] = value;
  };

  static headerExists = (key: string) => {
    return !!axiosInstance.defaults.headers.common[key];
  };

  static getHeaders = () => {
    return axiosInstance.defaults.headers;
  };

  static deleteHeader = (key: string) => {
    delete axiosInstance.defaults.headers.common[key];
  };

  static setLocationHeader = () => {
    const locationId = getLocationUUID(window.location.pathname);
    axiosInstance.defaults.headers.common['Location-Id'] = locationId;
  };

  static request = <T = any>(config: AxiosRequestConfig): AxiosPromise<T> => {
    CustomAxios.setLocationHeader();
    return axiosInstance.request<T>(config);
  };
  static get = <T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> => {
    CustomAxios.setLocationHeader();
    return axiosInstance.get<T>(url, config);
  };
  static delete = <T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): AxiosPromise<T> => {
    CustomAxios.setLocationHeader();
    return axiosInstance.delete<T>(url, config);
  };
  static head = <T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> => {
    CustomAxios.setLocationHeader();
    return axiosInstance.head<T>(url, config);
  };
  static post = <T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): AxiosPromise<T> => {
    CustomAxios.setLocationHeader();
    return axiosInstance.post<T>(url, data, config);
  };
  static put = <T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): AxiosPromise<T> => {
    CustomAxios.setLocationHeader();
    return axiosInstance.put<T>(url, data, config);
  };
  static patch = <T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): AxiosPromise<T> => {
    CustomAxios.setLocationHeader();
    return axiosInstance.patch<T>(url, data, config);
  };
}

export const getResponseData = <T = any>({ data }: AxiosResponse): T =>
  data.hasOwnProperty('data') ? data.data : data;

export const makeResponseData = (data) => ({ data: { data } });

export const getErrorMessage = (error: AxiosError) =>
  error.response?.data?.error?.message ??
  error.response?.data?.error ??
  error.message ??
  error;
