import { CustomAxios, getResponseData } from '../../redux/axios';
import { IntakeForm } from './onboarding.types';

export const getOnboardingIntakeForm = () =>
  CustomAxios.get(`portal/v1/onboarding/intake-form`).then(getResponseData);

export const updateOnboardingIntakeForm = (onboarding: { data: IntakeForm }) =>
  CustomAxios.put(`portal/v1/onboarding/intake-form`, onboarding).then(getResponseData);

export const uploadPhoneBill = (data: FormData) =>
  CustomAxios.post('portal/v1/onboarding/phone-bill-images', data).then(getResponseData);

export const getVerifiedAddress = (query: string) =>
  CustomAxios.get(`portal/v1/onboarding/intake-form/address/verify?${query}`).then(
    getResponseData
  );

export const getOperationsStatus = () =>
  CustomAxios.get(`/portal/v1/onboarding/intake-form/operation-status`).then(
    getResponseData
  );

export const onboardingIntakeFormApi = {
  getOnboardingIntakeForm,
  updateOnboardingIntakeForm,
  uploadPhoneBill,
  getVerifiedAddress,
  getOperationsStatus,
};
