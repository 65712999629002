import React, { FC } from 'react';
import { css } from 'emotion';
import { Text } from '@weave/design-system';

interface Props {
  name: string;
  value: any;
}

export const DeviceRowReadOnly: FC<Props> = ({ name, value }) => {
  return (
    <li
      className={css`
        margin-top: 24px;
      `}
    >
      <Text
        size="medium"
        weight="bold"
        className={css`
          margin-bottom: 8px;
        `}
      >
        {name}
      </Text>
      <Text size="medium">{typeof value === 'boolean' ? `${value}` : value ?? '-'}</Text>
    </li>
  );
};
