import { Reducer } from 'redux';
import { createSelector } from 'reselect';
import { Store } from '../../../store/store.model';
import {
  clearLocationAccessRules,
  getLocationAccessRules,
  selectLocationAccessRules,
} from '../location-access/location-access.action';
import {
  clearLocationChildren,
  locationChildrenSearch,
  selectLocationChildren,
} from '../location-search';
import {
  selectLocations,
  LocationSummary,
  LocationModel,
  DataCenters,
  addLocations,
} from '../locations';
import * as api from '../locations/api';
import { history } from '../../../store/history';
import {
  getLocationUUID,
  hasLocationUUID,
  replaceLocationUUID,
} from '../../../../helpers/utils';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { addLocationToHistory } from '../location-history/location-history.action';
import { LocationHistoryModel } from '../../../../models/location-history.model';
import { clearGreetings } from '../../voice-mailboxes/voice-mailboxes.action';
import { getLocationFeatures } from '../../customization/customization.action';
import { showError } from '@weave/alert-system';
import { OTHER } from '../../../../constants/paths';
import { getFeatureFlags } from '../../feature-flags';
import { clearCachedActions } from '../../cached-actions';
import { CustomAxios } from '../../../axios';
import { AuthStorage } from '../../auth/auth.types';
import { handleLocalStorage } from '../../auth/auth.helpers';

export enum CurrentLocationActionTypes {
  SetCurrentLocation = 'SET_CURRENT_LOCATION',
  UnsetCurrentLocation = 'UNSET_CURRENT_LOCATION',
  RouteChange = 'ROUTE_CHANGE',
  CurrentLocationChanged = 'CURRENT_LOCATION_CHANGED',
}

export type SetCurrentLocationAction = {
  type: CurrentLocationActionTypes.SetCurrentLocation;
  payload: string;
};

export type UnsetCurrentLocationAction = {
  type: CurrentLocationActionTypes.UnsetCurrentLocation;
};

export type RouteChangeAction = {
  type: CurrentLocationActionTypes.RouteChange;
  payload: string;
};

export type CurrentLocationChanged = {
  type: CurrentLocationActionTypes.CurrentLocationChanged;
  payload: string;
};

export type CurrentLocationActions =
  | SetCurrentLocationAction
  | UnsetCurrentLocationAction
  | RouteChangeAction
  | CurrentLocationChanged;

export type CurrentLocationState = string | null;

export const setCurrentLocationId = (locationId: string): SetCurrentLocationAction => ({
  type: CurrentLocationActionTypes.SetCurrentLocation,
  payload: locationId,
});

export const unsetCurrentLocationId = (): UnsetCurrentLocationAction => ({
  type: CurrentLocationActionTypes.UnsetCurrentLocation,
});

export const routeChange = (pathname: string): RouteChangeAction => ({
  type: CurrentLocationActionTypes.RouteChange,
  payload: pathname,
});

export const currentLocationChanged = (locationId: string): CurrentLocationChanged => ({
  type: CurrentLocationActionTypes.CurrentLocationChanged,
  payload: locationId,
});

export const currentLocationReducer: Reducer<
  CurrentLocationState,
  CurrentLocationActions
> = (state = '', action) => {
  switch (action.type) {
    case CurrentLocationActionTypes.UnsetCurrentLocation:
      return null;
    case CurrentLocationActionTypes.CurrentLocationChanged:
      //we wait until the location has been fully loaded before
      //setting the new location id in state
      return action.payload;
    default:
      return state;
  }
};

// Wrapper around history.push because 'call' requires a return value
const pushHistory = (path: string) => {
  history.push(path);
  return true;
};

const updateLocationIdInRoute = (locationId: string, queryParams: string) => {
  if (!hasLocationUUID(history.location.pathname)) {
    pushHistory(`/location/${locationId}/dashboard`);
  } else {
    const path = replaceLocationUUID(history.location.pathname + queryParams, locationId);
    pushHistory(path);
  }

  return true;
};

export const setCurrentLocationIdSaga = function* (action: SetCurrentLocationAction) {
  try {
    const locationId = action.payload;
    const router = yield select((state: Store) => state.router);
    const headers = yield CustomAxios.getHeaders();
    if (!headers.common.Authorization) {
      const localToken = handleLocalStorage.get(AuthStorage.weave_token);
      CustomAxios.setHeader('Authorization', `Bearer ${localToken}`);
    }

    const location: LocationModel = yield call(api.getLocation, locationId);
    const summary: LocationSummary = yield call(api.getLocationSummary, locationId);
    location.PhoneSystemTimezone = summary.Timezone;
    location.Phone = summary.MainDID;
    location.DataCenter = summary.DataCenter || DataCenters.None;

    // NOTE: location & locationid specific handlers need to happen before any other
    yield put(addLocationToHistory(new LocationHistoryModel(location)));
    if (router) {
      yield call(updateLocationIdInRoute, locationId, router.location.search);
    }

    yield put(clearGreetings());
    yield put(clearLocationAccessRules());
    yield put(clearLocationChildren());
    yield all([
      //The feature flags need to be retrieved here, after location is placed in the route
      put(getFeatureFlags(locationId)),
      put(getLocationFeatures(locationId)),
      put(getLocationAccessRules(locationId)),
      put(locationChildrenSearch(locationId)),
    ]);

    yield put(addLocations([location]));
    yield put(currentLocationChanged(locationId));
  } catch (error: any) {
    yield put(showError(`Error selecting location: ${error.message}`));
  }
};

// Ensure browser URL changes to locationId are reflected in redux
export const routeChangeSaga = function* (action: RouteChangeAction) {
  const currentLocation: ReturnType<typeof selectCurrentLocation> = yield select(
    selectCurrentLocation
  );
  const pathname = action.payload;
  const urlLocationId = getLocationUUID(pathname);
  const shouldUnsetLocationId =
    [OTHER.userConfig, OTHER.DesktopReleases].includes(pathname) || !urlLocationId;
  const shouldChangeLocationId =
    urlLocationId && urlLocationId !== currentLocation?.LocationID;

  if (shouldUnsetLocationId) {
    yield put(unsetCurrentLocationId());
  } else if (shouldChangeLocationId) {
    yield put(setCurrentLocationId(urlLocationId));
  }
};

export const unsetCurrentLocationSaga = function* (action: UnsetCurrentLocationAction) {
  // Todo: eventually we may not want to remove cached actions when logging out
  // Will revisit this once our cached actions season a little longer
  yield all([
    yield put(clearCachedActions()),
    yield put(clearGreetings()),
    yield put(clearLocationAccessRules()),
    yield put(clearLocationChildren()),
  ]);
};

export const currentLocationSaga = function* () {
  yield all([
    yield takeLatest(
      CurrentLocationActionTypes.SetCurrentLocation,
      setCurrentLocationIdSaga
    ),
    yield takeLatest(CurrentLocationActionTypes.RouteChange, routeChangeSaga),
    yield takeLatest(
      CurrentLocationActionTypes.UnsetCurrentLocation,
      unsetCurrentLocationSaga
    ),
  ]);
};

export const selectCurrentLocationId = (state: Store) => state.currentLocationId ?? '';
// export const selectCurrentUser = (state: Store) => state.userSearch ?? '';

export const selectCurrentLocation = createSelector(
  selectLocations,
  selectCurrentLocationId,
  (locations, currLocationId) => locations[currLocationId] ?? null
);

export const selectCurrentLocationDataCenter = createSelector(
  selectCurrentLocation,
  (location) => location?.DataCenter ?? ''
);

export const selectCurrentLocationSlug = createSelector(
  selectCurrentLocation,
  (location) => location?.Slug ?? ''
);

export const selectCurrentLocationTimeZone = createSelector(
  selectCurrentLocation,
  (location) => location?.PhoneSystemTimezone ?? ''
);

export const selectCurrentLocationVerticalID = createSelector(
  selectCurrentLocation,
  (location) => location?.VerticalID
);

export const selectCurrentLocationParentId = createSelector(
  selectCurrentLocation,
  (location) => location?.ParentID ?? ''
);

export const selectIsCurrentLocationActive = createSelector(
  selectCurrentLocation,
  (location) => location?.Active ?? true
);

export const selectCurrentLocationBannerMessages = createSelector(
  selectCurrentLocation,
  (location) => location?.BannerMessages
);

export const selectIsMultiLocationBeta = createSelector(
  selectCurrentLocationParentId,
  selectLocationAccessRules,
  selectLocationChildren,
  (parentId, accessRules, children) =>
    !!(parentId || accessRules?.length || children?.length)
);
