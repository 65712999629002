import { connect } from 'react-redux';

import { LocationSidebar } from './location-sidebar/location-sidebar.component';
import {
  selectCurrentLocation,
  selectCurrentLocationParentId,
  setCurrentLocationId as setLocation,
} from '../../redux/actions/location/current-location';
import { selectLocationChildren } from '../../redux/actions/location/location-search';
import { selectHasWeaveAcl } from '../../redux/actions/auth/auth.selectors';
import { Store } from '../../redux/store/store.model';
import { selectSidebarSyncApps } from '../../redux/actions/sync-app';
import { CoreACLs } from '../../redux/actions/auth/auth.types';
import { selectFeatureFlags } from 'redux/actions/feature-flags';
import FeatureFlagModel from 'components/settings/feature-flags-container/feature-flag.model';

const departmentsFilter = (ff: FeatureFlagModel) => ff.Name.indexOf('departments') >= 0;

export const LocationSidebarContainer = connect(
  (state: Store) => ({
    currentLocation: selectCurrentLocation(state),
    childrenAccounts: selectLocationChildren(state),
    isMultiOfficeBeta: !!(
      selectCurrentLocationParentId(state) ||
      state.locationAccess?.locationAccessRules?.length ||
      state.locationSearch?.locationChildren?.length
    ),
    allowParentageEdit: selectHasWeaveAcl(state, CoreACLs.WRITEMULTILOCATIONCONFIGS),
    hasDatasources: selectSidebarSyncApps(state).length > 0,
    departmentsEnabled:
      selectFeatureFlags(state)
        .filter(departmentsFilter)
        .find((ff) => ff.Name === 'departments')?.Value || false,
  }),
  {
    setLocation,
  }
)(LocationSidebar);
