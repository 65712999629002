import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  ConfirmationModal,
  IconButton,
  InfoRoundIconSmall,
  PrimaryButton,
  Text,
  useModalControl,
  useTooltip,
} from '@weave/design-system';

import {
  healthTooltipStyle,
  iconPointerStyle,
  labelFlexRight,
  labelHeading,
  labelInfoDiv,
  labelReason,
  mTopSpacing,
  tooltipLabelInfo,
} from '../../location-sidebar.styles';
import { SyncAppInterface } from '../../../sync-app/sync-app.types';
import { resetSyncAppDOVStatus } from '../../../../redux/actions/sync-app';
import { selectHasWeaveAcl } from '../../../../redux/actions/auth/auth.selectors';
import { Store } from '../../../../redux/store/store.model';
import { getTextColor } from '../sidebar-sync-app.component';
import { CoreACLs } from '../../../../redux/actions/auth/auth.types';

export enum StatusTypeText {
  VERIFIED = 'Verified',
  NOT_VERIFIED = 'Not Verified',
  LOCKED = 'Locked',
}

export const SyncAppHealth = ({
  syncApp,
  dovStatus,
}: {
  syncApp: SyncAppInterface;
  dovStatus: string;
}) => {
  const [sourceIdCopied, setSourceIdCopied] = useState<boolean>(false);
  const {
    SourceID: sourceId,
    SyncAppHealth,
    PracticeManagementSystem,
    SourceName,
    UpdatedReasons,
  } = syncApp;

  const resetDOVPermission = useSelector((state: Store) =>
    selectHasWeaveAcl(state, CoreACLs.SYNCAPPMANAGEADVANCED)
  );
  const dispatch = useDispatch();
  const {
    hide: hideResetTooltip,
    tooltipProps: resetTooltipProps,
    triggerProps: resetTriggerProps,
    Tooltip,
  } = useTooltip({ placement: 'right' });
  const {
    tooltipProps: healthTooltipProps,
    triggerProps: healthTriggerProps,
    Tooltip: HealthTooltip,
  } = useTooltip({ placement: 'right' });

  const { modalProps: resetModalProps, triggerProps: resetModalTriggerProps } =
    useModalControl();

  const showConfirmation = () => {
    resetModalTriggerProps.onClick();
    hideResetTooltip();
  };

  const handleReset = () => {
    dispatch(resetSyncAppDOVStatus({ sourceId, onFinish: resetModalProps.onClose }));
  };

  return (
    <>
      <div css={(theme: WeaveTheme) => labelInfoDiv(theme, 0)}>
        <Text as="span" size="medium" color="light">
          Sync Health
        </Text>
        <div css={labelFlexRight}>
          <Text as="span" size="medium" color={getTextColor(SyncAppHealth)}>
            {SyncAppHealth}
          </Text>
          {UpdatedReasons && (
            <>
              <IconButton label="Health Information" size="small" {...healthTriggerProps}>
                <InfoRoundIconSmall />
              </IconButton>
              <HealthTooltip
                {...healthTooltipProps}
                hasInteractiveContent
                css={healthTooltipStyle}
              >
                <Text
                  as="span"
                  css={(theme: WeaveTheme) => css`
                    font-size: ${theme.fontSize(20)};
                  `}
                  textAlign="center"
                >
                  Sync App Health Information
                </Text>
                {UpdatedReasons &&
                  Object.entries(UpdatedReasons).map(([key, reason]) => (
                    <span css={tooltipLabelInfo} key={key}>
                      <Text as="span" weight="bold" css={labelHeading}>
                        {key}:
                      </Text>
                      <Text as="span" css={labelReason}>
                        {reason}
                      </Text>
                    </span>
                  ))}
              </HealthTooltip>
            </>
          )}
        </div>
      </div>
      <div css={labelInfoDiv}>
        <Text as="span" size="medium" color="light">
          PM Details
        </Text>
        <Text as="span" size="medium">
          {PracticeManagementSystem}
        </Text>
      </div>
      <div css={labelInfoDiv}>
        <Text as="span" size="medium" color="light">
          Source Name
        </Text>
        <Text as="span" size="medium">
          {SourceName}
        </Text>
      </div>
      <CopyToClipboard text={sourceId} onCopy={() => setSourceIdCopied(true)}>
        <div css={(theme: WeaveTheme) => [iconPointerStyle, labelInfoDiv(theme)]}>
          <Text as="span" size="medium" color="light">
            Source ID
          </Text>
          <Text as="span" size="medium">
            {sourceId}
          </Text>
        </div>
      </CopyToClipboard>
      {sourceIdCopied && (
        <Text color="success" size="medium">
          Copied!
        </Text>
      )}
      <div css={labelInfoDiv}>
        <Text as="span" size="medium" color="light">
          Verification
        </Text>
        <div css={labelFlexRight}>
          <Text as="span" size="medium" color={getTextColor(dovStatus)}>
            {StatusTypeText[dovStatus] || ''}
          </Text>
          {resetDOVPermission && StatusTypeText[dovStatus] === StatusTypeText.LOCKED && (
            <>
              <IconButton label="Reset" size="small" {...resetTriggerProps}>
                <InfoRoundIconSmall />
              </IconButton>
              <Tooltip {...resetTooltipProps} hasInteractiveContent>
                <Text as="span" textAlign="center">
                  This data source is {StatusTypeText[dovStatus]}. Do you wish to reset
                  this data source to an unverified state?
                </Text>
                <PrimaryButton
                  {...resetModalTriggerProps}
                  onClick={showConfirmation}
                  css={mTopSpacing}
                >
                  Reset
                </PrimaryButton>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <hr />

      <ConfirmationModal
        {...resetModalProps}
        onConfirm={handleReset}
        confirmLabel="Yes"
        title="Reset data source verification"
        message="Are you sure you want to reset the verification of this data source? All patient data for the sync-app will be hidden from the client application if you proceed!"
      />
    </>
  );
};
