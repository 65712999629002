import React from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { Modal, Button, Input } from '@weave/platform-ui-components';
import { css } from 'emotion';

import { ManualTemplate } from '../../../redux/actions/manual-templates/manual-templates.reducer';

interface Props {
  editSave: (templates: ManualTemplate[]) => void;
  handleEditClose: () => void;
  modalOpen: boolean;
  templates: ManualTemplate[];
  title: string;
}

interface State {
  templates: ManualTemplate[];
}

export class ManualTemplatesEdit extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      templates: props.templates ? cloneDeep(props.templates) : [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!isEqual(nextProps.templates, this.props.templates)) {
      this.setState(() => {
        return {
          templates: cloneDeep(nextProps.templates),
        };
      });
    }
  }

  handleInput = (i) => (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    this.setState((prevState: State) => {
      // change value of only object being edited using index
      prevState.templates[i].Template = value;
      return {
        templates: prevState.templates,
      };
    });
  };

  cancel = () => {
    // Set the state back to how it was before
    this.setState(() => {
      return {
        templates: cloneDeep(this.props.templates),
      };
    });

    this.props.handleEditClose();
  };

  handleEditSave = () => {
    this.props.editSave(this.state.templates);
  };

  render() {
    return (
      <Modal
        isOpen={this.props.modalOpen}
        onRequestClose={this.cancel}
        title={this.props.title}
        className={css`
          min-width: 800px;
          width: 60%;

          .react-modal-card {
            max-height: 700px;
            text-align: right;
            width: 100%;
          }
        `}
      >
        {this.state.templates.map((template, i) => {
          return (
            <Input
              key={template.Template}
              value={template.Template}
              onChange={this.handleInput(i)}
              placeholder={template.TypeDescription}
              className={css`
                margin-top: 16px;
              `}
            />
          );
        })}
        <Button
          onClick={this.handleEditSave}
          className={css`
            display: inline-block !important;
            margin-top: 24px;
          `}
        >
          Save
        </Button>
      </Modal>
    );
  }
}
