import React from 'react';
import { css, cx } from 'emotion';

import { LeaderboardContainer } from '../leaderboard-container/leaderboard.container';
import { LabelerContainer } from '../labeler-container/labeler.container';

const mainWrapper = css`
  position: fixed;
  bottom: 50px;
  right: 20px;

  label: mainWrapper;
`;

const relative = css`
  position: relative;

  lable: relative;
`;

const initialDisplay = css`
  background-color: #f5f6f5;
  border-radius: 6px;
  padding: 5px 10px;
  position: fixed;
  bottom: 50px;
  right: 20px;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  border-left: 5px solid #ee2375;

  label: initialDisplay;
`;

const rankWrapper = css`
  background-color: #ee2375;
  border-radius: 6px 6px 0 0;
  min-width: 111px;
  padding: 0 5px;
  color: white;
  text-align: right;
  display: inline-block;
  right: 56px;
  position: absolute;
  top: -24px;
  box-shadow: 1px 1px 5px lightgrey;
  cursor: pointer;

  label: rankWrapper;
`;

export const closeX = css`
  position: absolute;
  right: 10px;
  top: -24px;
  color: white;
  border-radius: 6px 6px 0 0;
  padding: 0 4px;
  box-shadow: 1px 1px 5px lightgrey;
  background-color: #bfbfbf;
  cursor: pointer;

  label: closeX;
`;

interface Props {
  score: number;
  rank: number;
  getRank: () => void;
}

interface State {
  showLeaderboard: boolean;
  showMessage: boolean;
}

export class MachineLearningLabeler extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showLeaderboard: false,
      showMessage: false,
    };
  }

  componentDidMount() {
    if (typeof this.props.getRank === 'function') {
      this.props.getRank();
    }
  }

  toggleLeaderboard = () => {
    this.setState({
      showLeaderboard: !this.state.showLeaderboard,
    });
  };

  toggleMessage = () => {
    this.setState(
      {
        showMessage: !this.state.showMessage,
      },
      () => typeof this.props.getRank === 'function' && this.props.getRank()
    );
  };

  toggleMessageAndLeaderBoard = () => {
    this.setState(
      {
        showLeaderboard: !this.state.showMessage, // We want both to anchor off the statment
        showMessage: !this.state.showMessage,
      },
      () => typeof this.props.getRank === 'function' && this.props.getRank()
    );
  };

  render() {
    return (
      <div className={cx('machine-learning-labeler', mainWrapper)}>
        <div className={relative}>
          {!this.state.showMessage && !this.state.showLeaderboard && (
            <div
              onClick={this.toggleMessage}
              className={initialDisplay}
              title="Support Weave's A.I. by helping label data!"
            >
              Rank {this.props.rank} | Score {this.props.score}
            </div>
          )}
          {this.state.showLeaderboard && <LeaderboardContainer />}
          {this.state.showMessage && (
            <div>
              <div className={rankWrapper}>
                <span onClick={this.toggleLeaderboard}>
                  {this.state.showLeaderboard ? 'Hide' : 'View'} Leaderboard!
                </span>
              </div>
              <div className={closeX} onClick={this.toggleMessageAndLeaderBoard}>
                Close
              </div>
              <LabelerContainer />
            </div>
          )}
        </div>
      </div>
    );
  }
}
