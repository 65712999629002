import React from 'react';

import { css } from 'emotion';
import { ManualTemplate } from '../../../redux/actions/manual-templates/manual-templates.reducer';

interface Props {
  template: ManualTemplate;
}

const manualTemplatesStyles = css`
  display: flex;
  flex-direction: row;
  margin-left: 0;
  margin-right: 0;
  border-bottom: 1px solid #efefef;
  margin-bottom: 5px;

  h4 {
    font-size: 1.125rem;
  }
`;

export const ManualTemplateItem = (props: Props) => {
  const { Template, TypeDescription } = props.template;

  return (
    <div className={manualTemplatesStyles}>
      <div className="col-4">
        <h4>{TypeDescription}</h4>
      </div>
      <div className="col-8">
        <p>{Template}</p>
      </div>
    </div>
  );
};
