import { createAction, handleActions } from 'redux-actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { CustomAxios } from '../../axios';
import { showError, showSuccess } from '@weave/alert-system';
import { FeatureStates, LocationFeature } from '../../../models/location-feature.model';
import { createSelector } from 'reselect';
import { Store } from '../../store/store.model';
import { LegacySettings } from '../../../components/settings/reviews/helpers';

export interface LocationFeaturesPayload {
  locationId: string;
  features: LocationFeature[];
}

export const getLocationFeatures = createAction<string>('GET_LOCATION_FEATURES');
export const setLocationFeatures = createAction<LocationFeaturesPayload>(
  'SET_LOCATION_FEATURES'
);
export const locationFeaturesSuccess = createAction<LocationFeature[]>(
  'LOCATION_FEATURES_SUCCESS'
);
export const locationFeaturesFailure = createAction('LOCATION_FEATURES_FAILURE');
export const updateFeature = createAction<any>('UPDATE_FEATURE');
export const setFeatures = createAction<LocationFeature[]>('SET_FEATURES');

export const handleGetLocationFeatures = function* (action) {
  try {
    const locationId = action.payload;
    if (!locationId) {
      yield put(locationFeaturesFailure());
      return;
    }

    const response = yield call(
      CustomAxios.get,
      `support/v1/locations/${locationId}/features`
    );
    const features = response.data && response.data.data;
    yield put(locationFeaturesSuccess(features));
  } catch {
    yield put(locationFeaturesFailure());
    yield put(showError('Failed to get feature list'));
  }
};

export const handleSetLocationFeatures = function* (action) {
  try {
    const { locationId, features } = action.payload;

    const formattedFeatures = features.map((feature) => {
      return { id: feature.id, state: feature.state };
    });

    yield call(CustomAxios.post, `support/v1/locations/${locationId}/features`, {
      features: formattedFeatures,
    });
    yield put(locationFeaturesSuccess(features));
    yield call(setReviewsActivation, features);
    yield put(showSuccess('Features saved successfully'));
  } catch {
    yield put(locationFeaturesFailure());
    yield put(showError('Failed to save feature list'));
  }
};

const setReviewsActivation = async (features: LocationFeature[]) => {
  const reviewsFeatureId = 'ff764a4b-31a0-482d-b0d2-72b4dad92385';
  const reviewFeature = features.find((feature) => feature.id === reviewsFeatureId);
  if (reviewFeature === undefined) {
    return;
  }
  reviewFeature.state === FeatureStates.Active ? activateReviews() : deactivateReviews();
};

const activateReviews = async () => {
  const reviewSettings = await CustomAxios.get<{ data: LegacySettings }>(
    'support/v1/reviews/settings'
  );
  const accountStatus = reviewSettings.data.data.Status;
  if (accountStatus === '') {
    await CustomAxios.post('/support/v1/reviews/signup', { accepted: true });
  } else if (accountStatus === 'archived') {
    await CustomAxios.put('support/v1/reviews/accountStatus', { active: true });
  }
};

const deactivateReviews = async () => {
  await CustomAxios.put('support/v1/reviews/accountStatus', { active: false });
};

export const handleUpdateFeature = function* (action) {
  try {
    const { feature, newState } = action.payload;

    const state = yield select();

    const features = state.customization.features.map((currentFeature) => {
      if (currentFeature.id === feature.id) {
        return {
          ...currentFeature,
          state: newState.key,
        };
      }
      return currentFeature;
    });

    yield put(locationFeaturesSuccess(features));
  } catch {
    yield put(showError('Failed to update feature in the feature list'));
  }
};

export const customizationSaga = function* () {
  yield all([
    takeEvery(getLocationFeatures.toString(), handleGetLocationFeatures),
    takeEvery(setLocationFeatures.toString(), handleSetLocationFeatures),
    takeEvery(updateFeature.toString(), handleUpdateFeature),
  ]);
};

const defaultState = {
  loading: false,
  features: [],
};
export const customizationReducer = handleActions(
  {
    [getLocationFeatures.toString()]: (state) =>
      Object.assign({}, state, { loading: true }),
    [setLocationFeatures.toString()]: (state) =>
      Object.assign({}, state, { loading: true }),
    [locationFeaturesSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        loading: false,
        features: action.payload,
      }),
    [locationFeaturesFailure.toString()]: (state) =>
      Object.assign({}, state, { loading: false }),
    [setFeatures.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, features: action.payload }),
  },
  defaultState
);

export type CustomizationState = {
  features: LocationFeature[];
  loading: boolean;
};

export const selectFeatures = ({ customization }: Store): LocationFeature[] =>
  customization.features;

export const selectCustomizationFeature = createSelector(
  selectFeatures,
  (state, featureName: string) => featureName,
  (features, featureName): LocationFeature | undefined =>
    features.find(({ name }) => name === featureName)
);
