import { useAlert } from '@weave/alert-system';
import { theme } from '@weave/theme-original';
import {
  Heading,
  NumberField,
  PrimaryButton,
  Text,
  TextField,
  useFormField,
  ValidatorFieldState,
} from '@weave/design-system';
import { css } from '@emotion/core';
import React, { useEffect, useMemo, useState } from 'react';
import { tenantMigrationApi } from '../../../apis/tenant-migration/tenant-migration.api';
import {
  TenantMigrationNumberingResource,
  NumberingMigrationStatusRes,
  TenantMigrationNumberingReq,
} from '../../../apis/tenant-migration/tenant-migration.types';
import { Loader } from '../../shared/loader/loader.component';
import { InfoTooltip } from '../../shared/info-tooltip/info-tooltip.component';
import ReactTable from 'react-table';
import { TableStyles } from '../../../styles/table-style';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../redux/actions/location';

type Collisions = { [k: number]: number[] };

const getDstFromSrc = (
  src: TenantMigrationNumberingResource,
  dsts: TenantMigrationNumberingResource[]
) =>
  dsts.find(
    (dst) =>
      dst.voicemailBoxName === src.voicemailBoxName ||
      dst.extensionInstructionSetName === src.extensionInstructionSetName ||
      dst.extensionName === src.extensionName
  );

const getMapCollisions = (
  srcNumber: number,
  dstNumber: number,
  map: TenantMigrationNumberingReq['renumberMap']
) => {
  const { [srcNumber]: self, ...others } = map;
  return Object.entries(others)
    .filter(([src, dst]) => dst === dstNumber)
    .map(([src, dst]) => +src);
};

export const TenantConfiguration = () => {
  const alerts = useAlert();
  const locationId = useSelector(selectCurrentLocationId);
  const [numberingStatus, setNumberingStatus] = useState<NumberingMigrationStatusRes>();
  const [numberingMap, setNumberingMap] = useState<TenantMigrationNumberingReq>({
    parkStartingSlot: null,
    namePrefix: '',
    renumberMap: {},
  });

  const namePrefixProps = useFormField({ type: 'text', required: true });
  const parkStartingSlotProps = useFormField({
    type: 'number',
    min: 0,
    max: 99,
    required: false,
    includeThousandsSeparator: false,
    allowDecimal: false,
  });
  const [validations, setValidations] = useState<{ [key: number]: number }>({});

  const dstNumbers = useMemo(
    () => numberingStatus?.destinationTenantNumbering?.map((num) => num.number) ?? [],
    [numberingStatus?.destinationTenantNumbering]
  );

  useEffect(() => {
    setNumberingMap({ ...numberingMap, namePrefix: namePrefixProps.value });
  }, [namePrefixProps.value]);

  useEffect(() => {
    const value = parkStartingSlotProps.value ? +parkStartingSlotProps.value : null;
    if (typeof value === 'number' && (value > 99 || value < 1)) {
      return;
    }
    setNumberingMap({
      ...numberingMap,
      parkStartingSlot: value,
    });
  }, [parkStartingSlotProps.value]);

  useEffect(() => {
    tenantMigrationApi.getNumberingStatus().then(setNumberingStatus);
  }, [locationId]);

  useEffect(() => {
    if (!numberingStatus) {
      return;
    }
    const map = numberingStatus?.sourceTenantNumbering.reduce((map, src) => {
      const dst = getDstFromSrc(src, numberingStatus.destinationTenantNumbering);
      return {
        ...map,
        ...(dst && { [src.number]: dst.number }),
      };
    }, {});

    setNumberingMap({
      ...numberingMap,
      renumberMap: { ...numberingMap?.renumberMap, ...map },
    });
  }, [numberingStatus]);

  const setNumber = (src: number) => (dst: number) => {
    setNumberingMap({
      ...numberingMap,
      renumberMap: { ...numberingMap?.renumberMap, [src]: dst },
    });
  };

  /**
   * This is a work around to trigger validation updates on the number inputs
   */
  const validateAllNumbers = () =>
    setValidations(
      numberingStatus?.sourceTenantNumbering.reduce(
        (acc, resource) => ({
          ...acc,
          [resource.number]: Date.now(),
        }),
        {}
      ) ?? {}
    );

  const validateTouchedNumbers = () => setValidations(map(numberingMap, Date.now));

  const submit = () => {
    const mapCollisions = Object.entries(numberingMap.renumberMap).reduce<Collisions>(
      (collisions, [src, dst]) => {
        const myCollisions = getMapCollisions(+src, dst, numberingMap.renumberMap);
        return {
          ...collisions,
          ...(myCollisions.length ? { [+src]: myCollisions } : null),
        };
      },
      {}
    );

    const dstCollisions = Object.values(numberingMap.renumberMap).filter((dst) =>
      dstNumbers.includes(dst)
    );

    namePrefixProps.onBlur(); //validate name field
    parkStartingSlotProps.onBlur(); //validate park starting slot field
    validateAllNumbers(); //validate all number fields
    const hasMapCollisions = !!Object.keys(mapCollisions).length;
    const hasDstCollisions = !!dstCollisions.length;
    const allProvided =
      Object.values(numberingMap.renumberMap).length ===
        numberingStatus?.sourceTenantNumbering?.length &&
      Object.values(numberingMap.renumberMap).every((val) => !!val);

    if (hasMapCollisions) {
      alerts.error('Your numbers have collisions with eachother');
      return console.error('Collisions with assigned numers', mapCollisions);
    }
    if (hasDstCollisions) {
      alerts.error('Your numbers have collisions with existing destination numbers');
      return console.error('Collisions with destination numbers', dstCollisions);
    }
    if (!namePrefixProps.value) {
      alerts.error('Please provide a name prefix');
      return console.error('Name prefix is required');
    }
    const parkStartingSlot = parkStartingSlotProps.value
      ? +parkStartingSlotProps.value
      : null;
    if (
      typeof parkStartingSlot === 'number' &&
      (parkStartingSlot > 99 || parkStartingSlot < 1)
    ) {
      alerts.error('Park Starting Slot is invalid');
      return;
    }
    if (!allProvided) {
      alerts.error('Please provide numbers for each row');
      console.log(
        Object.values(numberingMap).length,
        numberingStatus?.sourceTenantNumbering?.length,
        Object.values(numberingMap.renumberMap)
      );
      return console.error('Not all numbers are provided');
    }

    console.log('Migrating! ', numberingMap);

    tenantMigrationApi
      .migrateNumbering(numberingMap)
      .then(() => {
        alerts.success('Migration Success!');
        setNumberingStatus({
          ...numberingStatus,
          migrationCompleted: true,
        } as NumberingMigrationStatusRes);
      })
      .catch((err) => {
        console.log(err);
        alerts.error(JSON.stringify(err.message));
      });
  };

  if (!numberingStatus) {
    return (
      <>
        <Heading
          css={css`
            margin-top: ${theme.spacing(6)};
          `}
        >
          Step 2: Copy Tenant Configuration
        </Heading>
        <Loader />
      </>
    );
  }

  return (
    <>
      <Heading
        css={css`
          margin-top: ${theme.spacing(6)};
        `}
      >
        Step 2: Copy Tenant Configuration
        <InfoTooltip
          css={css`
            display: inline-block;
            margin-left: ${theme.spacing(1)};
          `}
          body={`The numbering "new number" entry will change the Extension Number
            and replace the old number in Extension, Instruction Set, and Voicemail Box Name fields.`}
          tooltipPlacement="left"
        />
      </Heading>
      <Text size="small">
        This will copy all PDS primitive configuration data from the child tenant to the
        parent tenant.
        <br />
        <span
          css={css`
            color: ${theme.colors.warning};
          `}
        >
          Warning: This may only be performed once!
        </span>
        <br />
        Note: All device MAC addresses and phone numbers will be stubbed on the parent
        tenant to keep from interrupting call routing. To initiate call routing to the
        parent account the stubbed devices and phone numbers must be updated to be the
        actual MAC addresses and phone numbers. Devices will need to receive new
        configuration which can be accomplished by a resync.
      </Text>

      {!!numberingStatus.destinationTenantNumbering?.length && (
        <>
          <Text
            weight={'bold'}
            css={css`
              margin-top: ${theme.spacing(3)};
              margin-bottom: 0;
            `}
          >
            Numbers already on Destination Tenant
          </Text>
          <ReactTable
            columns={[
              {
                Header: 'Current Number',
                id: 'current-number',
                accessor: ({ number }) => (
                  <Text
                    weight="bold"
                    css={css`
                      margin-bottom: 0;
                    `}
                  >
                    {number}
                  </Text>
                ),
              },
              {
                Header: 'Extension Name',
                id: 'extension-name',
                accessor: ({ extensionName }) => extensionName,
              },
              {
                Header: 'Extension Instruction Set Name',
                id: 'instruction-name',
                accessor: ({ extensionInstructionSetName }) =>
                  extensionInstructionSetName,
              },
              {
                Header: 'Vm Box Name',
                id: 'voicemail-name',
                minWidth: 200,
                accessor: ({ voicemailBoxName }) => voicemailBoxName,
              },
            ]}
            data={numberingStatus.destinationTenantNumbering}
            pageSize={numberingStatus.destinationTenantNumbering.length}
            showPagination={false}
            css={[
              TableStyles,
              css`
                margin-top: ${theme.spacing(0)};
                .rt-tr-group:last-child {
                  border-bottom: none !important;
                }
                .rt-td {
                  padding: 0px ${theme.spacing(1)} !important;
                  background-color: #f2f2f2;
                }
              `,
            ]}
          />
        </>
      )}

      <Text
        weight="bold"
        css={css`
          margin-top: ${theme.spacing(4)};
        `}
      >
        Child Primitive Name Prefix
      </Text>
      <TextField
        {...namePrefixProps}
        name="name-prefix"
        label=""
        helperText="The Child Primitive Name Prefix will add the text entered to copied PDS Primitive Names for easy child association. This will affect all PDS primitives with the exception of SIP Profiles and Devices."
        css={css`
          width: 300px;
        `}
      />

      <Text
        weight="bold"
        css={css`
          margin-top: ${theme.spacing(4)};
        `}
      >
        Device Park Starting Slot (Optional)
        <InfoTooltip
          css={css`
            display: inline-block;
            margin-left: ${theme.spacing(1)};
          `}
          body={
            <>
              Example if a child location had 5 devices with 2 parking slots in line keys
              I would expect each device to
              <ul>
                <li>Auto Park to line 10 (SIP Profile starting slot)</li>
                <li>
                  BLF Keys to maintain their name/label however be changed to lines 10 and
                  11 (the first 2 of the range) (device line key change)
                </li>
              </ul>
            </>
          }
          tooltipPlacement="left"
        />
      </Text>
      <NumberField
        {...parkStartingSlotProps}
        name="park-starting-slot"
        label=""
        min={0}
        max={99}
        placeholder="1 - 99 (optional) "
        helperText="This will set all SIP Profiles with this park starting slot as well as modify all device park line keys to begin at this number"
        css={css`
          width: 300px;
        `}
      />

      {!numberingStatus.migrationCompleted && (
        <>
          <Heading
            level={2}
            css={css`
              margin-top: ${theme.spacing(4)};
            `}
          >
            Numbering
          </Heading>
          <Text size="small" color="light">
            Numbering allows you to choose the new numbers used for extensions and
            voicemail boxes. Note: Names will be updated to reflect the new number only if
            the "current number" is within primitive name.
          </Text>
          <ReactTable
            columns={[
              {
                Header: 'Current Number',
                id: 'current-number',
                maxWidth: 120,
                accessor: ({ number }) => (
                  <Text
                    weight="bold"
                    css={css`
                      margin-bottom: 0;
                    `}
                  >
                    {number}
                  </Text>
                ),
              },
              {
                Header: 'Extension Name',
                id: 'extension-name',
                maxWidth: 150,
                accessor: ({ extensionName }) => extensionName,
              },
              {
                Header: 'Extension Instruction Set Name',
                id: 'instruction-name',
                maxWidth: 220,
                accessor: ({ extensionInstructionSetName }) =>
                  extensionInstructionSetName,
              },
              {
                Header: 'Vm Box Name',
                id: 'voicemail-name',
                maxWidth: 200,
                accessor: ({ voicemailBoxName }) => voicemailBoxName,
              },
              {
                Header: 'New Number',
                id: 'newNumber',
                accessor: (resource) => {
                  const dstNumber = getDstFromSrc(
                    resource,
                    numberingStatus.destinationTenantNumbering
                  );
                  return (
                    <NumberInput
                      src={resource.number}
                      dst={dstNumber?.number ?? 0}
                      map={numberingMap.renumberMap}
                      dstNumbers={dstNumbers}
                      lastValidated={validations[resource.number]}
                      validate={validateTouchedNumbers}
                      onChange={setNumber(resource.number)}
                    />
                  );
                },
              },
            ]}
            data={numberingStatus.sourceTenantNumbering}
            pageSize={numberingStatus.sourceTenantNumbering.length}
            showPagination={false}
            css={[
              TableStyles,
              css`
                .rt-tr-group:last-child {
                  border-bottom: none !important;
                }
                .rt-td {
                  padding: 4px !important;
                }
              `,
            ]}
          />
        </>
      )}

      <PrimaryButton
        onClick={submit}
        disabled={numberingStatus.migrationCompleted}
        css={css`
          margin-top: ${theme.spacing(1)};
          width: 200px;
        `}
      >
        {numberingStatus.migrationCompleted ? 'Already Copied' : 'Copy'}
      </PrimaryButton>
    </>
  );
};

type NumberInputProps = {
  src: number;
  dst: number;
  map: TenantMigrationNumberingReq['renumberMap'];
  dstNumbers: number[];
  lastValidated: number;
  onChange: (value: number) => void;
  validate: () => void;
};

const NumberInput: React.FC<NumberInputProps> = ({
  src,
  dst,
  map,
  dstNumbers,
  onChange,
  validate,
  lastValidated,
}) => {
  const props = useFormField(
    {
      type: 'number',
      required: true,
      validator: (state: ValidatorFieldState<'number'>) => {
        const mapCollisions = getMapCollisions(src, +state.value, map);
        const dstCollision = dstNumbers.includes(+state.value);
        let errs = mapCollisions.length
          ? `Conflicts with ${mapCollisions.join(', ')}. `
          : '';
        if (dstCollision) {
          errs += `Already exists on destination`;
        }
        return errs;
      },
    },
    [map]
  );
  useEffect(() => {
    onChange(+props.value);
  }, [props.value]);

  /**
   * This is a way to allow the parent to trigger a validation
   */
  useEffect(() => {
    if (lastValidated) {
      props.onBlur();
    }
  }, [lastValidated]);

  return (
    <NumberField
      {...props}
      css={css`
        height: 30px;
        padding: ${theme.spacing(1)};
      `}
      name="number"
      label=""
      includeThousandsSeparator={false}
      onBlur={() => {
        props.onBlur();
        validate();
      }}
    />
  );
};
