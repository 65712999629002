import React, { useState } from 'react';
import { Text, ForwardIcon } from '@weave/design-system';
import { Title } from '@weave/platform-ui-components';

import { StatusMapping } from './status-mapping';
import { statusMapContainer } from './appointment-mapping';
import { useClientMapping } from './client-mapping.hook';

const clientKey = 'allow_appointment_writebacks';

export const WritebackMapping = ({ sourceID }) => {
  const [writeback, setWriteback] = useState(false);
  const { saveClientMapping } = useClientMapping(
    (data) => setWriteback(data[clientKey]),
    [clientKey]
  );

  const updateWriteback = (event) => {
    const value = event.target.value;
    if (!!value !== writeback) {
      saveClientMapping(clientKey, !writeback, 'adapter');
    }
  };

  return (
    <div css={statusMapContainer}>
      <Title size="large" weight="bold">
        Confirmation Mapping
      </Title>
      <dl>
        <Text weight="bold">Weave Status</Text>
        <ForwardIcon />
        <Text weight="bold">Office Status</Text>
      </dl>
      <Text color="light" size="medium">
        Marking this status as anything other than None will turn on Automated
        Confirmations
      </Text>
      <StatusMapping type="writeback" onChange={updateWriteback} sourceID={sourceID} />
    </div>
  );
};
