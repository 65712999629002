import React from 'react';
import { Route } from 'react-router-dom';
import { AutoReminders } from './auto-reminders/auto-reminders.component';
import { LocationSettings } from './location-settings/location-settings.component';
import { Reviews } from './reviews/reviews.component';
import { FeatureFlagsContainer } from './feature-flags-container/feature-flags.container';
import { StatusMappingPage } from './status-mapping/status-mapping.page.component';
import { OnlineScheduling } from './online-scheduling/online-scheduling';
import { EmailMarketingPage } from './email-marketing';
import { PaymentPage } from '../payments/payments-page.component';
import { ManualTemplates } from './manual-templates/manual-templates.component';
import { SETTINGS } from '../../constants/paths';
import { CrawlSwitch } from '../../routers/crawl-switch';
import { BillingPage } from './billing/billing-page';

export const SettingsPage = () => (
  <CrawlSwitch>
    <Route path={SETTINGS.autoReminders} component={AutoReminders} />
    <Route path={SETTINGS.emailMarketing} component={EmailMarketingPage} />
    <Route path={SETTINGS.featureFlags} component={FeatureFlagsContainer} />
    <Route path={SETTINGS.locations} component={LocationSettings} />
    <Route path={SETTINGS.manualTemplates} component={ManualTemplates} />
    <Route path={SETTINGS.onlineScheduling} component={OnlineScheduling} />
    <Route path={SETTINGS.payments} component={PaymentPage} />
    <Route path={SETTINGS.billing} component={BillingPage} />
    <Route path={SETTINGS.reviews} component={Reviews} />
    <Route path={SETTINGS.statusMapping} component={StatusMappingPage} />
  </CrawlSwitch>
);
