import React, { useState } from 'react';
import { css } from '@emotion/core';

import * as styles from './auto-reminder-style';
import { useTemplates } from './auto-reminder-templates.hook';
import { theme } from '@weave/theme-original';
import {
  OptionSwitchField,
  PrimaryButton,
  Text,
  TextLink,
  TextareaField,
  useFormField,
} from '@weave/design-system';
import {
  replaceTagsWithExample,
  templateTagExamples,
} from '../auto-reminders-helpers/auto-reminder-helpers';
import { TextTemplateTags } from './auto-reminder-template-tags';
import { NotificationSetting } from '../../recall-reminders/recall-settings.hook';
import { DocumentSelect } from './document-select';

const toggleOptsObj = {
  single: 'text',
  multi: 'multi_template_text',
};

export type Document = {
  id: string;
  name: string;
  type: DocumentType;
};

export type Language = 'Default' | 'English' | 'Spanish' | 'Unknown';

export type Template = {
  document_id?: string;
  document_type?: DocumentType;
  id: string;
  language: Language;
  multi?: string;
  single: string;
};

export enum DocumentType {
  unknown,
  form,
  packet,
}

type Props = {
  id: string;
  settings: NotificationSetting;
  historicTemplates?: Record<string, any>;
};

export const AutoReminderTemplates = ({
  id,
  settings,
  historicTemplates = {},
}: Props) => {
  const { templates, prepareTemplates, original, saveTemplates, updateTemplateDocument } =
    useTemplates(settings);
  const [templateLanguageIndex, setTemplateLanguageIndex] = useState(0);
  const [cursor, setCursor] = useState(0);

  const templateTypeFieldProps = useFormField({ type: 'optionswitch', value: 'single' });

  const type = templateTypeFieldProps.value;
  const displayText = !!templates?.length && templates[templateLanguageIndex][type];
  const templateFieldProps = useFormField({ type: 'text', value: displayText ?? '' }, [
    displayText,
  ]);

  const changesExist =
    !!original?.length &&
    (original[templateLanguageIndex][type] !== displayText ||
      original[templateLanguageIndex].document_id !==
        templates[templateLanguageIndex].document_id);
  const multiExists = !!templates?.length && !!templates[templateLanguageIndex].multi;
  const currentText =
    displayText && replaceTagsWithExample(displayText, templateTagExamples);

  const historicExists = 'type' in historicTemplates;
  const templateTitle = historicExists ? 'Removed Template' : 'Template';
  const previewTitle = historicExists ? 'Current Template' : 'Preview';
  const historicTemplate =
    historicExists && `${toggleOptsObj[type]}` in historicTemplates
      ? replaceTagsWithExample(
          historicTemplates[toggleOptsObj[type]],
          templateTagExamples
        )
      : '';
  const historicText = historicTemplate === currentText ? '' : historicTemplate;
  const added = historicExists ? styles.added : '';

  const updateCursor = (e) => setCursor(e.target.selectionStart);

  const handleClick = (val) => {
    const data = displayText.slice(0, cursor + 1) + val + displayText.slice(cursor + 1);
    prepareTemplates(templateLanguageIndex, type, data);
  };

  // @ts-ignore
  return (
    <>
      <div
        css={css`
          align-items: center;
          display: flex;
          justify-content: space-between;
        `}
      >
        <h3
          css={css`
            margin-bottom: 0;
          `}
        >
          Templates
        </h3>
        <PrimaryButton
          css={css`
            max-width: 150px;
          `}
          disabled={!changesExist}
          onClick={saveTemplates}
        >
          Save
        </PrimaryButton>
      </div>
      <hr />
      {multiExists && (
        <OptionSwitchField {...templateTypeFieldProps} label="" name="template-type">
          <OptionSwitchField.Option value="single">Individual</OptionSwitchField.Option>
          <OptionSwitchField.Option value="multi">Family</OptionSwitchField.Option>
        </OptionSwitchField>
      )}
      <div css={styles.settingContainer}>
        <div css={styles.langContainer}>
          <Text weight="bold">Languages</Text>
          {templates.map((template: Template, index) => {
            const selected = index === templateLanguageIndex ? styles.selectedTab : '';
            const language =
              template.language === 'Unknown' ? 'Default' : template.language;

            return (
              <TextLink
                css={[styles.langOpts, ...(selected ? [selected] : [])]}
                key={template.language}
                onClick={() => setTemplateLanguageIndex(index)}
              >
                {language}
              </TextLink>
            );
          })}
        </div>
        {(historicExists && templateLanguageIndex === 0) || !historicExists ? (
          <div css={styles.templateContainer}>
            <section
              css={css`
                margin-bottom: ${theme.spacing(4)};
              `}
            >
              <Text weight="bold">
                Form/Packet Link{' '}
                <Text as="span" color="light">
                  (optional)
                </Text>
              </Text>
              <DocumentSelect
                documentId={templates?.[templateLanguageIndex]?.document_id}
                templateLanguageIndex={templateLanguageIndex}
                updateTemplateDocument={updateTemplateDocument}
              />
            </section>
            <section
              css={css`
                margin-bottom: ${theme.spacing(3)};
              `}
            >
              <Text weight="bold">{templateTitle}</Text>
              {!historicExists ? (
                <>
                  <TextareaField
                    {...templateFieldProps}
                    css={css`
                      margin-bottom: ${theme.spacing(1)};
                    `}
                    label=""
                    name="template"
                    onChange={(event) =>
                      prepareTemplates(
                        templateLanguageIndex,
                        type,
                        (event as React.ChangeEvent<HTMLTextAreaElement>)?.target
                          ?.value ?? ''
                      )
                    }
                    onKeyDown={updateCursor}
                    onClick={updateCursor}
                  />
                  <TextTemplateTags
                    {...{
                      templateTagExamples,
                      handleDrag: () => {},
                      handleClick,
                      templateType: type,
                    }}
                  />
                </>
              ) : historicText ? (
                <Text css={[styles.templateStyle, styles.removed]}>{historicText}</Text>
              ) : (
                <Text css={styles.noChanges}>No changes were made to this template</Text>
              )}
            </section>
            <section>
              <Text weight="bold">{previewTitle}</Text>
              <Text css={[styles.templateStyle, added]}>{currentText}</Text>
            </section>
          </div>
        ) : (
          <div css={styles.templateContainer}>
            <Text css={styles.noChanges}>No data available</Text>
          </div>
        )}
      </div>
    </>
  );
};
