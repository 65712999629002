import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cx } from 'emotion';
import {
  ButtonBar,
  Heading,
  PrimaryButton,
  SecondaryButton,
  Text,
  TextField,
  useFormField,
} from '@weave/design-system';
import { useAlert } from '@weave/alert-system';
import { useDropzone } from 'react-dropzone';
import * as styles from './form-upload-media.styles';
import { CustomAxios } from '../../../redux/axios';
import { addPhoneMedia, PhoneMedia, phoneMediaSelector } from '../../../redux/actions';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  onMediaFileSelected: (mediaId: string) => void;
}

interface AudioFile extends File {
  path: string;
}

export const UploadMediaForm = (props: Props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [mediaFile, setMediaFileFile] = useState<AudioFile | null>(null);
  const [uploading, setUploading] = useState(false);
  const alerts = useAlert();
  const { locationId } = useSelector(phoneMediaSelector);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      setMediaFileFile(acceptedFiles[0]);
      setError('');
    } else if (rejectedFiles.length > 0) {
      setError('Invalid file type: Must be WAV, MPEG (MP3) or MP4');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'audio/wav, audio/mpeg, video/mp4',
    onDrop,
  });
  const onBrowseClick = getRootProps().onClick;

  const fieldProps = useFormField({
    type: 'text',
    required: true,
  });

  const onSubmitUpload = async () => {
    if (uploading) {
      return;
    }

    if (!mediaFile) {
      return setError('Please select an audio file');
    }

    setUploading(true);

    const uploadResponse = await uploadMediaFile(
      mediaFile,
      fieldProps.value || mediaFile.name
    );

    if (uploadResponse.error || !uploadResponse.mediaFile) {
      setUploading(false);
      return alerts.error('Error uploading media file');
    }

    props.onMediaFileSelected(uploadResponse?.mediaFile?.MediaID ?? '');

    setUploading(false);

    props.onRequestClose();
  };

  const uploadMediaFile = async (file, fileName) => {
    try {
      const data = new FormData();

      data.append('media-file', file);

      if (fileName) {
        data.append('file-name', fileName);
      }

      const response = await CustomAxios.post('support/v1/phonemedia/upload', data);

      const newMediaFile: PhoneMedia = {
        MediaID: response.data.ID,
        FileName: fileName,
        FilePath: response.data.HTTPPath,
        LocationID: locationId,
        MediaType: '',
        ContentType: '',
        CreatedAt: new Date().toISOString(),
        DeletedAt: '',
      };

      dispatch(addPhoneMedia({ locationId, media: newMediaFile }));

      return { error: null, mediaFile: newMediaFile };
    } catch (error: any) {
      return { error, mediaFile: null };
    }
  };

  const renderDropZoneContents = (audioFile: AudioFile | null, dragActive: boolean) => {
    if (audioFile?.name) {
      if (dragActive) {
        return (
          <div className={styles.dropAnother}>
            <Text>Drop file here...</Text>
          </div>
        );
      }

      return <div className={styles.containFile}>File selected: {audioFile.name}</div>;
    }

    if (dragActive) {
      return (
        <div className={styles.dropFirst}>
          <Text>Drop the file here...</Text>
        </div>
      );
    }

    return (
      <>
        <Heading textAlign={'center'}>Upload Media File</Heading>
        <PrimaryButton className={styles.browseButton} onClick={onBrowseClick}>
          Browse Files
        </PrimaryButton>
        <p>Drag and Drop WAV, MPEG (MP3), or MP4 file here</p>
      </>
    );
  };

  return (
    <div>
      <div className={styles.mainSection}>
        <div
          className={cx(styles.dropzone, mediaFile?.name ? 'hasFile' : '')}
          {...getRootProps({
            onClick: mediaFile?.name
              ? () => {
                  return;
                }
              : (event) => event.stopPropagation(),
          })}
        >
          <input {...getInputProps()} />
          {renderDropZoneContents(mediaFile, isDragActive)}
        </div>
        {error && <div className={styles.error}>{error}</div>}
        {mediaFile?.name && (
          <div className={styles.containRename}>
            <TextField {...fieldProps} label="Rename File" name="mediaFileName" />
            <div className={styles.mediaFileName}>
              <Text>Give your file a name you can remember</Text>
            </div>
          </div>
        )}
      </div>
      <hr className={styles.hr} />
      {!mediaFile?.name && (
        <>
          <Heading level={2} textAlign="center">
            Don't see your media file?
          </Heading>
          <div className={styles.instructions}>
            <Text>
              Dial *86 on your phone to record a new media file, then refresh this page to
              have it appear here for selection.
            </Text>
          </div>
        </>
      )}
      {mediaFile?.name && (
        <ButtonBar>
          <SecondaryButton onClick={props.onRequestClose}>Cancel</SecondaryButton>
          <PrimaryButton onClick={onSubmitUpload} disabled={uploading}>
            Upload File
          </PrimaryButton>
        </ButtonBar>
      )}
    </div>
  );
};
