import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { SwitchField, useForm } from '@weave/design-system';
import React from 'react';
import { useLocationSettingsResource } from '../use-location-settings.hook';

const pmsContactPrefKey = 'use-patient-contact-preferences';

const container = (theme: WeaveTheme) => css`
  width: 400px;
  margin: ${theme.spacing(1, 0)};
  padding: ${theme.spacing(1, 0)};
`;

export const AutoRemindersHonorPMS = () => {
  const { findSetting, updateSetting } = useLocationSettingsResource('sms');
  const setting = findSetting(pmsContactPrefKey);

  const isOn = setting.value !== 'false';
  const { getFieldProps } = useForm({
    fields: {
      honorPMSPref: { type: 'switch' },
    },
  });

  const toggleButton = () => {
    updateSetting({
      set: 'sms',
      key: pmsContactPrefKey,
      value: !isOn ? 'true' : 'false',
    });
  };

  return (
    <div css={container}>
      <SwitchField
        {...getFieldProps('honorPMSPref')}
        value={isOn}
        label={`Honor Communication Preferences`}
        labelPlacement="left"
        onChange={toggleButton}
      />
    </div>
  );
};
