import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

export const bold = css`
  position: relative;
  top: 20px;
  & span {
    font-weight: 600;
    font-size: 90%;
  }
`;

export const toggle = css`
  & > div {
    align-items: center;
    display: flex;
  }

  & > div > .toggle-switch {
    top: 0;
  }

  & > div > span {
    color: #37cff1;
    font-size: 120%;
  }
`;

export const focusedRow = css`
  outline-width: 1px;
  outline-style: solid;
  outline-color: ${theme.colors.weaveBlue};
  box-shadow: 0 0 3px 3px ${theme.colors.weaveBlue};
`;

export const actionButtons = css`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-right: ${theme.spacing(1)};

  svg {
    --size: 20px;
    cursor: pointer;
    height: var(--size);
    width: var(--size);
  }
  svg:hover {
    transform: scale(1.2);
    transition: transform 100ms ease-in-out;
  }
`;

export const rowStyle = css`
  align-items: center;
  display: flex;
  justify-content: space-between;

  & > span.smallCol {
    width: 65px;
    flex: inherit;
  }
  & > span.medCol {
    width: 90px;
    flex: inherit;
    overflow: hidden;
  }
  & > span.bigCol {
    width: 180px;
    flex: inherit;
  }
  & > span {
    flex: 1;
    display: flex;
    align-items: center;
  }
  & > span:first-child {
    padding-left: ${theme.spacing(1)};
    display: block;
    &:not(.headerStyle) {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: auto;
      &:hover {
        display: block;
        white-space: normal;
        overflow: visible;
        z-index: 10;
        height: auto;
        background-color: #eee;
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 10px;
        cursor: pointer;
      }
    }
  }
  & > span.reminder-toggle {
    display: flex;
    justify-content: flex-end;
  }
  & > span:last-of-type {
    flex: inherit;
    width: 56px;
  }
`;

export const copyToClipboard = css`
  align-items: center;
  color: ${theme.colors.weaveBlue};
  cursor: pointer;
  display: flex;
  font-size: ${theme.fontSize(12)};
`;

export const copied = css`
  margin-left: 5px;
  color: #28a745;
`;
