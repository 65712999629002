import React from 'react';
import { BillingInvoiceDetails } from '../../billing.types';
import { InvoiceDetailsTemplateHeader } from './invoice-details-template-header';
import { InvoiceDetailsTemplateContent } from './invoice-details-template-content';
import { InvoiceDetailsTemplateFooter } from './invoice-details-template-footer';
import {
  containerStyle,
  pageFooter,
  pageFooterSpace,
  pageHeader,
  pageHeaderSpace,
  table,
} from './invoice-details-styles';

type Props = {
  invoiceDetails: BillingInvoiceDetails | undefined;
  currency: string;
};

export const InvoiceDetailsTemplate = ({ invoiceDetails, currency }: Props) => {
  return (
    <main css={containerStyle}>
      <div css={pageHeader}>
        <InvoiceDetailsTemplateHeader invoiceDetails={invoiceDetails} />
      </div>

      <div css={pageFooter}>
        <InvoiceDetailsTemplateFooter />
      </div>
      <table css={table}>
        <thead>
          <tr>
            <td>
              <div css={pageHeaderSpace}></div>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <InvoiceDetailsTemplateContent
                invoiceDetails={invoiceDetails}
                currency={currency}
              />
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div css={pageFooterSpace}></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </main>
  );
};
