import { css } from 'emotion';
import { gray3, gray5, white, weaveRed } from '@weave/platform-ui-components';

export const modalCard = css`
  .react-modal-card {
    padding: 30px 20px;
    width: 450px;
  }

  label: modalCard;
`;

export const modalBody = css`
  text-align: center;

  label: modalBody;
`;

export const modalText = css`
  color: ${gray5};
  font-size: 15px;
  margin: 15px 0 25px;

  label: modalText;
`;

export const modalCancelButton = css`
  display: inline-flex;

  label: modalCancelButton;
`;

export const modalContinueButton = css`
  display: inline-flex;
  margin-left: 10px;

  label: modalContinueButton;
`;

export const title = css`
  align-items: center;
  display: flex;

  label: title;
`;

export const containToggle = css`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 32px 0;

  label: containToggle;
`;

export const toggleText = css`
  margin-right: 8px;

  label: toggleText;
`;

export const vmoText = css`
  display: inline-block;
  margin-top: 20px;
  max-width: 850px;

  label: vmoText;
`;

export const eventBodyStyles = css`
  align-items: flex-start;
  background-color: ${white};
  border: 1px solid ${gray3};
  border-radius: 10px;
  display: inline-flex;
  margin-top: 24px;
  max-width: 1000px;
  min-height: 62px;
  padding: 10px 20px;
  vertical-align: top;

  label: eventBodyStyles;
`;

export const containIconStyles = css`
  margin-right: 30px;
  padding-top: 6px;

  label: containIconStyles;
`;

export const mediaDropdown = css`
  display: inline-block;
  width: 300px;

  &.activationError {
    div {
      border: 1px solid ${weaveRed};
    }
  }

  div {
    margin-top: 0;
  }

  label: mediaDropdown;
`;

export const audio = css`
  height: 40px;
  margin-left: 30px;

  label: audio;
`;

export const error = css`
  color: ${weaveRed};
  margin-top: 10px;

  label: error;
`;
