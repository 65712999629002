import { createAction } from 'redux-actions';
import { call, put } from 'redux-saga/effects';
import { CustomAxios } from '../../../axios';

export const sentimentTrainingLeaderboard = createAction(
  'SENTIMENT_TRAINING_LEADERBOARD'
);
export const sentimentTrainingLeaderboardSuccess = createAction(
  'SENTIMENT_TRAINING_LEADERBOARD_SUCCESS'
);
export const sentimentTrainingLeaderboardFailure = createAction<Error>(
  'SENTIMENT_TRAINING_LEADERBOARD_FAILURE'
);

export const sentimentTrainingLeaderboardUrl =
  '/support/v1/sentimenttraining/leaderboard';

export const handleSentimentTrainingLeaderboard = function* (action) {
  try {
    const response = yield call(CustomAxios.get, sentimentTrainingLeaderboardUrl);
    yield put(sentimentTrainingLeaderboardSuccess(response.data.data));
  } catch (error: any) {
    yield put(sentimentTrainingLeaderboardFailure(error));
  }
};
