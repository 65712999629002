import React from 'react';
import { DeleteIcon, NakedButton } from '@weave/design-system';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

import { LocationModel } from '../../../redux/actions/location';

export type LocationTabProps = {
  active: boolean;
  location: LocationModel;
  removeLocation: (locationId: string) => void;
  selectLocation: (locationId: string) => void;
};

export const LocationTab = (props: LocationTabProps) => {
  const handleClick = () => {
    props.selectLocation(props.location.LocationID);
  };

  const removeFromList = () => {
    props.removeLocation(props.location.LocationID);
  };

  const classes = ['location-tab', ...(props.active ? ['active'] : [])].join(' ');

  return (
    <div
      className={classes}
      css={css`
        cursor: pointer;
        border-radius: 4px;
        margin: 0 2px;
        padding: 2px 8px;
        color: inherit;
        background-color: rgba(0, 106, 133, 0.15);
        border: 1px solid rgba(255, 255, 255, 0.25);
        transition: 0.2s all ease;
        min-width: max-content;

        & > a {
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 150px;
          overflow: hidden;
          display: inline-block;
          vertical-align: middle;
          padding-right: 3px;
        }

        &:hover,
        &.active {
          color: rgba(0, 0, 0, 0.5);
          background-color: rgba(255, 255, 255, 0.5);
          border-color: rgba(255, 255, 255, 0.5);
        }

        &.active {
          cursor: default;
        }
      `}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={handleClick} title={`${props.location.Name} (${props.location.Slug})`}>
        {props.location.Name}
      </a>
      <NakedButton
        className="delete-icon"
        css={css`
          svg {
            --size: 20px;
            height: var(--size);
            margin-left: ${theme.spacing(1)};
            width: var(--size);

            &:hover {
              transform: scale(1.2);
            }
          }
        `}
        onClick={removeFromList}
      >
        <DeleteIcon color="white" />
      </NakedButton>
    </div>
  );
};
