import { css } from 'emotion';
import { weaveTheme } from '@weave/theme-original/dist/weaveTheme';

export const containerStyles = css`
  margin-top: 20px;
  width: 49%;

  @media only screen and (max-width: 1200px) {
    width: 80%;
  }
  @media only screen and (max-width: 970px) {
    width: 100%;
  }
`;
export const titlesDiv = css`
  margin-bottom: 24px;
`;
export const formSection = css`
  margin-bottom: 32px;
`;
export const formTitle = css`
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
`;
export const errorMessage = css`
  color: ${weaveTheme.colors.error};
  display: block;
  margin-top: 16px;
  font-size: 15px;
`;
export const marginTop = css`
  margin-top: 24px;
`;
export const inputBox = css`
  width: 63%;
`;
export const selectBox = css`
  div:first-child {
    margin: 8px 0px 0px;
  }
`;
export const secondaryTextStyles = css`
  font-size: 12px;
  display: inline-block;
  margin-top: 6px;
`;
export const radioGroup = css`
  padding-left: 24px;
  margin-bottom: 25px;
  div:nth-child(1) {
    margin-bottom: 20px;
  }
`;
export const radioButton = css`
  margin-right: 23px;
`;
export const radioButtonLabel = css`
  font-size: 16px;
`;
export const bodyText = css`
  font-size: 16px;
  line-height: 22px;
`;
export const textAreaStyles = css`
  min-height: 142px;
  margin: 16px 0px 0px 0px;
`;
export const marginBottom = css`
  margin-bottom: 8px;
`;
