import React from 'react';
import { css, cx } from 'emotion';

import ServerDataTableComponent from '../../shared/server-data-table/server-data-table.component';
import { Loader } from '../../shared/loader/loader.component';
import UserListModel from '../../users/UserListModel';
import { NetworkAuditModel } from './network-audits.model';
import { tableColumns } from './network-audits-table';
import { Page } from '../../shared';
import { LocationModel } from '../../../redux/actions/location';
import { RowDetails } from './row-details/row-details';
import { getDataCenterPingUrl } from './network-audits.helpers';
import { Modal, PrimaryButton } from '@weave/design-system';

interface NetworkAuditsProps {
  loading: boolean;
  networkAudits: NetworkAuditModel[];
  getNetworkAudits: () => void;
  getConnectedUsers: () => void;
  runNetworkAudit: (userId) => void;
  locationId: string;
  currentLocation: LocationModel;
  users: UserListModel[];
  loadingUsers: boolean;
}

interface NetworkAuditsState {
  modalOpen: boolean;
  clickedRowData?: NetworkAuditModel;
}

const modalContainer = css`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.03);
`;

const headerButtons = css`
  display: flex;
  width: 30%;
`;

const loader = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export class NetworkAudits extends React.Component<
  NetworkAuditsProps,
  NetworkAuditsState
> {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      clickedRowData: undefined,
    };
  }

  componentDidUpdate(prevProps: NetworkAuditsProps) {
    if (this.props.locationId !== prevProps.locationId) {
      this.loadData();
    }
  }

  loadData = () => {
    if (this.props.locationId) {
      this.props.getNetworkAudits();
    }
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  openModal = () => {
    this.setState({ modalOpen: true });
    this.props.getConnectedUsers();
  };

  runAudit = (userId) => {
    this.closeModal();
    this.props.runNetworkAudit(userId);
  };

  render() {
    return (
      <Page
        title="Network Audits"
        headerActions={
          <div className={headerButtons}>
            <PrimaryButton onClick={this.openModal}>Run Audit</PrimaryButton>
            <PrimaryButton
              className={css`
                margin-left: 8px;
              `}
              onClick={this.loadData}
            >
              Refresh Data
            </PrimaryButton>
          </div>
        }
      >
        <ServerDataTableComponent
          columns={tableColumns}
          className={css`
            .rt-td {
              cursor: pointer;
            }
          `}
          data={this.props.networkAudits}
          sortable={false}
          loading={this.props.loading}
          loadData={this.loadData}
          resetPage={false}
          getTdProps={(state, rowInfo) => ({
            dataCenterPingUrl: getDataCenterPingUrl(
              this.props.currentLocation.DataCenter
            ),
            onClick: () => {
              this.setState({ clickedRowData: rowInfo?.original });
            },
          })}
        />
        <Modal onClose={this.closeModal} show={this.state.modalOpen}>
          <Modal.Header>Select User to Audit</Modal.Header>
          <Modal.Body>
            {this.props.loadingUsers ? (
              <div className={loader}>
                <Loader />
              </div>
            ) : (
              <div>
                {this.props.users.map((user, index) => {
                  return (
                    <div
                      key={user.UserID}
                      className={cx(
                        modalContainer,
                        css(
                          index % 2 && {
                            backgroundColor: 'white',
                          }
                        )
                      )}
                    >
                      <div>
                        {user.FirstName} {user.LastName}
                      </div>
                      <div>
                        <PrimaryButton onClick={() => this.runAudit(user.UserID)}>
                          Run
                        </PrimaryButton>
                      </div>
                    </div>
                  );
                })}
                {this.props.users.length === 0 && (
                  <div>
                    No user is currently logged in to the client for this location.{' '}
                  </div>
                )}
              </div>
            )}
          </Modal.Body>
        </Modal>

        <Modal
          onClose={() => this.setState({ clickedRowData: undefined })}
          show={!!this.state.clickedRowData}
          maxWidth={500}
        >
          <Modal.Header>Data Center Pings</Modal.Header>
          <Modal.Body>
            <RowDetails
              clickedRowData={this.state.clickedRowData}
              dataCenterPingUrl={getDataCenterPingUrl(
                this.props.currentLocation.DataCenter
              )}
            />
          </Modal.Body>
        </Modal>
      </Page>
    );
  }
}
