import React, { ReactNode } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { css } from '@emotion/core';
import { WeaveTheme, theme } from '@weave/theme-original';
import { Heading, BackIcon, Text, SpinningLoader } from '@weave/design-system';
import { ExpandText } from '../expand-text/expand-text.component';

type breadcrumbType = {
  url: string;
  title: string | undefined;
};

export type PageProps = {
  className?: string;
  loading?: boolean;
  expandedSubtitle?: string;
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  headerActions?: ReactNode;
  children?: ReactNode;
  showBackBtn?: boolean;
  breadcrumbs?: breadcrumbType[];
  customBackUrl?: string;
};

const page = (theme: WeaveTheme) => css`
  border-radius: ${theme.borderRadius.medium};
  box-shadow: ${theme.shadows.heavy};
  display: block;
  background-color: ${theme.colors.white};
  margin: ${theme.spacing(0, 2, 2, 2)};
  padding: ${theme.spacing(5)};
  flex: auto;

  label: page;
`;

const pageHeader = css`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 100%;
  margin-bottom: ${theme.spacing(3)};
  /* padding-bottom: 24px; */
  label: pageHeader;
`;

const breadcrumbs = css`
  list-style: none;
  padding: 0;
  margin-bottom: ${theme.spacing(3)};
  color: ${theme.colors.gray500};
`;

/**
 * Css Function for Handling Spinner Loader View at center
 * @returns function -> css : SerializedStyles
 */
const spinnerLoader = () => css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * Css Function for Handling Subtitle Styling
 * Note: Added font size as same as Subtitle component of platform-ui-components
 * in order to achieve same user experience.
 * @returns : function -> css : SerializedStyles
 */
export const subTitle = (theme: WeaveTheme) => css`
  margin: ${theme.spacing(1, 0, 0)};
  max-width: 750px;
`;

const Breadcrumbs: React.FC<{ items: breadcrumbType[] }> = ({ items }) => (
  <ul css={breadcrumbs}>
    {items.map((item, index) => (
      <li
        key={`${item.title}-${index}`}
        css={css`
          display: inline-flex;
          &:first-child {
            &:before {
              display: none;
            }
          }
          &:before {
            content: '>';
            padding: 0 16px;
          }
        `}
      >
        {index === items.length - 1 ? (
          item.title
        ) : (
          <>
            <NavLink to={item.url}>{item.title}</NavLink>
          </>
        )}
      </li>
    ))}
  </ul>
);

export const Page = ({
  loading = false,
  expandedSubtitle,
  title,
  subtitle,
  breadcrumbs,
  headerActions,
  children,
  showBackBtn = false,
  customBackUrl,
}: PageProps) => {
  const history = useHistory();

  /**
   * Function is defined to showcase SpinningLoader or Children Props(Page Content)
   * @returns : JSX
   */
  const pageContent = () =>
    loading ? (
      <div css={spinnerLoader}>
        <SpinningLoader size="large" />
      </div>
    ) : (
      children
    );

  return (
    <section css={page}>
      {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}

      <header css={pageHeader}>
        <div>
          <Heading
            level={1}
            css={css`
              margin: 0;
              display: inline-flex;
              align-items: center;
              border: none;
            `}
          >
            <>
              {showBackBtn && history.length && (
                <BackIcon
                  css={css`
                    margin-right: 16px;
                    cursor: pointer;
                  `}
                  onClick={() =>
                    customBackUrl ? history.push(customBackUrl) : history.goBack()
                  }
                />
              )}
              {title}
            </>
          </Heading>
          {subtitle && (
            <Text css={subTitle} color="light">
              <ExpandText text={subtitle} expandedText={expandedSubtitle} />
            </Text>
          )}
        </div>
        {headerActions && headerActions}
      </header>
      {pageContent()}
    </section>
  );
};
