import { css } from '@emotion/core';
import React from 'react';
import { BillingInvoiceDetails } from '../billing.types';
import { InvoiceDetailsTemplate } from './invoice-details-template/invoice-details-template';

type Props = {
  invoiceDetails: BillingInvoiceDetails | any;
  currency: string;
};

// Wrapper component meant to wrap the InvoiceDetails component to add print specific
// styles and also to use the ref used by react-to-print library.
export const InvoiceDetailsPrint = React.forwardRef<any, Props>(
  ({ invoiceDetails, currency }, ref) => {
    return (
      <div
        css={css`
          /* Keep this hidden so that it will not show up on the page. react-to-print will
        select the child div using the ref to display in the print dialogue. */
          display: none;
        `}
      >
        <div ref={ref}>
          <InvoiceDetailsTemplate currency={currency} invoiceDetails={invoiceDetails} />
        </div>
      </div>
    );
  }
);
