import React, { useEffect, useState } from 'react';
import { ExternalLinkIcon, Heading, SyncAppHealthIcon, Text } from '@weave/design-system';
import {
  cardHeaderContainer,
  cardHeaderTitle,
  cardTabsHeaderContainer,
  connectionDiv,
  externalLinkStyle,
  mRightSpacing,
  syncAppCard,
} from './sync-app-card.styles';
import { useInterval, useResource, useWebSocket } from '../../shared/hooks';
import { LastSyncInfoTab } from './sync-app-tabs/last-sync-info-tab.component';
import { SyncStatusTab } from './sync-app-tabs/sync-status-tab.component';
import { TerminalTab } from './sync-app-tabs/terminal-tab.component';
import { SyncAppTabHeader } from './sync-app-tab-header.component';
import { CopyInfo } from './copy-info';
import { ConnectionState, SyncAppCardProps, SyncTabs } from '../sync-app.types';
import { getMultipleSyncAppInfoPayload, syncTabs } from './sync-app-card.utils';
import { parseIfJson } from '../../../helpers/utils';
import { REACT_APP_SYNCAPP_SOCKET_URL } from '../../../config/app';
import { AuthStorage } from '../../../redux/actions/auth/auth.types';
import { handleLocalStorage } from '../../../redux/actions/auth/auth.helpers';

const connectionPollInterval = 30000;

const getStatusColor = (type: string) => {
  const COLOR_MAP = {
    Healthy: 'success',
    Unhealthy: 'warn',
    Dead: 'error',
    default: 'default',
  };
  return COLOR_MAP[type] || COLOR_MAP.default;
};

export const SyncAppCard = (props: SyncAppCardProps) => {
  const {
    syncApp,
    syncAppsInfo,
    getSyncAppInfo,
    getMultipleSyncAppInfo,
    addToTerminalText,
    managePermission,
    manageAdvancedPermission,
    installAdvancedPermission,
    popoutSyncApp,
    externalWindowDoc,
  } = props;
  const {
    SourceID: sourceId,
    PracticeManagementSystem,
    SyncAppHealth,
    SourceName: sourceName,
  } = syncApp;
  const syncAppDetails = syncAppsInfo[sourceId] || {};
  const osInfo = syncAppDetails.osInfo || {};
  const adapterInfo = syncAppDetails.adapterInfo || {};
  const versionInfo = syncAppDetails.versionInfo || '';
  const slug = syncAppDetails?.integrationInfo?.username ?? '';
  const terminalPermissions = {
    managePermission,
    manageAdvancedPermission,
    installAdvancedPermission,
  };
  const [activeTab, setActiveTab] = useState<string>('');
  const [socketState, setSocketState] = useState<ConnectionState | null>(null);

  useEffect(() => {
    const payload = getMultipleSyncAppInfoPayload(sourceId);
    getMultipleSyncAppInfo(payload);
  }, []);

  const {
    error: fetchConnectionError,
    data: connectionId,
    loading: fetchingConnectionId,
    refresh: getConnectionId,
  } = useResource<string>({
    url: `support/v1/syncapp/connections?sourceID=${sourceId}`,
    emptyState: null,
    transformData: (response) => {
      const connections = response?.data?.data?.connections ?? [];
      return connections.length === 1 ? connections[0]?.connectionID ?? '' : '';
    },
  });

  useInterval(() => {
    getConnectionId();
  }, connectionPollInterval);

  useEffect(() => {
    if (!fetchingConnectionId && (connectionId === '' || fetchConnectionError)) {
      setSocketState(ConnectionState.Disconnected);
    }
  }, [fetchingConnectionId, connectionId, socketState]);

  useWebSocket({
    url: `${REACT_APP_SYNCAPP_SOCKET_URL}?id=${slug}:${connectionId}&token=${handleLocalStorage.get(
      AuthStorage.weave_token
    )}`,
    dependencies: [fetchingConnectionId, connectionId],
    condition: !!connectionId && !fetchingConnectionId,
    onopen: () => {
      setSocketState(ConnectionState.Connected);
      if (!activeTab) {
        setActiveTab(SyncTabs.Terminal);
      }
    },
    onerror: (evt: Event) => {
      setSocketState(ConnectionState.Disconnected);
    },
    onmessage: (evt: MessageEvent) => {
      if (evt.data) {
        try {
          const message = parseIfJson(evt.data);
          console.log('>>>>>>socket-message>>>>', message);
          if (message.error) {
            addToTerminalText({ sourceId, terminalText: `Error: ${message.error}` });
          }
          if (typeof message === 'string') {
            addToTerminalText({ sourceId, terminalText: message });
          }
        } catch {
          // Continue regardless of the error.
        }
      }
    },
  });

  const togglePopoutWindow = () => {
    if (!externalWindowDoc) {
      popoutSyncApp(syncApp);
    }
    setActiveTab('');
  };

  return (
    <div css={syncAppCard}>
      <div css={cardHeaderContainer}>
        <div>
          <SyncAppHealthIcon color={getStatusColor(SyncAppHealth)} />
          <Heading level={2} as="span" css={cardHeaderTitle}>
            {PracticeManagementSystem}
            {adapterInfo.version ? ` | ${adapterInfo.version}` : ''}
          </Heading>
          {!fetchingConnectionId && socketState === ConnectionState.Disconnected && (
            <Text as="span" css={connectionDiv}>
              Disconnected
            </Text>
          )}
          {!externalWindowDoc && (
            <ExternalLinkIcon css={externalLinkStyle} onClick={togglePopoutWindow} />
          )}
        </div>
        <div>
          <Text as="span" weight="bold" css={mRightSpacing(1)}>
            OS:
          </Text>
          <Text as="span" color="light" css={mRightSpacing(5)}>
            {osInfo.Product ? osInfo.Product : ''}
          </Text>
          <Text as="span" weight="bold" css={mRightSpacing(1)}>
            Host Name:
          </Text>
          <Text as="span" color="light" css={mRightSpacing(5)}>
            {osInfo.Hostname ? osInfo.Hostname : ''}
          </Text>
          <Text as="span" weight="bold" css={mRightSpacing(1)}>
            Sync App Version:
          </Text>
          <Text as="span" color="light" css={mRightSpacing(5)}>
            {versionInfo ? versionInfo : ''}
          </Text>
          <Text as="span" weight="bold" css={mRightSpacing(1)}>
            Source Name:
          </Text>
          <Text as="span" color="light" css={mRightSpacing(5)}>
            {sourceName ? sourceName : '-'}
          </Text>
          <Text as="span" weight="bold" css={mRightSpacing(1)}>
            Source ID:
          </Text>
          <CopyInfo text={sourceId} />
        </div>
      </div>
      <div css={cardTabsHeaderContainer}>
        {syncTabs.map((tab, index) => (
          <SyncAppTabHeader
            key={index}
            setActiveTab={setActiveTab}
            icon={tab.icon}
            activeStatus={activeTab === tab.syncTab}
            label={tab.label}
            syncTab={tab.syncTab}
          />
        ))}
      </div>
      {activeTab && sourceId && (
        <React.Fragment>
          {activeTab === SyncTabs.Terminal && (
            <TerminalTab
              sourceId={sourceId}
              loading={syncAppDetails.loading}
              terminalPermissions={terminalPermissions}
              slug={slug}
              connectionId={connectionId}
              socketOpen={socketState === ConnectionState.Connected}
              terminalMessages={syncAppDetails.terminalText}
              getSyncAppInfo={getSyncAppInfo}
              getConnectionId={getConnectionId}
              addToTerminalText={addToTerminalText}
              clearTerminalText={props.clearTerminalText}
              externalWindowDoc={externalWindowDoc}
            />
          )}
          {activeTab === SyncTabs.SyncStatus && (
            <SyncStatusTab
              sourceId={sourceId}
              loading={syncAppDetails.loading}
              getSyncAppInfo={getSyncAppInfo}
              syncStatusInfo={syncAppDetails['syncStatus']}
              externalWindowDoc={externalWindowDoc}
            />
          )}
          {activeTab === SyncTabs.LastSyncInfo && (
            <LastSyncInfoTab
              sourceId={sourceId}
              loading={syncAppDetails.loading}
              getSyncAppInfo={getSyncAppInfo}
              lastSyncInfo={syncAppDetails['lastSyncInfo']}
              externalWindowDoc={externalWindowDoc}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};
