import { connect } from 'react-redux';

import { DayOfWeekSettings } from './day-of-week.component';

import {
  dayOfWeekRemindersReset,
  getDayOfWeekReminders,
  updateDayOfWeekReminders,
} from '../../../redux/actions/day-of-week-reminders';
import FeatureFlagModel from '../../settings/feature-flags-container/feature-flag.model';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';

interface StoreProps {
  loading: boolean;
  features: FeatureFlagModel[];
  locationId: string;
}
interface DispatchProps {
  getDayOfWeekReminders: () => void;
}

const props = (store) => ({
  loading: store.notificationsSettings.loading,
  dayOfWeekReminderSettings: store.dayOfWeek.settings,
  features: store.featureFlags.data,
  locationId: selectCurrentLocationId(store),
});

const actions = {
  getDayOfWeekReminders,
  updateDayOfWeekReminders,
  dayOfWeekRemindersReset,
};

export const DayOfWeekSettingsContainer = connect<StoreProps, DispatchProps, any, any>(
  props,
  actions
)(DayOfWeekSettings);
