import { css } from '@emotion/core';
import { useAlert } from '@weave/alert-system';
import { BackIcon, ContentLoader, Heading, IconButton, Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { ReactComponent as PdfIcon } from '../../../../images/pdf-icon.svg';
import { selectCurrentLocationId } from '../../../../redux/actions/location';
import { history } from '../../../../redux/store/history';
import { InvoicesDetailsContext } from '../billing-page';
import { billingApi } from '../billing.api';
import { formatSubscriberInvoiceDateForDisplay } from '../billing.helpers';
import { BillingInvoiceDetails } from '../billing.types';
import { InvoiceDetails as InvoiceDetailsComponent } from './invoice-details';
import { InvoiceDetailsPrint } from './invoice-details-print';

export const InvoiceDetailsPage = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const [invoiceDetails, setInvoiceDetails] = useState<BillingInvoiceDetails>();
  const { addInvoiceDetails } = useContext(InvoicesDetailsContext);
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => {
      return componentRef.current;
    },
    documentTitle: `${invoiceDetails?.invoice.billingContact.companyName}-${invoiceDetails?.invoice.id}`,
  });

  const fetchInvoiceDetails = async () => {
    try {
      setLoading(true);

      const data = await billingApi.getInvoiceDetails(invoiceId);
      setInvoiceDetails(data);

      // Also add it to the context in case it gets used later on.
      addInvoiceDetails(invoiceId, data);
    } catch (error: any) {
      console.error(error);
      alert.error('Error retrieving invoice details.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (locationId) {
      setInvoiceDetails(undefined);

      fetchInvoiceDetails();
    }
  }, [locationId]);

  const currency = invoiceDetails?.invoice.currency ?? 'USD';

  return (
    <main>
      {loading ? (
        <ContentLoader show={true} />
      ) : (
        <div css={containerStyle}>
          <header css={headerStyle}>
            <div>
              <div css={headerIconAndTitle}>
                <IconButton
                  css={backButtonStyles}
                  showLabelOnHover
                  onClick={() => history.push(`/location/${locationId}/settings/billing`)}
                  label="Back to Invoices"
                >
                  <BackIcon />
                </IconButton>
                <Heading level={1}>Invoice</Heading>
              </div>
              {invoiceDetails?.invoice?.createTime && (
                <Text>
                  Your statement from{' '}
                  {formatSubscriberInvoiceDateForDisplay(
                    invoiceDetails?.invoice?.createTime
                  )}
                </Text>
              )}
            </div>

            <IconButton label="Print Invoice" onClick={handlePrint}>
              <PdfIcon />
            </IconButton>
          </header>

          <InvoiceDetailsComponent invoiceDetails={invoiceDetails} currency={currency} />

          <InvoiceDetailsPrint
            ref={componentRef}
            invoiceDetails={invoiceDetails}
            currency={currency}
          />
        </div>
      )}
    </main>
  );
};

const containerStyle = css`
  padding: ${theme.spacing(4)};

  & + & {
    margin-bottom: ${theme.spacing(1)};
  }
`;

const headerStyle = css`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.gray400};
  margin-bottom: ${theme.spacing(3)};

  h1 {
    margin: 0;
  }

  p {
    margin: ${theme.spacing(0, 0, 1)};
  }
`;

const headerIconAndTitle = css`
  display: flex;
  position: relative;
`;

const backButtonStyles = css`
  bottom: 5px;
`;
