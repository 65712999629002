import React, { useCallback, useState } from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

import { RemindersListItem } from './auto-reminders-list-item.component';
import { AutoReminderModalContainer } from '../auto-reminder-modal-container/auto-reminder-modal.container';
import {
  ReminderDetails,
  ReminderModalContent,
} from '../../../../redux/actions/notifications-settings/notification-settings.types';

interface Props {
  data: ReminderDetails[];
}

export const RemindersList = ({ data }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReminderModalContent>('options');
  const [reminderId, setReminderId] = useState<string>('');

  const setModal = useCallback((reminderId, modalContent) => {
    setModalContent(modalContent);
    setReminderId(reminderId);
    setShowModal(true);
  }, []);

  const toggleModal = () => setShowModal(!showModal);

  return (
    <>
      <div
        css={css`
          border: 1px solid rgba(0, 0, 0, 0.03);
          margin-bottom: ${theme.spacing(2)};

          & > div:nth-child(odd) {
            background: rgba(0, 0, 0, 0.03);
          }
          & > div {
            height: 42px;
          }
          & > div:hover {
            background: rgba(0, 0, 0, 0.05);
          }
        `}
      >
        {data.map((item) => (
          <RemindersListItem
            key={`${item.id}-reminders`}
            setModalContent={setModal}
            reminderDetails={item}
          />
        ))}
      </div>

      {showModal && (
        <AutoReminderModalContainer
          onRequestClose={toggleModal}
          content={modalContent}
          reminderId={reminderId}
        />
      )}
    </>
  );
};
