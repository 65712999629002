import { css } from 'emotion';

export const modalMainSectionHeight = '322px';

export const modalCard = css`
  .react-modal-card {
    height: 621px;
    max-height: none;
    padding: 20px 50px;
    text-align: center;
    width: 616px;
  }
`;

export const containTabs = css`
  margin-top: 16px;

  label: containTabs;
`;

export const customTabs = css`
  li {
    margin: 0;
    width: 50%;
  }

  label: customTabs;
`;
