export interface LocationFeature {
  id: string;
  name: string;
  description: string;
  state: FeatureStates;
}

export enum FeatureStates {
  Hide = 'hide',
  Promote = 'promote',
  Active = 'active',
}
