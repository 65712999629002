import React from 'react';
import { css } from 'emotion';
import { modalTitle, modalBodyText, modalButton } from '../handoff-hub.styles';
import { FailedFactorsData } from '../../handoff-hub.types';
import {
  ButtonBar,
  Heading,
  PrimaryButton,
  SecondaryButton,
  Text,
  TextareaField,
  useFormField,
  XIcon,
} from '@weave/design-system';
import { useEffect } from 'react';

const modalButtonGroup = css`
  display: flex;
  justify-content: center;
`;
const failureModalButton = css`
  font-size: 14px;
  height: 40px;
  width: 119px;
  box-shadow: 0 5px 10px 0 rgba(121, 126, 130, 0.15);
  margin: 0px 8px;
`;
const textAreaStyles = css`
  width: 318px;
  min-height: 120px;
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 0px;
`;
const failedFactorsTable = css`
  text-align: left;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  tr {
  }
  td {
    padding: 8px;
  }
`;

export type ErrorsVerificationProps = {
  getFailedFactors: () => FailedFactorsData;
  toggleModal: () => void;
  handleHandoffSubmit: () => void;
  setBypassNotes: (value: string) => void;
  bypassNotes: string;
};

export const ErrorsVerification = (props: ErrorsVerificationProps) => {
  const byPassNotesField = useFormField({ type: 'text' });
  const { unByPassable, byPassable, total } = props.getFailedFactors();

  if (!(total > 0)) {
    props.handleHandoffSubmit();
  }

  useEffect(() => {
    props.setBypassNotes(byPassNotesField.value);
  }, [byPassNotesField.value]);

  return (
    <div>
      {unByPassable.length > 0 ? (
        <div>
          <Heading textAlign="center" className={modalTitle}>
            Handoff Not Allowed
          </Heading>
          <Text color="light" className={modalBodyText}>
            The errors below are required tasks for handoff. They cannot be bypassed.
          </Text>
          <div className={failedFactorsTable}>
            <table>
              <tbody>
                {unByPassable.map((name) => (
                  <tr key={name}>
                    <td>
                      <XIcon color="error" />
                    </td>
                    <td>{name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <PrimaryButton onClick={props.toggleModal} className={modalButton}>
            Got it
          </PrimaryButton>
        </div>
      ) : (
        <div>
          <div>
            <Heading textAlign="center" className={modalTitle}>
              Verification Needed
            </Heading>
            <Text color="light" className={modalBodyText}>
              The errors below are required tasks for handoff. Please provide detailed
              explanations to add to your handoff note below to proceed.
            </Text>
            <div className={failedFactorsTable}>
              <table>
                <tbody>
                  {byPassable.map((name) => (
                    <tr key={name}>
                      <td>
                        <XIcon color="error" />
                      </td>
                      <td>{name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <TextareaField
              {...byPassNotesField}
              name="bypassNotes"
              label="By Pass Notes"
              value={props.bypassNotes}
              className={textAreaStyles}
            />
          </div>
          <div className={modalButtonGroup}>
            <ButtonBar>
              <SecondaryButton onClick={props.toggleModal} className={failureModalButton}>
                cancel
              </SecondaryButton>
              <PrimaryButton
                onClick={props.handleHandoffSubmit}
                className={failureModalButton}
                disabled={!props.bypassNotes}
              >
                Handoff
              </PrimaryButton>
            </ButtonBar>
          </div>
        </div>
      )}
    </div>
  );
};
