type PricingObject = {
  id: string;
  transactionCost: number;
  cardNotPresentTransactionCost: number;
  rate: number;
  cardNotPresentRate: number;
  isDefault: boolean;
  createdAt: string;
};

type PricingDetailsInput = {
  rate: number;
  transactionCost: number;
};

export type PricingModel = {
  pricingId: string;
  cardNotPresent: PricingDetailsInput;
  cardPresent: PricingDetailsInput;
  createdAt: string;
  locationId: string;
  plan: { name: MerchantPlanTypes };
};

// The pricing response was merged with a pricing object for backwards compatibility.
// Going forward with split tear pricing, we will use the pricings array. The top level
// pricing object will be the latest price.
export type OldPricingModel = PricingObject & {
  pricings: PricingObject[];
};

export enum MerchantPlanTypes {
  TextToPay = 'TextToPay',
  Full = 'Full',
}
export enum MerchantPlanTypeLabels {
  TextToPay = 'Text to Pay',
  Full = 'Full Payments',
}
export enum CreateNewPricingMerchantPlanTypes {
  TextToPay = 'TEXT_TO_PAY',
  Full = 'FULL',
}

export type CreateNewPricingModel = {
  pricingId?: string;
  cardNotPresent: PricingDetailsInput;
  cardPresent?: PricingDetailsInput;
  planName: CreateNewPricingMerchantPlanTypes;
  locationId: string;
};
