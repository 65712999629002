import React from 'react';
import { Typography } from '@weave/platform-ui-components';
import moment from 'moment';
import { css } from 'emotion';

const textStyles = css`
  font-size: 16px;
`;

export type PortDateTextProps = {
  ports: any[];
};

export const PortDateText = ({ ports }: PortDateTextProps) => {
  const getPortDateText = () => {
    if (!ports.length) {
      return undefined;
    }

    let nextPortDate;
    let isFirmOrderCommitmentDate = false;
    ports.forEach((port) => {
      if (!port?.porting_requests?.[0]) {
        return;
      }

      const portRequest = port.porting_requests[0];
      if (portRequest.firm_order_commitment_date) {
        const focDate = moment(portRequest.firm_order_commitment_date).utc();
        if (!nextPortDate && focDate.isAfter(moment())) {
          nextPortDate = focDate;
          isFirmOrderCommitmentDate = true;
        } else if (focDate.isAfter(moment()) && focDate.isBefore(nextPortDate)) {
          nextPortDate = focDate;
          isFirmOrderCommitmentDate = true;
        }
      } else if (
        portRequest.requested_firm_order_commitment_date &&
        !isFirmOrderCommitmentDate
      ) {
        const requestedFocDate = moment(
          portRequest.requested_firm_order_commitment_date
        ).utc();
        if (!nextPortDate && requestedFocDate.isAfter(moment())) {
          nextPortDate = requestedFocDate;
        } else if (
          requestedFocDate.isAfter(moment()) &&
          requestedFocDate.isBefore(nextPortDate)
        ) {
          nextPortDate = requestedFocDate;
        }
      }
    });

    if (!nextPortDate) {
      return undefined;
    }

    return (
      <Typography variant="Body" className={textStyles}>
        {`NOTE: This location’s requested port date\
          is ${nextPortDate.utc().format('MM/DD/YY')}. Please be sure to\
          schedule all onboarding events before the date mentioned.`}
      </Typography>
    );
  };

  return <>{getPortDateText()}</>;
};
