import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@weave/platform-ui-components';

import { ManualTemplatesEdit } from './manual-templates-edit.component';
import { ManualTemplateItem } from './manual-template-item.component';
import {
  getManualTemplates,
  postManualTemplates,
} from '../../../redux/actions/manual-templates';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';
import { ManualTemplate } from '../../../redux/actions/manual-templates/manual-templates.reducer';
import { Page } from '../../shared/page/page.component';

const templatesSelector = (state: any) => ({
  locationId: selectCurrentLocationId(state),
  templates: state.manualTemplates.data,
});

export const ManualTemplates = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [cleanedTemplates, setCleanedTemplates] = useState<ManualTemplate[]>([]);
  const dispatch = useDispatch();

  const { locationId, templates } = useSelector(templatesSelector);

  useEffect(() => {
    setCleanedTemplates(
      templates.filter((template) => !!template.Type && template.Type !== 'missedcall')
    );
  }, [JSON.stringify(templates)]);

  useEffect(() => {
    dispatch(getManualTemplates());
  }, [locationId]);

  const editSave = (templatesToSave: ManualTemplate[]) => {
    dispatch(postManualTemplates(templatesToSave));

    setModalOpen(false);
  };
  return (
    <Page
      title="Manual Templates"
      headerActions={
        <Button color="blue" onClick={() => setModalOpen(true)}>
          Edit
        </Button>
      }
    >
      {cleanedTemplates.map((template, index) => {
        return <ManualTemplateItem template={template} key={index} />;
      })}
      <ManualTemplatesEdit
        modalOpen={modalOpen}
        handleEditClose={() => setModalOpen(false)}
        editSave={editSave}
        title="Manual Templates"
        templates={[...cleanedTemplates]}
      />
    </Page>
  );
};
