import { css } from 'emotion';
import {
  borderGrey,
  weaveBlue,
  weaveDanger,
  weaveSuccess,
  weaveWarning,
} from '../../../shared/styles/colors';
import { gray5 } from '@weave/platform-ui-components';

export const flex = css`
  label: flex;
  display: flex;
`;

export const title = css`
  label: title;
  text-transform: capitalize;
`;

export const emailEdit = css`
  label: emailEdit;
  margin: 35px;
`;

export const updateButton = css`
  label: updateButton;
  margin: auto;
`;

export const content = css`
  label: content;
  width: 95%;
`;

export const templateTags = css`
  label: templateTags;
  border-bottom: 1px solid ${gray5};
  border-radius: 5px;
  box-shadow: 0px 0px 4px ${gray5};
  background-color: rgba(255, 255, 255, 0.6);
  position: sticky;
  top: 0px;
  padding: 10px;
  z-index: 1;
`;

export const action = css`
  label: action;
  width: 5%;
  margin: auto;
  margin-left: 5px;
`;

export const emailHeader = css`
  label: emailHeader;
  display: flex;
`;

export const emailSave = css`
  label: emailSave;
  margin-left: 65%;
`;

export const splitRow = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
  & > div {
    width: 49%;
    display: inline-block;
  }
`;

export const difference = css`
  position: relative;

  & .key .added {
    background-color: ${weaveSuccess}33;
  }

  & .key .removed {
    background-color: ${weaveWarning}33;
  }

  & del {
    background-color: ${weaveWarning}33;
    text-decoration: none;
  }

  & ins {
    background-color: ${weaveSuccess}33;
    text-decoration: none;
  }

  & .clearSelection {
    position: absolute;
    color: ${weaveDanger};
    font-weight: bolder;
    top: -10px;
    right: 7px;
    :hover {
      cursor: pointer !important;
    }
  }
`;

export const textTemplateStyle = css`
  width: 200px;
`;

export const templateTag = css`
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
  background-color: ${weaveBlue};
  padding: 3px;
  color: white;
  border-radius: 5px;
  cursor: grab;
  &.disabledTag {
    background-color: ${borderGrey};
    cursor: not-allowed;
  }
`;

export const tags = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 3px;
`;

export const textarea = css`
  border-radius: 5px;
  border: 1px solid ${borderGrey};
  width: 100%;
  height: 100%;
  min-height: 100px;
  padding: 0;
  &:focus {
    outline: none;
  }
  &.focusedTextArea {
    border: 1px solid ${weaveBlue};
  }
  &.foo {
    border: 5px solid ${weaveBlue};
  }
`;

export const wrapper = css`
  display: flex;
  height: 500px;
  flex-direction: column;
  justify-content: space-between;
`;
export const container = css`
  overflow: scroll;
`;

export const button = css`
  position: absolute;
  top: 75px;
  right: 20px;
`;

export const addContent = css`
  label: addContentBox;
  display: flex;
  text-align: center;
  width: 100%;
`;

export const selectContent = css`
  label: selectContent;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
`;
