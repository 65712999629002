import { connect } from 'react-redux';
import { FormikBag, FormikErrors, withFormik } from 'formik';
import { compose } from 'lodash/fp';

import {
  LocationForm,
  LocationFormProps,
} from '../location-form/location-form.component';
import {
  requestCreateLocation,
  LocationModelWithPhoneData,
  mockLocationWithPhoneData,
  CreateLocationDTO,
} from '../../../redux/actions/location/locations';
import { resetLocationCreationStatus } from '../../../redux/actions/location/location-status';
import { Timezones } from '../../../models/timezones';
import { isPhoneNumber } from '../../../helpers/validators';
import { selectHasWeaveAcl } from '../../../redux/actions/auth/auth.selectors';
import { Store } from '../../../redux/store/store.model';
import { CoreACLs } from '../../../redux/actions/auth/auth.types';

interface Props extends LocationFormProps {
  createLocation: (location: CreateLocationDTO) => void;
}

export const validateLocationCreateForm = function (values) {
  const errors: FormikErrors<LocationModelWithPhoneData> = {};

  if (!values.Name) {
    errors.Name = 'Required';
  }
  if (!values.Slug) {
    errors.Slug = 'Required';
  }
  if (/[A-Z]/.test(values.Slug)) {
    errors.Slug = 'Slug may not contain capital letters';
  }

  if (/^[sp].*/.test(values.Slug) && !values.SalesforceID) {
    errors.SalesforceID =
      'Salesforce account ID is mandatory if slug starts with s or p (SF account)';
  }

  if (!values.Email) {
    errors.Email = 'Required';
  }

  if (values.createPhoneTenant) {
    if (!values.zip) {
      errors.zip = 'Required';
    }
    if (!values.city) {
      errors.city = 'Required';
    }
    if (!values.state) {
      errors.state = 'Required';
    }
    if (!values.street1) {
      errors.street1 = 'Required';
    }
    if (!isPhoneNumber(values.caller_number)) {
      errors.caller_number = 'Not a valid phone number';
    }
  }

  return errors;
};

export const LocationCreateContainer = compose(
  connect(
    (state: Store) => ({
      creationStatus: state.locationStatus.creationStatus,
      isNew: true,
      loading: state.locationStatus.loading,
      allowSetParent: selectHasWeaveAcl(state, CoreACLs.WRITEMULTILOCATIONCONFIGS),
    }),
    (dispatch, ownProps: any) => ({
      createLocation: (location: LocationModelWithPhoneData) => {
        return dispatch(requestCreateLocation(location));
      },
      onClose: () => ownProps.history.push('/'),
      resetLocationCreationStatus: () => dispatch(resetLocationCreationStatus()),
    })
  ),
  withFormik({
    mapPropsToValues: () => {
      const location = mockLocationWithPhoneData({
        Timezone: Timezones[0].Value,
        Type: 'Regular',
        VerticalID: 1,
        Active: true,
      });
      return location;
    },
    validate: validateLocationCreateForm,
    handleSubmit: (
      values: CreateLocationDTO,
      formikBag: FormikBag<Props, LocationModelWithPhoneData>
    ) => {
      const location: CreateLocationDTO = {
        ...values,
        VerticalID: Number(values.VerticalID),
        ParentID: values.ParentID || null, //Has to be null, can't be empty string
        primary_data_center: [
          'Canada/Atlantic',
          'Canada/Eastern',
          'US/Central',
          'US/Eastern',
          'US/East-Indiana',
          'US/Indiana-Starke',
          'US/Michigan',
          'America/Puerto_Rico', // GMT -4
        ].includes(values.Timezone)
          ? 'central-ia' // GMT -6
          : 'pacific-ca', // GMT -8
      };

      formikBag.props.createLocation(location);
      formikBag.resetForm();
    },
  })
)(LocationForm);
