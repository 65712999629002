import React, { FC, useEffect } from 'react';
import { css } from 'emotion';
import { Button } from '@weave/platform-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Page } from '../../shared/page/page.component';
import {
  requestGetDevice,
  requestRebootDevice,
  requestUpdateDevice,
} from '../../../redux/actions/devices/devices.actions';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';
import { useResources, UseResourcesResponse } from '../../../helpers/useResources';
import {
  DeviceOptionModel,
  DevicePendingChangesModel,
  PartialDevice,
} from '../../../redux/actions/devices/devices.types';
import { DeviceSectionGeneral } from './device-section-general.component';
import { DeviceSectionCallSettings } from './device-section-call-settings.component';
import { DeviceSectionPhysical } from './device-section-physical.component';
import { DeviceSectionAdvanced } from './device-section-advanced.component';
import { PHONES, injectParams } from '../../../constants/paths';
import { selectDevice, selectDevicesUpdating } from '../../../redux/actions/devices';
import { useLocationChangeRedirect } from '../../shared/hooks';

interface FetchDeviceOptionsResponse extends UseResourcesResponse {
  data: DeviceOptionModel[];
}

export const Device: FC = () => {
  const dispatch = useDispatch();
  const { deviceId } = useParams<{ deviceId: string }>();
  const locationId = useSelector(selectCurrentLocationId);
  const { loading, device } = useSelector(selectDevice(deviceId));
  const { loading: updating } = useSelector(selectDevicesUpdating);

  useLocationChangeRedirect(locationId, injectParams(PHONES.devices, { id: locationId }));

  useEffect(() => {
    if (deviceId) {
      dispatch(requestGetDevice(deviceId));
    }
  }, [deviceId]);

  const {
    loading: loadingVoiceMailboxOptions,
    data: voiceMailboxOptions,
  }: FetchDeviceOptionsResponse = useResources(
    'support/v1/phone/devices/options/voicemailboxes',
    {
      deps: [locationId],
      suppressFetch: !locationId,
      resourceLabel: 'voice mailboxes',
    }
  );

  // this pending device model doesn't exactly match the device model,
  // so we should see if we can reconcile them
  const onUpdateDevice = async (changes: DevicePendingChangesModel) => {
    const sipProfile = { ...device?.sipProfile, ...changes?.sipProfile };
    const update = { ...device, ...changes, sipProfile };
    dispatch(requestUpdateDevice(device.ID, update as PartialDevice));
  };

  if (!device) {
    return null;
  }

  return (
    <Page
      title="Edit Device"
      loading={loading || loadingVoiceMailboxOptions}
      showBackBtn
      customBackUrl={`/location/${locationId}/phones/devices`}
      headerActions={
        <div
          className={css`
            display: inline-block;
          `}
        >
          <Button
            className={css`
              display: inline-block !important;
              margin-right: 16px;
            `}
            fill="outline"
            disabled={loading}
            onClick={() => dispatch(requestGetDevice(deviceId))}
          >
            Refresh Data
          </Button>
          <Button
            className={css`
              display: inline-block !important;
            `}
            fill="outline"
            disabled={!device.isRegistered}
            onClick={() =>
              dispatch(requestRebootDevice(device.sipProfile?.username ?? ''))
            }
          >
            Resync Device
          </Button>
        </div>
      }
    >
      <DeviceSectionGeneral
        isRegistered={device.isRegistered}
        sipDisabled={device.sipProfile?.disabled ?? true}
        region={device.registration?.region ?? ''}
        locationId={locationId}
        name={device.sipProfile?.name ?? ''}
        macAddress={device.macAddress}
        make={device.make}
        signalingProtocol={device.signalingProtocol}
        updating={updating}
        updateDevice={onUpdateDevice}
      />
      <DeviceSectionCallSettings
        voicemailBoxID={device.sipProfile?.voicemailBoxID}
        externalCallerName={device.sipProfile?.externalCallerName}
        externalCallerNumber={device.sipProfile?.externalCallerNumber}
        internalCallerName={device.sipProfile?.internalCallerName}
        internalCallerNumber={device.sipProfile?.internalCallerNumber}
        updating={updating}
        voiceMailboxOptions={voiceMailboxOptions}
        updateDevice={onUpdateDevice}
      />
      <DeviceSectionPhysical
        contactDirectoryID={device.contactDirectoryID}
        locationId={locationId}
        messageWaitingIndicatorID={device.messageWaitingIndicatorID}
        updating={updating}
        voiceMailboxOptions={voiceMailboxOptions}
        updateDevice={onUpdateDevice}
      />
      <DeviceSectionAdvanced
        adminPassword={device.adminPassword}
        adminUsername={device.adminUsername}
        callWaitingIndicatorDisabled={device.callWaitingIndicatorDisabled}
        clearLocalSettings={device.clearLocalSettings}
        expires={device.expires}
        moveable={device.moveable}
        parkRingbackEnabled={device.sipProfile?.parkRingbackEnabled}
        parkRingbackLag={device.sipProfile?.parkRingbackLag}
        updating={updating}
        updateDevice={onUpdateDevice}
      />
    </Page>
  );
};
