import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { cx } from 'emotion';
import { sortBy, orderBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Button,
  Modal,
  ListItem,
  Loader,
} from '@weave/platform-ui-components';
import successImage from '../../images/success.png';
import { LocationModel } from '../../redux/actions/location/locations/locations.types';
import { EventCard } from './event-card/event-card.component';
import {
  getChilipiperScheduleEvent,
  scheduleEvent,
  updateScheduleEvent,
  selectScheduledEvents,
  selectGetScheduledEventsLoading,
  selectScheduledEventsUpsertState,
  selectPreProvisionState,
  resetScheduleEventUpsertState,
  cancelScheduledEvent,
  getSalesforceData,
} from '../../redux/actions/chilipiper-schedule-event';
import {
  schedulingHubStyles,
  topBar,
  titlesDiv,
  topTitleMargin,
  schedulingTitle,
  refreshButtonDiv,
  buttonStyles,
  cardsSection,
  additionalEventsSection,
  additionalSectionSubtitle,
  scheduleLink,
  scheduleAdditionalEventDiv,
  scheduleAdditionalEventText,
  additionalEventDiv,
  additionalEventModal,
  modalTitleStyles,
  modalSubtitleStyles,
  actionsContainer,
  cancelEventModal,
  cancelModalSubtitleStyles,
  cancelEventModalLoader,
  loaderSubtitleStyles,
  successImageStyle,
  successContainer,
  successSubtitleStyles,
} from './scheduling-hub.styles';
import {
  ChilipiperScheduleEventModel,
  ScheduleEventBodyPayload,
  ChilipiperScheduleEventTypes,
  ChilipiperCancelEventModel,
} from '../../redux/actions/chilipiper-schedule-event/chilipiper-schedule-event.types';
import { SchedulingFrame } from './scheduling-frame/scheduling-frame.component';
import { getPortingData } from '../../redux/actions/porting/porting.action';
import { selectPortsList } from '../../redux/actions/porting/porting.selectors';

import { PortDateText } from './port-date-text/port-date-text.component';

interface ChilipiperEventData {
  action: string;
  args: {
    actionType: string;
    assigneeId: string;
    eventId: string;
    routeId: string;
    slot: {
      end: number;
      start: number;
    };
  };
}

type EventTypeEvents = {
  past: ChilipiperScheduleEventModel[];
  upcoming: ChilipiperScheduleEventModel[];
};

export const defaultEvent = {
  id: '',
  location_id: '',
  event_id: '',
  event_type: '',
  route_id: '',
  assignee_id: '',
  contact_id: '',
  start_at: '',
  end_at: '',
  canceled_at: '',
  created_at: '',
  updated_at: '',
};

export const eventNameMap: Record<ChilipiperScheduleEventTypes, string> = {
  next_steps_call: 'Next Steps Call',
  phone_install_call: 'Remote Phone Installation',
  admin_training: 'Configuration Call',
  onsite_install: 'Onsite Phone Installation',
  software_install_call: 'Software Install - Single',
  software_installation_multi: 'Software Install - Multi',
  phone_customization_multi: 'Phone Customization - Multi',
  phone_customization_single: 'Phone Customization - Hour - Has IVR - Single',
  phone_customization_single_no_ivr: 'Phone Customization - Half Hour - No IVR - Single',
};

interface SchedulableEvent {
  eventType: ChilipiperScheduleEventTypes;
  icon: 'Phone' | 'Chat' | 'Training' | 'CellPhone';
}
const schedulableEvents: SchedulableEvent[] = [
  {
    eventType: ChilipiperScheduleEventTypes.onsiteInstall,
    icon: 'Phone',
  },
  {
    eventType: ChilipiperScheduleEventTypes.phoneInstallCall,
    icon: 'Phone',
  },
  {
    eventType: ChilipiperScheduleEventTypes.phoneInstallationMulti,
    icon: 'Phone',
  },
  {
    eventType: ChilipiperScheduleEventTypes.phoneCustomizationSingle,
    icon: 'Phone',
  },
  {
    eventType: ChilipiperScheduleEventTypes.phoneCustomizationSingleNoIvr,
    icon: 'Phone',
  },
  {
    eventType: ChilipiperScheduleEventTypes.adminTraining,
    icon: 'Training',
  },
  {
    eventType: ChilipiperScheduleEventTypes.softwareInstallCall,
    icon: 'Chat',
  },
  {
    eventType: ChilipiperScheduleEventTypes.softwareInstallationMulti,
    icon: 'Chat',
  },
];

export type SchedulingHubProps = {
  currentLocation: LocationModel;
  onboarders: any[];
  getOnboardersLoading: boolean;
  getOnboarders: () => void;
  debug: boolean;
};

export const SchedulingHub = (props: SchedulingHubProps) => {
  const dispatch = useDispatch();
  const getScheduledEventsLoading = useSelector(selectGetScheduledEventsLoading);
  const scheduledEvents: ChilipiperScheduleEventModel[] =
    useSelector(selectScheduledEvents);
  const upsertState = useSelector(selectScheduledEventsUpsertState);
  const ports = useSelector(selectPortsList);
  const preProvisionData = useSelector(selectPreProvisionState);

  const [nearestEvents, setNearestEvents] = useState<ChilipiperScheduleEventModel[]>([]);
  const [additionalEvents, setAdditionalEvents] = useState<
    ChilipiperScheduleEventModel[]
  >([]);
  const [currentClickedEvent, setCurrentClickedEvent] =
    useState<ChilipiperScheduleEventModel | undefined>();
  const [isReschedule, setIsReschedule] = useState(false);
  const [additionalEventModalIsOpen, setAdditionalEventModalIsOpen] = useState(false);
  const [cancelModalEvent, setCancelModalEvent] =
    useState<ChilipiperCancelEventModel | null>(null);

  useEffect(() => {
    dispatch(getSalesforceData({ locationId: props.currentLocation.LocationID }));
  }, []);

  /**
   * This useEffect will trigger when a chilipiperscheduleevent has been successfully
   * updated or created.
   */
  useEffect(() => {
    if (upsertState.data) {
      dispatch(resetScheduleEventUpsertState());
      dispatch(getChilipiperScheduleEvent());
    }
  }, [upsertState.data]);

  /**
   * Triggers on location change to clear state and fetch data.
   */
  useEffect(() => {
    handleRefresh();
  }, [props.currentLocation.LocationID]);

  // Create a string out of the events "start_at" field to add to the useEffect deps array
  const startTimes = scheduledEvents.map((event) => event.start_at).join('');
  /**
   * Triggers when the fetched events data has been changed.
   */
  useEffect(() => {
    clearEventsStateData();
    setupEvents();
  }, [startTimes]);

  const handleRefresh = () => {
    dispatch(getSalesforceData({ locationId: props.currentLocation.LocationID }));
    clearEventsStateData();
    props.getOnboarders();
    dispatch(getPortingData());
    dispatch(getChilipiperScheduleEvent());
  };

  const clearEventsStateData = () => {
    setAdditionalEvents([]);
    setNearestEvents([]);
  };

  /**
   * Helper method used to set the event data that should be displayed, will first handle
   * the sorting of events by type and whether they are past or upcoming events, then
   * will sets the event data by nearest date.
   */
  const setupEvents = () => {
    const eventsByEventType: { [key: string]: EventTypeEvents } = {};

    scheduledEvents.forEach((event) => {
      const { event_type: eventType } = event;

      if (!eventsByEventType[eventType]) {
        eventsByEventType[eventType] = {
          past: [],
          upcoming: [],
        };
      }

      // If the current stored event is before today.
      if (moment().isAfter(event.start_at)) {
        eventsByEventType[eventType].past.push(event);
      } else {
        eventsByEventType[eventType].upcoming.push(event);
      }
    });

    const initial: {
      nearestEventTypeEvents: ChilipiperScheduleEventModel[];
      additionalEvents: ChilipiperScheduleEventModel[];
    } = {
      nearestEventTypeEvents: [],
      additionalEvents: [],
    };

    /**
     * Separate event data that should be displayed according to the following logic:
     * 1) if there is one event of a given type, show it - even if it is in the past
     * 2) if there is more than one event of a given type, don't show any that are in the
     * past - but show all future events.
     */
    const sortedEvents = Object.entries(eventsByEventType).reduce(
      (acc, [eventType, events]) => {
        // Filter out any canceled events.
        const upcomingEvents = events.upcoming.filter((event) => !event.canceled_at);
        const pastEvents = events.past.filter((event) => !event.canceled_at);

        if (upcomingEvents.length) {
          // Get the next future event that is closest to today's date.
          const nearest = sortBy(upcomingEvents, ['start_at'])[0];
          return {
            ...acc,
            nearestEventTypeEvents: [...acc.nearestEventTypeEvents, nearest],
            // Add all other future events to the additionalEvents array.
            additionalEvents: [...acc.additionalEvents, ...upcomingEvents.slice(1)],
          };
        } else if (pastEvents.length) {
          // Get the past event that is closest to today's date.
          const nearest = orderBy(pastEvents, ['start_at'], ['desc'])[0];
          return {
            ...acc,
            nearestEventTypeEvents: [...acc.nearestEventTypeEvents, nearest],
          };
        }

        return acc;
      },
      initial
    );

    setNearestEvents(sortedEvents.nearestEventTypeEvents);
    setAdditionalEvents(sortedEvents.additionalEvents);
  };

  const handleScheduleEvent = (
    clickedEvent: ChilipiperScheduleEventModel,
    reschedule?: boolean
  ) => {
    if (reschedule) {
      setIsReschedule(true);
    }
    setCurrentClickedEvent(clickedEvent);
  };

  const handleModalClose = () => {
    setCurrentClickedEvent(undefined);
    setIsReschedule(false);
  };

  const getOnboarder = (event) => {
    if (!props.onboarders) {
      return { name: '', email: '' };
    }

    if (event) {
      const eventOnboarder = props.onboarders.find(
        (onboarder) => onboarder.salesforceUserID === event.assignee_id
      );
      if (eventOnboarder && eventOnboarder.salesforceUserID) {
        return eventOnboarder;
      }
    }

    return { name: 'unknown', email: '' };
  };

  /**
   * Sets up the data that will be submitted to the backend to be stored for future use
   * and dispatches the correct action.
   *
   * @param data The data returned from CP after successfully scheduling an event.
   * @param eventType
   */
  const submitScheduleEventData = (
    data: ChilipiperEventData,
    currentEvent: ChilipiperScheduleEventModel
  ) => {
    const time = data.args.slot;
    const { assigneeId, eventId, routeId } = data.args;

    const eventData = {
      event_id: eventId,
      route_id: routeId,
      assignee_id: assigneeId,
      start_at: moment(time.start).format(),
      end_at: moment(time.end).format(),
    };

    if (!isReschedule) {
      const newEventData: ScheduleEventBodyPayload = {
        ...eventData,
        location_id: props.currentLocation.LocationID,
        event_type: currentEvent.event_type,
      };

      dispatch(scheduleEvent(newEventData));
    } else {
      const onboarder = getOnboarder(eventData);
      const newEventData: ScheduleEventBodyPayload = {
        ...eventData,
        location_id: props.currentLocation.LocationID,
        event_type: currentEvent.event_type,
      };

      const oldEventData = {
        ...currentEvent,
        assignee_email: onboarder.email,
        event_title: eventNameMap[currentEvent.event_type],
      };

      dispatch(updateScheduleEvent({ newEvent: newEventData, oldEvent: oldEventData }));
    }
  };

  const shouldDisableButton = () => {
    if (props.getOnboardersLoading || getScheduledEventsLoading) {
      return true;
    }
    return false;
  };

  const additionalEventModalToggle = () => {
    setAdditionalEventModalIsOpen(!additionalEventModalIsOpen);
  };

  const handleScheduleAdditionalEvent = (eventType: ChilipiperScheduleEventTypes) => {
    setAdditionalEventModalIsOpen(false);
    const newEvent = {
      ...defaultEvent,
      event_type: eventType,
    };
    handleScheduleEvent(newEvent);
  };

  const handleCancelEventClick = () => {
    dispatch(cancelScheduledEvent(cancelModalEvent));
  };

  const handleCloseCancelModal = () => {
    if (!upsertState.loading) {
      setCancelModalEvent(null);
      if (upsertState.cancelSuccess) {
        dispatch(resetScheduleEventUpsertState());
        handleRefresh();
      }
    }
  };

  return (
    <>
      <div className={schedulingHubStyles}>
        <div className={topBar}>
          <div className={titlesDiv}>
            <Typography variant="Title" className={cx(schedulingTitle, topTitleMargin)}>
              Scheduled Events
            </Typography>
            <PortDateText ports={ports} />
          </div>
          <div className={refreshButtonDiv}>
            <Button
              className={buttonStyles}
              onClick={handleRefresh}
              disabled={shouldDisableButton()}
            >
              Refresh
            </Button>
          </div>
        </div>

        <SchedulingFrame
          currentEvent={currentClickedEvent}
          debug={props.debug}
          onScheduleSuccess={submitScheduleEventData}
          onModalClose={handleModalClose}
          preProvisionData={preProvisionData}
        />

        <div className={cardsSection}>
          {nearestEvents.map((event) => (
            <EventCard
              key={event.id}
              scheduleEvent={event}
              eventType={event.event_type}
              onboarders={props.onboarders}
              onScheduleButtonClick={handleScheduleEvent}
              onCancelButtonClick={setCancelModalEvent}
            />
          ))}
        </div>

        <div className={scheduleAdditionalEventDiv}>
          <Typography variant="Body" className={scheduleAdditionalEventText}>
            Need to schedule additional calls?{' '}
          </Typography>
          <Typography
            variant="Body"
            className={cx(scheduleAdditionalEventText, scheduleLink)}
            onClick={additionalEventModalToggle}
          >
            Click here
          </Typography>
        </div>

        {!!additionalEvents.length && (
          <>
            <Typography variant="Title" className={additionalSectionSubtitle}>
              Additional Scheduled Events
            </Typography>
            <div className={additionalEventsSection}>
              {additionalEvents.map((event) => (
                <EventCard
                  key={event.id}
                  scheduleEvent={event}
                  onboarders={props.onboarders}
                  onScheduleButtonClick={handleScheduleEvent}
                  onCancelButtonClick={setCancelModalEvent}
                  eventType={event.event_type}
                />
              ))}
            </div>
          </>
        )}

        <Modal
          isOpen={additionalEventModalIsOpen}
          onRequestClose={additionalEventModalToggle}
        >
          <div className={additionalEventModal}>
            <h1 className={modalTitleStyles}>Schedule Additional Event</h1>
            <h3 className={modalSubtitleStyles}>Select event type</h3>
            {schedulableEvents.map((event) => (
              <div
                key={event.eventType}
                onClick={() => handleScheduleAdditionalEvent(event.eventType)}
                className={additionalEventDiv}
              >
                <ListItem title={eventNameMap[event.eventType]} icon={event.icon} />
              </div>
            ))}
          </div>
        </Modal>

        <Modal isOpen={!!cancelModalEvent} onRequestClose={handleCloseCancelModal}>
          <div className={cancelEventModal}>
            {upsertState.cancelSuccess ? (
              <div className={successContainer}>
                <img src={successImage} className={successImageStyle} alt="Success" />
                <h3 className={successSubtitleStyles}>Success!</h3>
                <Button className={buttonStyles} onClick={handleCloseCancelModal}>
                  Close
                </Button>
              </div>
            ) : (
              <>
                {upsertState.loading && (
                  <div className={cancelEventModalLoader}>
                    <Loader />
                    <h3 className={loaderSubtitleStyles}>Canceling...</h3>
                  </div>
                )}
                <h1 className={modalTitleStyles}>
                  Are you sure you want to cancel this event?
                </h1>
                <h3 className={cancelModalSubtitleStyles}>
                  The event owner will be emailed notifying them to delete this event from
                  their calendar. This cannot be undone.
                </h3>
                <div className={actionsContainer}>
                  <Button className={buttonStyles} onClick={handleCancelEventClick}>
                    Yes
                  </Button>
                  <Button className={buttonStyles} onClick={handleCloseCancelModal}>
                    No
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal>

        {/* Display ALL scheduled events (past and future) */}
        {props.debug && (
          <>
            <hr />
            <Typography variant="Title" className={additionalSectionSubtitle}>
              ALL Events
            </Typography>
            <div className={additionalEventsSection}>
              {scheduledEvents &&
                sortBy(scheduledEvents, ['start_at']).map((event) => (
                  <EventCard
                    key={event.id}
                    scheduleEvent={event}
                    onboarders={props.onboarders}
                    onScheduleButtonClick={handleScheduleEvent}
                    onCancelButtonClick={setCancelModalEvent}
                    eventType={event.event_type}
                  />
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};
