import React from 'react';
import { css } from '@emotion/core';
import { useAlert } from '@weave/alert-system';
import { theme } from '@weave/theme-original';
import {
  FormRow,
  Heading,
  PrimaryButton,
  SearchField,
  SpinningLoader,
  useFormField,
} from '@weave/design-system';
import axios, { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../../redux/actions/location';
import { billingApi } from '../billing.api';
import { Card } from '../card';
import { InvoiceHistoryTable } from './invoice-history-table';

export const InvoiceHistoryCard = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const alert = useAlert();

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery(
    [locationId, 'billing-invoice-list'],
    async ({ pageParam = '' }) => {
      const result = await billingApi.getInvoiceList(25, pageParam);
      return result;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.nextPageToken;
      },
      onError: (error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          alert.warning('No invoice data found.');
        } else {
          alert.error('Error retrieving invoices.');
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const searchFieldProps = useFormField({ type: 'text' });

  const fetchMore = () => {
    fetchNextPage();
  };

  const invoices = useMemo(() => {
    if (!data) return [];

    // react-query's useInfiniteQuery hook returns the data in pages arrays so we need to
    // flatten it first.
    const flattened = data?.pages.map((page) => page?.invoices ?? []).flat() ?? [];

    if (searchFieldProps.value) {
      // For now just filter using all of the invoice object values. This if probably
      // not ideal and we should figure out what data we should allow searching for.
      const filtered = flattened.filter((invoice) =>
        Object.values(invoice).some((value) =>
          ('' + value).toLowerCase().includes(searchFieldProps.value.toLowerCase())
        )
      );

      return filtered;
    }

    return flattened;
  }, [data, searchFieldProps.value]);

  return (
    <Card as="section" css={cardStyle}>
      <header css={headerStyles}>
        <Heading level={2} css={titleStyles}>
          Invoice History
        </Heading>
      </header>

      <FormRow css={searchBoxContainer}>
        <SearchField css={searchFieldStyles} {...searchFieldProps} name="search" />
      </FormRow>

      <InvoiceHistoryTable locationId={locationId} invoices={invoices} />

      {isFetching && (
        <div css={SpinnerContainerStyles}>
          <SpinningLoader />
        </div>
      )}

      {hasNextPage && (
        <div css={loadMoreBtnContainer}>
          <PrimaryButton
            onClick={fetchMore}
            disabled={isFetching}
            css={loadMoreButtonStyles}
          >
            Load More
          </PrimaryButton>
        </div>
      )}
    </Card>
  );
};

const cardStyle = css`
  margin: ${theme.spacing(6, 3)};
  padding: ${theme.spacing(0, 0, 4, 0)};
`;

const headerStyles = css`
  padding: ${theme.spacing(3)};
`;

const titleStyles = css`
  border-bottom: 1px solid ${theme.colors.gray300};
  padding-bottom: ${theme.spacing(2)};
`;

const searchBoxContainer = css`
  padding: ${theme.spacing(0, 2)};
`;
const searchFieldStyles = css`
  max-width: 318px;
`;

const SpinnerContainerStyles = css`
  display: flex;
  justify-content: center;
  margin: ${theme.spacing(1)};
`;

const loadMoreBtnContainer = css`
  display: flex;
  justify-content: center;
  margin: ${theme.spacing(4, 0, 1)};
`;

const loadMoreButtonStyles = css`
  width: 300px;
`;
