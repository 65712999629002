import { connect } from 'react-redux';
import { FormikBag, FormikErrors, withFormik } from 'formik';
import { compose } from 'lodash/fp';

import {
  LocationDemoForm,
  LocationFormProps,
} from '../location-form/location-demo-form.component';
import {
  requestCreateLocation,
  LocationModelWithPhoneData,
  mockLocationWithPhoneData,
} from '../../../redux/actions/location/locations';
import { setCurrentLocationId } from '../../../redux/actions/location/current-location';
import { resetLocationCreationStatus } from '../../../redux/actions/location/location-status';
import { Store } from '../../../redux/store/store.model';
import { VerticalEnum } from '../../../models/vertical-enum';

interface Props extends LocationFormProps {
  createLocation: (location: LocationModelWithPhoneData) => void;
}

export const validateLocationCreateForm = function (values) {
  const errors: FormikErrors<LocationModelWithPhoneData> = {};

  if (!values.Name) {
    errors.Name = 'Required';
  }
  if (!values.Slug) {
    errors.Slug = 'Required';
  }
  if (/[A-Z]/.test(values.Slug)) {
    errors.Slug = 'Slug may not contain capital letters';
  }
  if (!values.Email) {
    errors.Email = 'Required';
  }

  return errors;
};

export const LocationCreateDemoContainer = compose(
  connect(
    (state: Store) => ({
      creationStatus: state.locationStatus.creationStatus,
      loading: state.locationStatus.loading,
    }),
    (dispatch, ownProps: any) => ({
      createLocation: (location: LocationModelWithPhoneData) =>
        dispatch(requestCreateLocation(location)),
      onClose: () => ownProps.history.push('/'),
      resetLocationCreationStatus: () => dispatch(resetLocationCreationStatus()),
      setCurrentLocationId: (locationId: string) =>
        dispatch(setCurrentLocationId(locationId)),
    })
  ),
  withFormik({
    mapPropsToValues: () => {
      const location = mockLocationWithPhoneData({
        Timezone: 'US/Mountain',
        Type: 'Demo',
        VerticalID: VerticalEnum.Dental,
        Active: true,
      });
      return location;
    },
    validate: validateLocationCreateForm,
    handleSubmit: (
      values: LocationModelWithPhoneData,
      formikBag: FormikBag<Props, LocationModelWithPhoneData>
    ) => {
      values.VerticalID = Number(values.VerticalID);
      values.primary_data_center = 'pacific-ca';
      values.caller_number = '8018779256';
      values.street1 = '2000 Ashton Blvd';
      values.street2 = 'Suite 100';
      values.city = 'Lehi';
      values.state = 'UT';
      values.zip = '84043';
      values.country = 'US';
      values.createPhoneTenant = true;
      if (values.VerticalID === VerticalEnum.Other) {
        values.salesforce_products = ['01t1N00000I80sB', '01t1N00000AwMJ6'];
      } else {
        values.salesforce_products = ['01to0000008tial', '01t1N00000AwMJ6'];
      }

      formikBag.props.createLocation(values);
      formikBag.resetForm();
    },
  })
)(LocationDemoForm);
