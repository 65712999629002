import React, { useState } from 'react';
import { noop } from 'lodash';
import { css } from '@emotion/core';

type Props = {
  originalText: string;
  onSave: (text: string) => void;
};

export const AutoSaveInput = ({ originalText, onSave }: Props) => {
  const [editedText, setEditedText] = useState<string>(originalText);

  const onTextSave = () => {
    if (editedText !== originalText) {
      onSave(editedText);
    }
  };

  return (
    <div>
      <input
        css={css`
          padding: 0 5px;
          border: thin solid transparent;
          font-weight: inherit;
          &:hover {
            border: thin solid #999;
          }
        `}
        onKeyPress={(event) => (event.key === 'Enter' ? onTextSave() : noop)}
        onBlur={onTextSave}
        onChange={(e) => setEditedText(e.target.value)}
        value={editedText}
      />
    </div>
  );
};
