import { useMemo } from 'react';
import {
  ProtoRouteParams,
  protosApiFactory,
  protosQueryFactory,
  replaceRouteParams,
} from './proto-utils';

export const protosHookFactory =
  <Paths>(prefix?: string) =>
  <Path extends keyof Paths>(path: Path) =>
  (routeParams?: ProtoRouteParams<Path>) => {
    const url = useMemo(
      () => replaceRouteParams(`${path}`, routeParams),
      [JSON.stringify(routeParams)]
    );

    const methods = useMemo(
      () => protosApiFactory<Paths>(prefix)(path)(routeParams),
      [url]
    );
    return { methods, useQuery: protosQueryFactory(prefix)(url)(routeParams) };
  };
