import React from 'react';
import { css, cx } from 'emotion';
import { Task } from '../onboarding-task.model';
import { OnboardingTaskStatus } from '../onboarding-task-status/onboarding-task-status.component';
import { InfoTooltip } from '../../shared/info-tooltip/info-tooltip.component';

export type Props = {
  tasks: Task[];
  markTaskAsComplete: (task: Task, status: number) => void;
  currentTask: Task;
  onboardingSetCurrentTask: (task: Task) => void;
  saveExplanationOnTask: (task: Task, explanation: string) => void;
  filter: string;
};

const taskStatusContainer = css`
  font-size: 13px;
  text-align: left;
  color: rgb(189, 197, 201);
  margin-top: 10px;
`;
const taskContainer = css`
  font-weight: bold;
  font-size: 16px;
  text-align: left;
`;
const taskDetails = css`
  font-size: 14px;
  text-align: left;
  padding: 10px;
`;
const taskButtonContainer = css`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;
const buttonStyle = cx(
  'btn btn-lg',
  css`
    border-radius: 45px;
    background-color: rgb(43, 213, 60);
    color: white;
    &:hover {
      color: white;
      background-color: rgb(37, 185, 52);
    }
  `
);
const waitingOnWeaveButton = cx(
  'btn btn-lg',
  css`
    border-radius: 45px;
    background-color: rgb(43, 213, 60);
    color: white;
    margin-top: 10px;
    margin-left: 0px;
    &:hover {
      color: white;
      background-color: rgb(37, 185, 52);
    }
  `
);
const infoContainer = css`
  display: flex;
  align-items: center;
`;
const completedButtonStyle = cx(
  'btn btn-lg',
  css`
    border-radius: 45px;
    background-color: #000000;
    color: white;
    &:hover {
      color: white;
      background-color: #0c0c0c;
    }
  `
);

export class OnboardingTasks extends React.Component<Props, any> {
  constructor(props) {
    super(props);
    this.state = {
      explanation: this.props.currentTask.explanation,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.currentTask.id !== nextProps.currentTask.id) {
      this.setState({ explanation: nextProps.currentTask.explanation });
    }
  }

  updateExplanation = (event) => {
    this.setState({ explanation: event.target.value });
  };

  saveExplanation = () => {
    this.props.saveExplanationOnTask(this.props.currentTask, this.state.explanation);
  };

  createMarkup = function (task) {
    return { __html: task.content };
  };

  openWindow = (task) => {
    window.open(task.buttonExternalURL, '_blank');
  };

  handleTaskClicked = (task) => {
    if (task.id === this.props.currentTask.id) {
      this.props.onboardingSetCurrentTask({});
    } else {
      this.props.onboardingSetCurrentTask(task);
    }
  };

  taskStyle = (task) => {
    return cx(
      css`
        border: 1px solid rgb(209, 217, 221);
        background-color: #ffffff;
        border-radius: 10px;
        margin-bottom: 10px;
        padding: 15px;
        cursor: pointer;
      `,
      {
        [css`
          opacity: 0.5;
          background-color: transparent;
        `]:
          !task.isReadyToWorkOn &&
          task.status !== 'Completed' &&
          task.status !== 'Verified',
      },
      {
        [css`
          border-left: 10px solid #ed9825;
        `]: task.isReadyToWorkOn && task.status === 'WaitingOnCustomer',
      },
      {
        [css`
          border-left: 10px solid #37cff1;
        `]: task.isReadyToWorkOn && task.status === 'WaitingOnWeave',
      },
      {
        [css`
          border-left: 10px solid #27b523;
        `]: task.status === 'Completed',
      }
    );
  };

  filterTask = (task) => {
    if (
      this.props.filter === 'Current' &&
      (!task.isReadyToWorkOn || task.status === 'Completed' || task.status === 'Verified')
    ) {
      return null;
    }

    if (
      this.props.filter === 'Completed' &&
      task.status !== 'Completed' &&
      task.status !== 'Verified'
    ) {
      return null;
    }

    return (
      <div
        onClick={() => this.handleTaskClicked(task)}
        key={task.id}
        className={this.taskStyle(task)}
      >
        <div className={taskContainer}>{task.title}</div>
        <div className={taskStatusContainer}>
          <OnboardingTaskStatus
            task={task}
            updateTaskStatus={this.props.markTaskAsComplete}
            status={
              (task.isReadyToWorkOn ||
                task.status === 'Completed' ||
                task.status === 'Verified') &&
              task.status
                ? task.status
                : 'WaitingOnPreviousTask'
            }
          />
        </div>
        {this.props.currentTask.id === task.id && (
          <div id="task-details">
            <div
              className={taskDetails}
              dangerouslySetInnerHTML={this.createMarkup(task)}
            />
            <div className={taskButtonContainer}>
              {task.status === 'Completed' ||
              task.status === 'Verified' ||
              task.status === 'WaitingOnWeave' ||
              !task.isReadyToWorkOn ? null : (
                <div className={infoContainer}>
                  <button
                    disabled={!!task.buttonInternalURL}
                    onClick={() => this.openWindow(task)}
                    className={buttonStyle}
                  >
                    {task.buttonInternalURL
                      ? 'Weave Client Link Only'
                      : task.buttonContent}
                  </button>

                  {!task.buttonInternalURL && (
                    <div>
                      <InfoTooltip
                        body={task.buttonExternalURL}
                        tooltipPlacement="right"
                      />
                    </div>
                  )}
                </div>
              )}
              {task.status === 'WaitingOnWeave' && (
                <div>
                  <p
                    className={css`
                      font-weight: bold;
                    `}
                  >
                    Why are they Waiting on Weave for this task?
                  </p>
                  <input
                    onChange={this.updateExplanation}
                    value={this.state.explanation}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    type="text"
                    placeholder="Enter explanation..."
                  />
                  <button onClick={this.saveExplanation} className={waitingOnWeaveButton}>
                    {' '}
                    Save Explanation{' '}
                  </button>
                </div>
              )}
              {!task.isReadyToWorkOn && (
                <button
                  onClick={() => this.props.markTaskAsComplete(task, 2)}
                  className={completedButtonStyle}
                >
                  Mark Completed
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div
        className={css`
          width: 340px;
        `}
      >
        {this.props.tasks.map((task) => this.filterTask(task))}
      </div>
    );
  }
}
