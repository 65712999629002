type InlineHTML = 'strong' | 'em' | 'span' | 'mark';

export const highlightLetters = (
  item: string,
  keyword: string | undefined,
  inlineHTMLElement: InlineHTML = 'strong'
): string => {
  if (!keyword || !item) return item;

  const constrRegEx = new RegExp(keyword, 'gi');
  return item.replace(constrRegEx, `<${inlineHTMLElement}>$&</${inlineHTMLElement}>`);
};
