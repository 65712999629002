import React from 'react';
import { Modal, ModalControlModalProps, Tabs } from '@weave/design-system';
import { SelectMediaForm } from './form-select-media.component';
import { UploadMediaForm } from './form-upload-media.component';
import * as styles from './select-media-modal.styles';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

interface Props {
  onMediaFileSelected: (mediaId: string) => void;
  modalProps: Pick<ModalControlModalProps, 'onClose' | 'show'>;
}

export const SelectMediaModal = ({ modalProps, onMediaFileSelected }: Props) => {
  return (
    <Modal
      css={css`
        padding: ${theme.spacing(2)};
        text-align: center;
      `}
      {...modalProps}
      maxWidth={616}
    >
      <div className={styles.containTabs}>
        <Modal.Header>Select Your Hold Music</Modal.Header>
        <Tabs
          initialTab="library"
          css={css`
            li {
              margin: 0;
              width: 50%;
            }
          `}
        >
          <Tabs.Bar>
            <Tabs.Tab id="library" controls="library-panel">
              Media Library
            </Tabs.Tab>
            <Tabs.Tab id="upload" controls="upload-panel">
              Upload New
            </Tabs.Tab>
          </Tabs.Bar>
          <Tabs.Panel id="library-panel" controller="library">
            <SelectMediaForm
              isOpen={modalProps.show}
              onRequestClose={modalProps.onClose}
              onMediaFileSelected={onMediaFileSelected}
            />
          </Tabs.Panel>
          <Tabs.Panel id="upload-panel" controller="upload">
            <UploadMediaForm
              isOpen={modalProps.show}
              onRequestClose={modalProps.onClose}
              onMediaFileSelected={onMediaFileSelected}
            />
          </Tabs.Panel>
        </Tabs>
      </div>
    </Modal>
  );
};
