import {
  weaveGreen,
  weavePink,
  weaveYellow,
  weaveRed,
  weaveBlueDark,
  weaveBlueLight,
  weavePurple,
} from '@weave/platform-ui-components';

export const friendlyStatuses = {
  delivered: 'Delivered',
  dataChanged: 'Appointment Moved',
  optedOut: 'Opted Out',
  missingData: 'Missing Contact Info', // Missing Contact Info
  pending: 'Pending',
  sending: 'Sending',
  unkown: 'Error',
};
export const statusList = Object.values(friendlyStatuses).map((status) => status);

export const statusColorsMap = {
  [friendlyStatuses.delivered]: weaveGreen,
  [friendlyStatuses.sending]: weaveBlueLight,
  [friendlyStatuses.dataChanged]: weavePink,
  [friendlyStatuses.optedOut]: weaveYellow,
  [friendlyStatuses.missingData]: weaveRed,
  [friendlyStatuses.pending]: weaveBlueDark,
  [friendlyStatuses.unkown]: weavePurple,
};

export const friendlyStatusMap = {
  grouped_delivered: friendlyStatuses.delivered,
  delivered: friendlyStatuses.delivered,
  sent: friendlyStatuses.delivered,

  'chooses not to receive sms': friendlyStatuses.optedOut,
  'grouped_chooses not to receive sms': friendlyStatuses.optedOut,

  'in-transit': friendlyStatuses.sending,
  delivering: friendlyStatuses.sending,
  sending: friendlyStatuses.sending,
  'retrying-transit': friendlyStatuses.sending,

  waiting: friendlyStatuses.pending,

  'grouped_setting not found': friendlyStatuses.missingData,
  'setting not found': friendlyStatuses.missingData,
  'could not find main patient': friendlyStatuses.missingData,
  'could not find person': friendlyStatuses.missingData,
  'recall event not found': friendlyStatuses.missingData,
  'item not found during prepare send': friendlyStatuses.missingData,
  'grouped_unable to fetch appointment': friendlyStatuses.missingData,
  'unable to fetch location': friendlyStatuses.missingData,
  'unable to fetch appointment': friendlyStatuses.missingData,
  'grouped_unable to fetch location': friendlyStatuses.missingData,
  'grouped_unable to fetch main patient': friendlyStatuses.missingData,
  'unable to fetch main patient': friendlyStatuses.missingData,
  'unable to fetch patient': friendlyStatuses.missingData,
  'error - unable to lookup person phone from person id': friendlyStatuses.missingData,

  'appointment has changed': friendlyStatuses.dataChanged,
  'grouped_appointment has changed': friendlyStatuses.dataChanged,
  'appointment message delayed': friendlyStatuses.dataChanged,
  'message deadline passed': friendlyStatuses.dataChanged,

  'checksum mismatch': friendlyStatuses.unkown,
  'unsupported delivery method': friendlyStatuses.unkown,
  'grouped_message deadline passed': friendlyStatuses.unkown,
};
