import React from 'react';
import { Text } from '@weave/design-system';
import { gray5 } from '@weave/platform-ui-components';
import { statusList, friendlyStatuses, statusColorsMap } from './map-friendly-statuses';
import { css, cx } from 'emotion';

const container = css`
  label: message-queue-summary;
  display: block;
  width: 280px;
  margin-left: 25px;
`;

const itemStyle = css`
  label: itemStyle;
  display: flex;
  justify-content: space-between;
  p {
    padding: 0px;
    padding-left: 8px;
    margin: 4px 8px;
    margin-left: 0;
  }
`;

const lineStyle = css`
  label: lineStyle;
  width: calc(100% - 12px);
  margin-top: 4px;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 24px;
  padding: 0;
`;

const spacer = css`
  label: undelivered;
  content: '';
  margin: 50px 0;
  line-height: 50px;
`;

const getColor = (status, quantity) => {
  const color = quantity ? statusColorsMap[status] : gray5;
  const weight = quantity ? 'bold' : 'normal';
  return css`
    border-left: 4px solid ${color};
    font-weight: ${weight};
  `;
};

const deliveredStatuses = [
  friendlyStatuses.delivered,
  friendlyStatuses.sending,
  friendlyStatuses.pending,
];

interface MessageQueueSummaryType {
  summaryTotals: {
    Delivered?: number;
    'Appointment Moved'?: number;
    'Opted Out'?: number;
    'Missing Contact Info': number;
    Pending?: number;
    Sending?: number;
    Error?: number;
  };
}

export const MessageQueueSummary = ({ summaryTotals }: MessageQueueSummaryType) => {
  return (
    <div className={container}>
      <Text color="light">Weekly Summary</Text>
      {deliveredStatuses.map((status, idx) => (
        <div key={`${idx}-delivered-${status}`}>
          <div className={cx(itemStyle, getColor(status, summaryTotals[status]))}>
            <Text>{status}</Text>
            <Text>{summaryTotals[status]}</Text>
          </div>
          <hr className={lineStyle} />
        </div>
      ))}
      <>
        <Text weight="bold">Undelivered</Text>
        <br className={spacer} />
      </>
      {statusList
        .filter((status) => !deliveredStatuses.includes(status))
        .map((status, idx) => (
          <div key={`${idx}-undelivered-${status}`}>
            <div className={cx(itemStyle, getColor(status, summaryTotals[status]))}>
              <Text>{status}</Text>
              <Text>{summaryTotals[status]}</Text>
            </div>
            <hr className={lineStyle} />
          </div>
        ))}
    </div>
  );
};
