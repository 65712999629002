import { createAction } from 'redux-actions';
import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { compareDesc } from 'date-fns';
import { showError } from '@weave/alert-system';

import { CustomAxios, getErrorMessage, getResponseData } from '../../axios';
import { LocationModel } from '../location';
import {
  selectCurrentLocation,
  selectCurrentLocationId,
} from '../location/current-location';
import {
  UpdateScheduleEventPayload,
  ScheduleEventBodyPayload,
  PreProvisionDataModel,
  StageNameDenyList,
  SalesforceOpportunity,
} from './chilipiper-schedule-event.types';

/** Retrieve */
export const getChilipiperScheduleEvent = createAction('GET_CHILIPIPER_SCHEDULE_EVENT');
export const getChilipiperScheduleEventSuccess = createAction(
  'GET_CHILIPIPER_SCHEDULE_EVENT_SUCCESS'
);
export const getChilipiperScheduleEventFailure = createAction<Error>(
  'GET_CHILIPIPER_SCHEDULE_EVENT_FAILURE'
);
export const getSalesforceData = createAction('GET_SALESFORCE_DATA');

/** Update */
export const updateScheduleEvent = createAction<UpdateScheduleEventPayload>(
  'UPDATE_SCHEDULE_EVENT'
);
export const updateScheduleEventSuccess = createAction('UPDATE_SCHEDULE_EVENT_SUCCESS');
export const updateScheduleEventFailure = createAction<Error>(
  'UPDATE_SCHEDULE_EVENT_FAILURE'
);
export const resetScheduleEventUpsertState = createAction(
  'RESET_SCHEDULE_EVENT_UPSERT_STATE'
);
export const updatePreProvisionData = createAction('UPDATE_PRE_PROVISION_DATA');

/** Create */
export const scheduleEvent = createAction<ScheduleEventBodyPayload>('SCHEDULE_EVENT');
export const scheduleEventSuccess = createAction('SCHEDULE_EVENT_SUCCESS');
export const scheduleEventFailure = createAction<Error>('SCHEDULE_EVENT_FAILURE');

/** Cancel */
export const cancelScheduledEvent = createAction('CANCEL_SCHEDULE_EVENT');
export const cancelScheduledEventSuccess = createAction('CANCEL_SCHEDULE_EVENT_SUCCESS');
export const cancelScheduledEventFailure = createAction('CANCEL_SCHEDULE_EVENT_FAILURE');

/** SELECTORS */
export const selectGetScheduledEventsLoading = (state) =>
  state.chilipiperScheduleEvent.retrieveScheduleEvents.loading;
export const selectScheduledEvents = (state) =>
  state.chilipiperScheduleEvent.retrieveScheduleEvents.data;
export const selectScheduledEventsUpsertState = (state) =>
  state.chilipiperScheduleEvent.upsertScheduleEvents;
export const selectPreProvisionState = (state) =>
  state.chilipiperScheduleEvent.additionalChilipiperData.preProvisionData;

export const handleGetChilipiperScheduleEvent = function* (action) {
  try {
    const currentLocationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    const response = yield call(
      CustomAxios.get,
      `support/v1/chilipiperscheduleevent/${currentLocationId}`
    );
    yield put(getChilipiperScheduleEventSuccess(response.data.data || []));
  } catch (error: any) {
    yield put(getChilipiperScheduleEventFailure(error));
  }
};

export const handleScheduleEvent = function* (action) {
  try {
    const url = `support/v1/chilipiperscheduleevent`;

    const response = yield call(CustomAxios.post, url, action.payload);

    yield put(scheduleEventSuccess(response.data));
  } catch (error: any) {
    yield put(scheduleEventFailure(error));
  }
};

export const handleUpdateScheduleEvent = function* (action) {
  try {
    const url = `support/v1/chilipiperscheduleevent`;

    const response = yield call(CustomAxios.post, url, action.payload.newEvent);

    const canceledEvent = yield call(
      CustomAxios.put,
      `support/v1/chilipiperscheduleevent/${action.payload.oldEvent.event_id}/cancel`
    );

    const zap = action.payload.oldEvent;
    if (zap.assignee_email) {
      const zapierPayload = { ...zap, ...canceledEvent.data.data };
      yield call(fetch, 'https://hooks.zapier.com/hooks/catch/883949/o6udtjr/', {
        credentials: 'omit',
        body: JSON.stringify(zapierPayload),
        method: 'POST',
      });
    }

    yield put(updateScheduleEventSuccess(response.data));
  } catch (error: any) {
    yield put(updateScheduleEventFailure(error));
  }
};

export const handleCancelScheduledEvent = function* (action) {
  try {
    const canceledEvent = yield call(
      CustomAxios.put,
      `support/v1/chilipiperscheduleevent/${action.payload.event_id}/cancel`
    );
    const zapierPayload = { ...action.payload, ...canceledEvent.data.data };
    yield call(fetch, 'https://hooks.zapier.com/hooks/catch/883949/o6udtjr/', {
      credentials: 'omit',
      body: JSON.stringify(zapierPayload),
      method: 'POST',
    });
    yield put(cancelScheduledEventSuccess());
  } catch (error: any) {
    yield put(cancelScheduledEventFailure());
    yield put(showError(getErrorMessage(error)));
  }
};

export const filterAndSortOpportunities = (opportunities: SalesforceOpportunity[]) => {
  return opportunities
    .filter(
      (item) =>
        item.record_type === 'New Sale' &&
        item.onboarding_contact_id &&
        !StageNameDenyList.includes(item.stage_name_id)
    )
    .sort((a, b) => {
      return compareDesc(new Date(a.close_date), new Date(b.close_date));
    });
};

const handleGetSalesforceData = function* () {
  try {
    const location: LocationModel = yield select(selectCurrentLocation);
    if (!location.SalesforceID) {
      throw new Error('Missing Salesforce ID');
    }

    const response = yield call(
      CustomAxios.get,
      `support/v1/salesforce/opportunities?account_ids=${location.SalesforceID}`
    );
    const data: { opportunities: SalesforceOpportunity[] } = getResponseData(response);
    const filteredOpportunities = filterAndSortOpportunities(data.opportunities);

    if (filteredOpportunities.length === 0) {
      console.error(
        'Opportunity must be of type New Sale and have an Onboarding Contact ID'
      );
      throw new Error('No usable Opportunities found');
    }

    // Use the most recent opportunity
    const opportunity = filteredOpportunities[0];

    const preProvisionData: PreProvisionDataModel = {
      opportunityId: opportunity.id,
      contactId: opportunity.onboarding_contact_id,
    };
    yield put(updatePreProvisionData(preProvisionData));
  } catch (error: any) {
    yield put(updatePreProvisionData(null));
    yield put(showError(getErrorMessage(error)));
  }
};

export const chilipiperScheduleEventSaga = function* () {
  yield all([
    takeEvery(getChilipiperScheduleEvent.toString(), handleGetChilipiperScheduleEvent),
    takeEvery(updateScheduleEvent.toString(), handleUpdateScheduleEvent),
    takeEvery(scheduleEvent.toString(), handleScheduleEvent),
    takeEvery(cancelScheduledEvent.toString(), handleCancelScheduledEvent),
    takeEvery(getSalesforceData.toString(), handleGetSalesforceData),
  ]);
};
