import React, { useEffect, useState } from 'react';
import { showError } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import { Heading, PrimaryButton, Text } from '@weave/design-system';
import { css } from '@emotion/core';
import { OnOffIndicator } from '../shared/on-off-indicator/on-off-indicator.component';
import { CustomAxios } from '../../redux/axios';
import { put } from 'redux-saga/effects';
import { Page } from '../shared';
import { CreateFlagModal } from './create-flag-modal.component';
import { BatchSetFlagModal } from './batch-set-flag-modal.component';
import { EditFlagModal } from './edit-flag-modal.component';
import { DeleteFlagModal } from './delete-flag-modal.component';
import { FeatureFlagLocationsModal } from './flag-locations-modal.component';
import { FeatureFlag } from './feature-flag.types';

interface Props {
  canCreateFeatureFlags: boolean;
  canEditFeatureFlags: boolean;
}

export const FeatureFlagsManager = (props: Props) => {
  const [flags, setFlags] = useState<FeatureFlag[]>([]);
  const [selectedFlag, setSelectedFlag] = useState<FeatureFlag>({
    name: '',
    description: '',
    defaultValue: false,
    id: '',
  });

  // show modal values
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showBatchSetModal, setShowBatchSetModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLocationsModal, setShowLocationsModal] = useState(false);

  useEffect(() => {
    loadFlags();
  }, []);

  function loadFlags() {
    CustomAxios.get('/feature-flags/v1/flags')
      .then((res) => {
        setFlags(res.data.flags);
      })
      .catch((err) => {
        put(showError('Failed to load feature flags'));
      });
  }

  return (
    <div>
      <Page
        title="Feature Flags"
        headerActions={
          <div
            css={(theme: WeaveTheme) => css`
              align-items: center;
              display: flex;

              & button:not(:last-of-type) {
                margin-right: ${theme.spacing(1)};
              }
            `}
          >
            {props.canCreateFeatureFlags && (
              <PrimaryButton
                style={{ background: '#1fc717', border: '#1fc717', width: 'inherit' }}
                size={'small'}
                onClick={() => {
                  setShowCreateModal(true);
                }}
              >
                Create Feature Flag
              </PrimaryButton>
            )}
          </div>
        }
      >
        {!flags.length && (
          <Heading style={{ textAlign: 'center' }}>No feature flags found 🤔</Heading>
        )}
        {flags.map((flag) => {
          return (
            <div
              key={flag.id}
              css={css`
                height: 30px;
                border-bottom: 1px solid #f1f2f2;
                margin: 15px;
              `}
            >
              <Text style={{ marginRight: '1rem', display: 'inline' }}>Default</Text>
              <OnOffIndicator on={flag.defaultValue} />
              {flag.description} [{flag.name}]
              <div style={{ float: 'right' }}>
                <PrimaryButton
                  size={'tiny'}
                  style={{ marginRight: '.25rem' }}
                  onClick={() => {
                    setSelectedFlag(flag);
                    setShowLocationsModal(true);
                  }}
                >
                  Locations Set
                </PrimaryButton>
                {props.canEditFeatureFlags && (
                  <PrimaryButton
                    size={'tiny'}
                    style={{ marginRight: '.25rem' }}
                    onClick={() => {
                      setSelectedFlag(flag);
                      setShowEditModal(true);
                    }}
                  >
                    Edit Flag
                  </PrimaryButton>
                )}
                {props.canEditFeatureFlags && (
                  <PrimaryButton
                    size={'tiny'}
                    style={{ marginRight: '.25rem' }}
                    onClick={() => {
                      setSelectedFlag(flag);
                      setShowBatchSetModal(true);
                    }}
                  >
                    Batch Set
                  </PrimaryButton>
                )}
                {props.canCreateFeatureFlags && (
                  <PrimaryButton
                    destructive
                    size={'tiny'}
                    onClick={() => {
                      setSelectedFlag(flag);
                      setShowDeleteModal(true);
                    }}
                  >
                    Delete
                  </PrimaryButton>
                )}
              </div>
            </div>
          );
        })}

        {showCreateModal && (
          <CreateFlagModal
            close={(flag) => {
              setShowCreateModal(false);
              if (flag) {
                setFlags([...flags, flag]);
              }
            }}
          />
        )}

        {showBatchSetModal && (
          <BatchSetFlagModal
            flagName={selectedFlag.name}
            close={(newDefaultValue) => {
              // if a new default value has been set from setAll then update that flag to reflect it.
              if (newDefaultValue !== undefined) {
                const flagCopy = [...flags];

                for (let i = flagCopy.length - 1; i >= 0; i--) {
                  if (flagCopy[i].name === selectedFlag.name) {
                    flagCopy[i].defaultValue = newDefaultValue;
                    break;
                  }
                }

                setFlags(flagCopy);
              }

              setShowBatchSetModal(false);
            }}
          />
        )}

        <EditFlagModal
          show={showEditModal}
          flag={selectedFlag}
          close={() => {
            setShowEditModal(false);
          }}
        />

        {showDeleteModal && (
          <DeleteFlagModal
            flagName={selectedFlag.name}
            close={() => setShowDeleteModal(false)}
            onDelete={() => {
              setFlags(
                flags.filter((flag) => {
                  return flag.name !== selectedFlag.name;
                })
              );
              setShowDeleteModal(false);
            }}
          />
        )}

        {showLocationsModal && (
          <FeatureFlagLocationsModal
            flagName={selectedFlag.name}
            close={() => setShowLocationsModal(false)}
          />
        )}
      </Page>
    </div>
  );
};
