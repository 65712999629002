import React from 'react';
import { cx } from 'emotion';
import { LocationInfo } from '../../handoff-hub.types';
import { handoffTitle, handoffSubtitle } from '../handoff-hub.styles';
import {
  containerStyles,
  titlesDiv,
  formSection,
  formTitle,
  marginTop,
  inputBox,
  selectBox,
  secondaryTextStyles,
  bodyText,
  textAreaStyles,
  marginBottom,
  errorMessage,
} from './location-information.styles';
import UserListModel from '../../../users/UserListModel';
import {
  DropdownField,
  Heading,
  RadioField,
  Text,
  TextareaField,
  TextField,
  useControlledField,
} from '@weave/design-system';

export type LocationInformationProps = {
  locationInfo: LocationInfo;
  isSoftwareOnly: boolean;
  users: UserListModel[];
  showErrors: boolean;
  refProp: any;
  readOnly: boolean;
  updateLocationInfo: (key: string, value: string) => void;
};

export const LocationInformation = (props: LocationInformationProps) => {
  const { locationInfo, users, showErrors, isSoftwareOnly } = props;
  const pointOfContactFieldProps = useControlledField({
    type: 'dropdown',
    required: true,
    value: locationInfo.pointOfContact ?? '',
    onChange: (value) => updateData('pointOfContact', value ?? ''),
  });

  const reasonForPurchaseFieldProps = useControlledField({
    type: 'text',
    value: locationInfo.purchaseReason ?? '',
    required: true,
    onChange: (value) => updateData('purchaseReason', value ?? ''),
  });

  const hasCustomizationsFieldProps = useControlledField({
    type: 'radio',
    value: locationInfo.hasCustomizations ?? '',
    required: true,
    onChange: (value) => updateData('hasCustomizations', value ?? ''),
  });

  const routerTypeFieldProps = useControlledField({
    type: 'dropdown',
    required: true,
    value: locationInfo.routerType ?? '',
    onChange: (value) => updateData('routerType', value ?? ''),
  });

  const disclaimerTypeFieldProps = useControlledField({
    type: 'dropdown',
    required: true,
    value: locationInfo.disclaimerType ?? '',
    onChange: (value) => updateData('disclaimerType', value ?? ''),
  });

  const customSetupNotesFieldProps = useControlledField({
    type: 'text',
    value: locationInfo.customSetupNotes ?? '',
    required: true,
    onChange: (value) => updateData('customSetupNotes', value ?? ''),
  });

  const routerInfoFieldProps = useControlledField({
    type: 'text',
    value: locationInfo.routerInfo ?? '',
    required: true,
    onChange: (value) => updateData('routerInfo', value ?? ''),
  });

  const networkNotesFieldProps = useControlledField({
    type: 'text',
    value: locationInfo.networkNotes ?? '',
    required: true,
    onChange: (value) => updateData('networkNotes', value ?? ''),
  });

  const billingNotesFieldProps = useControlledField({
    type: 'text',
    value: locationInfo.billingNotes ?? '',
    required: true,
    onChange: (value) => updateData('billingNotes', value ?? ''),
  });

  const officeExperienceFieldProps = useControlledField({
    type: 'text',
    value: locationInfo.officeExperience ?? '',
    required: true,
    onChange: (value) => updateData('officeExperience', value ?? ''),
  });

  const updateData = (key: string, value: string) => {
    props.updateLocationInfo(key, value);
  };

  return (
    <div className={containerStyles} ref={props.refProp}>
      <div className={titlesDiv}>
        <Heading className={handoffTitle}>Location Information</Heading>
        <Text color="light" className={handoffSubtitle}>
          Fill out the information below to add to your handoff note
        </Text>
        {showErrors && (
          <Text color="error" className={cx(handoffSubtitle, errorMessage)}>
            Please fill out all the required fields below to submit your handoff
          </Text>
        )}
      </div>

      <div className={formSection}>
        <Text weight="bold" className={cx(formTitle, marginBottom)}>
          Office Information
        </Text>
        <div>
          <Text className={bodyText}>Point of contact</Text>
          {!props.readOnly ? (
            <DropdownField
              label="Point of Contact"
              {...pointOfContactFieldProps}
              disabled={props.readOnly}
              placeholder="Point of Contact"
              value={locationInfo.pointOfContact}
              error={
                showErrors && !locationInfo.pointOfContact
                  ? 'Please select valid value'
                  : ''
              }
              name="pointOfContact"
              className={cx(inputBox, selectBox)}
            >
              {users.map((user, i) => (
                <DropdownField.Option value={user.Username} key={i}>
                  {`${user.FirstName} ${user.LastName} - ${user.Username}`}
                </DropdownField.Option>
              ))}
            </DropdownField>
          ) : (
            <Text className={bodyText}>{locationInfo.pointOfContact}</Text>
          )}
        </div>
        <div className={marginTop}>
          <TextField
            label="Reason for Purchase"
            {...reasonForPurchaseFieldProps}
            disabled={props.readOnly}
            name={'reasonForPurchase'}
            placeholder="Reason for Purchase"
            value={locationInfo.purchaseReason}
            error={
              showErrors &&
              !locationInfo.purchaseReason &&
              reasonForPurchaseFieldProps.required
                ? '* Required Field'
                : ''
            }
            className={inputBox}
          />
          <Text color="light" className={secondaryTextStyles}>
            i.e. appt reminders, reviews, etc.
          </Text>
        </div>

        <div>
          <RadioField
            {...hasCustomizationsFieldProps}
            name="radio1"
            label="Are there any special or complicated customizations?"
          >
            <RadioField.Radio value="true">Yes</RadioField.Radio>
            <RadioField.Radio value="false">No</RadioField.Radio>
          </RadioField>
        </div>

        {locationInfo.hasCustomizations === 'true' && (
          <div className={marginTop}>
            <Text className={bodyText}>
              Explain the customization setup in detail below:
            </Text>
            <TextareaField
              {...customSetupNotesFieldProps}
              disabled={props.readOnly}
              name="customSetup"
              value={locationInfo.customSetupNotes}
              error={
                showErrors && !locationInfo.customSetupNotes ? '* Required Field' : ''
              }
              label="Custom Setup Notes"
              className={textAreaStyles}
            />
          </div>
        )}
      </div>

      {!isSoftwareOnly && (
        <div className={formSection}>
          <Text weight="bold" className={cx(formTitle, marginBottom)}>
            Network Information
          </Text>
          <div>
            <Text className={cx(bodyText)}>Router Type</Text>
            {!props.readOnly ? (
              <DropdownField
                {...routerTypeFieldProps}
                label="Router Type"
                disabled={props.readOnly}
                placeholder="Router Type"
                value={locationInfo.routerType}
                error={
                  showErrors && !locationInfo.routerType && routerTypeFieldProps.required
                    ? '* Required Field'
                    : ''
                }
                name="routerType"
                className={cx(inputBox, selectBox)}
              >
                <DropdownField.Option value="green">Green</DropdownField.Option>
                <DropdownField.Option value="yellow">Yellow</DropdownField.Option>
                <DropdownField.Option value="red">Red</DropdownField.Option>
                <DropdownField.Option value="red">
                  modem/router combo
                </DropdownField.Option>
                <DropdownField.Option value="red">unknown</DropdownField.Option>
              </DropdownField>
            ) : (
              <Text className={bodyText}>{locationInfo.routerType}</Text>
            )}
          </div>
          <div>
            <Text className={cx(bodyText, marginTop)}>Disclaimer Type Sent</Text>
            {!props.readOnly ? (
              <DropdownField
                {...disclaimerTypeFieldProps}
                disabled={props.readOnly}
                label="Disclaimer Type Sent"
                placeholder="Disclaimer Type Sent"
                value={locationInfo.disclaimerType}
                error={
                  showErrors &&
                  !locationInfo.disclaimerType &&
                  disclaimerTypeFieldProps.required
                    ? '* Required Field'
                    : ''
                }
                name="disclaimerType"
                className={cx(inputBox, selectBox)}
              >
                <DropdownField.Option value="Email sent">Email sent</DropdownField.Option>
                <DropdownField.Option value="Phone conversation with office">
                  Phone conversation with office
                </DropdownField.Option>
                <DropdownField.Option value="Phone conversation with IT">
                  Phone conversation with IT
                </DropdownField.Option>
              </DropdownField>
            ) : (
              <Text className={bodyText}>{locationInfo.disclaimerType}</Text>
            )}
          </div>
          <div className={marginTop}>
            <TextField
              {...routerInfoFieldProps}
              label="Router Make and Model"
              disabled={props.readOnly}
              name={'routerInfo'}
              placeholder="Router Make and Model"
              value={locationInfo.routerInfo}
              error={
                showErrors && !locationInfo.routerInfo && routerTypeFieldProps.required
                  ? '* Required Field'
                  : ''
              }
              className={cx(inputBox, marginTop)}
            />
            <Text size="small" className={secondaryTextStyles}>
              i.e. Sonicwall, Netgear
            </Text>
          </div>
          <Text className={cx(bodyText, marginTop)}>
            Explain the office’s network decision below:
          </Text>
          <TextareaField
            {...networkNotesFieldProps}
            disabled={props.readOnly}
            name="networkSetup"
            className={textAreaStyles}
            value={locationInfo.networkNotes}
            error={
              showErrors && !locationInfo.networkNotes && networkNotesFieldProps.required
                ? '* Required Field'
                : ''
            }
            label="Notes for Network Setup"
            placeholder="Who did you talk to about the network? Did that person agree to make the recommended upgrades? If not, why not?"
          />
        </div>
      )}

      <div className={formSection}>
        <Text weight="bold" className={cx(formTitle, marginBottom)}>
          Office Experience
        </Text>
        <div>
          <Text className={bodyText}>Billing Notes:</Text>
          <TextareaField
            {...billingNotesFieldProps}
            disabled={props.readOnly}
            label="Billing Notes"
            name="billingNotes"
            className={textAreaStyles}
            value={locationInfo.billingNotes}
            error={
              showErrors && !locationInfo.billingNotes && billingNotesFieldProps.required
                ? '* Required Field'
                : ''
            }
            placeholder="Did we give any credits or refunds during the onboarding process? If so, why?"
          />
        </div>
        <div className={marginTop}>
          <Text className={bodyText}>
            Please include any helpful notes and details below:
          </Text>
          <TextareaField
            {...officeExperienceFieldProps}
            disabled={props.readOnly}
            name="officeExperience"
            label="Office Experience"
            className={textAreaStyles}
            value={locationInfo.officeExperience}
            error={
              showErrors &&
              !locationInfo.officeExperience &&
              officeExperienceFieldProps.required
                ? '* Required Field'
                : ''
            }
          />
        </div>
      </div>
    </div>
  );
};
