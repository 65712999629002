import React from 'react';
import { css } from 'emotion';
import { theme } from '@weave/theme-original';

const dot = ({ color, size, isActive }) => {
  const activeStyle = isActive
    ? `background-color: ${color};`
    : `border: 1px solid ${color}`;
  return css`
    cursor: unset;
    height: ${size};
    width: ${size};
    border-radius: 50%;
    display: inline-block;
    margin: 0 ${theme.fontSize(4)};
    ${activeStyle}
  `;
};

export const Dot = ({ isActive }) => {
  const color = isActive ? theme.colors.success : theme.colors.error;
  return (
    <span
      className={dot({
        color,
        size: theme.fontSize(10),
        isActive,
      })}
    />
  );
};
