import { connect } from 'react-redux';

import { LocationFeature } from '../../models/location-feature.model';
import { CustomizationPage } from './customization-page/customization-page.component';
import { selectHasWeaveAcl } from '../../redux/actions/auth/auth.selectors';
import {
  getLocationFeatures,
  setFeatures,
  setLocationFeatures,
  updateFeature,
} from '../../redux/actions/customization/customization.action';
import {
  selectCurrentLocation,
  selectCurrentLocationVerticalID,
} from '../../redux/actions/location';
import { CoreACLs } from '../../redux/actions/auth/auth.types';
import { showError } from '@weave/alert-system';

export const CustomizationPageContainer = connect(
  (state: any) => ({
    // For now the Customization Features are controlled by the FeatureFlag ACL
    canEdit: selectHasWeaveAcl(state, CoreACLs.FEATUREFLAGWRITE),
    loading: state.customization.loading,
    location: selectCurrentLocation(state),
    features: state.customization.features,
    verticalId: selectCurrentLocationVerticalID(state),
  }),
  (dispatch) => ({
    getLocationFeatures: (locationId: string) =>
      dispatch(getLocationFeatures(locationId)),
    setLocationFeatures: (locationId: string, features: LocationFeature[]) =>
      dispatch(
        setLocationFeatures({
          locationId,
          features,
        })
      ),
    updateFeature: (feature, newState) => dispatch(updateFeature({ feature, newState })),
    setFeatures: (features) => dispatch(setFeatures(features)),
    showError: (message: string) => dispatch(showError(message)),
  })
)(CustomizationPage);
