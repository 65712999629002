import React, { useState, useEffect, FC } from 'react';
import { css } from 'emotion';
import { useAlert } from '@weave/alert-system';
import { NakedUl } from '@weave/design-system';
import { isEmpty, omit, isUndefined } from 'lodash';
import { DeviceSection } from './device-section.component';
import { DeviceRowReadOnly } from './device-row-read-only.component';
import { DeviceRowSelect } from './device-row-select.component';
import { DeviceRowText } from './device-row-text.component';
import { DevicePendingChangesModel } from '../../../redux/actions/devices/devices.types';

interface Props {
  adminPassword: string;
  adminUsername: string;
  callWaitingIndicatorDisabled: boolean;
  clearLocalSettings: boolean;
  expires: number;
  moveable: boolean;
  parkRingbackEnabled?: boolean;
  parkRingbackLag?: number;
  updating: boolean;
  updateDevice: (changes: DevicePendingChangesModel) => void;
}

interface PendingChanges {
  callWaitingIndicatorDisabled?: boolean;
  clearLocalSettings?: boolean;
  sipProfile: {
    parkRingbackEnabled?: boolean;
    parkRingbackLag?: number;
  };
}

export const DeviceSectionAdvanced: FC<Props> = ({
  adminPassword,
  adminUsername,
  callWaitingIndicatorDisabled,
  clearLocalSettings,
  expires,
  moveable,
  parkRingbackEnabled,
  parkRingbackLag,
  updating,
  updateDevice,
}) => {
  const [parkRingbackLagError, setParkRingbackLagError] = useState<boolean>(false);
  const [pendingChanges, setPendingChanges] = useState<PendingChanges>({
    sipProfile: {},
  });
  const alerts = useAlert();

  useEffect(() => {
    // When the editable device values update, clear pending changes
    setPendingChanges({ sipProfile: {} });
  }, [
    callWaitingIndicatorDisabled,
    clearLocalSettings,
    parkRingbackEnabled,
    parkRingbackLag,
  ]);

  const onSave = async () => {
    if (parkRingbackLagError) {
      alerts.error('Invalid park ringback lag value');
      return;
    }

    updateDevice(pendingChanges);
  };

  return (
    <DeviceSection
      name="Advanced Settings"
      showSaveButton
      hasRowChanges={
        (!isEmpty(pendingChanges.sipProfile) ||
          !isUndefined(pendingChanges.callWaitingIndicatorDisabled) ||
          !isUndefined(pendingChanges.clearLocalSettings)) &&
        !updating
      }
      onSaveClick={onSave}
    >
      <NakedUl
        className={css`
          margin: 16px 0 0;
          max-width: 600px;
        `}
      >
        <DeviceRowReadOnly name="Admin Username" value={adminUsername} />
        <DeviceRowReadOnly name="Admin Password" value={adminPassword} />
        <DeviceRowReadOnly name="Expires (Seconds)" value={expires} />
        <DeviceRowReadOnly name="Movable" value={moveable} />
        <DeviceRowSelect
          id="clear-local-settings"
          label="Clear Local Settings"
          value={`${pendingChanges.clearLocalSettings ?? ''}` || `${clearLocalSettings}`}
          onChange={(newValue: string) => {
            if (newValue === `${clearLocalSettings}`) {
              // Clear pending changes
              setPendingChanges(omit(pendingChanges, 'clearLocalSettings'));
            } else {
              setPendingChanges({
                ...pendingChanges,
                clearLocalSettings: newValue === 'true' ? true : false,
              });
            }
          }}
          options={[
            { label: 'False', value: 'false' },
            { label: 'True', value: 'true' },
          ]}
        />
        <DeviceRowSelect
          id="park-ringback-enabled"
          label="Park Ringback Enabled"
          value={
            `${pendingChanges.sipProfile.parkRingbackEnabled ?? ''}` ||
            `${parkRingbackEnabled}`
          }
          onChange={(newValue: string) => {
            if (newValue === `${parkRingbackEnabled}`) {
              // Clear pending changes
              setPendingChanges({
                ...pendingChanges,
                sipProfile: omit(pendingChanges.sipProfile, 'parkRingbackEnabled'),
              });
            } else {
              setPendingChanges({
                ...pendingChanges,
                sipProfile: {
                  ...pendingChanges.sipProfile,
                  parkRingbackEnabled: newValue === 'true' ? true : false,
                },
              });
            }
          }}
          options={[
            { label: 'False', value: 'false' },
            { label: 'True', value: 'true' },
          ]}
        />
        <DeviceRowText
          name="Park Ringback Lag (Seconds)"
          value={`${pendingChanges.sipProfile?.parkRingbackLag ?? parkRingbackLag ?? 30}`}
          error={parkRingbackLagError}
          helpText="Please enter a number between 30 and 300"
          onChange={(value: string) => {
            if (isNaN(+value)) {
              return;
            }

            const newValue = +value;

            if (newValue < 30 || newValue > 300) {
              setParkRingbackLagError(true);
            } else {
              setParkRingbackLagError(false);
            }

            if (newValue === parkRingbackLag) {
              // Clear pending changes
              setPendingChanges({
                ...pendingChanges,
                sipProfile: omit(pendingChanges.sipProfile, 'parkRingbackLag'),
              });
            } else {
              setPendingChanges({
                ...pendingChanges,
                sipProfile: {
                  ...pendingChanges.sipProfile,
                  parkRingbackLag: newValue,
                },
              });
            }
          }}
        />
        <DeviceRowSelect
          id="call-waiting-indicator-beep"
          label="Call Waiting Indicator Beep"
          value={
            `${pendingChanges.callWaitingIndicatorDisabled ?? ''}` ||
            `${callWaitingIndicatorDisabled}`
          }
          onChange={(newValue: string) => {
            if (newValue === `${callWaitingIndicatorDisabled}`) {
              // Clear pending changes
              setPendingChanges(omit(pendingChanges, 'callWaitingIndicatorDisabled'));
            } else {
              setPendingChanges({
                ...pendingChanges,
                callWaitingIndicatorDisabled: newValue === 'true' ? true : false,
              });
            }
          }}
          options={[
            { label: 'On', value: 'false' },
            { label: 'Off', value: 'true' },
          ]}
        />
      </NakedUl>
    </DeviceSection>
  );
};
