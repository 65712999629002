const DATA_CENTER_PING_URL_MAPPINGS = {
  'pacific-ca': 'test-ca.getweave.com',
  'mountain-ut': 'test-ut.getweave.com',
  'central-ia': 'test-ia.getweave.com',
  'eastern-va': 'test-va.getweave.com',
};

export const getDataCenterPingUrl = (dataCenter: string): string | undefined => {
  return DATA_CENTER_PING_URL_MAPPINGS[dataCenter];
};

export const getLossString = (loss: number) => {
  return loss !== -1 ? `${loss.toFixed(0)}%` : '-';
};

export const getFixedString = (value: number) => {
  return value !== -1 ? `${value.toFixed(1)}ms` : '-';
};
