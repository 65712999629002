import React, { useState } from 'react';
import { cloneDeep, forEach } from 'lodash';
import { Text, TextField, useFormField } from '@weave/design-system';
import { Button, Modal } from '@weave/platform-ui-components';

import FeatureFlagModel from '../feature-flag.model';
import { ToggleSwitch } from '../../../shared/toggle-switch/toggle-switch.component';
import { css } from 'emotion';

interface Props {
  handleCloseModal: (save: boolean, flagsToSave: FeatureFlagModel[]) => void;
  featureFlags: FeatureFlagModel[];
  modalOpen: boolean;
}

export const FeatureFlagsEdit = (props: Props) => {
  const [featureFlags, setFeatureFlags] = useState(props.featureFlags);
  const filterProps = useFormField({ type: 'text', value: '' }, [props.modalOpen]);

  // Returns a list of the flags that have been changed
  const aggregateChangedFlags = (): FeatureFlagModel[] => {
    const changedFlags: FeatureFlagModel[] = [];
    const oldFlags = props.featureFlags;
    const newFlags = featureFlags;

    forEach(oldFlags, (flag, index) => {
      if (flag.Value !== newFlags[index].Value) {
        changedFlags.push(cloneDeep(newFlags[index]));
      }
    });

    return changedFlags;
  };

  const handleCancel = () => {
    // Set the state back to how it was before
    setFeatureFlags(props.featureFlags);
    props.handleCloseModal(false, []);
  };

  const handleSave = () => {
    const flagsToSave = aggregateChangedFlags();
    props.handleCloseModal(true, flagsToSave);
  };

  const toggleFlag = (featureFlag) => {
    const flags = cloneDeep(featureFlags);
    flags.forEach((flag) => {
      if (flag.Name === featureFlag.Name) {
        flag.Value = !flag.Value;
      }
    });
    setFeatureFlags(flags);
  };

  const filterLowerCase = filterProps.value.toLowerCase();
  const filteredList = featureFlags.filter(
    (item) =>
      !filterLowerCase ||
      item.Name.toLowerCase().includes(filterLowerCase) ||
      item.DisplayName.toLowerCase().includes(filterLowerCase)
  );
  return (
    <Modal
      isOpen={props.modalOpen}
      onRequestClose={handleCancel}
      title="Edit Feature Flags"
      className={css`
        min-height: 50vh;
        .react-modal-card {
          padding: 24px;
        }
      `}
    >
      <div
        className={css`
          position: relative;
        `}
      >
        <Text size="medium" color="error">
          NOTE: Feature flags affect many parts of how Weave Account Manager (WAM)
          retrieves data. Feature flags also affect the desktop client. Make sure you know
          what the ramifications are before making changes.
        </Text>
        <div
          className={css`
            margin: 24px 0 8px;
            text-align: center;
          `}
        >
          <Button
            color="gray"
            fill="outline"
            onClick={handleCancel}
            className={css`
              display: inline-block !important;
              margin-right: 8px;
            `}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            className={css`
              display: inline-block !important;
            `}
          >
            Save
          </Button>
        </div>
        <div
          className={css`
            max-height: 500px;
            overflow: auto;
          `}
        >
          <TextField {...filterProps} name="filter-flags" label="Filter Feature Flags" />
          {filteredList.map((flag) => {
            return (
              <div
                key={flag.Name}
                className={css`
                  height: 30px;
                  border-bottom: 1px solid #f1f2f2;
                  margin: 15px;
                `}
              >
                <ToggleSwitch
                  on={flag.Value}
                  label={flag.DisplayName || flag.Name}
                  onChange={() => {
                    toggleFlag(flag);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};
