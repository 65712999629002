import React from 'react';
import { ProgressBar } from '../progress-bar/progress-bar.component';
import { css } from 'emotion';
import { Task } from '../onboarding-task.model';

export type OnboardingProgressBarProps = {
  tasks: Task[];
};

export const OnboardingProgressBar = (props: OnboardingProgressBarProps) => {
  const calculateTaskPercentageDone = () => {
    const completedTasks = props.tasks.filter((task) => {
      return task.status === 'Completed' || task.status === 'Verified';
    });
    return Math.floor((completedTasks.length / props.tasks.length) * 100);
  };
  return (
    <div>
      <ProgressBar
        prefixCls="rc-progress"
        strokeLinecap="round"
        percent={calculateTaskPercentageDone()}
        strokeWidth="7"
        strokeColor={'#27B523'}
        trailWidth="7"
        trailColor="#D4DBDF"
        className={css`
          height: 27px;
          width: 347px;
        `}
      />
    </div>
  );
};
