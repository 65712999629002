import React from 'react';
import { cx } from 'emotion';

import { HandoffCardsSection } from './handoff-cards-section/handoff-cards-section.component';
import { LocationInformation } from './location-information/location-information.component';
import moment from 'moment';
import {
  CategoryName,
  Factor,
  FactorName,
} from '../../../redux/actions/handoff-hub/handoff-hub.reducer';
import {
  Categories,
  Category,
  KeyFactor,
  LocationInfo,
  FailedFactorsData,
} from '../handoff-hub.types';
import {
  bottomButtonRow,
  handoffHubStyles,
  modalStyles,
  topBar,
  titlesDiv,
  topTitleMargin,
  handoffTitle,
  handoffSubtitle,
  buttonStyles,
  refreshButtonDiv,
  modalTitle,
  modalBodyText,
  modalButton,
  submitError,
  modalDiv,
  modalSpinner,
} from './handoff-hub.styles';
import { Alert } from 'reactstrap';
import UserListModel from '../../users/UserListModel';
import { GuideTrigger } from './guide-trigger/guide-trigger.component';
import { LocationModel } from '../../../redux/actions/location/locations/locations.types';
import SuccessSVG from '../../../images/success.svg';
import { ErrorsVerification } from './errors-verification/errors-verification.component';
import { debounce, isEqual } from 'lodash';
import {
  Heading,
  Modal,
  PrimaryButton,
  SpinningLoader,
  Text,
} from '@weave/design-system';
import { css } from '@emotion/core';

export const getHealthScoreColor = (score: number) => {
  if (score === 100) {
    return 'text-success';
  } else if (score === 0) {
    return 'text-danger';
  } else {
    return 'text-warning';
  }
};

const defaultLocationInformation: LocationInfo = {
  pointOfContact: '',
  purchaseReason: '',
  hasCustomizations: 'false',
  customSetupNotes: '',
  routerType: '',
  disclaimerType: '',
  routerInfo: '',
  networkNotes: '',
  billingNotes: '',
  officeExperience: '',
};

export interface HandoffHubProps {
  snapshot: any;
  currentLocation: LocationModel;
  connectedUsers: UserListModel[];
  users: UserListModel[];
  loadingUsers: boolean;
  submitting: boolean;
  submitError: any;
  saveCSATData: (data) => void;
  saveHandoffNotesSnapshot: (snapshot) => void;
  getConnectedUsers: () => void;
  getUsers: (payload: { limit: number }) => void;
  getHandoffHubData: () => void;
  submitHandoffData: (finalResults: any) => void;
  runCustomerSatisfactionSurvey: (userId: string) => void;
  phonesCategory: {
    inboundOutboundCalls: Factor;
    phonesRegistered: Factor;
    phonesCustomized: Factor;
    networkHealth: Factor;
  };
  dataSyncCategory: {
    dataSyncInstalled: Factor;
    writebacks: Factor;
    statusMappingSet: Factor;
  };
  softwareCategory: {
    autoMessagingOn: Factor;
    manualMessaging: Factor;
    newUsersAdded: Factor;
  };
  premiumFeaturesCategory: {
    reviewsEnabled: Factor;
    missedCallSMS: Factor;
  };
  isNotIntegrated: boolean;
  isPhonesOnly: boolean;
  isSoftwareOnly: boolean;
  hasPremiumFeatures: boolean;
}

interface State {
  verificationModalIsOpen: boolean;
  handoffModalIsOpen: boolean;
  guideTriggerModalIsOpen: boolean;
  locationInformation: LocationInfo;
  bypassNotes: string;
  showFormErrors: boolean;
}

export class HandoffHub extends React.Component<HandoffHubProps, State> {
  locationInfoFormRef: any;
  handedOff: boolean;

  constructor(props) {
    super(props);

    this.locationInfoFormRef = React.createRef();
    this.handedOff = false;
    this.state = {
      verificationModalIsOpen: false,
      handoffModalIsOpen: false,
      guideTriggerModalIsOpen: false,
      bypassNotes: '',
      showFormErrors: false,
      locationInformation: defaultLocationInformation,
    };
    this.persistForm = debounce(this.persistForm, 500);
  }

  persistForm = () => {
    const snapshotData: any = {
      point_of_contact_email: this.state.locationInformation.pointOfContact || null,
      reason_for_purchase: this.state.locationInformation.purchaseReason,
      customizations:
        this.state.locationInformation.hasCustomizations === 'false' ? null : true,
      customization_setup: this.state.locationInformation.customSetupNotes,
      router_type: this.state.locationInformation.routerType,
      disclaimer_type_sent: this.state.locationInformation.disclaimerType,
      router_make_and_model: this.state.locationInformation.routerInfo,
      network_decision: this.state.locationInformation.networkNotes,
      billing_notes: this.state.locationInformation.billingNotes,
      notes: this.state.locationInformation.officeExperience,
      onboarders_location_id: this.props.snapshot.onboarders_location_id,
    };
    if (this.props.snapshot.id) {
      snapshotData.id = this.props.snapshot.id;
    }
    if (this.props.snapshot.csat_recipient_user_email) {
      snapshotData.csat_recipient_user_email =
        this.props.snapshot.csat_recipient_user_email;
    }
    if (this.props.snapshot.csat_sent_at) {
      snapshotData.csat_sent_at = this.props.snapshot.csat_sent_at;
    }
    if (this.props.snapshot.created_at) {
      snapshotData.created_at = this.props.snapshot.created_at;
    }
    if (this.props.snapshot.updated_at) {
      snapshotData.updated_at = this.props.snapshot.updated_at;
    }
    this.props.saveHandoffNotesSnapshot(snapshotData);
  };

  updateLocationInformation = (key: string, value: string) => {
    this.setState((prevState) => ({
      locationInformation: {
        ...prevState.locationInformation,
        [key]: value,
      },
    }));
    this.persistForm();
  };

  getAllCategories = (): Categories => {
    const { isNotIntegrated, isSoftwareOnly, hasPremiumFeatures, isPhonesOnly } =
      this.props;

    const categories: Categories = {};

    if (!isSoftwareOnly) {
      categories.phonesCategory = this.getPhonesCategory();
    }
    if (!isPhonesOnly) {
      categories.softwareCategory = this.getSoftwareCategory();
    }
    if (!isNotIntegrated) {
      categories.dataSyncCategory = this.getDataSyncCategory();
    }
    if (hasPremiumFeatures) {
      categories.premiumFeaturesCategory = this.getPremiumFeaturesCategory();
    }

    return categories;
  };

  componentDidMount() {
    this.handleRefresh();
  }

  initializeFromSnapshot = (snapshot) => {
    const defaultHubData = {
      pointOfContact: snapshot.point_of_contact_email || '',
      purchaseReason: snapshot.reason_for_purchase || '',
      hasCustomizations: snapshot.customizations ? 'true' : 'false',
      customSetupNotes: snapshot.customization_setup || '',
      routerType: snapshot.router_type || '',
      disclaimerType: snapshot.disclaimer_type_sent || '',
      routerInfo: snapshot.router_make_and_model || '',
      networkNotes: snapshot.network_decision || '',
      billingNotes: snapshot.billing_notes || '',
      officeExperience: snapshot.notes || '',
    };

    this.setState({
      locationInformation: defaultHubData,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps: HandoffHubProps) {
    if (this.props.currentLocation.LocationID !== nextProps.currentLocation.LocationID) {
      this.handleRefresh();
    }
    if (
      (this.props.snapshot.id || nextProps.snapshot.id) &&
      !isEqual(this.props.snapshot, nextProps.snapshot)
    ) {
      this.initializeFromSnapshot(nextProps.snapshot);
    }
  }

  handleRefresh = () => {
    this.props.getHandoffHubData();
    this.props.getUsers({ limit: 200 });
    this.initializeFromSnapshot(this.props.snapshot);
    this.handedOff = false;
  };

  shouldDisableButton = (): boolean => {
    const categories = Object.values(this.getAllCategories());
    if (this.props.snapshot.handed_off_at) {
      return true;
    }
    // True if any factor data is loading.
    return categories.some((category) =>
      category.factors.some((factor) => factor.loading)
    );
  };

  /**
   * This is the method used to calculate the health score of a category.
   */
  calculateHealthScore = (keyFactors: KeyFactor[]): number => {
    const total = keyFactors.length;
    let passed = 0;

    for (const factor of keyFactors) {
      if (factor.status === 'pass') {
        passed++;
      }
    }

    const healthScore = Math.round((passed / total) * 100);
    return healthScore;
  };

  calculateOverallScore = (categories: Categories): number => {
    const scores = Object.values(categories).map((category) =>
      this.calculateHealthScore(category.factors)
    );

    let value = 0;
    let total = 0;
    for (const score of scores) {
      value += score;
      total += 100;
    }

    const overall = Math.round((value / total) * 100);
    return overall;
  };

  handleHandoffSubmit = async () => {
    if (this.handedOff) {
      return;
    }
    this.handedOff = true;

    this.setState({
      verificationModalIsOpen: false,
      handoffModalIsOpen: true,
    });

    const categories = this.getAllCategories();
    const overallHealthScore = this.calculateOverallScore(categories);

    const categoryData = {};
    Object.values(categories).forEach((category) => {
      const { categoryId } = category;
      categoryData[`${categoryId}Category`] = {
        score: this.calculateHealthScore(category.factors),
        factors: this.props[`${categoryId}Category`],
      };
    });

    const finalResults: any = {
      overallHealthScore: overallHealthScore,
      categories: categoryData,
      locationInformation: this.state.locationInformation,
    };

    if (this.state.bypassNotes) {
      finalResults.bypassNotes = this.state.bypassNotes;
    }

    this.props.submitHandoffData(finalResults);
  };

  handleHandoffSuccessConfirm = () => {
    this.setState({
      handoffModalIsOpen: false,
      bypassNotes: '',
    });
  };

  toggleHandoffModal = () => {
    this.setState((prevState) => ({
      handoffModalIsOpen: !prevState.handoffModalIsOpen,
    }));
  };

  toggleVerificationModal = () => {
    this.setState((prevState) => ({
      verificationModalIsOpen: !prevState.verificationModalIsOpen,
    }));
  };

  toggleGuideTriggerModal = () => {
    const guideTriggerModalIsOpen = !this.state.guideTriggerModalIsOpen;
    if (guideTriggerModalIsOpen) {
      this.props.getConnectedUsers();
    }
    this.setState({
      guideTriggerModalIsOpen,
    });
  };

  getFactorData = (categoryName: CategoryName, factorName: FactorName): Factor => {
    return this.props[categoryName][factorName];
  };

  getPhonesCategory = (): Category => {
    const categoryName = 'phonesCategory';
    return {
      label: 'Phones',
      categoryId: 'phones',
      factors: [
        {
          factorName: 'Calls Working',
          canBeBypassed: false,
          ...this.getFactorData(categoryName, 'inboundOutboundCalls'),
        },
        {
          factorName: 'Phones Registered',
          canBeBypassed: false,
          ...this.getFactorData(categoryName, 'phonesRegistered'),
        },
        {
          factorName: 'Phones Customized',
          canBeBypassed: true,
          ...this.getFactorData(categoryName, 'phonesCustomized'),
        },
        {
          factorName: 'Network Health',
          canBeBypassed: true,
          ...this.getFactorData(categoryName, 'networkHealth'),
        },
      ],
    };
  };

  getDataSyncCategory = (): Category => {
    const categoryName = 'dataSyncCategory';
    return {
      label: 'Data Sync',
      categoryId: 'dataSync',
      factors: [
        {
          factorName: 'Data Sync Installed',
          canBeBypassed: true,
          ...this.getFactorData(categoryName, 'dataSyncInstalled'),
        },
        {
          factorName: 'Writebacks',
          canBeBypassed: true,
          ...this.getFactorData(categoryName, 'writebacks'),
        },
        {
          factorName: 'Status Mapping Set',
          canBeBypassed: false,
          ...this.getFactorData(categoryName, 'statusMappingSet'),
        },
      ],
    };
  };

  getSoftwareCategory = (): Category => {
    const { isNotIntegrated } = this.props;
    const categoryName = 'softwareCategory';
    const category = {
      label: 'Software',
      categoryId: 'software',
      factors: [
        {
          factorName: 'Manual Messaging',
          canBeBypassed: true,
          ...this.getFactorData(categoryName, 'manualMessaging'),
        },
        {
          factorName: 'New Users Added',
          canBeBypassed: false,
          ...this.getFactorData(categoryName, 'newUsersAdded'),
        },
      ],
    };

    if (!isNotIntegrated) {
      category.factors.push({
        factorName: 'Auto Messaging On',
        canBeBypassed: true,
        ...this.getFactorData(categoryName, 'autoMessagingOn'),
      });
    }

    return category;
  };

  getPremiumFeaturesCategory = (): Category => {
    const { isSoftwareOnly } = this.props;
    const categoryName = 'premiumFeaturesCategory';
    const category = {
      label: 'Premium Features',
      categoryId: 'premiumFeatures',
      factors: [
        {
          factorName: 'Reviews Enabled',
          canBeBypassed: true,
          ...this.getFactorData(categoryName, 'reviewsEnabled'),
        },
      ],
    };

    if (!isSoftwareOnly) {
      category.factors.push({
        factorName: 'Missed Call SMS',
        canBeBypassed: true,
        ...this.getFactorData(categoryName, 'missedCallSMS'),
      });
    }

    return category;
  };

  getFailedFactors = (): FailedFactorsData => {
    const categories = this.getAllCategories();
    const byPassable: string[] = [];
    const unByPassable: string[] = [];
    let total = 0;

    Object.values(categories).forEach((category) =>
      category.factors.forEach((el) => {
        if (el.status === 'fail' || el.status === '') {
          total++;
          if (el.canBeBypassed) {
            byPassable.push(el.factorName);
          } else {
            unByPassable.push(el.factorName);
          }
        }
      })
    );

    if (!this.props.snapshot.csat_sent_at) {
      total++;
      unByPassable.push('Send CSAT');
    }
    return {
      byPassable,
      unByPassable,
      total,
    };
  };

  setBypassNotes = (value: string) => {
    this.setState({ bypassNotes: value });
  };

  handleVerification = () => {
    // Define the keys to ignore when this location is software only.
    const softwareOnlyIgnores = [
      'routerType',
      'disclaimerType',
      'routerInfo',
      'networkNotes',
    ];
    const { locationInformation: locationInfo } = this.state;
    const locationInfoNotSet = Object.entries(locationInfo).some(([key, value]) => {
      if (!value) {
        if (key === 'customSetupNotes' && locationInfo.hasCustomizations === 'false') {
          return false;
        }
        if (this.props.isSoftwareOnly && softwareOnlyIgnores.includes(key)) {
          return false;
        }
        return true;
      }
      return false;
    });

    if (locationInfoNotSet) {
      this.setState({ showFormErrors: true });
      window.scrollTo({
        top: this.locationInfoFormRef.current.offsetTop - 70,
        behavior: 'smooth',
      });
    } else {
      this.setState({ verificationModalIsOpen: true, showFormErrors: false });
    }
  };

  render() {
    const categories = this.getAllCategories();

    return (
      <>
        <div className={handoffHubStyles}>
          <div className={topBar}>
            <div className={titlesDiv}>
              <Heading className={cx(handoffTitle, topTitleMargin)}>
                Handoff Dashboard
              </Heading>
              <Text className={handoffSubtitle} color="light">
                Create your handoff note below
              </Text>
            </div>
            <div className={refreshButtonDiv}>
              <PrimaryButton
                className={buttonStyles}
                onClick={this.handleRefresh}
                disabled={this.shouldDisableButton()}
              >
                Refresh
              </PrimaryButton>
            </div>
          </div>

          <HandoffCardsSection
            categories={categories}
            calculateHealthScore={this.calculateHealthScore}
          />

          {!this.props.snapshot.hasNoOnboarder && (
            <LocationInformation
              locationInfo={this.state.locationInformation}
              isSoftwareOnly={this.props.isSoftwareOnly}
              updateLocationInfo={this.updateLocationInformation}
              users={this.props.users}
              showErrors={this.state.showFormErrors}
              refProp={this.locationInfoFormRef}
              readOnly={!!this.props.snapshot.handed_off_at}
            />
          )}

          {this.props.snapshot.hasNoOnboarder && (
            <Alert color="warning">
              Cannot handoff - This location does not have an onboarder assigned.
            </Alert>
          )}

          {!this.props.snapshot.hasNoOnboarder && (
            <div className={bottomButtonRow}>
              {this.props.snapshot.csat_sent_at && (
                <div>
                  CSAT previously sent on{' '}
                  {moment(this.props.snapshot.csat_sent_at).format('MM/DD/YYYY')} to{' '}
                  {this.props.snapshot.csat_recipient_user_email}
                </div>
              )}
              <div
                css={css`
                  display: flex;
                  width: 25%;
                `}
              >
                <PrimaryButton
                  className={buttonStyles}
                  onClick={this.toggleGuideTriggerModal}
                  disabled={!!this.props.snapshot.handed_off_at}
                >
                  Send CSAT
                </PrimaryButton>
                <PrimaryButton
                  className={buttonStyles}
                  onClick={this.handleVerification}
                  disabled={this.shouldDisableButton()}
                  data-testid="handoff-button"
                >
                  Handoff
                </PrimaryButton>
              </div>
            </div>
          )}

          <hr />
        </div>

        <Modal
          onClose={this.toggleGuideTriggerModal}
          show={this.state.guideTriggerModalIsOpen}
          className={modalStyles}
        >
          <Modal.Body>
            <GuideTrigger
              toggleModal={this.toggleGuideTriggerModal}
              runGuide={this.props.runCustomerSatisfactionSurvey}
              saveGuideRun={this.props.saveCSATData}
              users={this.props.connectedUsers}
              loading={this.props.loadingUsers}
              locationInformation={this.state.locationInformation}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.verificationModalIsOpen}
          className={modalStyles}
          onClose={this.toggleVerificationModal}
        >
          <Modal.Body>
            <div className={modalDiv}>
              <ErrorsVerification
                getFailedFactors={this.getFailedFactors}
                toggleModal={this.toggleVerificationModal}
                handleHandoffSubmit={this.handleHandoffSubmit}
                bypassNotes={this.state.bypassNotes}
                setBypassNotes={this.setBypassNotes}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.handoffModalIsOpen}
          className={modalStyles}
          onClose={this.toggleHandoffModal}
        >
          <Modal.Body>
            <div className={modalDiv}>
              {this.props.submitting ? (
                <SpinningLoader className={modalSpinner} />
              ) : (
                <>
                  {this.props.submitError ? (
                    <div>
                      <Heading textAlign="center" className={cx(modalTitle, submitError)}>
                        Error
                      </Heading>
                      <Text color="light" className={cx(modalBodyText, submitError)}>
                        Handoff submit was unsuccessful. Please try again.
                      </Text>
                      <PrimaryButton
                        onClick={this.toggleHandoffModal}
                        className={modalButton}
                      >
                        Got it
                      </PrimaryButton>
                    </div>
                  ) : (
                    <div>
                      <img src={SuccessSVG} alt="Success" />
                      <Heading textAlign="center" className={modalTitle}>
                        Success!
                      </Heading>
                      <Text color="light" className={modalBodyText}>
                        Your handoff note has been submitted! Please be sure to close all
                        your cases in Salesforce as the last step to handing off your
                        account to Support and Customer Success.
                      </Text>
                      <PrimaryButton
                        onClick={this.handleHandoffSuccessConfirm}
                        className={modalButton}
                      >
                        Got it
                      </PrimaryButton>
                    </div>
                  )}
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
