import { CustomAxios, getResponseData } from '../../axios';
import { UserModel, WeaveUserModel } from '../../../models/user.model';
import { JobTitlesModel, RolesModel, UpdateUserAccessReq } from './user-roles.action';
import { formatJobTitles } from '../../../components/users/users.helpers';

export const usersApi = {
  /* GET REQUESTS */
  getUser: (userId: string) =>
    CustomAxios.get(`support/v1/users/${userId}`).then<{ user: UserModel }>(
      getResponseData
    ),
  getUsers: (limit: number) =>
    CustomAxios.get('support/v1/locations/users', { params: { limit } }).then<{
      users: UserModel[];
    }>(getResponseData),
  getAvailableRoles: () =>
    CustomAxios.get(`/support/v1/users/access/roles`).then<{
      availableRoles: RolesModel[];
    }>(getResponseData),
  getAvailableJobs: () =>
    CustomAxios.get(`/support/v1/job-titles`).then<{ availableJobs: JobTitlesModel[] }>(
      getResponseData
    ),
  getJobTitles: (user: UserModel) =>
    CustomAxios.get(`/support/v1/user-details/${user.UserID}/job-titles`)
      .then<{ jobTitles: JobTitlesModel[] }>(getResponseData)
      .then((res) => {
        return { ...user, JobTitles: formatJobTitles(res) };
      }),

  getUserJobs: (user: UserModel) =>
    CustomAxios.get(`/support/v1/user-details/${user.UserID}/job-titles`).then<{
      userJobs: JobTitlesModel[];
    }>(getResponseData),

  /* PUT REQUESTS */

  updateUser: (user: UserModel): Promise<UserModel[]> =>
    CustomAxios.put(`/support/v1/users/${user.UserID}`, user).then(getResponseData),
  updateWeaveUser: (user: WeaveUserModel): Promise<WeaveUserModel[]> =>
    CustomAxios.put(`/support/v1/users/access`, user).then(getResponseData),
  updateUserRoles: (userDetails: UpdateUserAccessReq) =>
    CustomAxios.put(`/support/v1/users/access`, userDetails).then(getResponseData),

  /* POST REQUESTS */
  passwordReset: (userDetails) =>
    CustomAxios.post(`/support/v1/users/resetPassword`, userDetails),
  addUser: (userDetails) =>
    CustomAxios.post(`/support/v1/users/access`, userDetails).then(getResponseData),
  updateJobTitles: (userId, jobTitles) =>
    CustomAxios.post(`/support/v1/user-details/${userId}/job-titles`, jobTitles).then(
      getResponseData
    ),

  /* DELETE REQUESTS */
  deleteUser: (userId: string) => CustomAxios.delete(`/support/v1/users/${userId}`),
};
