import { all, call, put } from 'redux-saga/effects';

import {
  factorLogic,
  factorLogicFailure,
  factorLogicSuccess,
} from '../handoff-hub.action';
import { CategoryName, FactorName, FactorTest } from '../handoff-hub.reducer';
import {
  checkCallsWorking,
  checkNetworkHealth,
  checkPhonesCustomized,
  checkPhonesRegistered,
} from './phones.action-helpers';

export const CATEGORY: CategoryName = 'phonesCategory';

export const handleLogic = function* (factorName: FactorName, func: () => any) {
  yield put(factorLogic({ category: CATEGORY, factorName }));
  try {
    const { status, tests } = yield call(func);

    yield put(
      factorLogicSuccess({
        category: CATEGORY,
        factorName,
        status,
        tests,
      })
    );
  } catch (error: any) {
    const tests: FactorTest[] = [
      {
        name: factorName,
        actual: `${error}`,
        status: '',
      },
    ];
    yield put(
      factorLogicFailure({
        category: CATEGORY,
        factorName,
        tests,
      })
    );
  }
};

export const handlePhonesLogic = function* () {
  yield all([
    call(handleLogic, 'inboundOutboundCalls', checkCallsWorking),
    call(handleLogic, 'phonesCustomized', checkPhonesCustomized),
    call(handleLogic, 'networkHealth', checkNetworkHealth),
    call(handleLogic, 'phonesRegistered', checkPhonesRegistered),
  ]);
};
