import React from 'react';
import { theme } from '@weave/theme-original';
import {
  IconButton,
  Text,
  Modal,
  useForm,
  TextField,
  PhoneField,
  FormRow,
  PlusIcon,
  TextButton,
  ValidatorFieldState,
} from '@weave/design-system';
import { css } from '@emotion/core';

import { ForwardingNumber } from '../../../models';

interface Props {
  addNew: (number: ForwardingNumber) => void;
  forwardingNumbers?: ForwardingNumber[];
  isOpen: boolean;
  children?: React.ReactChild;
  toggle: () => void;
}

export const AddNumber = (props: Props) => {
  const { formProps, getFieldProps, values, reset, getErrors } = useForm({
    fields: {
      name: { type: 'text', required: true },
      number: {
        type: 'phone',
        required: true,
        validator: ({ value }: ValidatorFieldState<'text'>) => {
          if (
            props.forwardingNumbers?.length &&
            !!props.forwardingNumbers?.find(({ number }: ForwardingNumber) => {
              return number === value;
            })
          ) {
            return 'Number already in list.';
          }
          return '';
        },
      },
    },
  });

  const errors = Object.values(getErrors());

  const save = () => {
    if (errors.length) {
      return;
    }
    props.addNew(values as ForwardingNumber);
    props.toggle();
    reset();
  };

  return (
    <div>
      {props.children ? (
        <TextButton
          css={css`
            display: flex;
          `}
          onClick={props.toggle}
        >
          <IconButton id="addNewBtn" label="Add Forwarding Number">
            <PlusIcon />
          </IconButton>
          <Text
            css={css`
              padding: ${theme.spacing(1, 1, 0)};
            `}
          >
            {props.children}
          </Text>
        </TextButton>
      ) : (
        <IconButton onClick={props.toggle} id="addNewBtn" label="Add Forwarding Number">
          <PlusIcon />
        </IconButton>
      )}
      <Modal show={props.isOpen} onClose={props.toggle}>
        <Modal.Header>Add Forwarding Number</Modal.Header>
        <Modal.Body>
          <form
            {...formProps}
            css={css`
              margin-top: ${theme.spacing(1)};
            `}
          >
            <FormRow>
              <TextField {...getFieldProps('name')} label="Last Name" />
            </FormRow>
            <FormRow>
              <PhoneField {...getFieldProps('number')} label="Phone" />
            </FormRow>
          </form>
        </Modal.Body>
        <Modal.Actions
          disablePrimary={!!errors.length}
          onPrimaryClick={save}
          primaryLabel="Save"
          onSecondaryClick={props.toggle}
          secondaryLabel="Cancel"
        />
      </Modal>
    </div>
  );
};
