import React from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { DateTimePicker } from 'react-widgets';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

import CallRecordModel from '../call-record.model';
import ServerDataTableComponent from '../../../shared/server-data-table/server-data-table.component';
import { InfoTooltip } from '../../../shared/info-tooltip/info-tooltip.component';
import { formatDuration, formatPhoneNumber } from '../../../../helpers/utils';
import { css } from 'emotion';

const flex = css`
  display: flex;
`;
const tooltipMargin = css`
  margin: 5px;
`;
const pointerCursor = css`
  cursor: pointer;
`;

interface Props {
  downloadCDR: (channelId) => void;
  getRecords: (phoneNumber, start, end, limit, skip) => void;
  isNextPageAvailable: boolean;
  loading: boolean;
  locationId: string;
  records: CallRecordModel[];
  slug: string;
}

interface State {
  endDate: any;
  refreshing: boolean;
  searchText: string;
  startDate: any;
}

export class CallRecords extends React.Component<Props, State> {
  defaultPageSize = 25;

  constructor(props) {
    super(props);
    moment.locale('en');
    momentLocalizer();
    this.state = {
      searchText: '',
      startDate: moment(new Date()).subtract(1, 'week').toDate(),
      endDate: moment(new Date()).toDate(),
      refreshing: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.locationId !== prevProps.locationId) {
      this.setState(() => {
        return {
          searchText: '',
          startDate: moment(new Date()).subtract(1, 'week').toDate(),
          endDate: moment(new Date()).toDate(),
        };
      });

      this.loadData();
    }

    // The refresh is used to reset the page number in the ServerDataTable Component when the filter params change
    if (this.state.refreshing) {
      this.setState({ refreshing: false });
    }
  }

  filter = (e) => {
    e.preventDefault();
    this.setState({ refreshing: true });
    this.loadData();
  };

  handleSearchChange = (event) => {
    this.setState({ searchText: event.target.value.trim() });
  };

  loadData = (
    skip = 0,
    limit: number = this.defaultPageSize + 1,
    sortColumn = '',
    isSortDesc = false
  ) => {
    if (this.props.locationId) {
      this.props.getRecords(
        this.state.searchText.trim(),
        moment(this.state.startDate).startOf('day').toISOString(),
        moment(this.state.endDate).endOf('day').toISOString(),
        limit,
        skip
      );
    }
  };

  downloadCDR(channelId: string, date: number) {
    this.props.downloadCDR(channelId);
  }

  formatCSVData() {
    return this.props.records.map((record) => {
      return {
        Type: record.Direction,
        OfficeNumber:
          record.Direction === 'inbound'
            ? formatPhoneNumber(record.DialedNumber, false)
            : formatPhoneNumber(record.CallerNumber, false),
        PatientNumber:
          record.Direction === 'inbound'
            ? formatPhoneNumber(record.CallerNumber, false)
            : formatPhoneNumber(record.DialedNumber, false),
        DateTime: moment(record.StartedAt).format('MM/DD/YYYY h:mm A'),
        Duration: formatDuration(record.Duration),
        Result: record.Status,
      };
    });
  }

  render() {
    const columns: any[] = [
      {
        Header: 'Channel ID',
        className: 'weave-react-table-column',
        id: 'ChannelID',
        accessor: 'ChannelID',
        width: 330,
      },
      {
        Header: 'Type',
        id: 'direction',
        className: 'weave-react-table-column',
        accessor: (d) => {
          return d.Direction.charAt(0).toUpperCase() + d.Direction.slice(1);
        },
      },
      {
        Header: 'MOS',
        id: 'mos',
        className: 'weave-react-table-column',
        accessor: (cr: CallRecordModel) => cr.MOSScore,
        maxWidth: 50,
      },
      {
        Header: 'Office Number',
        id: 'office-number',
        className: 'weave-react-table-column',
        accessor: (d) => {
          if (d.Direction === 'inbound') {
            return formatPhoneNumber(d.DialedNumber, false);
          }
          return formatPhoneNumber(d.CallerNumber, false);
        },
      },
      {
        Header: 'Patient Number',
        id: 'patient-number',
        className: 'weave-react-table-column',
        accessor: (d) => {
          if (d.Direction === 'inbound') {
            return formatPhoneNumber(d.CallerNumber, false);
          }
          return formatPhoneNumber(d.DialedNumber, false);
        },
      },
      {
        Header: 'Date / Time',
        id: 'date-time',
        className: 'weave-react-table-column',
        accessor: (d) => {
          return moment(d.StartedAt).format('MM/DD/YYYY h:mm A');
        },
        minWidth: 140,
      },
      {
        Header: 'Duration',
        id: 'duration',
        className: 'weave-react-table-column',
        accessor: (d) => {
          return formatDuration(d.Duration);
        },
      },
      {
        Header: 'Result',
        className: 'weave-react-table-column',
        id: 'status',
        accessor: (d) => {
          return d.Status.charAt(0).toUpperCase() + d.Status.slice(1);
        },
      },
    ];

    return (
      <div className="container-fluid">
        <div className="main">
          <div className="title-group">
            <div className={flex}>
              <div className="page-title">Call Records</div>
              <InfoTooltip
                className={tooltipMargin}
                body={<div>Click on a record to download the CDR file</div>}
                tooltipPlacement="right"
              />
            </div>
          </div>
          <div className="datatable-library-container">
            <Form inline className="datatable-search-container">
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Label for="search" className="mr-sm-2 datatable-label">
                  Search
                </Label>
                <Input
                  type="text"
                  className="datatable-search-input"
                  name="search"
                  id="search"
                  value={this.state.searchText}
                  onChange={this.handleSearchChange}
                  placeholder="Phone #"
                />
              </FormGroup>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Label for="examplePassword" className="mr-sm-2 datatable-label">
                  Date Range
                </Label>
                <div className="date-range-container datatable-search-spacing">
                  <DateTimePicker
                    format="MM/DD/YYYY"
                    time={false}
                    value={this.state.startDate}
                    onChange={(startDate: Date) => this.setState({ startDate })}
                  />
                </div>
                <div className="date-range-container datatable-search-spacing">
                  <DateTimePicker
                    format="MM/DD/YYYY"
                    time={false}
                    value={this.state.endDate}
                    onChange={(endDate: Date) => this.setState({ endDate })}
                  />
                </div>
              </FormGroup>
              <Button
                size="sm"
                color="info"
                className="datatable-search-spacing"
                onClick={this.filter}
              >
                Filter
              </Button>
              <CSVLink
                className="btn btn-info btn-sm datatable-search-spacing"
                filename={`${moment(this.state.startDate).format('MM/DD/YYYY')}-${moment(
                  this.state.endDate
                ).format('MM/DD/YYYY')}-call-records.csv`}
                data={this.formatCSVData()}
              >
                Export
              </CSVLink>
            </Form>
            <ServerDataTableComponent
              className={pointerCursor}
              columns={columns}
              data={this.props.records}
              loading={this.props.loading}
              sortable={false}
              loadData={this.loadData}
              resetPage={this.state.refreshing}
              getTdProps={(state, rowInfo) => {
                return {
                  onClick: () => {
                    if (
                      rowInfo &&
                      rowInfo.row &&
                      rowInfo.row.ChannelID &&
                      !(window as any).getSelection().toString()
                    ) {
                      this.downloadCDR(rowInfo.row.ChannelID, rowInfo.row['date-time']);
                    }
                  },
                };
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
