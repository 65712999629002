import { createRequestSaga } from '@weave/alert-system';
import { createAction } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { OnbaordingActionTypes, OnboardingStatusGetAction } from './onboarding-hub.types';
import { getOnboardingStatusApi } from './onboarding-hub.api';

export const getOnboardingStatus = createAction('GET_ONBOARDING_STATUS');
export const setOnboardingStatus = createAction('SET_ONBOARDING_STATUS');

//saga
const getOnBoardingStatuses = createRequestSaga<OnboardingStatusGetAction>({
  key: OnbaordingActionTypes.GET_ONBOARDING_STATUS,
  displayErrors: true,
  onError: (err) => err.message,
  saga: function* ({ payload }) {
    try {
      const onboardingStatus = yield call(getOnboardingStatusApi);
      yield put(setOnboardingStatus(onboardingStatus));
    } catch (error: any) {
      yield put(setOnboardingStatus({ error: error }));
      throw new Error(`Failed to load onboarding status: ${error}`);
    }
  },
});

export const onBoardingStatusSaga = function* () {
  yield all([
    takeLatest(OnbaordingActionTypes.GET_ONBOARDING_STATUS, getOnBoardingStatuses),
  ]);
};
