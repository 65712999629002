import React, { useEffect, useState, useMemo } from 'react';
import * as _ from 'lodash';
import { theme } from '@weave/theme-original';
import {
  DropdownField,
  OptionSwitchField,
  Text,
  useFormField,
  PrimaryButton,
} from '@weave/design-system';
import { EmailPreview } from './email-preview';
import { EditEmailTemplate } from './edit-email-template';
import { useTemplates } from '../auto-reminder-templates.hook';
import { css } from '@emotion/core';
import { generateContent, generateHTMLString } from './email-utils';
import { Button } from '@weave/platform-ui-components';
import { useDispatch } from 'react-redux';
import { updateNotificationSettings } from '../../../../../redux/actions/notifications-settings/notifications-settings.action';
import { ReminderDetails } from '../../../../../redux/actions/notifications-settings/notification-settings.types';
import { NotificationSetting } from '../../../recall-reminders/recall-settings.hook';
import { DocumentSelect } from '../document-select';
// const headerContainer = (theme: WeaveTheme) => css`
//   display: flex;
//   justify-content: space-between;
//   border-bottom: 1px solid ${theme.colors.gray[200]};
//   padding: 8px;
//   margin-bottom: 16px;
//   p {
//     margin: auto 0;
//   }
//   button {
//     margin: 8px;
//   }
// `;

type Props = {
  settings: ReminderDetails | NotificationSetting;
};

const updateContainer = css`
  width: 75%;
  margin: auto;
  button {
    margin: auto;
  }
`;

const getInfoText = (needsRefresh) => {
  return needsRefresh
    ? {
        text: 'Refresh to get updates.',
        button: 'Refresh',
      }
    : {
        text: 'The current template is not editable. Please click update to enable the editable email template.',
        button: 'Update',
      };
};

const DOMParsed = new DOMParser();

export const EmailTemplate = ({ settings }: Props) => {
  const dispatch = useDispatch();
  const {
    templates,
    prepareTemplates,
    original,
    saveTemplates,
    refresh,
    updateTemplateDocument,
  } = useTemplates(settings);
  const { ...viewProps } = useFormField({ type: 'optionswitch', value: 'Preview' });
  const { ...langProps } = useFormField({ type: 'dropdown', value: '0' });

  const template = templates[langProps.value]?.single ?? '';
  const [templateNeedsUpdate, setTemplateNeedsUpdate] = useState(false);
  const [templateLanguageIndex, setTemplateLanguageIndex] = useState(0);
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [HTMLItems, setHTMLItems] = useState<any[]>([]);
  const [originalContent, setOriginal] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const content = parseHTML(template);
    setHTMLItems([...content]);
    setOriginal([...content]);
  }, [template]);

  const templatesList = templates.map((item) => item.single);
  useEffect(() => {
    if (loading) {
      setLoading(false);
      saveTemplates();
    }
  }, [templatesList, loading]);

  useEffect(() => {
    if (Number(langProps.value) !== templateLanguageIndex) {
      setTemplateLanguageIndex(Number(langProps.value));
    }
  }, [langProps.value]);

  const revertChanges = () => {
    setHTMLItems([...originalContent]);
    updateTemplate(originalContent);
  };

  const parseHTML = (html) => {
    const DOM = DOMParsed.parseFromString(html, 'text/html').documentElement;
    const docChecker = DOM.querySelector('.template');
    if (docChecker) {
      setTemplateNeedsUpdate(false);
      return generateContent(DOM);
    } else {
      setTemplateNeedsUpdate(true);
      return [];
    }
  };

  const updateTemplate = (items = HTMLItems) => {
    const DOM = generateHTMLString(DOMParsed, items, template);
    const content = DOM.outerHTML;
    const index = Number(langProps.value);
    prepareTemplates(index, 'single', content);
  };

  const convertTemplate = () => {
    dispatch(
      updateNotificationSettings({
        id: settings.id,
        template: { ...settings.template, text: '' },
      })
    );
    setNeedsRefresh(true);
  };

  const saveTemplate = () => {
    updateTemplate();
    setLoading(true);
  };

  const htmlChangesExist = useMemo(() => {
    return !_.isEqual(HTMLItems, originalContent);
  }, [HTMLItems, originalContent]);

  const changesExist =
    !!original?.length &&
    (htmlChangesExist ||
      original[templateLanguageIndex].document_id !==
        templates[templateLanguageIndex].document_id);

  const infoText = getInfoText(needsRefresh);
  const updateBtnHandler = needsRefresh ? refresh : convertTemplate;

  return (
    <div>
      <div
        css={css`
          align-items: center;
          display: flex;
          justify-content: space-between;
        `}
      >
        <h3
          css={css`
            margin-bottom: 0;
          `}
        >
          Email Template
        </h3>
        {htmlChangesExist && (
          <Button color="red" onClick={revertChanges}>
            Revert Changes
          </Button>
        )}
        <PrimaryButton
          css={css`
            max-width: 150px;
          `}
          disabled={!changesExist}
          onClick={saveTemplate}
        >
          Save
        </PrimaryButton>
      </div>
      <hr />

      <OptionSwitchField {...viewProps} name="view-options">
        <OptionSwitchField.Option value="Preview">Preview</OptionSwitchField.Option>
        <OptionSwitchField.Option value="Template">Template</OptionSwitchField.Option>
      </OptionSwitchField>

      <DropdownField
        {...langProps}
        label="Language"
        name="language-options"
        disabled={changesExist}
        css={css`
          margin: 16px;
          z-index: 150;
        `}
      >
        {templates.map((item: any, idx) => (
          <DropdownField.Option key={idx + item.language} value={idx.toString()}>
            {item.language === 'Unknown' ? 'Default' : item.language}
          </DropdownField.Option>
        ))}
      </DropdownField>

      {viewProps.value === 'Preview' && (
        <EmailPreview template={template} settings={settings} />
      )}
      {viewProps.value === 'Template' &&
        (templateNeedsUpdate ? (
          <div css={updateContainer}>
            <Text>{infoText.text}</Text>
            <br />
            <Button onClick={updateBtnHandler}>{infoText.button}</Button>
          </div>
        ) : (
          <>
            <section
              css={css`
                margin-bottom: ${theme.spacing(4)};
                z-index: 111;
                position: relative;
              `}
            >
              <Text weight="bold">
                Form/Packet Link{' '}
                <Text as="span" color="light">
                  (optional)
                </Text>
              </Text>
              <DocumentSelect
                documentId={templates?.[templateLanguageIndex]?.document_id}
                templateLanguageIndex={templateLanguageIndex}
                updateTemplateDocument={updateTemplateDocument}
              />
            </section>
            <EditEmailTemplate
              type={settings.type}
              HTMLItems={HTMLItems}
              setHTMLItems={setHTMLItems}
            />
          </>
        ))}
    </div>
  );
};
