import React from 'react';
import { css } from 'emotion';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

import { BlacklistModalButton } from '../../../blacklist-modal/blacklist-modal-button.component';
import { locationDashboardStyles } from './location-dashboard.styles';
import {
  Writebacks,
  EmailMessages,
  SMSMessages,
  Syncs,
} from '../../../../redux/actions/smart-alerts/smart-alerts.action';
import WritebackTableComponent from './location-dashboard-writebacks.component';
import SyncTableComponent from './location-dashboard-syncs.component';
import EmailTableComponent from './location-dashboard-emails.component';
import SMSTableComponent from './location-dashboard-sms.component';
import { Page } from '../../../shared/page/page.component';

interface Props {
  autoEmailMessageData: EmailMessages[];
  autoSMSMessageData: SMSMessages[];
  writebacksData: Writebacks[];
  getAutoEmailMessagesAlertData: (locationID: string, searchParams: object) => void;
  getAutoSMSMessagesAlertData: (locationID: string, searchParams: object) => void;
  getSyncsAlertData: (locationID: string, searchParams: object) => void;
  getWritebacksAlertData: (locationID: string, searchParams: object) => void;
  loading: boolean;
  locationID: string;
  removeEmailFromBlacklist: (emailAddress: string) => void;
  syncData: Syncs[];
}

export interface State {
  modalOpen: boolean;
  startDate: Date;
  endDate: Date;
}

export class LocationDashboard extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    moment.locale('en');
    momentLocalizer();

    this.state = {
      modalOpen: false,
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: new Date(),
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.locationID !== prevProps.locationID) {
      this.setState(
        {
          startDate: moment().subtract(1, 'week').toDate(),
          endDate: new Date(),
        },
        this.loadData
      );
    }
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = (
    skip = 0,
    limit: number = Number.MAX_VALUE, // we want all the data available for the start and end range
    sortColumn = '',
    isSortDesc = false
  ) => {
    const params = {
      start: moment(this.state.startDate).startOf('day').format('YYYY-MM-DD'),
      end: moment(this.state.endDate).add(1, 'week').format('YYYY-MM-DD'),
      limit,
      skip,
      order: (isSortDesc ? '-' : '') + sortColumn.toString().toLowerCase(),
    };
    this.props.getAutoEmailMessagesAlertData(this.props.locationID, { params });
    this.props.getAutoSMSMessagesAlertData(this.props.locationID, { params });
    this.props.getWritebacksAlertData(this.props.locationID, { params });
    this.props.getSyncsAlertData(this.props.locationID, { params });
  };

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  render() {
    return (
      <Page
        title="Alerts"
        subtitle="View all alerts for this location below"
        loading={this.props.loading}
        headerActions={
          <BlacklistModalButton
            removeEmailFromBlacklist={this.props.removeEmailFromBlacklist}
          />
        }
      >
        <table className={locationDashboardStyles.tableView}>
          <thead>
            <tr className={locationDashboardStyles.tableHeadRow}>
              <th
                className={css`
                  width: 150px;
                `}
              >
                Issue Type
              </th>
              <th>Incident Date</th>
              <th>Description</th>
              <th>Details (click for more information)</th>
            </tr>
          </thead>
          <WritebackTableComponent writebacksData={this.props.writebacksData} />
          <SyncTableComponent syncData={this.props.syncData} />
          <EmailTableComponent autoEmailMessageData={this.props.autoEmailMessageData} />
          <SMSTableComponent autoSMSMessageData={this.props.autoSMSMessageData} />
        </table>
        {!this.props?.writebacksData?.length &&
          !this.props?.syncData?.length &&
          !this.props?.autoSMSMessageData?.length &&
          !this.props?.autoEmailMessageData?.length && (
            <div
              className={css`
                margin-top: 16px;
                text-align: center;
              `}
            >
              There are no alerts for this location.
            </div>
          )}
      </Page>
    );
  }
}
