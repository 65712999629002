import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { ButtonGroup } from 'reactstrap';

import { AuditContext } from './audit.context';
import { useAlert } from '@weave/alert-system';
import { Filters, LocationFilter, TimeFilter, User, UserFilter } from './audit.types';
import { auditFilterMenu } from './styles/filter-drawer';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {
  ChecklistField,
  DatePickerField,
  FilterAltIcon,
  Heading,
  OptOutAlertIcon,
  PrimaryButton,
  SecondaryButton,
  SpinningLoader,
  Text,
  TimeField,
  useFlyoutDialog,
  useForm,
  useSlideFadeTransition,
} from '@weave/design-system';
import { useHistory } from 'react-router';

const startTimeInterval = 15;
const endTimeInterval = 20;
const defaultTime = '00:00:00';
const endTimeMin = '00:15:00';

export const AuditFilterDrawer = () => {
  const alerts = useAlert();
  const { location } = useHistory();
  const userSearchRef = useRef<any>();
  const locationSearchRef = useRef<any>();
  const [users, setUsers] = useState<UserFilter[]>();
  const [locations, setLocations] = useState<LocationFilter[]>();
  const {
    filters,
    userQuery,
    eventQuery,
    actionQuery,
    queriesStatus,
    locationQuery,
    resourceQuery,
    locationUserQuery,
    setFilters,
    setUserSearch,
    setQueriesStatus,
    setLocationSearch,
  } = useContext(AuditContext);

  const { active, FlyoutDialog, flyoutHeaderProps, flyoutProps, triggerProps } =
    useFlyoutDialog({});
  const transitions = useSlideFadeTransition({ active, direction: 'up' });

  const handleFormSubmit = (
    startDate?: string,
    endDate?: string,
    startTime?: string,
    endTime?: string,
    action?: string[],
    resource?: string[]
  ) => {
    let Time: TimeFilter[] | undefined;
    if (startDate && endDate) {
      Time = [
        {
          start: new Date(startDate + ' ' + startTime || defaultTime).toISOString(),
          end: new Date(endDate + ' ' + endTime || defaultTime).toISOString(),
          display: `${moment(startDate + ' ' + startTime || defaultTime).format(
            'LLLL'
          )} - ${moment(endDate + ' ' + endTime || defaultTime).format('LLLL')}`,
        },
      ];
      if (Time[0].start > Time[0].end) {
        alerts.error('Date Range Invalid');
        return;
      }
    }

    const updatedFilters: Filters = {
      ...(Time && { Time: Time }),
      ...(users && users?.length > 0 && { User: [...users] }),
      ...(locations && locations?.length > 0 && { Location: [...locations] }),
      ...(action &&
        action.length > 0 && {
          Action: action.map((a) => {
            return { display: a };
          }),
        }),
      ...(resource &&
        resource.length > 0 && {
          Resource: resource.map((r) => {
            return { display: r };
          }),
        }),
    };
    setFilters(Object.keys(updatedFilters).length === 0 ? undefined : updatedFilters);
    triggerProps.onClick();
  };

  const { formProps, getFieldProps, isComplete, reset, seedValues, values } = useForm({
    fields: {
      startDate: { type: 'datePicker', required: false },
      endDate: { type: 'datePicker', required: false },
      startTime: { type: 'time', required: false },
      endTime: { type: 'time', minTime: endTimeMin, required: false },
      action: { type: 'checklist', required: false },
      resource: { type: 'checklist', required: false },
    },
    onSubmit: ({ startDate, endDate, startTime, endTime, action, resource }) => {
      handleFormSubmit(startDate, endDate, startTime, endTime, action, resource);
    },
  });

  const clearAllFilters = () => {
    reset();
    const updatedFilters: Filters = {
      ...(locations &&
        locations?.length > 0 && {
          Location: location.pathname.includes('/audits') ? [] : [...locations],
        }),
    };
    setFilters(Object.keys(updatedFilters).length === 0 ? undefined : updatedFilters);
    triggerProps.onClick();
  };

  const handleLocationSearch = (query) => {
    if (locationQuery.isIdle) {
      setQueriesStatus({ ...queriesStatus, location_query: true });
    }
    setLocationSearch(query);
  };

  const handleUserSearch = (query) => {
    if (location.pathname.includes('/audits')) {
      setQueriesStatus({ ...queriesStatus, user_query: true });
    } else {
      setQueriesStatus({ ...queriesStatus, location_user_query: true });
    }
    setUserSearch(query);
  };

  const handleLocationsUpdate = (location: Location) => {
    if (locations) {
      const locationFound = !!locations.find((location) => {
        return location.id === location[0].LocationID;
      });
      if (locationFound) {
        setLocationSearch('');
        locationSearchRef.current.getInstance().clear();
        alerts.warning('Location already added to filters!');
        return;
      }
    }
    if (location[0]) {
      setLocations(
        locations
          ? [...locations, { display: location[0].Name, id: location[0].LocationID }]
          : [{ display: location[0].Name, id: location[0].LocationID }]
      );
      setLocationSearch('');
      locationSearchRef.current.getInstance().clear();
    }
  };

  const handleUsersUpdate = (selectedUser: User) => {
    if (users) {
      const userFound = users.some((user) => {
        return user.id === selectedUser[0].UserID;
      });
      if (userFound) {
        setUserSearch('');
        userSearchRef.current.getInstance().clear();
        alerts.warning('User already added to filters!');
        return;
      }
    }
    if (selectedUser[0]) {
      setUsers(
        users
          ? [
              ...users,
              {
                display: `${selectedUser[0].FirstName} ${selectedUser[0].LastName}`,
                id: selectedUser[0].UserID,
              },
            ]
          : [
              {
                display: `${selectedUser[0].FirstName} ${selectedUser[0].LastName}`,
                id: selectedUser[0].UserID,
              },
            ]
      );
      setUserSearch('');
      userSearchRef.current.getInstance().clear();
    }
  };

  useEffect(() => {
    if (active && eventQuery.status !== 'loading') {
      reset();
      seedValues({
        startDate: filters?.Time && moment(filters.Time[0].start).format('MM/DD/YYYY'),
        endDate: filters?.Time && moment(filters.Time[0].end).format('MM/DD/YYYY'),
        startTime: filters?.Time && moment(filters.Time[0].start).format('HH:mm:ss'),
        endTime: filters?.Time && moment(filters.Time[0].end).format('HH:mm:ss'),
        resource: filters?.Resource
          ? filters.Resource.map((r) => {
              return r.display;
            })
          : [],
        action: filters?.Action
          ? filters.Action.map((a) => {
              return a.display;
            })
          : [],
      });
      if (filters?.Location && filters.Location.length > 0)
        setLocations(filters.Location);
      if (filters?.User && filters.User.length > 0) setUsers(filters.User);
    }

    return () => {
      reset();
      setUsers(undefined);
      setLocations(undefined);
    };
  }, [active]);

  return (
    <React.Fragment>
      <span style={{ position: 'relative' }}>
        {filters && <div className="active-dot" />}
        <FilterAltIcon
          className="action-icon"
          spacing={2}
          size={24}
          onClick={() => {
            eventQuery.status === 'loading'
              ? alerts.info('query in progress . . .')
              : triggerProps.onClick();
          }}
        />
      </span>

      {active &&
        transitions.map(
          ({ key, item, props }) =>
            item && (
              <FlyoutDialog
                key={key}
                style={props}
                {...flyoutProps}
                css={auditFilterMenu}
              >
                {actionQuery.isFetching || resourceQuery.isFetching ? (
                  <SpinningLoader size="medium" />
                ) : (
                  <form {...formProps}>
                    <div className="wrapper divider filter-header">
                      <Heading level={2} {...flyoutHeaderProps}>
                        Refine Filters
                      </Heading>
                      <span
                        className="sub-title-action"
                        onClick={() => {
                          reset();
                          triggerProps.onClick();
                        }}
                      >
                        Cancel
                      </span>
                    </div>

                    <div className="divider">
                      <div className="filter-header wrapper">
                        <Text weight={'semibold'}>Date</Text>
                        <span
                          className="sub-title-action"
                          onClick={() => {
                            seedValues({
                              ...values,
                              startDate: '',
                              endDate: '',
                              startTime: '',
                              endTime: '',
                            });
                          }}
                        >
                          Clear
                        </span>
                      </div>

                      <div className="filter-body">
                        <DatePickerField
                          className="date-picker"
                          label="Start Date"
                          {...getFieldProps('startDate')}
                        />
                        <DatePickerField
                          className="date-picker"
                          label="End Date"
                          {...getFieldProps('endDate')}
                        />
                        <TimeField
                          label="Start Time"
                          {...getFieldProps('startTime')}
                          interval={startTimeInterval}
                        />
                        <TimeField
                          label="End Time"
                          {...getFieldProps('endTime')}
                          interval={endTimeInterval}
                        />
                      </div>
                    </div>

                    <div className="divider">
                      <div className="filter-header wrapper">
                        <Text weight={'semibold'}>User</Text>
                        <span
                          className="sub-title-action"
                          onClick={() => {
                            setUsers(undefined);
                          }}
                        >
                          Clear
                        </span>
                      </div>
                      <div className="filter-body">
                        <AsyncTypeahead
                          id="userSearchBox"
                          className="user-search-box"
                          isLoading={
                            location.pathname.includes('/audits')
                              ? userQuery.isFetching
                              : locationUserQuery.isFetching
                          }
                          placeholder="Add Person"
                          ref={(ref: any) => (userSearchRef.current = ref)}
                          labelKey={(option) => `${option.FirstName} ${option.LastName}`}
                          onSearch={handleUserSearch}
                          onChange={handleUsersUpdate}
                          options={
                            location.pathname.includes('/audits')
                              ? userQuery?.data?.sort((a, b) =>
                                  a.FirstName.localeCompare(b.FirstName)
                                )
                              : locationUserQuery?.data?.sort((a, b) =>
                                  a.FirstName.localeCompare(b.FirstName)
                                )
                          }
                          emptyLabel="No users found"
                        />
                        {users && (
                          <div className="user-list wrapper">
                            {users.map((user) => (
                              <div className="user wrapper" key={user.id}>
                                {user.display}
                                <OptOutAlertIcon
                                  className="remove-x"
                                  onClick={() =>
                                    setUsers(users.filter((u) => u.id !== user.id))
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    {location.pathname.includes('/audits') && (
                      <div className=" divider">
                        <div className="filter-header wrapper">
                          <Text weight={'semibold'}>Location</Text>
                          <span
                            className="sub-title-action"
                            onClick={() => {
                              setLocations(undefined);
                            }}
                          >
                            Clear
                          </span>
                        </div>

                        <div className="filter-body">
                          <AsyncTypeahead
                            id="locationSearchBox"
                            className="location-search-box"
                            placeholder="Add Location"
                            isLoading={locationQuery.isFetching}
                            ref={(ref: any) => (locationSearchRef.current = ref)}
                            labelKey={(option) => `${option.Name}`}
                            onSearch={handleLocationSearch}
                            onChange={handleLocationsUpdate}
                            options={locationQuery?.data?.sort((a, b) =>
                              a.Name.localeCompare(b.Name)
                            )}
                            emptyLabel="No locations found"
                          />
                          {locations && (
                            <div className="location-list wrapper">
                              {locations.map((location) => (
                                <div className="location wrapper" key={location.id}>
                                  {location.display}
                                  <OptOutAlertIcon
                                    className="remove-x"
                                    onClick={() =>
                                      setLocations(
                                        locations.filter((l) => l.id !== location.id)
                                      )
                                    }
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="divider">
                      <div className="filter-header wrapper">
                        <Text weight={'semibold'}>Action</Text>
                        <span
                          className="sub-title-action"
                          onClick={() => {
                            seedValues({ ...values, action: [] });
                          }}
                        >
                          Clear
                        </span>
                      </div>

                      <div className="filter-body">
                        <ChecklistField
                          label=""
                          disabled={actionQuery.status !== 'success'}
                          labelPlacement="left"
                          className="checklist-field"
                          {...getFieldProps('action')}
                        >
                          {actionQuery.status === 'success' ? (
                            actionQuery.data['actionIds'].sort().map((a, i) => (
                              <ChecklistField.Option key={i} name={a}>
                                {a}
                              </ChecklistField.Option>
                            ))
                          ) : (
                            <ChecklistField.Option name="null">
                              No actions
                            </ChecklistField.Option>
                          )}
                        </ChecklistField>
                      </div>
                    </div>

                    <div className="divider">
                      <div className="filter-header wrapper">
                        <Text weight={'semibold'}>Resource</Text>
                        <span
                          className="sub-title-action"
                          onClick={() => {
                            seedValues({ ...values, resource: [] });
                          }}
                        >
                          Clear
                        </span>
                      </div>

                      <div className="filter-body">
                        <ChecklistField
                          label=""
                          disabled={resourceQuery.status !== 'success'}
                          labelPlacement="left"
                          className="checklist-field"
                          {...getFieldProps('resource')}
                        >
                          {resourceQuery.status === 'success' ? (
                            resourceQuery.data['resources'].sort().map((r, i) => (
                              <ChecklistField.Option key={i} name={r}>
                                {r}
                              </ChecklistField.Option>
                            ))
                          ) : (
                            <ChecklistField.Option name="null">
                              No resources
                            </ChecklistField.Option>
                          )}
                        </ChecklistField>
                      </div>
                    </div>

                    <ButtonGroup className="filter-form-buttons">
                      <SecondaryButton onClick={clearAllFilters}>
                        Clear All Filters
                      </SecondaryButton>
                      <PrimaryButton disabled={!isComplete} type="submit">
                        Apply Filters
                      </PrimaryButton>
                    </ButtonGroup>
                  </form>
                )}
              </FlyoutDialog>
            )
        )}
    </React.Fragment>
  );
};
