import format from 'date-fns/format';
import { createSelector } from 'reselect';
import { selectHasWeaveAcl } from '../../../redux/actions/auth/auth.selectors';
import { CoreACLs } from '../../../redux/actions/auth/auth.types';
import { selectCurrentLocation } from '../../../redux/actions/location';
import { Store } from '../../../redux/store/store.model';

export const formatNumber = (num: number, currency: string) => {
  const fixedNum = num.toFixed(2);
  const decimals = fixedNum.substr(-3);
  let localeNum = Math.round(parseInt(fixedNum)).toLocaleString();
  if (decimals !== '.00') {
    localeNum += decimals;
  }
  return `${currency === 'CAD' ? 'C$' : '$'}${localeNum}`;
};

/**
 * Derived selector to compute if user has billing read permission.
 */
export const selectHasBillingReadPermission = createSelector(
  (state: Store) => selectCurrentLocation(state)?.Type === 'Dev',
  (state: Store) => selectHasWeaveAcl(state, CoreACLs.BILLINGINFORMATIONREAD),
  (isDevLocation, weaveUserCanRead) => isDevLocation || weaveUserCanRead
);

/**
 * Derived selector to compute if user has billing write permission.
 */
export const selectHasBillingWritePermission = createSelector(
  (state: Store) => selectCurrentLocation(state)?.Type === 'Dev',
  (state: Store) => selectHasWeaveAcl(state, CoreACLs.BILLINGINFORMATIONWRITE),
  (isDevLocation, weaveUserCanUpdate) => isDevLocation || weaveUserCanUpdate
);

export function formatSubscriberInvoiceDateForPrint(
  invoiceTime: string | undefined
): string {
  if (!invoiceTime) return '';

  const [year, month, day] = invoiceTime.substr(0, 10).split('-');

  return month + '/' + day + '/' + year;
}

export function formatSubscriberInvoiceDateForDisplay(date: string): string {
  const [year, month, day] = date.substr(0, 10).split('-');

  return format(new Date(Number(year), Number(month) - 1, Number(day)), 'MMMM d, yyyy');
}
