import { connect } from 'react-redux';

import { CallRecords } from './call-records/call-records.component';
import { downloadCdr, getRecords } from '../../../redux/actions/records';
import {
  selectCurrentLocationId,
  selectCurrentLocationSlug,
} from '../../../redux/actions/location/current-location';

export const CallRecordsContainer = connect(
  (state: any) => ({
    isNextPageAvailable: state.records.isNextPageAvailable,
    loading: state.records.loading,
    locationId: selectCurrentLocationId(state),
    records: state.records.records,
    slug: selectCurrentLocationSlug(state),
  }),
  (dispatch) => ({
    getRecords: (phoneNumber, start, end, limit, skip) => {
      dispatch(getRecords({ phoneNumber, start, end, limit, skip }));
    },
    downloadCDR: (channelId) => {
      dispatch(downloadCdr(channelId));
    },
  })
)(CallRecords);
