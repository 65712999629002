import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { CustomAxios, getErrorMessage } from '../../../axios';
import { showError } from '@weave/alert-system';
import { Store } from '../../../store/store.model';
import { createSelector } from 'reselect';

export const clearLocationAccessRules = createAction('CLEAR_LOCATION_ACCESS_RULES');
export const getLocationAccessRules = createAction<string>('GET_LOCATION_ACCESS_RULES');
export const getLocationAccessRulesSuccess = createAction(
  'GET_LOCATION_ACCESS_RULES_SUCCESS'
);
export const getLocationAccessRulesFailure = createAction(
  'GET_LOCATION_ACCESS_RULES_FAILURE'
);

export const handleGetLocationAccess = function* (action) {
  try {
    const locationId = action.payload;
    const response = yield call(
      CustomAxios.get,
      `/support/v1/locations/${locationId}}/accessrules`
    );
    const results = response.data.data as LocationAccess[];
    yield put(getLocationAccessRulesSuccess(results));
  } catch (error: any) {
    yield put(getLocationAccessRulesFailure());
    yield put(showError(getErrorMessage(error)));
  }
};

export const locationAccessSaga = function* () {
  yield all([yield takeLatest(getLocationAccessRules, handleGetLocationAccess)]);
};

export interface LocationAccess {
  ID: string;
  LocationID: string;
  SourceID: string;
  ClientLocationID: string;
  Type: string;
  CreatedAt: string;
  ModifiedAt: string;
  DeletedAt: string;
}

export interface LocationAccessState {
  locationAccessRules: LocationAccess[];
  loading: boolean;
}

const locationAccessDefaultState: LocationAccessState = {
  locationAccessRules: [],
  loading: false,
};

export const locationAccessReducer = handleActions<LocationAccessState, LocationAccess[]>(
  {
    [clearLocationAccessRules.toString()]: (state, action) =>
      Object.assign({}, state, locationAccessDefaultState),
    [getLocationAccessRules.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [getLocationAccessRulesSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, locationAccessRules: action.payload }),
    [getLocationAccessRulesFailure.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false }),
  },
  locationAccessDefaultState
);

export const selectLocationAccess = (state: Store) => state.locationAccess;
export const selectLocationAccessRules = createSelector(
  selectLocationAccess,
  (locationAccess) => locationAccess?.locationAccessRules ?? []
);
