import { createAction } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { showError, showSuccess } from '@weave/alert-system';

import { CustomAxios } from '../../axios';

export const getNetworkAudits = createAction('GET_NETWORK_AUDITS');
export const getNetworkAuditsSuccess = createAction('GET_NETWORK_AUDITS_SUCCESS');
export const getNetworkAuditsFailure = createAction('GET_NETWORK_AUDITS_FAILURE');

export const runNetworkAudit = createAction<any>('RUN_NETWORK_AUDIT');

export const handleGetNetworkAudits = function* () {
  try {
    const response = yield call(CustomAxios.get, '/support/v1/netstats/summaries');

    // TODO: Remove once we are done testing on prod. This is just temporary to log
    // request data to the console for a new endpoint.
    const responseEthr = yield call(
      CustomAxios.get,
      '/support/v1/netstats/summaries/ethr'
    );
    console.log('netstats/summaries/ethr data:', responseEthr?.data);

    const data = response.data && response.data.data ? response.data.data : [];
    yield put(getNetworkAuditsSuccess(data || []));
  } catch {
    yield put(showError('Could not get network audit data. Please try again.'));
    yield put(getNetworkAuditsFailure());
  }
};

export const getNetworkAuditsSaga = function* () {
  yield takeLatest(getNetworkAudits.toString(), handleGetNetworkAudits);
};

type AuditStatusResponse = {
  data: {
    data: {
      id: string;
      audit_id: string;
      location_id: string;
      username: string;
      status: string;
      os_user: string;
      os_hostname: string;
      os_platform: string;
      shell_version: string;
      client_version: string;
      created_at: string;
      updated_at: string;
    };
  };
};

const handleRunNetworkAudit = function* (action) {
  try {
    const userId = action.payload;

    // Make audit status call
    const response: AuditStatusResponse = yield call(
      CustomAxios.post,
      '/support/v1/netstats/auditstatus',
      {
        username: userId, // userId keyed as username due to the backend
        status: 'sent',
      }
    );

    // Run network audit
    yield call(CustomAxios.post, `/support/v1/connections/send/${userId}`, {
      method: 'RunNetworkAudit',
      params: `auditStatusId=${response.data.data.id}`,
    });
    yield put(showSuccess('Network audit has started.'));
  } catch {
    yield put(showError('Network audit did not start. Please try again.'));
  }
};

export const runNetworkAuditSaga = function* () {
  yield takeLatest(runNetworkAudit.toString(), handleRunNetworkAudit);
};
