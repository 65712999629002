import React from 'react';
import { cx } from 'emotion';

import { Loader } from '../../../shared/loader/loader.component';
import { KeyFactor } from '../../handoff-hub.types';
import { FactorMessages } from '../factor-messages/factor-messages.component';
import {
  cardStyles,
  clickableFactor,
  keyFactorsDiv,
  modalStyles,
  tdSVG,
  offFactor,
  cardTitle,
  factorContent,
  factorName,
  emojiIcon,
  exclamationIconStyles,
} from './handoff-card.styles';
import { FactorStatus } from '../../../../redux/actions/handoff-hub/handoff-hub.reducer';
import { ReactComponent as ExclamationIcon } from '../../../../images/exclamation-circle.svg';
import { ReactComponent as EmojiHappy } from '../../../../images/emoji-happy.svg';
import { ReactComponent as EmojiSad } from '../../../../images/emoji-sad.svg';
import { ReactComponent as EmojiNeutral } from '../../../../images/emoji-neutral.svg';
import { CheckIcon, Modal, Text, XIcon } from '@weave/design-system';

const EmojiList = {
  happy: EmojiHappy,
  sad: EmojiSad,
  neutral: EmojiNeutral,
};

type EmojiState = keyof typeof EmojiList;

export type HandoffCardProps = {
  categoryId: string;
  categoryName: string;
  keyFactors: KeyFactor[];
  healthScore: number;
};

export const HandoffCard = (props: HandoffCardProps) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [currFactor, setCurrFactor] = React.useState<KeyFactor | undefined>(undefined);

  const getStatusIcon = (status: FactorStatus) => {
    switch (status) {
      case 'pass':
        return <CheckIcon color="success" />;
      case 'fail':
        return <XIcon color="error" />;
      case 'off':
        return (
          <Text weight="bold" className={offFactor}>
            OFF
          </Text>
        );
      default:
        return <ExclamationIcon className={exclamationIconStyles} />;
    }
  };

  const getStatusIconComponent = (factor: KeyFactor) => {
    if (factor.loading) {
      return <Loader />;
    } else {
      return getStatusIcon(factor.status);
    }
  };

  const handleClickedFactor = (factor: KeyFactor) => {
    setCurrFactor(factor);
    toggleModal();
  };

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const getEmoji = () => {
    const score = props.healthScore;
    let currentState: EmojiState = 'happy';
    if (score === 100) {
      currentState = 'happy';
    } else if (score === 0) {
      currentState = 'sad';
    } else {
      currentState = 'neutral';
    }

    const Emoji = EmojiList[currentState];

    return <Emoji className={emojiIcon} />;
  };

  return (
    <div>
      <Text weight="bold" className={cardTitle}>
        {props.categoryName}
        {getEmoji()}
      </Text>
      <div className={cardStyles}>
        <div className={keyFactorsDiv}>
          {props.keyFactors.map((factor: KeyFactor, index) => {
            return (
              <div
                className={cx(clickableFactor, 'key-factor')}
                key={index}
                onClick={() => handleClickedFactor(factor)}
              >
                <div className={factorContent}>
                  <Text className={factorName}>{factor.factorName}</Text>
                  <span className={tdSVG}>{getStatusIconComponent(factor)}</span>
                </div>
              </div>
            );
          })}
        </div>

        <Modal show={modalIsOpen} onClose={toggleModal} className={modalStyles}>
          <Modal.Body>
            <FactorMessages factor={currFactor} getStatusIcon={getStatusIcon} />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
