import { css } from 'emotion';
import { theme } from '@weave/theme-original';

export const checkboxExclude = css`
  background-color: ${theme.colors.error} !important;
  border: 1px solid ${theme.colors.error} !important;
`;

export const editIcon = css`
  display: inline;

  svg:hover {
    transform: scale(1.2);
  }
`;

export const columns = css`
  display: flex;
  flex-direction: row;
  height: 330px;
  overflow: hidden;
  & > div {
    flex: 1;
  }
`;

export const checkbox = css`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  & > * {
    flex: inherit;
  }
  & > span {
    padding-left: 5px;
  }
`;

export const yOverflow = (isLocation) => css`
  position: relative;
  height: calc(100% - (${isLocation ? '151px' : '91px'}));
  overflow: scroll;
  padding-bottom: 16px;
`;

export const optionValues = css`
  height: auto;
  max-height: 270px;
  overflow: scroll;
  transition: all 1s;
`;

export const hide = css`
  max-height: 0;
  opacity: 0;
`;

export const include = css`
  color: ${theme.colors.success};
`;
export const exclude = css`
  color: ${theme.colors.error};
`;
