import {
  OnbaordingActionTypes,
  OnboardingStatusGetAction,
  OnboardingStatusSetAction,
  OnBoardingStatusState,
} from './onboarding-hub.types';

const defaultState: OnBoardingStatusState = {
  isLoading: true,
  error: null,
  modules: [],
  tasks: [],
};

export function onBoardingStatusReducer(
  state: OnBoardingStatusState = defaultState,
  action: OnboardingStatusSetAction | OnboardingStatusGetAction
): OnBoardingStatusState {
  switch (action.type) {
    case OnbaordingActionTypes.SET_ONBOARDING_STATUS:
      return { ...state, ...action.payload, isLoading: false };
    case OnbaordingActionTypes.GET_ONBOARDING_STATUS:
      return { ...state, isLoading: true };
    default:
      return state;
  }
}
