import React, { useEffect } from 'react';
import { css } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton, Tabs, Heading } from '@weave/design-system';
import { useResource } from '../shared/hooks';
import { sendPasswordReset } from '../../redux/actions/users';
import { getUser } from '../../redux/actions/auth/user/user.action';
import { ChangePasswordForm } from './change-password-form.component';
import { useState } from 'react';
import { OnboardingConfig } from './onboarding-config-form.component';

export const UserConfig = () => {
  const [activeTab, setActiveTab] = useState('pass');
  const username = useSelector((state: any) => state.auth.user.username);
  const user = useSelector((state: any) => state.user);
  const { UserID: userId } = user;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser({ username }));
  }, [username]);

  const oktaMigrationStatus = useResource({
    deps: [userId],
    suppressFetch: !userId,
    url: `/support/v1/users/${userId}/okta/isMigrated`,
  });

  const sendPasswordResetEmail = () => {
    dispatch(
      sendPasswordReset({
        Username: username,
        UserID: userId,
        FirstName: user.FirstName,
        LastName: user.LastName,
      })
    );
  };

  return (
    <div className="user-config">
      <div
        className="container-fluid"
        css={css`
          @media only screen and (max-width: 790px) {
            padding: 0;
          }
        `}
      >
        <div
          css={css`
            padding: 20px;
            text-align: center;
          `}
        >
          <Heading
            css={css`
              text-align: center;
              margin-bottom: 0;
            `}
          >
            Weave User Configurations
          </Heading>
        </div>
        <div
          className="main"
          css={css`
            align-items: center;
            display: flex;
            flex-direction: column;
          `}
        >
          <Tabs initialTab={activeTab} onChange={setActiveTab}>
            <Tabs.Bar
              css={css`
                width: 350px;
              `}
            >
              <Tabs.Tab id="pass" controls="pass-panel">
                Change Password
              </Tabs.Tab>

              <Tabs.Tab id="onBoard" controls="onboard-panel">
                Onboarder Config
              </Tabs.Tab>
            </Tabs.Bar>

            <Tabs.Panel
              css={css`
                display: flex;
                justify-content: center;
                width: 300px;
              `}
              id="pass-panel"
              controller="pass"
            >
              <div>
                <div
                  css={css`
                    margin-top: 20px;
                    margin-bottom: 20px;
                  `}
                >
                  <span
                    css={css`
                      font-weight: bold;
                    `}
                  >
                    Username:{' '}
                  </span>
                  {username}
                </div>
                <div
                  css={css`
                    font-weight: bold;
                    margin-bottom: 10px;
                  `}
                >
                  Change Password:{' '}
                </div>
                {!oktaMigrationStatus.loading && !oktaMigrationStatus.data ? (
                  <ChangePasswordForm user={user} />
                ) : (
                  <PrimaryButton onClick={sendPasswordResetEmail}>
                    Send Reset Email
                  </PrimaryButton>
                )}
              </div>
            </Tabs.Panel>
            <Tabs.Panel
              id="onboard-panel"
              controller="onBoard"
              css={css`
                display: flex;
                justify-content: center;
                width: 300px;
              `}
            >
              <div
                css={css`
                  width: 100%;
                  margin-top: 20px;
                  margin-bottom: 20px;
                `}
              >
                <OnboardingConfig />
              </div>
            </Tabs.Panel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
