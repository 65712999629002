import React from 'react';
import { css } from 'emotion';
import { RouteComponentProps } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { isEmpty } from 'lodash';
import { PrimaryButton } from '@weave/design-system';

import { Task } from '../onboarding-task.model';
import { weaveBlue } from '../../shared/styles/colors';
import { LocationModel } from '../../../redux/actions/location';
import { Loader } from '../../shared/loader/loader.component';
import { OnboardingTasks } from '../onboarding-tasks/onboarding-tasks.component';
import { OnboardingAssignOnboarder } from '../onboarding-assign-onboarder/onboarding-assign-onboarder.component';
import FeatureFlagModel from '../../settings/feature-flags-container/feature-flag.model';
import { OnboardingProgressBar } from '../onboarding-progress-bar/onboarding-progress-bar.component';
import { FilterToggle } from '../../shared/filter-toggle/filter-toggle.component';
import { PortingHubContainer } from '../../porting-hub-container/porting-hub.container';
import { HandoffHubContainer } from '../../handoff-hub-container/handoff-hub.container';
import { SchedulingHub } from '../../scheduling-hub/scheduling-hub.component';
import {
  alertContainer,
  displayFlexCenter,
  enableTrackerButton,
  filterContainer,
  hubContainer,
  hubsSection,
  progressBarContainer,
  trackerContainer,
  trackerHeader,
  trackerText,
  trackerTitle,
} from './onboarding.styles';

import { LocationFeature } from '../../../models/location-feature.model';
import { Onboarder } from '../../../redux/actions/onboarding-tasks/onboarding-tasks.reducer';
import { SyncAppInterface } from '../../sync-app/sync-app.types';
import { VerticalEnum } from '../../../models/vertical-enum';
import { OnboardingHub } from '../../onboarding-hub/onboarding-hub.component';

export const WAITING_ON_YOU = 0;
export const WAITING_ON_WEAVE = 1;
export const COMPLETED = 2;
export const VERIFIED = 3;

const reassignButtonContainer = css`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
`;

const buttonStyle = css`
  max-width: 210px;
`;

const onboardingStyleContainer = css`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 15px;
  height: 67px;
`;

interface OnboardingProps extends RouteComponentProps {
  loading: boolean;
  tasks: Task[];
  currentLocation: LocationModel | null;
  currentTask: Task;
  onboarders: Onboarder[];
  getOnboardersLoading: boolean;
  schedulingLinks: {
    scheduleCustomizationLink: string;
    schedulePortingLink: string;
    scheduleNetworkLink: string;
    scheduleSoftwareTrainingLink: string;
    schedulePhoneTrainingLink: string;
  };
  onboardingGetTasks: () => void;
  getOnboarders: () => void;
  saveFeatureFlags: (updatedFlags: FeatureFlagModel[]) => void;
  markTaskAsComplete: (task: Task, status: number) => void;
  onboardingSetCurrentTask: (task: Task) => void;
  saveExplanationOnTask: (task: Task, explanation: string) => void;
  getOnboarderSchedulingLinks: (user) => void;
  backToPortingHubContainer: () => void;
  saveOnboarderSchedulingLinks: (
    locationId,
    userId,
    schedulingLinks,
    installsAlreadyScheduled
  ) => void;
  userId: string;
  locationId: string;
  featureFlags: FeatureFlagModel[];
  customizations: LocationFeature[];
  verticalId: VerticalEnum;
  syncApp: SyncAppInterface[] | undefined;
}

type HubTypes =
  | 'portingHub'
  | 'handoffHub'
  | 'trackerHub'
  | 'schedulingHub'
  | 'onboardingHub';

interface State {
  taskFilter: string;
  currentHub: HubTypes;
  showChangeOnboarder: boolean;
}

export class Onboarding extends React.Component<OnboardingProps, State> {
  private filterToggleArray: any[] = [
    {
      displayText: 'All',
      clickFunction: () => {
        this.setState({ taskFilter: 'All' });
      },
    },
    {
      displayText: 'Current',
      clickFunction: () => {
        this.setState({ taskFilter: 'Current' });
      },
    },
    {
      displayText: 'Completed',
      clickFunction: () => {
        this.setState({ taskFilter: 'Completed' });
      },
    },
  ];

  constructor(props: OnboardingProps) {
    super(props);

    this.state = {
      taskFilter: 'All',
      currentHub: 'trackerHub',
      showChangeOnboarder: false,
    };
  }

  componentDidMount() {
    this.props.onboardingGetTasks();
  }

  UNSAFE_componentWillReceiveProps(nextProps: OnboardingProps) {
    if (
      this.props?.currentLocation?.LocationID !== nextProps?.currentLocation?.LocationID
    ) {
      this.props.onboardingGetTasks();
      this.setState(() => ({
        showChangeOnboarder: false,
      }));
    }
  }

  isOnboardingFeatureFlagEnabled = () => {
    if (!isEmpty(this.props.featureFlags)) {
      const onboardingFlag = this.props.featureFlags.find(
        (flag) => flag.Name === 'onboardingBetaEnabled'
      );
      return onboardingFlag && onboardingFlag.Value;
    }
    return false;
  };

  saveOnboardingFeatureFlag = (isOn) => {
    this.props.saveFeatureFlags([
      {
        DisplayName: 'OnboardingBetaEnabled',
        Name: 'onboardingBetaEnabled',
        Value: isOn,
      },
    ]);
  };

  getCurrentHubComponent = () => {
    if (!this.props.currentLocation) {
      return null;
    }
    const { onboarders, getOnboardersLoading, getOnboarders } = this.props;
    const searchParams = new URLSearchParams(this.props.location.search);
    let debug = false;
    if (
      searchParams.has('debug') &&
      (searchParams.get('debug') === 'true' || searchParams.get('debug') === '1')
    ) {
      debug = true;
    }

    const { currentLocation, customizations } = this.props;
    let isNotIntegrated = false;
    if (customizations) {
      isNotIntegrated = customizations.some(
        (customization) =>
          customization.name === 'Integrated' && customization.state === 'hide'
      );
    }
    let chatIsOff = false;
    if (customizations) {
      chatIsOff = customizations.some(
        (customization) => customization.name === 'Chat' && customization.state === 'hide'
      );
    }
    const isPhonesOnly = isNotIntegrated && chatIsOff;
    let isSoftwareOnly = false;
    if (customizations) {
      isSoftwareOnly = customizations.some(
        (customization) =>
          customization.name === 'Phone Home Icon' && customization.state === 'hide'
      );
    }
    let hasPremiumFeatures = false;
    if (customizations) {
      hasPremiumFeatures = customizations.some(
        (customization) =>
          customization.name === 'Fax' && customization.state === 'active'
      );
    }

    if (this.state.currentHub === 'handoffHub') {
      return (
        <HandoffHubContainer
          {...{
            isNotIntegrated,
            isSoftwareOnly,
            hasPremiumFeatures,
            currentLocation,
            isPhonesOnly,
          }}
        />
      );
    } else if (this.state.currentHub === 'schedulingHub') {
      return (
        <SchedulingHub
          {...{ currentLocation, onboarders, getOnboardersLoading, getOnboarders, debug }}
        />
      );
    } else if (this.state.currentHub === 'trackerHub') {
      return (
        <div className={trackerHeader}>
          <div className={trackerTitle}>
            <div className={trackerText}>Onboarding Tracker</div>
          </div>

          {!isEmpty(this.props.tasks) && !this.isOnboardingFeatureFlagEnabled() && (
            <Alert color="warning" className={alertContainer}>
              The onboarding feature flag is disabled. The tracker will not be shown to
              this office until you enable it.
              <span
                onClick={() => {
                  this.saveOnboardingFeatureFlag(true);
                }}
                className={enableTrackerButton}
              >
                Enable Tracker
              </span>
            </Alert>
          )}

          {!isEmpty(this.props.tasks) && !this.state.showChangeOnboarder && (
            <div>
              <div className={progressBarContainer}>
                <div
                  className={css`
                    width: 347px;
                  `}
                >
                  <OnboardingProgressBar tasks={this.props.tasks} />
                </div>
              </div>
              <div className={filterContainer}>
                <FilterToggle
                  filterButtons={this.filterToggleArray}
                  activeFilterButton={this.state.taskFilter}
                />
              </div>
            </div>
          )}

          <div className={displayFlexCenter}>
            {this.props.loading ? (
              <div className={displayFlexCenter}>
                <Loader />
              </div>
            ) : (
              <section
                className={css`
                  margin-top: 20px;
                `}
              >
                <div
                  className={css`
                    display: flex;
                    flex-wrap: wrap;
                  `}
                >
                  {isEmpty(this.props.tasks) || this.state.showChangeOnboarder ? (
                    <div>
                      <OnboardingAssignOnboarder
                        onboarders={this.props.onboarders}
                        getOnboardersLoading={this.props.getOnboardersLoading}
                        showChangeOnboarder={this.state.showChangeOnboarder}
                        changeOnboarder={this.changeOnboarder}
                        getOnboarders={this.props.getOnboarders}
                        locationId={this.props.locationId}
                        saveOnboarderSchedulingLinks={
                          this.props.saveOnboarderSchedulingLinks
                        }
                        userId={this.props.userId}
                        getOnboarderSchedulingLinks={
                          this.props.getOnboarderSchedulingLinks
                        }
                        scheduleCustomizationLink={
                          this.props.schedulingLinks.scheduleCustomizationLink
                        }
                        scheduleNetworkLink={
                          this.props.schedulingLinks.scheduleNetworkLink
                        }
                        scheduleSoftwareTrainingLink={
                          this.props.schedulingLinks.scheduleSoftwareTrainingLink
                        }
                        schedulePhoneTrainingLink={
                          this.props.schedulingLinks.schedulePhoneTrainingLink
                        }
                        customizations={this.props.customizations}
                        verticalId={this.props.verticalId}
                        syncApp={this.props.syncApp ?? []}
                      />
                    </div>
                  ) : (
                    <OnboardingTasks
                      tasks={this.props.tasks}
                      saveExplanationOnTask={this.props.saveExplanationOnTask}
                      markTaskAsComplete={this.props.markTaskAsComplete}
                      filter={this.state.taskFilter}
                      currentTask={this.props.currentTask}
                      onboardingSetCurrentTask={this.props.onboardingSetCurrentTask}
                    />
                  )}

                  {!isEmpty(this.props.tasks) && !this.state.showChangeOnboarder && (
                    <div className={reassignButtonContainer}>
                      <PrimaryButton
                        className={buttonStyle}
                        onClick={() => {
                          this.changeOnboarder();
                        }}
                      >
                        Reassign Onboarder
                      </PrimaryButton>
                    </div>
                  )}
                </div>
              </section>
            )}
          </div>
        </div>
      );
    } else if (this.state.currentHub === 'portingHub') {
      return <PortingHubContainer />;
    } else {
      return <OnboardingHub locationId={this.props.locationId} />;
    }
  };

  changeHub = (hubName: HubTypes) => {
    this.setState({ currentHub: hubName });
  };

  changeOnboarder = () => {
    this.setState((prevState) => ({
      showChangeOnboarder: !prevState.showChangeOnboarder,
    }));
  };

  getNavButtonStyle = (hub: HubTypes) => {
    return css`
      color: ${this.state.currentHub === hub ? weaveBlue : '#8C9496'};
      flex: 1 100%;
      border-right: 1px solid #e9edef;
      border-left: none;
      padding: 10px;
      text-align: center;
      border-bottom: ${this.state.currentHub === hub ? '3px' : '1px'} solid
        ${this.state.currentHub === hub ? weaveBlue : '#E9EDEF'};
      height: 100%;
      font-weight: bold;
      font-size: 16px;
      padding-top: 20px;
      border-top: none;
      &:hover {
        cursor: pointer;
        box-shadow: inset 0 0 3px #eaeaea;
      }
    `;
  };

  render() {
    if (!this.props.currentLocation) {
      return null;
    }
    return (
      <div className="container-fluid">
        <div className={trackerContainer}>
          <div className={hubsSection}>
            <div className={onboardingStyleContainer}>
              <div
                className={this.getNavButtonStyle('trackerHub')}
                onClick={() => this.changeHub('trackerHub')}
              >
                Tracker
              </div>
              <div
                className={this.getNavButtonStyle('onboardingHub')}
                onClick={() => this.changeHub('onboardingHub')}
              >
                Onboarding
              </div>
              <div
                className={this.getNavButtonStyle('portingHub')}
                onClick={() => {
                  this.changeHub('portingHub');
                  this.props.backToPortingHubContainer();
                }}
              >
                Porting
              </div>
              <div
                className={this.getNavButtonStyle('schedulingHub')}
                onClick={() => this.changeHub('schedulingHub')}
              >
                Scheduled Events
              </div>
              <div
                className={this.getNavButtonStyle('handoffHub')}
                onClick={() => this.changeHub('handoffHub')}
              >
                Handoff
              </div>
            </div>
            <div className={hubContainer}>{this.getCurrentHubComponent()}</div>
          </div>
        </div>
      </div>
    );
  }
}
