import { all, takeLatest } from 'redux-saga/effects';
import { call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';
import { history } from '../../store/history';
import { setLoading, showError, unsetLoading } from '@weave/alert-system';
import { setupPendo } from './pendo';
import { ResolvedPromise } from '../../store/store-utils';
import { AuthApi } from './auth.api';
import { handleLocalStorage } from './auth.helpers';
import * as authActions from './auth.action';
import * as authTypes from './auth.types';

/* ~~~~~~~~~~~~~~~~~ GENERATOR FUNCTIONS ~~~~~~~~~~~~~~~~~ */

const handleAuthorization = function* (action) {
  const { token, redirectPath, oktaAccessToken } =
    action.payload as authTypes.AuthorizationPayload;
  CustomAxios.setUpAuth(token, oktaAccessToken);
  yield put(authActions.signInSuccess({ token }));
  yield put(unsetLoading('SIGN_IN'));
  history.push(redirectPath || '/');
};

export function* authenticationSuccess(
  jwtToken: string,
  redirectPath: string,
  accessToken?: string
) {
  const action = {
    type: authActions.signIn.toString(),
    payload: {
      token: jwtToken,
      redirectPath: redirectPath,
      oktaAccessToken: accessToken,
    },
  };
  yield put(action);
}

export function* authenticationError(err: string) {
  console.error(err);
  yield put(unsetLoading(authActions.signIn.toString()));
  yield put(showError(err));
}

export const handleOktaAuthentication = function* (action: {
  type: 'HANDLE_OKTA_AUTH';
  payload: authTypes.OktaAuthPayload;
}): Generator {
  try {
    yield put(setLoading(authActions.signIn.toString()));
    const token: ResolvedPromise<ReturnType<typeof AuthApi.POST.authenticateOkta>> =
      (yield call(
        AuthApi.POST.authenticateOkta,
        action.payload.tokens.oktaIdToken
      )) as string;

    handleLocalStorage.create(
      authTypes.AuthStorage.okta_id_token,
      action.payload.tokens.oktaIdToken
    );
    handleLocalStorage.create(authTypes.AuthStorage.user_email, action.payload.email);

    yield authenticationSuccess(
      token,
      action.payload.redirectPath,
      action.payload.tokens.oktaAccessToken
    );
  } catch (err: any) {
    yield authenticationError(err?.message || 'Error Validating ID_Token');
  }
};

export const handleGoogleAuthentication = function* (action: {
  type: 'HANDLE_GOOGLE_AUTH';
  payload: authTypes.GoogleAuthPayload;
}) {
  try {
    yield put(setLoading(authActions.signIn.toString()));

    const token: ResolvedPromise<ReturnType<typeof AuthApi.POST.authenticateGoogle>> =
      yield call(AuthApi.POST.authenticateGoogle, action.payload.credentials);

    setupPendo(action.payload.credentials);
    yield authenticationSuccess(token, action.payload.redirectPath);
  } catch (err: any) {
    yield authenticationError(err.message || '*Google login failed.');
  }
};

const handleSignOut = function* () {
  yield put(unsetLoading('SIGN_IN'));
  yield CustomAxios.clearAuth();

  handleLocalStorage.delete([
    'audits_filter_history',
    authTypes.AuthStorage.weave_token,
    authTypes.AuthStorage.okta_id_token,
    authTypes.AuthStorage.okta_access_token,
    authTypes.AuthStorage.user_email,
    authTypes.AuthStorage.redirect_path,
    authTypes.AuthStorage.midway,
  ]);

  history.push('/');
};

/* ~~~~~~~~~~~~~~~~~ SAGAS ~~~~~~~~~~~~~~~~~ */

export const signOutSaga = function* () {
  yield takeEvery(authActions.signOut.toString(), handleSignOut);
};

export const authorizationSaga = function* () {
  yield takeEvery(authActions.signIn.toString(), handleAuthorization);
};

export const oktaAuthenticationSaga = function* () {
  yield all([
    takeLatest(authActions.handleOktaAuth.toString(), handleOktaAuthentication),
  ]);
};

export const googleAuthenticationSaga = function* () {
  yield all([
    takeLatest(authActions.handleGoogleAuth.toString(), handleGoogleAuthentication),
  ]);
};

/* MAIN AUTH SAGA */
export const authSaga = function* () {
  yield all([
    signOutSaga(),
    authorizationSaga(),
    oktaAuthenticationSaga(),
    googleAuthenticationSaga(),
  ]);
};
