import React, { Component } from 'react';

export class OvalIcon extends Component<any, any> {
  render() {
    return (
      <svg
        width="13px"
        height="13px"
        viewBox="0 0 13 13"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Oval 4</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            id="Weave-Onboarding"
            transform="translate(-30.000000, -383.000000)"
            stroke={this.props.color}
            strokeWidth="2.5"
          >
            <circle id="Oval-4" cx="36.5" cy="389.5" r="4.5" />
          </g>
        </g>
      </svg>
    );
  }
}
