import React from 'react';
import { css, cx } from 'emotion';
import { getLossString, getFixedString } from '../network-audits.helpers';
import { NetworkAuditModel } from '../network-audits.model';
import { weaveTheme } from '@weave/theme-original/dist/weaveTheme';

interface RowDetailsProps {
  clickedRowData?: NetworkAuditModel;
  dataCenterPingUrl?: string;
}

export const RowDetails = (props: RowDetailsProps) => {
  if (!props.clickedRowData) {
    return null;
  }

  const { pings } = props.clickedRowData;

  const max = {
    jitter: 0,
    avgLatency: 0,
    maxLatency: 0,
  };
  const min = {
    jitter: -1,
    avgLatency: -1,
    maxLatency: -1,
  };

  pings.forEach((ping) => {
    if (min.jitter === -1) {
      min.jitter = ping.jitter;
    } else if (ping.jitter < min.jitter) {
      min.jitter = ping.jitter;
    } else if (ping.jitter > max.jitter) {
      max.jitter = ping.jitter;
    }

    if (min.avgLatency === -1) {
      min.avgLatency = ping.avg_latency;
    } else if (ping.avg_latency < min.avgLatency) {
      min.avgLatency = ping.avg_latency;
    } else if (ping.avg_latency > max.avgLatency) {
      max.avgLatency = ping.avg_latency;
    }

    if (min.maxLatency === -1) {
      min.maxLatency = ping.max_latency;
    } else if (ping.max_latency < min.maxLatency) {
      min.maxLatency = ping.max_latency;
    } else if (ping.max_latency > max.maxLatency) {
      max.maxLatency = ping.max_latency;
    }
  });

  return (
    <div>
      <table
        className={css`
          color: #555;
          th,
          td {
            padding: 8px;
          }
        `}
      >
        <thead
          className={css`
            border-bottom: solid 1px #e9edef;
          `}
        >
          <tr>
            <th>Data Center</th>
            <th>Loss</th>
            <th>Jitter</th>
            <th>Avg</th>
            <th>Worst</th>
          </tr>
        </thead>
        <tbody>
          {pings.map((ping, i) => (
            <tr
              key={i}
              className={cx({
                [css`
                  background-color: #eee;
                `]: ping.dest_name === props.dataCenterPingUrl,
              })}
            >
              <td>{ping.dest_name || ping.dest_ip}</td>
              <td>{getLossString(100 - (ping.sent / ping.returned) * 100)}</td>
              <td
                className={cx({
                  [minValue]: ping.jitter === max.jitter,
                  [maxValue]: ping.jitter === min.jitter,
                })}
              >
                {getFixedString(ping.jitter)}
              </td>
              <td
                className={cx({
                  [minValue]: ping.avg_latency === max.avgLatency,
                  [maxValue]: ping.avg_latency === min.avgLatency,
                })}
              >
                {getFixedString(ping.avg_latency)}
              </td>
              <td
                className={cx({
                  [minValue]: ping.max_latency === max.maxLatency,
                  [maxValue]: ping.max_latency === min.maxLatency,
                })}
              >
                {getFixedString(ping.max_latency)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const maxValue = css`
  color: ${weaveTheme.colors.success};
  font-weight: bold;
`;
const minValue = css`
  color: ${weaveTheme.colors.error};
  font-weight: bold;
`;
