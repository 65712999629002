import React from 'react';

import { AddressAutoComplete } from './address-autocomplete.component';
import { AddressForm } from './address-form';
import { Address, useAddressForm } from './use-address-form';

type Props = {
  addressFormProps: ReturnType<typeof useAddressForm>['addressFormProps'];
};

export const AddressInputForm = ({ addressFormProps }: Props) => {
  const { handleStreetChange, handleAddressChange } = addressFormProps;

  const handleAddressSelected = (address: Address) => {
    handleAddressChange(address);
  };

  return (
    <>
      <AddressAutoComplete
        onSelectAddress={handleAddressSelected}
        handleStreetChange={handleStreetChange}
      />
      <AddressForm {...addressFormProps} />
    </>
  );
};
