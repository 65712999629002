import { css } from 'emotion';
import { weaveTheme } from '@weave/theme-original/dist/weaveTheme';

export const cardStyles = css`
  min-height: 112px;
  border: 1px solid #b9c0c2;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: none;
  margin: 0px 0px 24px 0px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media only screen and (max-width: 1380px) and (min-width: 1200px) {
    padding: 8px;
  }
`;
export const keyFactorsDiv = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const tdSVG = css`
  cursor: default;
  .inline-svg svg {
    width: 25px;
    height: 25px;
    max-width: 25px;
    max-height: 25px;
  }
  &:hover svg {
    transform: none;
    cursor: default;
  }
`;
export const clickableFactor = css`
  flex-basis: 45%;
  width: 190px;
  cursor: pointer;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  &:hover {
    transform: scale(1.04);
  }

  @media only screen and (max-width: 790px) {
    flex-basis: 100%;
  }
`;
export const modalStyles = css`
  .modal-content {
    border-radius: 10px;
  }
`;
export const offFactor = css`
  color: ${weaveTheme.colors.gray400};
  font-size: 16px;
`;
export const cardTitle = css`
  color: ${weaveTheme.colors.gray600};
  font-size: 16px;
  font-weight: bold;
`;
export const factorContent = css`
  display: flex;
  justify-content: space-between;
  padding: 8px;
`;
export const factorName = css`
  line-height: 1.4em;
  width: 176px;
  color: ${weaveTheme.colors.gray600};
  font-size: 16px;
`;
export const emojiIcon = css`
  margin-left: 15px;
  position: relative;
  bottom: 3px;
`;
export const exclamationIconStyles = css`
  width: 22px;
  fill: ${weaveTheme.colors.warning};
`;
