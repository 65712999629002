import React from 'react';
import { useHoverLabel, TextLink } from '@weave/design-system';

import { useClipboard } from './clipboard-copy.hook';

type CopyTextLinkProps = {
  label: string;
  copyData: string;
};

export const CopyTextLink = ({ label, copyData }: CopyTextLinkProps) => {
  const { copy, copied } = useClipboard();
  const { triggerProps, labelProps, HoverLabel } = useHoverLabel({
    placement: 'top',
  });

  return (
    <>
      <span
        //wrapper because textlink doesn't forward the ref
        {...triggerProps}
      >
        <TextLink size="large" onClick={() => copy(copyData)}>
          {label}
        </TextLink>
      </span>
      {copied && <HoverLabel {...labelProps}>Copied</HoverLabel>}
    </>
  );
};
