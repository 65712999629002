import { css } from 'emotion';
import { weaveTheme } from '@weave/theme-original/dist/weaveTheme';

export const handoffHubStyles = css`
  width: 98%;
`;
export const topBar = css`
  display: flex;
  justify-content: space-between;
`;
export const titlesDiv = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;
export const topTitleMargin = css`
  margin-top: 20px;
`;
export const handoffTitle = css`
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  margin-bottom: 8px;
`;
export const handoffSubtitle = css`
  font-size: 16px;
`;
export const refreshButtonDiv = css`
  margin-top: 9px;
`;
export const buttonStyles = css`
  width: 160px;
  height: 40px;
  font-size: 14px;
`;
export const bottomButtonRow = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    margin: 0px 8px;
  }
`;
export const modalStyles = css`
  border-radius: 10px;
  padding: 24px;
  text-align: center;
  align-items: center;
`;
export const modalDiv = css`
  width: 100%;
`;
export const modalSpinner = css`
  margin-top: 25px;
`;
export const modalTitle = css`
  font-size: 24px;
  line-height: 32px;
`;
export const modalBodyText = css`
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 22px;
`;
export const modalButton = css`
  height: 48px;
  width: 254px;
  font-size: 16px;
  margin: auto;
`;
export const submitError = css`
  color: ${weaveTheme.colors.error} !important;
`;
