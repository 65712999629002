import { all } from 'redux-saga/effects';
import { getHandoffHubDataSaga } from './handoff-hub.action';

export const handoffHubSaga = function* () {
  yield all([getHandoffHubDataSaga()]);
};

export {
  getHandoffHubData,
  submitHandoffData,
  saveHandoffNotesSnapshot,
  saveCSATData,
  saveHandoffDate,
} from './handoff-hub.action';
export { reducer as handoffHubReducer } from './handoff-hub.reducer';
