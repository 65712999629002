import { handleActions } from 'redux-actions';

import {
  sentimentTrainingClassifying,
  sentimentTrainingClassifyingFailure,
  sentimentTrainingClassifyingSuccess,
} from './get-sentiment-training-classifying/get-sentiment-training-classifying.action';

import {
  sentimentTrainingLeaderboard,
  sentimentTrainingLeaderboardFailure,
  sentimentTrainingLeaderboardSuccess,
} from './get-sentiment-training-leaderboard/get-sentiment-training-leaderboard.action';

import {
  sentimentTrainingRank,
  sentimentTrainingRankFailure,
  sentimentTrainingRankSuccess,
} from './get-sentiment-training-rank/get-sentiment-training-rank.action';

import {
  postSentimentTrainingClassifying,
  postSentimentTrainingClassifyingFailure,
  postSentimentTrainingClassifyingSuccess,
} from './post-sentiment-training-classifying/post-sentiment-training-classifying.action';

export interface Employee {
  ID: string;
  LastName: string;
  FirstName: string;
}

export interface Leader {
  Rank: number;
  Score: number;
  Employee: Employee;
}

export interface SentimentTraining {
  messageData: {
    MessageText: string;
    MessageID: string;
    AvailableClassifications: string[];
  };
  messageLoading: boolean;
  messageError: boolean;
  leaderboardData: Leader[];
  leaderboardLoading: boolean;
  leaderboardError: boolean;
  rankData: {
    Rank: number;
    Score: number;
  };
  rankLoading: boolean;
  rankError: boolean;
  sentimentLoading: boolean;
  sentimentError: boolean;
}

const defaultState = {
  messageData: {
    messageText: '',
    messageId: '',
    AvailableClassifications: [],
  },
  messageLoading: false,
  messageError: false,

  leaderboardData: [],
  leaderboardLoading: false,
  leaderboardError: false,

  rankData: { Rank: 0, Score: 0 },
  rankLoading: false,
  rankError: false,

  sentimentLoading: false,
  sentimentError: false,
};

export const sentimentTrainingReducer = handleActions(
  {
    // Message
    [sentimentTrainingClassifying.toString()]: (state, action) =>
      Object.assign({}, state, { messageLoading: true }),
    [sentimentTrainingClassifyingSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { messageData: action.payload, messageLoading: false }),
    [sentimentTrainingClassifyingFailure.toString()]: (state, action) =>
      Object.assign({}, state, { messageError: action.payload, messageLoading: false }),
    // Leaderboard
    [sentimentTrainingLeaderboard.toString()]: (state, action) =>
      Object.assign({}, state, { leaderboardLoading: true }),
    [sentimentTrainingLeaderboardSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        leaderboardData: action.payload,
        leaderboardLoading: false,
      }),
    [sentimentTrainingLeaderboardFailure.toString()]: (state, action) =>
      Object.assign({}, state, {
        leaderboardError: action.payload,
        leaderboardLoading: false,
      }),
    // Rank
    [sentimentTrainingRank.toString()]: (state, action) =>
      Object.assign({}, state, { rankLoading: true }),
    [sentimentTrainingRankSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { rankData: action.payload, rankLoading: false }),
    [sentimentTrainingRankFailure.toString()]: (state, action) =>
      Object.assign({}, state, { rankError: action.payload, rankLoading: false }),
    // Sentiment
    [postSentimentTrainingClassifying.toString()]: (state, action) =>
      Object.assign({}, state, { sentimentLoading: true }),
    [postSentimentTrainingClassifyingSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { sentimentLoading: false }),
    [postSentimentTrainingClassifyingFailure.toString()]: (state, action) =>
      Object.assign({}, state, {
        sentimentError: action.payload,
        sentimentLoading: false,
      }),
  },
  defaultState
);
