import { createRequestStateSelector } from '@weave/alert-system';
import { createSelector } from 'reselect';
import { Store } from '../../store/store.model';
import { DevicesActionTypes } from './devices.types';
import { selectCurrentLocationDeviceIds } from '../location/location-entities/location-devices';
import { pick } from 'lodash';

export const selectDevicesFetchingAll = createRequestStateSelector(
  DevicesActionTypes.RequestList
);
export const selectDevicesFetching = createRequestStateSelector(
  DevicesActionTypes.RequestGet
);
export const selectDevicesUpdating = createRequestStateSelector(
  DevicesActionTypes.RequestUpdate
);
export const selectDevicesCreating = createRequestStateSelector(
  DevicesActionTypes.RequestCreate
);
export const selectDevicesDeleting = createRequestStateSelector(
  DevicesActionTypes.RequestDelete
);
export const selectDevicesDisablingAll = createRequestStateSelector(
  DevicesActionTypes.RequestDisableAll
);
export const selectDevicesEnablingAll = createRequestStateSelector(
  DevicesActionTypes.RequestEnableAll
);
export const selectDevicesReboating = createRequestStateSelector(
  DevicesActionTypes.Reboot
);
export const selectDevicesReboatingAll = createRequestStateSelector(
  DevicesActionTypes.RebootAll
);

const selector = (state: Store) => state.devices;

export const selectAllDevices = createSelector(
  selector,
  selectDevicesFetchingAll,
  (state, reqState) => ({
    ...reqState,
    devices: state,
  })
);

export const selectDevices = createSelector(
  selectAllDevices,
  selectCurrentLocationDeviceIds,
  ({ devices, ...reqState }, deviceIds) => ({
    ...reqState,
    devices: deviceIds ? pick(devices, deviceIds) : {},
  })
);

export const selectDevicesArray = createSelector(selectDevices, (state) => ({
  ...state,
  devices: Object.values(state.devices),
}));

export const selectDevice = (deviceId: string) =>
  createSelector(selectDevices, (state) => ({
    ...state,
    device: state.devices[deviceId],
  }));
