import React from 'react';
import { css } from 'emotion';
import { theme } from '@weave/theme-original';
import { Heading, Text } from '@weave/design-system';

import { useMerchant } from './use-merchant.hook';
import { Dot } from '../shared/atoms/Dot';
import { DisconnectStripeBtn } from './disconnect-stripe-btn';

const activeMerchantWrapper = css`
  display: flex;
  margin-bottom: 25px;
`;

const payoutsPaymentsStatuses = css`
  display: flex;
  justify-content: space-between;
  box-shadow: ${theme.shadows.light};
  padding: 10px;
  background-color: #f5f5f5;
  width: 240px;
  border-radius: ${theme.borderRadius.medium};
`;

const accounts = css`
  margin-left: 20px;
  padding: 10px;
  background-color: #f5f5f5;
  min-width: 240px;
  max-width: 300px;
  border-radius: ${theme.borderRadius.medium};
  overflow: hidden;
  padding-bottom: 5px;
  box-shadow: ${theme.shadows.light};
`;

const title = css`
  margin: -10px;
  margin-bottom: 5px;
  padding: 5px;
  background-color: ${theme.colors.weaveBlue};
`;

const subtitle = css`
  margin: 0;
`;

const statusesWrapper = css`
  display: flex;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  align-items: center;
  border-radius: ${theme.borderRadius.medium};
  padding: 10px 15px;
`;

const marginReset = css`
  margin: 0;
`;

const titleRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const PaymentsStatusDisplay = () => {
  const { activeProcessor, chargesEnabled, payoutsEnabled } = useMerchant();

  return (
    <>
      <div className={titleRow}>
        <Heading className={marginReset} level={2}>
          Active Merchant Account
        </Heading>
        <DisconnectStripeBtn />
      </div>
      <div className={activeMerchantWrapper}>
        <div className={payoutsPaymentsStatuses}>
          <div className={statusesWrapper}>
            <Text className={marginReset} weight="semibold">
              Payments
              <Dot isActive={chargesEnabled} />
            </Text>
            <Text className={marginReset} weight="semibold">
              Payouts
              <Dot isActive={payoutsEnabled} />
            </Text>
          </div>
        </div>
        <div className={accounts}>
          <Text
            color="white"
            textAlign="center"
            weight="semibold"
            size="medium"
            className={title}
          >
            Stripe Account ID
          </Text>
          <Text textAlign="center" className={subtitle}>
            {activeProcessor?.stripeId}
          </Text>
        </div>
        <div className={accounts}>
          <Text
            color="white"
            textAlign="center"
            weight="semibold"
            size="medium"
            className={title}
          >
            Stripe Account Type
          </Text>
          <Text textAlign="center" className={subtitle}>
            {activeProcessor?.stripeAccountType}
          </Text>
        </div>
      </div>
    </>
  );
};
