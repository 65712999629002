import fedHolidays from '@18f/us-federal-holidays';
import { getYear, format } from 'date-fns';

const phoneNumberRegex = /^[1-9]\d{9}$/;
const alphaNumericRegex = /^[a-zA-Z0-9]*$/;

export const USMountainTZ = 'US/Mountain';

export const splitAndTrimString = (phoneNumbers = '', deliminator = ''): string[] =>
  phoneNumbers
    .split(deliminator)
    .filter((num) => num?.trim())
    .map((num) => num?.trim());

export const validatePhoneNumbersRegex = (phoneNumbers: string): boolean => {
  for (const number of splitAndTrimString(phoneNumbers, ',')) {
    if (!phoneNumberRegex.test(number)) {
      return false;
    }
  }
  return true;
};

export const getFormattedNumbers = (numbers: string): string =>
  numbers.replace(/\+1/g, '').replace(/[-()]/g, '').replace(/\s+/g, '');

export const isHoliday = (requestedDate: string): boolean => {
  const date = new Date(requestedDate);
  const year = getYear(date).toString();
  return yearHolidays(year).includes(format(date, 'yyyy-MM-dd'));
};

export const yearHolidays = (year: string): string[] => {
  const start = new Date(`${year}-01-01`);
  const end = new Date(`${year}-12-31`);
  const holidays = fedHolidays.inRange(start, end);
  return holidays.map((holiday) => holiday.dateString);
};

export const isWeekend = (requestedDate: string): boolean => {
  const da = new Date(requestedDate);
  const isSaturday = da.getDay() === 6;
  const isSunday = da.getDay() === 0;
  return isSaturday || isSunday;
};

export const getUTCDateWithPortingTimeString = (date: string): string => {
  const parsedDate = new Date(date);

  // setting time to 15:30 UTC or 16:30 UTC because on provider side, weave provide porting time to be at 11:30 EST
  parsedDate.setHours(isDstObserved(parsedDate) ? 15 : 16);
  parsedDate.setMinutes(30);

  return format(parsedDate, `yyyy-MM-dd'T'HH:mm:ss'Z'`);
};

const stdTimezoneOffset = (date: Date) => {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

export const isDstObserved = (date: Date) => {
  return date.getTimezoneOffset() < stdTimezoneOffset(date);
};

export const isAlphaNumericValue = (value: string): boolean =>
  alphaNumericRegex.test(value);
