export enum ChilipiperScheduleEventTypes {
  nextStepCall = 'next_steps_call',
  phoneInstallCall = 'phone_install_call',
  adminTraining = 'admin_training',
  onsiteInstall = 'onsite_install',
  softwareInstallCall = 'software_install_call',
  softwareInstallationMulti = 'software_installation_multi',
  phoneInstallationMulti = 'phone_customization_multi',
  phoneCustomizationSingle = 'phone_customization_single',
  phoneCustomizationSingleNoIvr = 'phone_customization_single_no_ivr',
}

export interface UpdateScheduleEventBody {
  location_id?: string;
  event_id?: string;
  event_type?: string;
  route_id?: string;
  assignee_id?: string;
  contact_id?: string;
  start_at?: string;
  end_at?: string;
  assignee_email?: string;
  event_title?: string;
}

export interface ScheduleEventBodyPayload {
  location_id: string;
  event_id: string;
  event_type: string;
  route_id: string;
  assignee_id: string;
  // Make contact_id optional until we figure out how to retrieve this value.
  contact_id?: string;
  start_at: string;
  end_at: string;
}

export interface UpdateScheduleEventPayload {
  newEvent: ScheduleEventBodyPayload;
  oldEvent: UpdateScheduleEventBody;
}

export interface ChilipiperScheduleEventModel {
  id: string;
  location_id: string;
  event_id: string;
  event_type: ChilipiperScheduleEventTypes;
  route_id: string;
  assignee_id: string;
  contact_id: string;
  start_at: string;
  end_at: string;
  canceled_at: string;
  created_at: string;
  updated_at: string;
}

export interface PreProvisionDataModel {
  opportunityId: string;
  contactId: string;
}

export interface ChilipiperCancelEventModel {
  id: string;
  location_id: string;
  event_id: string;
  event_type: ChilipiperScheduleEventTypes;
  route_id: string;
  assignee_id: string;
  contact_id: string;
  start_at: string;
  end_at: string;
  created_at: string;
  updated_at: string;
  event_title: string;
  assignee_email: string;
}

// https://github.com/weave-lab/monorail/blob/2512d59c1d73869f77c9effdeb66f8701583258a/shared/protorepo/enums/insys/salesforce.proto
export enum OpportunityStageName {
  UNKNOWN_OPPORTUNITY_STAGE_NAME = 0,
  STAGE_CLOSED_WON = 1,
  STAGE_DEMO_COMPLETE_PROGRESSING = 2,
  STAGE_READY_TO_PURCHASE = 3,
  STAGE_DEMO_COMPLETE = 4,
  STAGE_OPEN = 5,
  STAGE_PROSPECTING = 6,
  STAGE_DEMO_SET = 7,
  STAGE_IDENTIFYING_OBJECTIONS = 8,
  STAGE_OVERCOMING_OBJECTIONS = 9,
  STAGE_PROGRESSING_TO_CLOSE = 10,
  STAGE_NEGOTIATING = 11,
  STAGE_CLOSED_NOT_COMPATIBLE = 12,
  STAGE_CLOSED_WELCOME_CALL = 13,
  STAGE_CLOSED_ONBOARDING = 14,
  STAGE_CLOSED_LOST = 15,
  STAGE_ONB_REJECTED = 16,
  STAGE_NO_DECISION = 17,
  STAGE_INSUFFICIENT_NETWORK = 18,
  STAGE_CLOSED_LOST_POTENTIAL = 19,
  STAGE_CLOSED_LOST_FINAL = 20,
  STAGE_CANCELED = 21,
  STAGE_NOT_INTERESTED = 22,
  STAGE_OBSOLETE_DUPLICATE = 23,
}

// Opportunities in this list should be filtered out when scheduling
export const StageNameDenyList: OpportunityStageName[] = [
  OpportunityStageName.STAGE_CLOSED_LOST,
  OpportunityStageName.STAGE_CLOSED_LOST_FINAL,
  OpportunityStageName.STAGE_CLOSED_LOST_POTENTIAL,
  OpportunityStageName.STAGE_CANCELED,
];

export type SalesforceOpportunity = {
  account_id: string;
  bookings: number;
  close_date: string;
  cross_schedules_10_of_appointments: string;
  do_these_locations_share_phone_number: string;
  id: string;
  insys_quote_signed: boolean;
  multi_notes: string;
  multiple_databases_in_single_location: string;
  multiple_locations_sharing_one_database: string;
  name: string;
  needs_call_center: string;
  onboarding_contact_id: string;
  onboarding_type_id: number;
  price_book_id: string;
  record_type: string;
  record_type_id: string;
  stage_name_id: OpportunityStageName;
  welcome_call_form: string;
};
