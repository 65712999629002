import { useSelector } from 'react-redux';
import { find } from 'lodash';

import {
  selectMerchant,
  selectMerchantLoading,
} from '../../redux/actions/merchant/merchant.action';
import { useMemo } from 'react';

export const useMerchant = () => {
  const merchant = useSelector(selectMerchant);
  const loading = useSelector(selectMerchantLoading);

  // Derived from merchant
  const activeProcessor = merchant && find(merchant.processors, 'isActive');
  const chargesEnabled = activeProcessor && activeProcessor.chargesEnabled;
  const payoutsEnabled = activeProcessor && activeProcessor.payoutsEnabled;

  const value = useMemo(
    () => ({
      merchant,
      loading,
      chargesEnabled,
      activeProcessor,
      payoutsEnabled,
    }),
    [merchant, loading]
  );

  return value;
};
