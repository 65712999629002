import React, { useState } from 'react';
import { css } from '@emotion/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReviewsOrderList } from '../reviews-order/reviews-order-list.component';
import { SolicitationModal } from '../solicitation-modal.component';
import { Settings, SiteName } from '../../helpers';
import { AuthenticationStatus } from '../authentication-status';
import { ChangedValues } from './reviews-settings.component';
import { WeaveTheme } from '@weave/theme-original';
import { useSelector } from 'react-redux';
import { selectHasFeatureFlagEnabled } from '../../../../../redux/actions/feature-flags';
import {
  Text,
  useForm,
  TextField,
  CheckboxField,
  TextareaField,
  FormRow,
  PrimaryButton,
  DropdownField,
  FormFieldActionTypes,
  ButtonBar,
  SecondaryButton,
  CopyIcon,
  TextLink,
  useModalControl,
  Heading,
  ContentLoader,
} from '@weave/design-system';
import { Store } from 'redux/store/store.model';

type ReviewsSettingsFormProps = {
  settings: Settings;
  onUpdate: (values: ChangedValues) => Promise<boolean>;
  onResync: () => void;
};

export const ReviewsSettingsForm = ({
  settings,
  onUpdate,
  onResync,
}: ReviewsSettingsFormProps) => {
  const [siteOrder, setSiteOrder] = useState(settings.siteOrder);
  const [isSiteOrderChanged, setIsSiteOrderChanged] = useState(false);
  const [googleUrlCopied, setGoogleUrlCopied] = useState(false);
  const [facebookUrlCopied, setFacebookUrlCopied] = useState(false);
  const [updating, setUpdating] = useState(false);

  const hasReviewsSync = useSelector((store: Store) =>
    selectHasFeatureFlagEnabled(store, 'marcomm-reviews-sync')
  );
  const showResyncButton =
    (settings.solicitationURLs?.facebook || settings.solicitationURLs?.google) &&
    hasReviewsSync;

  const { formProps, getFieldProps, changedValues, isComplete, seedValues } = useForm({
    computeChangedValues: true,
    fields: {
      template: { value: settings.template ?? '', type: 'text' },
      autoSend: { value: settings.autoSend ?? false, type: 'checkbox' },
      autoSendDelay: {
        value: settings.autoSendDelay?.toString(),
        type: 'dropdown',
        hidden: settings.autoSend !== true,
      },
      otherUrl: { value: settings.solicitationURLs?.additional, type: 'text' },
    },
    fieldStateReducer: (state, action) => {
      if (
        action.type === FormFieldActionTypes.Update &&
        action.payload.name === 'autoSend'
      ) {
        return {
          ...state,
          autoSendDelay: {
            ...state.autoSendDelay,
            hidden: action.payload.value !== true,
          },
        };
      }
      return null;
    },
    onSubmit: async (values, changed) => {
      if (!changed && !isSiteOrderChanged) {
        return;
      }

      const payload: ChangedValues = {
        ...changed,
        autoSendDelay: changed?.autoSendDelay ? Number(changed.autoSendDelay) : undefined,
      };

      if (isSiteOrderChanged && siteOrder !== null) {
        payload.siteOrder = siteOrder;
      }

      setUpdating(true);
      const updateSuccess = await onUpdate(payload);
      if (updateSuccess) {
        seedValues(values);
        setIsSiteOrderChanged(false);
      }
      setUpdating(false);
    },
  });

  const solicitationModalControl = useModalControl();

  const handleSiteOrderChange = (newOrder: SiteName[]) => {
    setSiteOrder(newOrder);
    setIsSiteOrderChanged(true);
  };

  const handleGoogleUrlCopied = () => {
    setGoogleUrlCopied(true);
    setTimeout(() => {
      setGoogleUrlCopied(false);
    }, 3000);
  };

  const handleFacebookUrlCopied = () => {
    setFacebookUrlCopied(true);
    setTimeout(() => {
      setFacebookUrlCopied(false);
    }, 3000);
  };

  return (
    <form
      {...formProps}
      css={css`
        max-width: 700px;
      `}
    >
      <FormRow
        css={(theme: WeaveTheme) => css`
          margin-bottom: ${theme.spacing(1)};
        `}
        cols={[60, 40]}
      >
        <AuthenticationStatus mappings={settings.mappings} />
        <div
          css={css`
            margin: 0;
          `}
        >
          <Heading level={2}>Solicitation Site Order</Heading>
          {siteOrder ? (
            <>
              {siteOrder.length > 0 ? (
                <ReviewsOrderList items={siteOrder} onChange={handleSiteOrderChange} />
              ) : (
                <Text
                  color="light"
                  size="large"
                  weight="bold"
                  css={css`
                    margin: 0;
                  `}
                >
                  No solicitation sites to order!
                </Text>
              )}
            </>
          ) : (
            <Text
              color="light"
              size="large"
              weight="bold"
              css={css`
                margin: 0;
              `}
            >
              An error occurred while fetching solicitation site ordering!
            </Text>
          )}
        </div>
      </FormRow>
      <Heading level={2}>Solicitation URLs</Heading>
      {settings.solicitationURLs?.google && (
        <>
          <CopyToClipboard
            text={settings.solicitationURLs.google}
            onCopy={handleGoogleUrlCopied}
          >
            <Text
              size="small"
              color="light"
              css={(theme: WeaveTheme) =>
                css`
                  display: flex;
                  align-items: center;
                  margin: ${theme.spacing(0, 0, 0.5, 2)};
                  cursor: pointer;
                `
              }
            >
              Google Solicitation URL
              <CopyIcon
                size={16}
                css={(theme: WeaveTheme) =>
                  css`
                    margin: ${theme.spacing(0, 0.5, 0, 2)};
                  `
                }
              />
              <Text as="span" size="small" color={googleUrlCopied ? 'success' : 'light'}>
                {googleUrlCopied ? 'Copied!' : 'Copy'}
              </Text>
            </Text>
          </CopyToClipboard>
          <Text
            css={(theme: WeaveTheme) =>
              css`
                margin: ${theme.spacing(0, 0, 2, 2)};
              `
            }
          >
            <TextLink to={settings.solicitationURLs.google} target="_blank">
              {settings.solicitationURLs.google}
            </TextLink>
          </Text>
        </>
      )}
      {settings.solicitationURLs?.facebook && (
        <>
          <CopyToClipboard
            text={settings.solicitationURLs.facebook}
            onCopy={handleFacebookUrlCopied}
          >
            <Text
              size="small"
              color="light"
              css={(theme: WeaveTheme) =>
                css`
                  display: flex;
                  align-items: center;
                  margin: ${theme.spacing(0, 0, 0.5, 2)};
                  cursor: pointer;
                `
              }
            >
              Facebook Solicitation URL
              <CopyIcon
                size={16}
                css={(theme: WeaveTheme) =>
                  css`
                    margin: ${theme.spacing(0, 0.5, 0, 2)};
                  `
                }
              />
              <Text
                as="span"
                size="small"
                color={facebookUrlCopied ? 'success' : 'light'}
              >
                {facebookUrlCopied ? 'Copied!' : 'Copy'}
              </Text>
            </Text>
          </CopyToClipboard>
          <Text
            css={(theme: WeaveTheme) =>
              css`
                margin: ${theme.spacing(0, 0, 2, 2)};
              `
            }
          >
            <TextLink to={settings.solicitationURLs.facebook} target="_blank">
              {settings.solicitationURLs.facebook}
            </TextLink>
          </Text>
        </>
      )}
      <FormRow
        css={(theme: WeaveTheme) =>
          css`
            margin-top: ${theme.spacing(3)};
          `
        }
      >
        <TextField
          {...getFieldProps('otherUrl')}
          label="Additional Solicitation URL"
          disabled={settings.solicitationURLs === null}
        />
      </FormRow>
      <Heading level={2}>Customize Text Message</Heading>
      <FormRow>
        <TextareaField
          {...getFieldProps('template')}
          label="Template"
          disabled={settings.template === null}
        />
      </FormRow>
      <Heading level={2}>Automated Review Solicitations</Heading>
      <FormRow
        css={css`
          align-items: center;
        `}
      >
        <CheckboxField
          {...getFieldProps('autoSend')}
          label="Automated Solicitations Enabled?"
          disabled={settings.autoSend === null}
        />
        <DropdownField
          {...getFieldProps('autoSendDelay')}
          label="Time to Send After Appointment"
          disabled={settings.autoSendDelay === null}
        >
          <DropdownField.Option value="1">1 Hour</DropdownField.Option>
          <DropdownField.Option value="2">2 Hours</DropdownField.Option>
          <DropdownField.Option value="3">3 Hours</DropdownField.Option>
          <DropdownField.Option value="4">4 Hours</DropdownField.Option>
        </DropdownField>
      </FormRow>
      <ButtonBar removeSpacing>
        {showResyncButton && (
          <SecondaryButton onClick={onResync}>Resync Reviews</SecondaryButton>
        )}
        <SecondaryButton {...solicitationModalControl.triggerProps} type="button">
          Send Review Solicitation
        </SecondaryButton>
        <PrimaryButton
          type="submit"
          disabled={!isComplete || (!changedValues && !isSiteOrderChanged)}
        >
          Update Review Settings
        </PrimaryButton>
      </ButtonBar>
      <SolicitationModal {...solicitationModalControl.modalProps} />
      <ContentLoader show={updating} />
    </form>
  );
};
