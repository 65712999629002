import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import {
  NakedButton,
  TextareaField,
  useFormField,
  useFlyoutMenu,
  FlyoutMenuAction,
  PlusIcon,
  TrashIcon,
} from '@weave/design-system';
import { Button } from '@weave/platform-ui-components';
import React from 'react';

const container = css`
  width: 90%;
`;
const trash = css`
  margin: auto;
  outline: none;
`;

const buttonStyle = (theme: WeaveTheme) => css`
  margin: 16px;
  color: ${theme.colors.weaveBlue};
  font-weight: bold;
`;

const emailBtnStyle = css`
  width: 50%;
  margin: auto;
`;

const flyOutContainer = css`
  width: max-content;
  position: relative;
`;

const flyOutMenuStyle = (theme: WeaveTheme) => css`
  width: 100%;
  box-shadow: ${theme.shadows.heavy};
  top: 0;
  left: 100%;
  z-index: 10;
`;

const contentMap = {
  emailHeader: 'Header',
  emailMessage: 'Message',
  emailFooter: 'Footer',
  emailButton: 'Button',
};

export const HtmlItem = ({ item, index, handleAddContent, editContent, removeItem }) => {
  const { active, FlyoutMenu, flyoutProps, triggerProps } = useFlyoutMenu();

  const { ...textProps } = useFormField(
    {
      type: 'text',
      value: item.content,
    },
    [item.content, item.type]
  );

  const actions: FlyoutMenuAction[] = [
    {
      Icon: PlusIcon,
      label: 'Header',
      onClick: () => handleAddContent('emailHeader'),
    },
    {
      Icon: PlusIcon,
      label: 'Message',
      onClick: () => handleAddContent('emailMessage'),
    },
    {
      Icon: PlusIcon,
      label: 'Footer',
      onClick: () => handleAddContent('emailFooter'),
    },
    {
      Icon: PlusIcon,
      label: 'Button',
      onClick: () => handleAddContent('emailButton'),
    },
  ];

  const isTextField =
    item.type === 'emailHeader' ||
    item.type === 'emailMessage' ||
    item.type === 'emailFooter';

  if (item.type === 'emailImage') return null;

  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <div css={container}>
        <div css={flyOutContainer}>
          <NakedButton css={buttonStyle} {...triggerProps}>
            Add Content Field
          </NakedButton>
          {active && (
            <FlyoutMenu css={flyOutMenuStyle} {...flyoutProps} actions={actions} />
          )}
        </div>

        {isTextField && (
          <TextareaField
            {...textProps}
            value={item.content}
            label={contentMap[item.type]}
            name={`content-${item.type}-${index}`}
            onChange={(e) => editContent(e)}
          />
        )}
        {item.type === 'emailButton' && (
          <Button css={emailBtnStyle}>{item.content}</Button>
        )}
      </div>
      <NakedButton css={trash} onClick={removeItem}>
        <TrashIcon />
      </NakedButton>
    </div>
  );
};
