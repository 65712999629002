import React from 'react';
import { theme } from '@weave/theme-original';
import { PrimaryButton, ContentLoader } from '@weave/design-system';
import { css } from '@emotion/core';

import { CallSettingsEdit } from './call-settings-edit/call-settings-edit.component';
import { OnOffIndicator } from '../../../shared/on-off-indicator/on-off-indicator.component';
import {
  CallSettingsSaveModel,
  ForwardingNumber,
  ForwardingStatus,
  SMSNumber,
} from '../models';
import { formatPhoneNumber } from '../../../../helpers/utils';
import { Page } from '../../../shared';

interface Props {
  locationId: string;
  activeSmsNumber: SMSNumber;
  callRecordingStatus: boolean;
  loading: boolean;
  forwardingNumbers: ForwardingNumber[];
  forwardingStatus: ForwardingStatus;
  smsNumberList: SMSNumber[];

  getCallSettings: (isRefresh: boolean) => void;
  addForwardingNumber: (number: ForwardingNumber) => void;
  updateForwardingNumber: (number: ForwardingNumber) => void;
  deleteForwardingNumber: (number: ForwardingNumber) => void;
  updateCallSettings: (settings: CallSettingsSaveModel) => void;
}

export function CallSettings(props: Props) {
  const [modalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    loadData(false);
  }, [props.locationId]);

  const handleRefreshData = () => {
    loadData(true);
  };

  const loadData = (isRefresh = false) => {
    props.getCallSettings(isRefresh);
  };

  return (
    <Page
      css={css`
        position: relative;
        & .content {
          & .loader {
            position: absolute;
          }
        }
      `}
      title="Call Settings"
      headerActions={
        <div
          className="actions"
          css={css`
            display: flex;
            min-width: 300px;
            & button {
              margin: ${theme.spacing(0, 1)};
            }
          `}
        >
          <PrimaryButton onClick={handleRefreshData}>Refresh Data</PrimaryButton>
          <PrimaryButton onClick={() => setModalOpen(true)}>Edit</PrimaryButton>
        </div>
      }
    >
      <div className={props.loading ? 'loading content' : 'content'}>
        <ContentLoader show={props.loading} className="loader" />
        <div
          className="call-settings"
          css={css`
            margin: 20px;

            & > div {
              height: 50px;
              border-bottom: 1px solid #f1f2f2;
              display: block;
              line-height: 50px;
            }

            .setting-name {
              display: inline-block;
              width: 15rem;
            }
          `}
        >
          <div>
            <span className="setting-name">Call Forwarding:</span>
            <OnOffIndicator on={props.forwardingStatus.enabled} />
            <span>{formatPhoneNumber(props.forwardingStatus.number)}</span>
          </div>
          <div>
            <span className="setting-name">Texting Number:</span>
            <span>{SMSNumber.formattedDisplay(props.activeSmsNumber)}</span>
          </div>
          <div>
            <span className="setting-name">Call Recording:</span>
            <OnOffIndicator on={props.callRecordingStatus} />
          </div>
        </div>
      </div>
      <CallSettingsEdit
        addForwardingNumber={props.addForwardingNumber}
        updateForwardingNumber={props.updateForwardingNumber}
        deleteForwardingNumber={props.deleteForwardingNumber}
        activeSmsNumber={props.activeSmsNumber}
        callRecordingStatus={props.callRecordingStatus}
        locationId={props.locationId}
        forwardingNumbers={props.forwardingNumbers}
        forwardingStatus={props.forwardingStatus}
        modalOpen={modalOpen}
        smsNumberList={props.smsNumberList}
        handleCloseModal={() => setModalOpen(false)}
        updateCallSettings={props.updateCallSettings}
      />
    </Page>
  );
}
