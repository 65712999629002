import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import { cx } from 'emotion';
import * as styles from './patient-info.styles.js';
import { PatientsModel } from '../../../../models/patient.model';
import { PatientContactInfo } from './patient-contact-info.component';
import { UuidCopier } from '../uuid-copier';
import { PatientPrefences } from './patient-prefences.component';

interface Props {
  person: PatientsModel;
  locationID: string;
}

interface State {
  contactTypes: Array<string>;
  contactIndex: number;
  copied: string;
}

export class PatientInfo extends React.Component<Props, State> {
  state = {
    copied: '',
    contactTypes: [],
    contactIndex: 0,
  };

  componentDidMount() {
    this.setContactTypes();
  }

  componentDidUpdate(prev) {
    if (this.props.person.contactInfo !== prev.person.contactInfo) {
      this.setContactTypes();
    }
  }

  setContactTypes() {
    if (this.props.person.contactInfo) {
      const contactTypes = Object.keys(this.props.person.contactInfo);
      this.setState({ contactTypes });
    } else {
      const contactTypes = ['Invalid Data'];
      this.setState({ contactTypes });
    }
  }

  render() {
    const modifiedAt = moment(_.get(this.props, 'person.modified_at', 'N/A')).format(
      'MMMM Do YYYY, h:mm:ss a'
    );
    const createdAt = moment(_.get(this.props, 'person.created_at', 'N/A')).format(
      'MMMM Do YYYY, h:mm:ss a'
    );
    let receiveSms = this.props.person.receive_sms ? 'Opted In' : 'Opted Out';
    if (this.props.person.receive_sms === null) {
      receiveSms = 'Preferences not enforced';
    }

    return (
      <div className={styles.flexStyle}>
        <div className={styles.infoBox}>
          <div className={cx(styles.flexStyle, styles.infoItem)}>
            <strong className={cx(styles.infoBoxLeft, styles.rightStyle)}>ID</strong>
            <p className={styles.rightP}>
              {_.get(this.props, 'person.patient_id', 'N/A')}
            </p>
          </div>
          <div className={cx(styles.flexStyle, styles.infoItem)}>
            <strong className={cx(styles.infoBoxLeft, styles.rightStyle)}>
              Person ID
            </strong>
            <UuidCopier uuid={this.props?.person?.PersonID} />
          </div>
          <div className={cx(styles.flexStyle, styles.infoItem)}>
            <strong className={cx(styles.infoBoxLeft, styles.rightStyle)}>
              Location ID
            </strong>
            <UuidCopier uuid={this.props?.person?.clientLocationID} />
          </div>
          <div className={cx(styles.flexStyle, styles.infoItem)}>
            <strong className={cx(styles.infoBoxLeft, styles.rightStyle)}>
              Person Status
            </strong>
            <p className={styles.rightP}>{_.get(this.props, 'person.status', 'N/A')}</p>
          </div>
          <div className={cx(styles.flexStyle, styles.infoItem)}>
            <strong className={cx(styles.infoBoxLeft, styles.rightStyle)}>
              Modified At
            </strong>
            <p className={styles.rightP}>{modifiedAt}</p>
          </div>
          <div className={cx(styles.flexStyle, styles.infoItem)}>
            <strong className={cx(styles.infoBoxLeft, styles.rightStyle)}>
              Created At
            </strong>
            <p className={styles.rightP}>{createdAt}</p>
          </div>
          <div className={cx(styles.flexStyle, styles.infoItem)}>
            <strong className={cx(styles.infoBoxLeft, styles.rightStyle)}>
              PM Text Preference
            </strong>
            <p className={styles.rightP}>{receiveSms}</p>
          </div>
          <PatientPrefences
            personID={this.props.person?.PersonID}
            locationID={this.props.locationID}
          />
        </div>
        <div>
          {this.props.person.contactInfo && (
            <PatientContactInfo
              personID={this.props.person.PersonID}
              contactInfoList={this.props.person.contactInfo}
            />
          )}
        </div>
      </div>
    );
  }
}
