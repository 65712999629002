import React, { useState } from 'react';
import { css } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { Text, ContentLoader, Heading } from '@weave/design-system';
import { theme } from '@weave/theme-original';

import { Modal } from '../shared/modal/modal.component';
import {
  selectMerchantConnectLink,
  getMerchantConnectLink,
} from '../../redux/actions/merchant/merchant.action';
import { CopyTextLink } from './copy-text-link';

const modalBody = css`
  overflow-wrap: break-word;
`;

const lineBreak = css`
  margin: 16px 0;
  border-color: ${theme.colors.gray400};
`;

const merchantLink = css`
  cursor: pointer;
  color: #007bff !important;
  margin-left: 10px;
`;

export const PaymentsOnboardingLink = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const merchantConnectLink = useSelector(selectMerchantConnectLink);

  const getMerchantLink = () => {
    dispatch(getMerchantConnectLink());
    setModalOpen(true);
  };

  return (
    <>
      <Heading level={2}>Stripe Merchant Onboarding</Heading>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={getMerchantLink} css={merchantLink}>
        <span>Get Express Onboarding Link</span>
      </a>
      <hr css={lineBreak} />
      <Modal
        isOpen={isModalOpen}
        title="Merchant Onboarding Link"
        onRequestClose={() => setModalOpen(false)}
        size="small"
      >
        {merchantConnectLink && (
          <div css={modalBody}>
            <CopyTextLink label="Copy link to clipboard" copyData={merchantConnectLink} />
            <Text size="small" color="light" css={{ marginTop: 16 }}>
              {merchantConnectLink}
            </Text>
          </div>
        )}
        {!merchantConnectLink && <ContentLoader />}
      </Modal>
    </>
  );
};
