import { useEffect, useState } from 'react';
import { PostalCodeLocale, useForm } from '@weave/design-system';

const defaultAddress: Address = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  postal: '',
};

export type Address = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  postal: string;
};

/**
 * Custom hook meant to be used with the AddressInputForm component. Returns back form
 * values as well as addressFormProps which is meant to be passed down as props to the
 * AddressInputForm component.
 *
 * @param initialAddress Address used to seed the initial values of the address form.
 */
export const useAddressForm = (initialAddress: Address | null) => {
  const [error, setError] = useState('');
  const [streetLine, setStreetLine] = useState('');

  const { getFieldProps, values, seedValues, reset } = useForm({
    fields: {
      city: { type: 'text', required: true },
      state: { type: 'dropdown', required: true },
      postal: { type: 'postalCode', locale: PostalCodeLocale.US, required: true },
    },
  });

  useEffect(() => {
    handleAddressChange(initialAddress ?? defaultAddress);
  }, []);

  const handleAddressChange = (newAddress: Address) => {
    const { city, state, postal } = newAddress;

    reset();
    setError('');

    seedValues({
      city,
      state,
      postal,
    });
  };

  const handleStreetChange = (newStreet: string) => {
    setStreetLine(newStreet);
  };

  const isValid = !!streetLine && !!values?.city && !!values?.postal && !!values?.state;

  return {
    values,
    streetLine,
    isValid,
    reset,
    addressFormProps: {
      values,
      getFieldProps: getFieldProps,
      handleAddressChange,
      handleStreetChange,
      error,
    },
  };
};
