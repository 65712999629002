import React, { useState } from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { Heading, Tabs, Text } from '@weave/design-system';
import { TabSetter } from '@weave/design-system/dist/components/tabs/tabs/tabs-provider.component';
import { PortalDetails } from './outage-alerts.types';
import { OutageAlertsDetails } from './outage-alerts-details/outage-alerts-details';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthToken,
  selectWeaveUserAcls,
} from '../../redux/actions/auth/auth.selectors';
import { firebaseTokenSelector } from '../../redux/actions/firebase-token/firebase-token.selector';
import { getAndAuthenticateFirebaseToken } from '../../redux/actions/firebase-token/firebase-token.actions';
import { CoreACLs } from '../../redux/actions/auth/auth.types';

const container = css`
  width: 100vw;
  padding: ${theme.spacing(4)};
`;

export const OutageAlerts = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  const firebaseToken = useSelector(firebaseTokenSelector);
  const [activeTab, setActiveTab] = useState<'desktop' | 'portal' | 'wam'>('desktop');
  const hasACL = useSelector(selectWeaveUserAcls).indexOf(CoreACLs.OUTAGEREPORT) >= 0;

  /**
   * useEffect to get and authenticate firebase token for accessible user which are defined in ACL
   */
  useEffect(() => {
    if (hasACL && !firebaseToken?.firebaseToken) {
      dispatch(getAndAuthenticateFirebaseToken());
    }
  }, [token]);

  if (!hasACL) {
    return (
      <div style={{ textAlign: 'center', margin: 'auto' }}>
        <Heading>Access Denied!</Heading>
      </div>
    );
  }

  return (
    <div css={container}>
      <div>
        <Heading level={2}>Outage Alerts</Heading>
        <Text>
          View and manage customer facing alerts for any outages affecting Desktop Client,
          Admin Portal, and WAM.
        </Text>
        <Tabs initialTab="desktop" onChange={setActiveTab as TabSetter}>
          <Tabs.Bar>
            <Tabs.Tab id="desktop" controls="desktop-panel">
              {PortalDetails.desktop}
            </Tabs.Tab>
            <Tabs.Tab id="portal" controls="portal-panel">
              {PortalDetails.portal}
            </Tabs.Tab>
            <Tabs.Tab id="wam" controls="wam-panel">
              {PortalDetails.wam}
            </Tabs.Tab>
          </Tabs.Bar>
          <OutageAlertsDetails application={activeTab} />
        </Tabs>
      </div>
    </div>
  );
};
