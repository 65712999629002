import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Subtitle } from '@weave/platform-ui-components';

import {
  Delivery,
  ReminderDetails,
  SendBefore,
} from '../../../redux/actions/notifications-settings/notification-settings.types';
import {
  getNotificationsSettings,
  resetNotificationSettings,
  selectNoficationSettingCategories,
} from '../../../redux/actions/notifications-settings/notifications-settings.action';
import { selectCurrentLocation } from '../../../redux/actions/location';
import { DayOfWeekSettingsContainer } from '../../shared/day-of-week-settings-container/day-of-week-settings.container';
import { Page } from '../../shared';
import { RemindersList } from './auto-reminders-list-component/auto-reminders-list.component';
import * as styles from './auto-reminders.styles';
import { HeaderRow } from './auto-reminders-list-component/header-row';
import { sortByKey } from './auto-reminders-helpers/auto-reminder-helpers';
import { AutoRemindersHonorPMS } from './auto-reminders-honor-pms.component';
import { ExternalLinkIcon, Text } from '@weave/design-system';
import { selectFeatureFlag } from 'redux/actions/feature-flags';
import { REACT_APP_API_URL } from 'config/app';

export interface CategorySetting {
  method: string;
  time: string;
  type: string;
  value: string;
  send_before: SendBefore;
  delivery: Delivery;
}

export const AutoReminders = () => {
  const dispatch = useDispatch();
  const location = useSelector(selectCurrentLocation);
  const notificationsSettings = useSelector(selectNoficationSettingCategories);
  const autoRemindersV2Enabled = useSelector((state) =>
    selectFeatureFlag(state, 'auto-reminders-to-portal')
  );

  useEffect(() => {
    dispatch(resetNotificationSettings());
    dispatch(getNotificationsSettings());
  }, [location?.LocationID]);

  const preProcessSendBefore = (setting: CategorySetting): string => {
    if (!setting || !setting.send_before) {
      return '';
    }
    const value: number = setting.send_before.value || 0;
    let type: string = setting.send_before.type || '';

    const intValue = parseInt(value.toString(), 10);
    type = Math.abs(intValue) === 1 ? type.replace(/[s]$/, '') : type;
    let sendBefore;
    if (intValue >= 0) {
      sendBefore = `${intValue} ${type} before`;
    } else {
      sendBefore = `${Math.abs(intValue)} ${type} after`;
    }

    // NOTE review notifications don't follow the same pattern as the others 🤷.
    if (setting.type === 'review') {
      sendBefore = sendBefore.replace('before', 'after');
    }
    return sendBefore;
  };

  const prepareCategoryData = (category: string): ReminderDetails => {
    const categorySettings = Object.assign(notificationsSettings[category] || {});
    const columns = Object.keys(categorySettings[0] || {});
    let deliveryTime: string;

    return categorySettings.map((setting) => {
      columns.forEach((column) => {
        if (column === 'delivery' && setting.delivery) {
          setting['delivery_type'] = setting.delivery.method;
          deliveryTime = setting.send_schedule.includes('hour')
            ? ''
            : setting.delivery.time;
          setting['delivery_time'] = deliveryTime;
        } else if (column === 'send_before') {
          setting.send_schedule = preProcessSendBefore(setting);
        }
      });
      return setting;
    });
  };

  const prepareData = (): ReminderDetails[] => {
    const categories = Object.keys(notificationsSettings || {});
    return categories.map(prepareCategoryData);
  };

  const preparedData = prepareData().map((data) => sortByKey(data, 'name', 'id')) || [];
  const tableNames = preparedData.map((data) => data[0].type) || [];

  const remindersWeavePortalUrl = `https://app.${
    REACT_APP_API_URL.includes('dev') ? 'weavedev.net' : 'getweave.com'
  }/admin/${location.LocationID}/auto-reminder-settings-v2`;

  return (
    <Page
      className={styles.minWidth}
      title="Auto Reminders"
      headerActions={
        !autoRemindersV2Enabled && (
          <div className={styles.settingContainer}>
            <DayOfWeekSettingsContainer />
            <div>
              <Button
                onClick={() => dispatch(getNotificationsSettings())}
                className={styles.refreshButtonStyles}
              >
                Refresh Data
              </Button>
            </div>
          </div>
        )
      }
    >
      {autoRemindersV2Enabled ? (
        <>
          <Text>Auto Reminders settings has been migrated to the Weave Portal.</Text>
          {location && (
            <a
              className={styles.autoReminderLinkStyles}
              href={remindersWeavePortalUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              Open Settings in Weave Portal
              <ExternalLinkIcon />
            </a>
          )}
        </>
      ) : (
        <>
          <AutoRemindersHonorPMS />
          {preparedData.map((data, idx) => {
            return (
              <div key={idx}>
                <Subtitle className={styles.tableNames}>
                  <strong>{tableNames[idx]} Reminders</strong>
                </Subtitle>
                <hr />
                <HeaderRow />
                <div key={`${data[0].type}_table`}>
                  <RemindersList data={data} />
                </div>
              </div>
            );
          })}
        </>
      )}
    </Page>
  );
};
