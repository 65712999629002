import React from 'react';
import {
  Checkbox,
  Label,
  Radio,
  RadioGroup,
  Subtitle,
} from '@weave/platform-ui-components';
import * as styles from './auto-reminder-advanced-options.styles';
import { AddNewAdvancedOption } from './add-new-advanced-option.component';
import { AdvancedOption } from '../../../../../redux/actions/notifications-settings/notification-settings.types';

interface EditInterface {
  type: string;
  list: AdvancedOption[];
  handleCheckBox: (type: string, value: string) => void;
  toggleExcludeOption: (type: string, value: boolean) => void;
  consolidatedOption: {
    excludeOptions: boolean;
    values: string[];
  };
}

export const EditAdvancedOptions = (props: EditInterface) => {
  const {
    type,
    consolidatedOption,
    list = [],
    handleCheckBox,
    toggleExcludeOption,
  } = props;

  if (consolidatedOption === undefined) {
    return null;
  }

  return (
    <div>
      <h5>
        <Subtitle> {type} </Subtitle>
        {type === 'location' && (
          <AddNewAdvancedOption type={type} handleCheckBox={handleCheckBox} />
        )}
        <RadioGroup horizontal>
          <div>
            <Radio
              name={`includeRadio-${type}`}
              id="include"
              value="include"
              onChange={() => toggleExcludeOption(type, false)}
              checked={!consolidatedOption.excludeOptions}
            />
            <Label htmlFor="include">Include</Label>
          </div>
          <div>
            <Radio
              name={`excludeRadio-${type}`}
              id="exclude"
              value="exclude"
              onChange={() => toggleExcludeOption(type, true)}
              checked={consolidatedOption.excludeOptions}
            />
            <Label htmlFor="exclude">Exclude</Label>
          </div>
        </RadioGroup>
      </h5>
      <div className={styles.yOverflow(type === 'location')}>
        {list.map((listItem) => {
          const checked = consolidatedOption.values.some(
            (value) => value === listItem.value
          );
          const style =
            checked && consolidatedOption.excludeOptions ? styles.checkboxExclude : '';
          return (
            <div
              className={styles.checkbox}
              key={`edit-options-${type}-${listItem.value}`}
            >
              <Checkbox
                className={style}
                checked={checked}
                onChange={() => handleCheckBox(type, listItem.value)}
                name={`checkbox-${type}-${listItem}`}
                value={listItem.value}
              />
              <span>{listItem.display_name} </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
