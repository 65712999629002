import { connect } from 'react-redux';
import { get } from 'lodash';

import { MachineLearningLabeler } from './machine-learning-labeler/machine-learning-labeler.component';
import { sentimentTrainingRank } from '../../redux/actions/sentiment-training/get-sentiment-training-rank/get-sentiment-training-rank.action';

export const MachineLearningLabelerContainer = connect(
  (state) => ({
    score: get(state, 'sentimentTraining.rankData.Score', 0),
    rank: get(state, 'sentimentTraining.rankData.Rank', 0),
  }),
  (dispatch) => ({
    getRank() {
      dispatch(sentimentTrainingRank());
    },
  })
)(MachineLearningLabeler);
