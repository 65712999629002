export enum Endpoint {
  appointmentHistory = 'appointment-history',
  callRecords = 'call-records',
  emailHistory = 'email-history',
  patients = 'patients',
  autoMsgQueue = 'auto-msg-queue',
  logs = 'logs',
  oldSmsHistory = 'old-sms-history',
  smsHistory = 'sms-history',
  recallEvents = 'recall-events',
  orderEvents = 'order-events',
  reviewsList = 'reviews-list',
  appointmentConfirmationWritebacks = 'appointment-confirmation-writebacks',
  syncAppWritebacks = 'sync-app-writebacks',
  faxHistory = 'fax-history',
  invoices = 'invoices',
  clientLocations = 'client-locations',
}

export const getEndpoint = (endpoint: Endpoint, locationId?: string) => {
  switch (endpoint) {
    case Endpoint.appointmentHistory:
      return `/support/v1/appointments`;
    case Endpoint.callRecords:
      return '/support/v1/calllogs';
    case Endpoint.emailHistory:
      return `/support/v1/locations/${locationId}/emails`;
    case Endpoint.patients:
      return `/support/v1/locations/${locationId}/patients`;
    case Endpoint.autoMsgQueue:
      return '/support/v1/notifications/queue';
    case Endpoint.logs:
      return `/support/v1/locations/${locationId}/supportlogs`;
    case Endpoint.smsHistory:
      return '/sms/data/v3/sms/list';
    case Endpoint.oldSmsHistory:
      return '/sms/v2/sms/list';
    case Endpoint.recallEvents:
      return '/support/v1/recall';
    case Endpoint.orderEvents:
      return '/support/v1/order';
    case Endpoint.reviewsList:
      return '/support/v1/reviews';
    case Endpoint.appointmentConfirmationWritebacks:
      return '/support/v1/writebacks';
    case Endpoint.syncAppWritebacks:
      return '/support/v1/syncapp/genericWritebackAttempts';
    case Endpoint.faxHistory:
      return '/support/v1/fax';
    case Endpoint.invoices:
      return '/invoices';
    case Endpoint.clientLocations:
      return `/support/v1/locations/${locationId}/clientlocations`;
    default:
      return '/';
  }
};
