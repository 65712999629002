import React from 'react';
import { connect } from 'react-redux';
import { pickBy, includes, isEmpty } from 'lodash';
import {
  Button,
  Checkbox,
  Modal,
  Subtitle,
  weaveBlue,
  weaveGreen,
  weaveRed,
} from '@weave/platform-ui-components';
import { TableStyles } from '../../../../../styles/table-style';
import ReactTable from 'react-table';
import { css } from 'emotion';

import { clearAvailablePhoneNumbers } from '../../../../../redux/actions/twilio';
import { Loader } from '../../../../shared/loader/loader.component';
import {
  AvailablePhoneNumbersModel,
  TwilioPhoneNumberResponseModel,
} from '../../twilio-models.interface';
import { portingCardStyles } from '../../../../porting-hub-container/porting-card/porting-card.styles';
import { orderNewNumberStyles } from '../order-new-number.styles';

interface Props {
  availablePhoneNumbers: AvailablePhoneNumbersModel;
  selectedNumbers: string[];
  purchaseSelectedNumbers: () => {};
  handlePurchaseChange: (event) => {};
  clearAvailablePhoneNumbers: () => void;
  loading: boolean;
  newNumberSuccessMessages: string[];
  newNumberFailureMessages: string[];
}

interface State {
  isPurchaseModalOpen: boolean;
}

class AvailablePhoneNumbers extends React.Component<Props, State> {
  state = {
    isPurchaseModalOpen: false,
  };

  render() {
    return (
      <>
        <div className={orderNewNumberStyles.formStyles}>
          <Subtitle
            className={css`
              color: ${weaveBlue};
              margin: 24px 0;
            `}
          >
            {this.props.availablePhoneNumbers.uri}
          </Subtitle>
          <div
            className={css`
              text-align: right;
            `}
          >
            <Button
              disabled={isEmpty(this.props.selectedNumbers)}
              onClick={this.openPurchaseModal}
              className={css`
                display: inline-block !important;
              `}
            >
              Purchase
            </Button>
          </div>
          <ReactTable
            columns={[
              {
                Header: 'Number',
                id: 'number',
                accessor: (phoneNumber: TwilioPhoneNumberResponseModel) =>
                  phoneNumber.phone_number,
              },
              {
                Header: 'Capabilities',
                id: 'phone_number_capabilities',
                accessor: (phoneNumber: TwilioPhoneNumberResponseModel) => {
                  const capabilitiesArrayOfTrueValues = Object.keys(
                    pickBy(phoneNumber.phone_number_capabilities)
                  );

                  return capabilitiesArrayOfTrueValues
                    .map((str) => `${str.charAt(0).toUpperCase()}${str.substring(1)}`)
                    .join(', ');
                },
              },
              {
                Header: '',
                id: 'select',
                accessor: (phoneNumber: TwilioPhoneNumberResponseModel) => {
                  return (
                    <span className={orderNewNumberStyles.checkboxStyles}>
                      <Checkbox
                        checked={includes(
                          this.props.selectedNumbers,
                          phoneNumber.phone_number
                        )}
                        value={phoneNumber.phone_number}
                        onChange={this.props.handlePurchaseChange}
                      />
                    </span>
                  );
                },
              },
            ]}
            data={this.props.availablePhoneNumbers.available_phone_numbers}
            sortable={false}
            loading={this.props.loading}
            pageSize={10}
            css={TableStyles}
          />
        </div>
        <Modal
          isOpen={this.state.isPurchaseModalOpen}
          onRequestClose={this.closePurchaseModal}
          className={portingCardStyles.divWidth}
        >
          <div className="container">
            {this.props.loading ? (
              <div>
                <div className={portingCardStyles.loading}>
                  <Loader size="large" />
                </div>
                <div className={portingCardStyles.modalStyle}>Purchasing...</div>
              </div>
            ) : (
              <div>
                <div className={orderNewNumberStyles.modalStyles}>
                  {this.props.newNumberSuccessMessages.length > 0 && (
                    <div>
                      {this.props.newNumberSuccessMessages.map((message) => {
                        return (
                          <div
                            key={message}
                            className={css`
                              color: ${weaveGreen};
                            `}
                          >
                            {message}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {this.props.newNumberFailureMessages.length > 0 && (
                    <div>
                      {' '}
                      {this.props.newNumberFailureMessages.map((message) => {
                        return (
                          <div
                            key={message}
                            className={css`
                              color: ${weaveRed};
                            `}
                          >
                            {message}
                          </div>
                        );
                      })}{' '}
                    </div>
                  )}
                </div>
                <Button
                  className={portingCardStyles.errorOkayButton}
                  variant="rounded"
                  fill="solid"
                  color="blue"
                  onClick={this.closePurchaseModal}
                >
                  Okay
                </Button>
              </div>
            )}
          </div>
        </Modal>
      </>
    );
  }

  openPurchaseModal = () => {
    this.setState({ isPurchaseModalOpen: true }, () => {
      this.props.purchaseSelectedNumbers();
    });
  };

  closePurchaseModal = () => {
    this.setState({ isPurchaseModalOpen: false }, () => {
      this.props.clearAvailablePhoneNumbers();
    });
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    availablePhoneNumbers: state.twilio.availablePhoneNumbers,
    purchaseSelectedNumbers: ownProps.purchaseSelectedNumbers,
    handlePurchaseChange: ownProps.handlePurchaseChange,
    selectedNumbers: ownProps.selectedNumbers,
    loading: state.twilio.purchasingNumbers,
    newNumberSuccessMessages: state.twilio.newNumberSuccessMessages,
    newNumberFailureMessages: state.twilio.newNumberFailureMessages,
  };
};

export default connect(mapStateToProps, { clearAvailablePhoneNumbers })(
  AvailablePhoneNumbers
);
