import { css } from 'emotion';
import { theme } from '@weave/theme-original';

import { modalMainSectionHeight } from './select-media-modal.styles';

export const mainSection = css`
  height: ${modalMainSectionHeight};
  overflow: hidden;

  label: mainSection;
`;

export const searchInput = css`
  margin: 8px 0;
`;

export const buttonContainer = css`
  margin-top: 50px;
  text-align: center;

  label: buttonContainer;
`;

export const submitButton = css`
  display: inline-block;
`;

export const hr = css`
  margin: 16px 0;
`;

export const mediaFileContainer = css`
  height: 252px;
  padding-right: 10px;
  overflow: auto;

  &::-webkit-scrollbar {
    background-color: ${theme.colors.gray100};
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${theme.colors.gray300};
  }
`;

export const mediaFileSelect = css`
  align-items: center;
  border: 1px solid ${theme.colors.gray300};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding: 8px 20px;

  &:first-of-type {
    margin-top: 0;
  }

  &.selected {
    border: 2px solid ${theme.colors.weaveBlue};
  }
`;

export const audio = css`
  height: 35px;
`;

export const instructions = css`
  margin-top: 10px;
`;
