import React, { useState } from 'react';
import { Modal } from '@weave/design-system';
import { CustomAxios } from '../../redux/axios';
import { put } from 'redux-saga/effects';
import { showError } from '@weave/alert-system';
import Loading from 'react-loading';

interface Props {
  flagName: string;
  close: Function;
}

export const FeatureFlagLocationsModal = (props: Props) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      show={true}
      onClose={() => {
        props.close();
      }}
    >
      <Modal.Header>Locations Enabled</Modal.Header>
      <Modal.Body>
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Loading type={'spin'} color={'blue'} />
          </div>
        )}

        <Modal.Actions
          // @ts-ignore It's the right type, Typescript just doesn't recognize that.
          onPrimaryClick={() => {
            setLoading(true);
            const enabledValues = CustomAxios.get(
              `feature-flags/v1/locations/flags/${props.flagName}?value=true`
            ).then((res) => {
              return res.data.locationIds
                .map((location) => {
                  return location + ',true';
                })
                .join('\n');
            });

            const disabledValues = CustomAxios.get(
              `feature-flags/v1/locations/flags/${props.flagName}?value=false`
            ).then((res) => {
              return res.data.locationIds
                .map((location) => {
                  return location + ',false';
                })
                .join('\n');
            });

            Promise.allSettled([enabledValues, disabledValues]).then((results) => {
              let csvData = '';
              for (let i = 0; i < results.length; i++) {
                if (results[i].status === 'rejected') {
                  put(showError('Error getting locations for flag'));
                  setLoading(false);
                  return;
                }

                csvData += '\n' + (results[i] as PromiseFulfilledResult<any>).value;
              }

              const element = document.createElement('a');
              element.setAttribute(
                'href',
                'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
              );
              element.setAttribute('download', 'locations.csv');
              element.style.display = 'none';
              document.body.append(element);

              element.click();

              document.body.removeChild(element);

              props.close();
            });
          }}
          primaryLabel="Download"
        />
      </Modal.Body>
    </Modal>
  );
};
