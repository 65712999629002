import { createAction } from 'redux-actions';
import { call, put } from 'redux-saga/effects';
import { CustomAxios } from '../../../axios';

export const sentimentTrainingClassifying = createAction(
  'SENTIMENT_TRAINING_CLASSIFYING'
);
export const sentimentTrainingClassifyingSuccess = createAction(
  'SENTIMENT_TRAINING_CLASSIFYING_SUCCESS'
);
export const sentimentTrainingClassifyingFailure = createAction<Error>(
  'SENTIMENT_TRAINING_CLASSIFYING_FAILURE'
);

export const classifyingUrl =
  '/support/v1/sentimenttraining/Appointment/needsclassifying';

export const handleSentimentTrainingClassifying = function* (action) {
  try {
    const response = yield call(CustomAxios.get, classifyingUrl);
    yield put(sentimentTrainingClassifyingSuccess(response.data.data));
  } catch (error: any) {
    yield put(sentimentTrainingClassifyingFailure(error));
  }
};
