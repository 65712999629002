import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { NakedButton, LocationIcon, XIcon } from '@weave/design-system';

import { LocationSearchContainer } from '../../location';
import { LocationHistoryModel } from '../../../models/location-history.model';
import { formatDateFromUnixTimestamp } from '../../../helpers/utils';
import { Loader } from '../../shared/loader/loader.component';
import { MachineLearningLabelerContainer } from '../../machine-learning-labeler-container/machine-learning-labeler.container';
import { LOCATION } from '../../../constants/paths';

interface Props {
  canCreateLocations: boolean;
  history: any;
  locationHistoryList: LocationHistoryModel[];
  loading: boolean;

  loadLocationHistory: () => {};
  removeLocationFromHistory: (location: LocationHistoryModel) => void;
  setLocation: (locationId: string) => void;
}

export class HomeComponent extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.props.loadLocationHistory();
  }

  addLocation = (event: React.MouseEvent) => {
    event.preventDefault();
    this.props.history.push(LOCATION.create);
  };

  deleteHistory = (location: LocationHistoryModel, event: React.MouseEvent) => {
    event.stopPropagation();
    this.props.removeLocationFromHistory(location);
  };

  render() {
    return (
      <div className="container-fluid home">
        <div
          css={css`
            margin: 40px auto;
            width: 400px;
            text-align: center;

            .location-search-box {
              display: inline-block;
              width: 90%;
            }

            .large-icon {
              position: relative;
              top: -3px;
              left: 5px;

              svg {
                max-height: 25px;
                max-width: 25px;
              }
            }
          `}
        >
          <h1>Location Search</h1>
          <LocationSearchContainer />
          {this.props.canCreateLocations && (
            <NakedButton
              css={css`
                svg:hover {
                  transform: scale(1.2);
                }
              `}
              onClick={this.addLocation}
            >
              <LocationIcon color="success" className="large-icon" />
            </NakedButton>
          )}
        </div>
        <hr />
        {this.props.loading ? (
          <Loader />
        ) : (
          <div
            className="history-section"
            css={css`
              text-align: center;
            `}
          >
            <h1
              css={css`
                margin-bottom: ${theme.spacing(2)};
              `}
            >
              Location History
            </h1>
            <span>Click on a row to activate that location</span>
            <table
              className="table table-striped table-bordered table-hover"
              css={css`
                max-width: 800px;
                margin: auto;
                text-align: left;
              `}
            >
              <thead>
                <tr>
                  <th>Location</th>
                  <th>ID / Slug</th>
                  <th>Last Used</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {this.props.locationHistoryList.map((location) => {
                  return (
                    <tr
                      css={css`
                        cursor: pointer;
                      `}
                      key={location.id}
                      onClick={() => this.props.setLocation(location.id)}
                    >
                      <td>{location.name}</td>
                      <td>
                        {location.id} <br /> {location.slug}
                      </td>
                      <td>{formatDateFromUnixTimestamp(location.created)}</td>
                      <td>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <NakedButton
                          css={css`
                            svg:hover {
                              transform: scale(1.2);
                            }
                          `}
                          onClick={(event) => this.deleteHistory(location, event)}
                        >
                          <XIcon color="error" />
                        </NakedButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <MachineLearningLabelerContainer />
      </div>
    );
  }
}
