import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import {
  TrashIcon,
  IconButton,
  Text,
  PrimaryButton,
  ButtonBar,
  SecondaryButton,
  EditIcon,
  Modal,
  DropdownField,
  useFormField,
  useModalControl,
  FormRow,
  CheckboxField,
  useForm,
  DatePickerField,
  ValidatorFieldState,
} from '@weave/design-system';
import { Loader } from '../../shared/loader/loader.component';
import { chunk, cloneDeep, noop } from 'lodash';
import { SuccessSVG } from '../porting-hub/success-svg';
import { portingCardStyles } from './porting-card.styles';
import { Provider } from './constants';
import {
  getUTCDateWithPortingTimeString,
  isHoliday,
  isWeekend,
  USMountainTZ,
} from '../utils/porting-utils';
import { theme } from '@weave/theme-original';
import {
  sendToSalesforce,
  clonePortingData,
  cancelPortOrder,
} from '../../../apis/porting/porting.api';
import { getErrorMessage } from 'redux/axios';
import { PortGetData, PortingData } from 'apis/porting/porting.types';
import { format, differenceInMinutes } from 'date-fns';
import { PortingCardOfficeInfo } from './porting-card-office-info.component';
import { PortingCardAttachments } from './port-card-attachments.component';
import { zonedTimeToUtc } from 'date-fns-tz';
import { PortStatusMapping, PortStatus } from '../utils/port-constants';
import { useAlert } from '@weave/alert-system';
import { MessageDialogue } from '../utils/message-dialogue.component';

type MessageModal = {
  header: string;
  body: string;
  success: boolean;
  actionLabel: string;
  onClose: () => void;
  onActionLabelClick: () => void;
};

interface Props {
  port: PortGetData;
  submitPortingDetails: any;
  downloadLOALoading: boolean;
  splitPortingRequest: number;
  downloadBillLoading: boolean;
  submitPortingLoading: boolean;
  splitPortingRequestsResults: any;
  updatePortingDetailsLoading: boolean;
  deletePortingRequestLoading: boolean;
  back: () => void;
  downloadLOA: (port) => void;
  downloadBill: (port) => void;
  getStatusColor: (port) => string;
  checkPortability: (port) => void;
  setPortingDetails: (port) => void;
  resetPortabilityCheck: () => void;
  updatePortingDetails: (port) => void;
  clearSubmitPortingDetails: () => void;
  deletePortingRequest: (id: string) => void;
  submitSplitPortingRequests: (port) => void;
}

const cancellationTimeDiffInMin = 72 * 60 + 5; // represents minutes in 72h + 5min for safe play

export const PortingCard = (props: Props) => {
  const alert = useAlert();
  const [error, setError] = useState<string>('');
  const [portingInfoEdit, setPortingInfoEdit] = useState<boolean>(false);
  const [phoneNumbersEdit, setPhoneNumbersEdit] = useState<boolean>(false);
  const [officeInfoEdit, setOfficeInfoEdit] = useState<boolean>(false);
  const [canEditAttachments, setCanEditAttachments] = useState<boolean>(false);
  const [portingInfoError, setPortingInfoError] = useState<string>('');
  const [portingRequestToCancel, setPortingRequestToCancel] = useState<any>();
  const [showCancelPortModal, setShowCancelPortModal] = useState<boolean>(false);
  const [showCancelLoading, setShowCancelLoading] = useState<boolean>(false);
  const [portingProviderError, setPortingProviderError] = useState<string>('');
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const {
    modalProps: messageModalProps,
    closeModal,
    triggerProps: triggerModal,
  } = useModalControl();
  const [messagePortModal, setMessagePortModal] = useState<MessageModal>({
    header: '',
    body: '',
    success: true,
    actionLabel: 'Okay',
    onClose: () => {},
    onActionLabelClick: () => {},
  });

  const {
    port,
    downloadLOALoading,
    downloadBillLoading,
    splitPortingRequest,
    submitPortingLoading,
    submitPortingDetails,
    splitPortingRequestsResults,
    deletePortingRequestLoading,
    updatePortingDetailsLoading,
    back,
    downloadLOA,
    getStatusColor,
    checkPortability,
    setPortingDetails,
    updatePortingDetails,
    deletePortingRequest,
    resetPortabilityCheck,
    clearSubmitPortingDetails,
    submitSplitPortingRequests,
  } = props;

  // references Provider map from ./constants and hardcoded to 2 because same values are stored in backend
  const ProviderTelnyx = 2;
  const providerDropdownProps = useFormField({
    type: 'text',
    value: Provider.has(port.provider) ? port.provider?.toString() : '',
  });

  const today = new Date();
  const minDate = format(new Date(), 'MM/dd/yyyy');
  const { getFieldProps } = useForm({
    fields: {
      asapPort: { type: 'checkbox', value: port.request_asap_port },
      requestedPortDate: {
        type: 'datePicker',
        minDate: getUTCDateWithPortingTimeString(today.toISOString()),
        value: port.porting_requests?.[0].requested_firm_order_commitment_date
          ? format(
              new Date(port.porting_requests?.[0].requested_firm_order_commitment_date),
              'MM/dd/yyyy'
            )
          : '',
        validator: ({ value }: ValidatorFieldState<'datePicker'>) =>
          value < minDate ? 'Reqeusted port date cannot be set in the past' : '',
      },
    },
  });

  const requestPortDate = getFieldProps('requestedPortDate').value;

  useEffect(() => {
    const portCopy = cloneDeep(port);
    const valCopy = requestPortDate
      ? zonedTimeToUtc(new Date(requestPortDate), 'US/Mountain')
      : undefined;
    const timeZonedDate = valCopy?.toISOString() ?? '';
    for (const portingRequest of portCopy.porting_requests) {
      portingRequest.requested_firm_order_commitment_date = timeZonedDate;
    }
    setPortingDetails(portCopy);
  }, [requestPortDate]);

  useEffect(() => {
    window.scroll(0, 0);
    return () => {
      clearSubmitPortingDetails();
    };
  }, []);

  const updatePortProvider = () => {
    if (!port.provider) {
      port.provider = 0;
    }
    const portCopy = cloneDeep(port);
    portCopy.provider = Number.parseInt(providerDropdownProps.value);
    portCopy.porting_requests[0].porting_carrier = Provider.get(
      portCopy.provider
    )?.toUpperCase();
    setPortingDetails(portCopy);
    updatePortingDetails(portCopy);
  };

  const submit = () => {
    if (isValidated()) {
      setError('');
      checkPortability(port);
    } else {
      setError(
        'ERROR. Oops! We were unable to submit your port. Please make sure fields are not empty and resubmit.'
      );
    }
  };

  const isValidated = () => {
    return (
      port.porting_requests[0].requested_firm_order_commitment_date &&
      port.company_name &&
      port.authorized_user_first_name &&
      port.phone_service_account_number &&
      port.service_city &&
      port.service_street1 &&
      port.service_zip &&
      port.service_state &&
      port.provider
    );
  };

  const handleSendToSalesforce = async () => {
    setShowLoading(true);
    try {
      await sendToSalesforce(port.id);
      setShowLoading(false);
      setMessagePortModal({
        body: 'Successfully sent to salesforce',
        header: 'Success',
        success: false,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: closeModal,
      });
    } catch (error) {
      console.error(error);
      setShowLoading(false);
      setMessagePortModal({
        header: 'Failed',
        body: getErrorMessage(error),
        success: false,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: closeModal,
      });
    } finally {
      triggerModal.onClick();
    }
  };

  const handleDeletePortingRequest = (requestId) => {
    deletePortingRequest(requestId);
    setPortingRequestToCancel(undefined);
  };

  const handleDrawerToggle = (
    drawer: string,
    toggleState: boolean,
    disableToggle = false
  ) => {
    switch (drawer) {
      case 'porting':
        setPortingInfoEdit(!toggleState);
        break;
      case 'phone':
        setPhoneNumbersEdit(!toggleState);
        break;
      case 'office':
        setOfficeInfoEdit(!toggleState);
        break;
      case 'attachments':
        if (!disableToggle) setCanEditAttachments(!toggleState);
        else {
          alert.warning('Please complete the form and save.');
        }
        break;
      default:
        break;
    }
  };

  const portingRequest = port.porting_requests[0];
  const chunkedPhoneNumbers: any = chunk(port.porting_requests, 2);
  const invalidCancellationPortStatuses = [
    PortStatus.cancelled,
    PortStatus.cancelPending,
    PortStatus.cancelSubmitted,
    PortStatus.requestedCancel,
    PortStatus.completed,
    PortStatus.splitPort,
    PortStatus.activationInProgress,
  ];

  const allowPortOrderCancellation = (): boolean => {
    if (portingRequest.firm_order_commitment_date) {
      const acceptedFOCDate = zonedTimeToUtc(
        new Date(portingRequest.firm_order_commitment_date),
        USMountainTZ
      );
      const currentDate = zonedTimeToUtc(new Date(), USMountainTZ);

      return (
        differenceInMinutes(acceptedFOCDate, currentDate, { roundingMethod: 'ceil' }) >=
        cancellationTimeDiffInMin
      );
    }

    return true;
  };

  const showPortOrderCancelButton = (): boolean => {
    if (portingRequest.porting_status) {
      const portStatus = portingRequest.porting_status;

      if (invalidCancellationPortStatuses.includes(portStatus)) {
        return false;
      }
    }

    return allowPortOrderCancellation();
  };

  const handleCancelClick = () => {
    if (!allowPortOrderCancellation()) {
      setMessagePortModal({
        body: 'Confirmed FOC date is less than 72 hours away.',
        header: 'Approval Needed',
        success: false,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: closeModal,
      });
      triggerModal.onClick();
      return;
    }
    setShowCancelPortModal(true);
  };

  const cancelPort = async () => {
    setShowCancelLoading(true);
    const successMsg =
      portingRequest.port_order_number &&
      !portingRequest.port_order_number.includes('Cancelled')
        ? 'Requested cancellation of port order'
        : 'Port order cancelled';

    try {
      await cancelPortOrder(port.id);
      setMessagePortModal({
        body: successMsg,
        header: 'Success',
        success: true,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: () => {
          closeModal();
          back();
        },
      });
    } catch (error) {
      console.error(error);
      setMessagePortModal({
        header: 'Failed',
        body: getErrorMessage(error),
        success: false,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: closeModal,
      });
    } finally {
      setShowCancelLoading(false);
      setShowCancelPortModal(false);
      triggerModal.onClick();
    }
  };

  const handleClonePortingData = async () => {
    setShowLoading(true);
    try {
      const clonedPortingData: PortingData = await clonePortingData(port.id);
      setMessagePortModal({
        body: 'Port order cloned. Open cloned port order',
        header: 'Success',
        success: true,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: (): void => {
          closeModal();
          setPortingDetails(clonedPortingData);
        },
      });
    } catch (error) {
      console.error(error);
      setMessagePortModal({
        header: 'Failed',
        body: getErrorMessage(error),
        success: false,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: closeModal,
      });
    } finally {
      setShowLoading(false);
      triggerModal.onClick();
    }
  };

  const handleUploadLoaClick = (order_id: string) => {
    downloadLOA(port);
    const url = `https://dashboard.bandwidth.com/portal/report/#orderdetail:%7B"a":5001811,"t":"PORT_IN","o":"${order_id}"%7D`;
    window.open(url, '_blank');
  };

  return (
    <>
      {updatePortingDetailsLoading && (
        <div className={portingCardStyles.loading}>
          <Loader />
        </div>
      )}
      <div>
        <div className={portingCardStyles.backButtonStyle} onClick={back}>
          {'< back'}
        </div>

        <div className={portingCardStyles.portOrderTitle}>
          Port Order: {portingRequest.port_order_number}
        </div>
        <div className={portingCardStyles.portingHubText}>
          View, edit, or submit information for this port order below
        </div>
        <div className={portingCardStyles.errorMessage}>{error}</div>
        <div className={portingCardStyles.container}>
          <div className={portingCardStyles.backgroundStyle}>
            <div className={portingCardStyles.flexCenter}>
              <div
                css={css`
                  margin-right: 15px;
                `}
              >
                <div
                  css={css`
                    padding: ${theme.spacing(1.5)};
                    border: 1px solid ${getStatusColor(portingRequest)};
                    background-color: ${getStatusColor(portingRequest)};
                    border-radius: 50%;
                  `}
                />
              </div>
              <div
                css={css`
                  width: 100%;
                `}
              >
                <div className={portingCardStyles.flexContainer}>
                  <div>
                    {portingRequest.porting_status
                      ? PortStatusMapping[portingRequest.porting_status]
                      : 'Draft'}
                  </div>
                  <div>
                    Requested Port Date:{' '}
                    {portingRequest.requested_firm_order_commitment_date &&
                      format(
                        new Date(portingRequest.requested_firm_order_commitment_date),
                        'MM/dd/yy'
                      )}
                  </div>
                </div>

                <div className={portingCardStyles.portOrderType}>
                  <div>Port Order # {portingRequest.port_order_number}</div>
                  <div>{portingRequest.number_type === 3 ? 'Voice/SMS' : 'Fax'} Port</div>
                </div>

                <div
                  css={css`
                    text-align: right;
                    margin-top: 5px;
                  `}
                >
                  <Text>
                    Created Date:{' '}
                    {portingRequest.created_at &&
                      format(new Date(portingRequest.created_at), 'MM/dd/yy')}
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div
            css={css`
              padding: 25px;
            `}
          >
            <div className={portingCardStyles.portingInfoContainer}>
              <div className={portingCardStyles.title}>Porting Information</div>
              {!portingRequest.port_order_number && (
                <div>
                  <EditIcon
                    css={css`
                      color: ${!portingInfoEdit && theme.colors.weaveBlue};
                    `}
                    onClick={() => handleDrawerToggle('porting', portingInfoEdit)}
                  />
                </div>
              )}
            </div>

            <div className={portingCardStyles.requestedPortDate}>
              <Text
                css={css`
                  ${portingInfoEdit ? 'margin-bottom: 12px;' : 'margin-bottom: 0px;'};
                `}
              >
                Porting Data Id
              </Text>
              <Text
                css={css`
                  color: ${theme.colors.gray500};
                  ${portingInfoEdit ? 'margin-bottom: 12px;' : 'margin-bottom: 0px;'};
                `}
              >
                {port.id}
              </Text>

              <div>Request Client</div>
              <div
                css={css`
                  color: ${theme.colors.gray500};
                `}
              >
                {port.request_client}
              </div>

              {!!port.request_asap_port && (
                <>
                  <div>ASAP Port</div>
                  <FormRow
                    css={css`
                      margin-bottom: 0;
                    `}
                  >
                    <CheckboxField
                      {...getFieldProps('asapPort')}
                      name="asapPort"
                      label={
                        <Text
                          css={css`
                            margin-bottom: 0;
                            color: ${theme.colors.gray500};
                          `}
                        >
                          Request ASAP Port
                        </Text>
                      }
                      disabled
                    />
                  </FormRow>
                </>
              )}

              <div>Requested Port Date</div>
              <div
                css={css`
                  color: ${theme.colors.gray500};
                `}
              >
                {portingInfoEdit && (
                  <FormRow>
                    <DatePickerField
                      {...getFieldProps('requestedPortDate')}
                      name="requestedPortDate"
                      label="Port Date"
                    />
                  </FormRow>
                )}
                {portingRequest.requested_firm_order_commitment_date &&
                  !portingInfoEdit &&
                  format(
                    new Date(portingRequest.requested_firm_order_commitment_date),
                    'MM/dd/yy'
                  )}
              </div>
              <div>Porting Company</div>
              {portingInfoEdit ? (
                <DropdownField
                  {...providerDropdownProps}
                  label="Provider"
                  name="provider"
                  css={css`
                    width: 200px;
                  `}
                >
                  <DropdownField.Option value="1">{Provider.get(1)}</DropdownField.Option>
                  <DropdownField.Option value="2">{Provider.get(2)}</DropdownField.Option>
                </DropdownField>
              ) : (
                <div
                  css={css`
                    color: ${theme.colors.gray500};
                  `}
                >
                  {port.provider !== 0 &&
                    Provider.has(port.provider) &&
                    Provider.get(port.provider)}
                </div>
              )}
              <div>Porting Order URL</div>
              <div
                css={css`
                  color: ${theme.colors.gray500};
                `}
              >
                {port.port_order_url}
              </div>
              {portingInfoEdit && (
                <div
                  css={css`
                    width: 200px;
                    margin: 10px auto;
                  `}
                >
                  <PrimaryButton
                    className={portingCardStyles.savePortCont}
                    color="blue"
                    size="large"
                    onClick={() => {
                      if (
                        !!portingRequest.requested_firm_order_commitment_date &&
                        (isHoliday(portingRequest.requested_firm_order_commitment_date) ||
                          isWeekend(portingRequest.requested_firm_order_commitment_date))
                      ) {
                        setPortingInfoError(
                          'Date cannot be a weekend or a major holiday.'
                        );
                      } else if (
                        !providerDropdownProps.value ||
                        providerDropdownProps.value === '0'
                      ) {
                        setPortingProviderError('Porting company needs to be selected.');
                      } else {
                        setPortingInfoError('');
                        setPortingProviderError('');
                        setPortingInfoEdit(true);
                        updatePortProvider();
                      }
                    }}
                  >
                    Save
                  </PrimaryButton>
                  {portingInfoError && (
                    <div
                      css={css`
                        margin-top: 10px;
                        color: red;
                      `}
                    >
                      {portingInfoError}
                    </div>
                  )}
                  {!!portingProviderError && (
                    <Text
                      css={css`
                        margin-top: 10px;
                      `}
                      color="error"
                    >
                      {portingProviderError}
                    </Text>
                  )}
                </div>
              )}
            </div>

            <div className={portingCardStyles.portingInfoContainer}>
              <div
                css={css`
                  font-weight: bold;
                  font-size: 16px;
                `}
              >
                Phone Numbers
              </div>
              {!portingRequest.port_order_number && (
                <div>
                  <EditIcon
                    css={css`
                      color: ${!phoneNumbersEdit && theme.colors.weaveBlue};
                    `}
                    onClick={() => handleDrawerToggle('phone', phoneNumbersEdit)}
                  />
                </div>
              )}
            </div>

            <div className={portingCardStyles.dids}>
              <div
                css={css`
                  display: flex;
                `}
              >
                <div
                  css={css`
                    width: 250px;
                    margin-bottom: 10px;
                  `}
                >
                  DID(s)
                </div>
                <div>
                  {chunkedPhoneNumbers.map((phoneNumbers, ind) => {
                    if (phoneNumbersEdit) {
                      return (
                        <div key={ind} className={portingCardStyles.phoneCont}>
                          {phoneNumbers.map((request, index) => {
                            return (
                              <div
                                key={index}
                                css={css`
                                  display: flex;
                                `}
                              >
                                <div>{request.phone_number}</div>
                                <IconButton
                                  label="Remove Phone Number"
                                  hoverLabelPlacement="top"
                                  showLabelOnHover
                                  css={css`
                                    height: 24px;
                                    margin-left: 8px;
                                    width: 24px;

                                    svg {
                                      fill: #ff6b59;
                                    }
                                  `}
                                >
                                  <TrashIcon
                                    color="error"
                                    onClick={() => setPortingRequestToCancel(request)}
                                  />
                                </IconButton>
                              </div>
                            );
                          })}
                        </div>
                      );
                    }
                    return (
                      <div key={ind} className={portingCardStyles.phoneCont}>
                        {phoneNumbers.map((request, index) => {
                          return <div key={index}>{request.phone_number}</div>;
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <PortingCardOfficeInfo
              port={port}
              officeInfoEdit={officeInfoEdit}
              setOfficeInfoEdit={setOfficeInfoEdit}
              handleDrawerToggle={handleDrawerToggle}
              setPortingDetails={setPortingDetails}
              updatePortingDetails={updatePortingDetails}
            />

            <PortingCardAttachments
              port={port}
              canEditAttachments={canEditAttachments}
              setCanEditAttachments={setCanEditAttachments}
              handleDrawerToggle={handleDrawerToggle}
              updatePortingDetails={updatePortingDetails}
            />

            {!portingRequest.port_order_number && (
              <div className={portingCardStyles.nextStepContainer}>
                <div className={portingCardStyles.title}>Next Steps</div>
              </div>
            )}

            {!portingRequest.port_order_number && (
              <div className={portingCardStyles.whatisnext}>
                <div>1. Review port information</div>
                <div>2. Submit port request</div>
              </div>
            )}

            <div className={portingCardStyles.errorText}>{error}</div>
            <div className={portingCardStyles.buttonContainers}>
              {!portingRequest.port_order_number && (
                <>
                  <PrimaryButton
                    className={portingCardStyles.submitButtonStyle}
                    disabled={portingInfoEdit || officeInfoEdit || phoneNumbersEdit}
                    color="blue"
                    size="large"
                    onClick={submit}
                    css={css`
                      width: 250px;
                    `}
                  >
                    Submit
                  </PrimaryButton>

                  <PrimaryButton
                    className={portingCardStyles.submitButtonStyle}
                    disabled={portingInfoEdit || officeInfoEdit || phoneNumbersEdit}
                    color="blue"
                    size="large"
                    css={css`
                      width: 250px;
                    `}
                    onClick={handleSendToSalesforce}
                  >
                    Send to Salesforce
                  </PrimaryButton>
                </>
              )}
              <div className={portingCardStyles.buttonContainersDownload}>
                {showPortOrderCancelButton() && (
                  <div
                    className={portingCardStyles.downloadLoaButton}
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </div>
                )}

                <div
                  className={portingCardStyles.downloadLoaButton}
                  onClick={handleClonePortingData}
                >
                  Clone
                </div>
              </div>
            </div>
          </div>
        </div>

        <MessageDialogue
          header={messagePortModal.header}
          body={messagePortModal.body}
          actionLabel={messagePortModal.actionLabel}
          messageModalProps={messageModalProps}
          closeMessageModal={messagePortModal.onClose}
          onActionLabelClick={messagePortModal.onActionLabelClick}
        />

        <Modal
          onClose={noop}
          show={
            downloadLOALoading ||
            downloadBillLoading ||
            submitPortingLoading ||
            showLoading ||
            false
          }
          className={portingCardStyles.divWidth}
        >
          <div>
            <div className={portingCardStyles.loading}>
              <Loader size="large" />
            </div>
            <div className={portingCardStyles.modalStyle}>
              {downloadLOALoading || downloadBillLoading
                ? 'Downloading...'
                : 'Submitting...'}
            </div>
          </div>
        </Modal>

        <Modal
          onClose={() => resetPortabilityCheck()}
          css={css`
            padding: ${theme.spacing(2)};
          `}
          show={splitPortingRequest !== -1}
          className={portingCardStyles.divWidth}
        >
          <div>
            <div className={portingCardStyles.lnpText}>
              Due to the complexities of porting, this port request needs to be split.
            </div>

            <div className={portingCardStyles.lnpText}>
              It will be split into {splitPortingRequest} porting requests.
            </div>
            <div className={portingCardStyles.loading}>
              <SecondaryButton
                className={portingCardStyles.lnpButton}
                color="gray"
                onClick={() => resetPortabilityCheck()}
              >
                Cancel
              </SecondaryButton>

              <PrimaryButton
                className={portingCardStyles.lnpButton}
                color="blue"
                onClick={() => submitSplitPortingRequests(port)}
              >
                Split & Submit All
              </PrimaryButton>
            </div>
          </div>
        </Modal>

        <Modal
          onClose={() => {
            resetPortabilityCheck();
            back();
          }}
          css={css`
            padding: ${theme.spacing(2)};
          `}
          show={splitPortingRequestsResults.length > 0}
          className={portingCardStyles.divWidth}
        >
          <div>
            <div className={portingCardStyles.portingResultsTitle}>Porting Results</div>
            {splitPortingRequestsResults.map((result, index) => {
              if (result.status.has_error) {
                return (
                  <div
                    className={`${portingCardStyles.splitError} ${
                      index % 2 === 1 ? portingCardStyles.gray : portingCardStyles.white
                    }`}
                  >
                    <div
                      css={css`
                        margin-right: 20px;
                        color: red;
                      `}
                    >
                      Failed
                    </div>
                    <div
                      className={portingCardStyles.uploadLoaButtonSplitFail}
                      onClick={() => {
                        back();
                        resetPortabilityCheck();
                        setPortingDetails(result.port);
                      }}
                    >
                      View Port Order
                    </div>
                    <ul>
                      {result.status.errors.map((error, index) => (
                        <li key={index}>{error.description}</li>
                      ))}
                    </ul>
                  </div>
                );
              } else {
                return (
                  <div
                    className={`${portingCardStyles.splitSuccess} ${
                      index % 2 === 1 ? portingCardStyles.gray : portingCardStyles.white
                    }`}
                  >
                    <div
                      css={css`
                        margin-right: 20px;
                      `}
                    >
                      Success
                    </div>
                    <div
                      className={portingCardStyles.uploadLoaButtonSplit}
                      onClick={() => {
                        back();
                        resetPortabilityCheck();
                        setPortingDetails(result.port);
                      }}
                    >
                      View Port Order
                    </div>

                    {port.provider !== ProviderTelnyx &&
                      Provider.get(ProviderTelnyx) === 'Telnyx' && (
                        <div
                          className={portingCardStyles.uploadLoaButtonSplit}
                          onClick={() => handleUploadLoaClick(result.status.order_id)}
                        >
                          Upload LOA
                        </div>
                      )}
                  </div>
                );
              }
            })}

            <PrimaryButton
              className={portingCardStyles.gotItButton}
              color="blue"
              size="large"
              onClick={() => {
                resetPortabilityCheck();
                back();
              }}
            >
              Got it
            </PrimaryButton>
          </div>
        </Modal>

        <Modal
          css={css`
            width: 450px;
            text-align: center;
            padding: ${theme.spacing(2)};
          `}
          show={!!portingRequestToCancel || deletePortingRequestLoading}
          onClose={() => setPortingRequestToCancel(undefined)}
        >
          {!deletePortingRequestLoading ? (
            <div>
              <h1
                css={css`
                  text-align: center;
                  margin-bottom: 16px;
                `}
              >
                Are you sure you want to remove the following phone number from this port
                request?
              </h1>
              {portingRequestToCancel && (
                <Text>{portingRequestToCancel.phone_number}</Text>
              )}
              <PrimaryButton
                onClick={() => handleDeletePortingRequest(portingRequestToCancel.id)}
              >
                Confirm
              </PrimaryButton>
            </div>
          ) : (
            <div
              css={css`
                padding: 100px;
              `}
            >
              <Loader />
            </div>
          )}
        </Modal>

        <Modal
          css={css`
            padding: ${theme.spacing(2)};
          `}
          show={showCancelPortModal}
          onClose={() => setShowCancelPortModal(false)}
        >
          {!showCancelLoading ? (
            <div>
              <h1
                css={css`
                  text-align: center;
                  margin-bottom: 16px;
                `}
              >
                Are you sure you want to cancel?
              </h1>
              <ButtonBar>
                <PrimaryButton onClick={() => cancelPort()}>Yes, cancel.</PrimaryButton>

                <SecondaryButton onClick={() => setShowCancelPortModal(false)}>
                  No, do not cancel.
                </SecondaryButton>
              </ButtonBar>
            </div>
          ) : (
            <div
              css={css`
                padding: 20px;
              `}
            >
              <div className={portingCardStyles.loading}>
                <Loader size="large" />
              </div>
              <Text
                css={css`
                  font-weight: bold;
                  font-size: 24px;
                  text-align: center;
                  margin-top: 20px;
                `}
              >
                Cancelling...
              </Text>
            </div>
          )}
        </Modal>

        <Modal
          onClose={() => {
            clearSubmitPortingDetails();
            if (submitPortingDetails && !submitPortingDetails.has_error) {
              back();
            }
          }}
          show={!!submitPortingDetails || false}
          css={css`
            padding: ${theme.spacing(2)};
          `}
          className={portingCardStyles.divWidth}
        >
          <div>
            {submitPortingDetails && submitPortingDetails.has_error && (
              <div>
                <div className={portingCardStyles.fixErrorReason}>
                  Porting request failed for the following reasons:
                </div>
                {submitPortingDetails.errors.map((error, index) => (
                  <div key={index}>{error.description}</div>
                ))}
                <div className={portingCardStyles.fixErrorTitle}>
                  Fix these errors and resubmit.
                </div>
                <PrimaryButton
                  className={portingCardStyles.errorOkayButton}
                  color="blue"
                  size="large"
                  onClick={() => clearSubmitPortingDetails()}
                >
                  Okay
                </PrimaryButton>
              </div>
            )}
            {submitPortingDetails && !submitPortingDetails.has_error && (
              <div>
                <div className={portingCardStyles.loading}>
                  <SuccessSVG />
                </div>
                <div className={portingCardStyles.successSubmit}>
                  Port Successfully Submitted!
                </div>
                {port.provider !== ProviderTelnyx &&
                  Provider.get(ProviderTelnyx) === 'Telnyx' && (
                    <div
                      className={portingCardStyles.uploadLoaButton}
                      onClick={() => handleUploadLoaClick(submitPortingDetails.order_id)}
                    >
                      Upload LOA
                    </div>
                  )}
                <PrimaryButton
                  className={portingCardStyles.gotItButton}
                  color="blue"
                  size="large"
                  onClick={() => {
                    clearSubmitPortingDetails();
                    back();
                  }}
                >
                  Got it
                </PrimaryButton>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};
