import { handleActions } from 'redux-actions';
import {
  getNetworkAudits,
  getNetworkAuditsFailure,
  getNetworkAuditsSuccess,
} from './network-audits.action';

export type NetworkAuditsState = {
  audits: any[];
  loading: boolean;
};

const initialState: NetworkAuditsState = {
  audits: [],
  loading: false,
};

export const networkAuditsReducer = handleActions(
  {
    [getNetworkAudits.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [getNetworkAuditsSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { audits: action.payload, loading: false }),
    [getNetworkAuditsFailure.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, audits: [] }),
  },
  initialState
);
