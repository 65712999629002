import React from 'react';
import { css } from 'emotion';
import { Alert, Button, Col, Fade, FormGroup, Input, Label } from 'reactstrap';
import { Loader } from '../../shared/loader/loader.component';
import { LocationFeature } from '../../../models/location-feature.model';
import { Typeahead } from 'react-bootstrap-typeahead';
import { VerticalEnum } from '../../../models/vertical-enum';

export type OnboardingAssignOnboarderProps = {
  scheduleCustomizationLink: string;
  scheduleNetworkLink: string;
  scheduleSoftwareTrainingLink: string;
  schedulePhoneTrainingLink: string;
  onboarders: any[];
  getOnboardersLoading: boolean;
  getOnboarderSchedulingLinks: (user) => void;
  getOnboarders: () => void;
  saveOnboarderSchedulingLinks: (
    locationId,
    userId,
    schedulingLinks,
    saveOnboarderSchedulingLinks
  ) => void;
  userId: string;
  locationId: string;
  customizations: LocationFeature[];
  verticalId: VerticalEnum;
  syncApp: any[];
  showChangeOnboarder: boolean;
  changeOnboarder: () => void;
};

export type OnboardingAssignOnboarderState = {
  selectedOnboarder: {
    scheduleCustomizationLink: string;
    scheduleNetworkLink: string;
    userID: string;
    salesforceUserID: string;
    name: string;
  };
  error: string;
  loading: boolean;
  installScheduled: boolean;
};

const installReady = css`
  margin-left: 13px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const typeaheadStyles = css`
  .rbt-input-hint {
    display: none;
  }
  input {
    height: 40px;
    width: 100%;
    border-radius: 5px;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-size: 14px;
      color: #ced4d6;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      font-size: 14px;
      color: #ced4d6;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      font-size: 14px;
      color: #ced4d6;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      font-size: 14px;
      color: #ced4d6;
    }
  }
`;

const installReadyLabelSpace = css`
  margin-left: 15px;
`;

const container = css`
  min-height: 900px;
  width: 325px;
`;

const alertStyle = css`
  text-align: center;
  margin-right: 6px;
  margin-bottom: 20px;
`;

const buttonContainer = css`
  display: flex;
  justify-content: flex-end;
  margin-right: 0px;
`;

const buttonStyle = css`
  margin-top: 20px;
`;

export class OnboardingAssignOnboarder extends React.Component<
  OnboardingAssignOnboarderProps,
  OnboardingAssignOnboarderState
> {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      loading: false,
      installScheduled: false,
      selectedOnboarder: {
        scheduleCustomizationLink: '',
        scheduleNetworkLink: '',
        userID: '',
        salesforceUserID: '',
        name: '',
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getOnboarders();
  }

  UNSAFE_componentWillReceiveProps(nextProps: OnboardingAssignOnboarderProps) {
    if (this.props.getOnboardersLoading && !nextProps.getOnboardersLoading) {
      for (const onboarder of nextProps.onboarders) {
        if (nextProps.userId === onboarder.userID) {
          this.setState({
            selectedOnboarder: {
              scheduleCustomizationLink: onboarder.scheduleCustomizationLink,
              scheduleNetworkLink: onboarder.scheduleNetworkLink,
              userID: nextProps.userId,
              salesforceUserID: onboarder.salesforceUserID,
              name: onboarder.name || '',
            },
            error: '',
          });
        }
      }
    }
  }

  getSoftwareTrainingLink = () => {
    let softwareTrainingLink = 'https://www.weavehelp.com/weave';

    if (this.props.verticalId === VerticalEnum.Dental) {
      softwareTrainingLink = 'https://www.weavehelp.com/dental-software-training';
    } else if (this.props.verticalId === VerticalEnum.Optometry) {
      softwareTrainingLink = 'https://www.weavehelp.com/optometry-software-training';
    } else if (this.props.verticalId === VerticalEnum.Wellness) {
      softwareTrainingLink =
        'https://www.weavehelp.com/healthandbeauty-software-training';
    }

    for (const customization of this.props.customizations) {
      if (
        customization.id === '2fc86faa-4062-40be-b4da-d6cafa982a81' &&
        customization.state === 'hide'
      ) {
        softwareTrainingLink = 'https://www.weavehelp.com/softwareonly-training';
        break;
      }
    }

    for (const customization of this.props.customizations) {
      if (
        customization.id === 'ff764a4b-31a0-482d-b0d2-72b4dad92384' &&
        customization.state === 'hide'
      ) {
        softwareTrainingLink = 'https://www.weavehelp.com/software-training-nc/';
        break;
      }
    }

    for (const syncApp of this.props.syncApp) {
      if (
        syncApp.PracticeManagementSystem === '-cloud9' ||
        syncApp.PracticeManagementSystem === 'Ortho2' ||
        syncApp.PracticeManagementSystem === '-advancedmd' ||
        syncApp.PracticeManagementSystem === 'Oasys'
      ) {
        softwareTrainingLink = 'https://www.weavehelp.com/software-training-b';
      }
    }

    return softwareTrainingLink;
  };

  handleAssign = (e) => {
    const selectedOnboarder = this.state.selectedOnboarder;

    e.preventDefault();
    if (!selectedOnboarder) {
      this.setState(() => {
        return {
          error: 'Onboarder required.',
        };
      });
    } else {
      this.setState(() => {
        return {
          error: '',
          loading: true,
        };
      });
      if (this.props.showChangeOnboarder) {
        this.props.changeOnboarder();
      }
      const links = {
        scheduleCustomizationLink: selectedOnboarder.scheduleCustomizationLink ?? '',
        scheduleNetworkLink: selectedOnboarder.scheduleNetworkLink ?? '',
        scheduleSoftwareTrainingLink: this.getSoftwareTrainingLink(),
        schedulePhoneTrainingLink: 'https://www.weavehelp.com/phone-training-videos',
        salesforceUserID: selectedOnboarder.salesforceUserID,
      };

      const userId = selectedOnboarder.userID || this.props.userId;
      this.props.saveOnboarderSchedulingLinks(
        this.props.locationId,
        userId,
        links,
        this.state.installScheduled
      );
    }
  };

  installScheduleChanged = (event) => {
    this.setState({ installScheduled: event.target.checked });
  };

  selectOnboarder = (val) => {
    if (val.length > 0) {
      const selected = val[0];
      for (const onboarder of this.props.onboarders) {
        if (onboarder.userID === selected.userID) {
          this.setState({
            selectedOnboarder: {
              scheduleCustomizationLink: onboarder.scheduleCustomizationLink,
              scheduleNetworkLink: onboarder.scheduleNetworkLink,
              userID: selected.userID,
              salesforceUserID: onboarder.salesforceUserID,
              name: onboarder.name || '',
            },
            error: '',
          });
        }
      }
    }
  };

  getSelectedOnboarder = () => {
    if (this.state.selectedOnboarder.userID) {
      return [this.state.selectedOnboarder];
    }
    return [];
  };

  render() {
    if (this.props.getOnboardersLoading) {
      return <Loader />;
    }
    return (
      <div className={container}>
        {!this.props.showChangeOnboarder && (
          <Alert color="warning" className={alertStyle}>
            This location does not have an onboarder assigned.
          </Alert>
        )}
        <Fade in>
          <form onSubmit={this.handleAssign}>
            <FormGroup row>
              <Col sm={12}>
                <Label for="onboarderLink">Choose the Onboarder</Label>
                <Typeahead
                  id="selectOnboarderId"
                  className={typeaheadStyles}
                  options={this.props.onboarders || []}
                  labelKey="name"
                  selected={this.getSelectedOnboarder()}
                  placeholder="Select onboarder"
                  highlightOnlyResult
                  onChange={(selected) => {
                    this.selectOnboarder(selected);
                  }}
                />
              </Col>
            </FormGroup>

            <FormGroup check row>
              <Label check for="alreadyScheduled" className={installReady}>
                <Input
                  type="checkbox"
                  checked={this.state.installScheduled}
                  onChange={this.installScheduleChanged}
                />
                <span className={installReadyLabelSpace}>Installs scheduled already</span>
              </Label>
            </FormGroup>
            <FormGroup row className={buttonContainer}>
              {this.props.showChangeOnboarder && (
                <Button
                  className={buttonStyle}
                  color="info"
                  size="sm"
                  onClick={() => {
                    this.props.changeOnboarder();
                  }}
                >
                  Cancel
                </Button>
              )}

              <Button
                className={buttonStyle}
                disabled={this.state.loading}
                color="info"
                size="sm"
              >
                {!this.state.loading ? 'Assign Onboarder' : <Loader />}
              </Button>
            </FormGroup>
          </form>
        </Fade>
        {this.state.error ? (
          <Alert
            color="danger"
            className={css`
              margin-bottom: 40px;
            `}
          >
            {this.state.error}
          </Alert>
        ) : null}
      </div>
    );
  }
}
