import React from 'react';
import { cx } from 'emotion';

import { KeyFactor } from '../../handoff-hub.types';
import {
  messagesDiv,
  messagesTitle,
  tableStyles,
  redText,
} from './factor-messages.styles';
import { FactorStatus } from '../../../../redux/actions/handoff-hub/handoff-hub.reducer';
import { Heading } from '@weave/design-system';

type FactorMessagesProps = {
  factor?: KeyFactor;
  getStatusIcon: (status: FactorStatus) => JSX.Element;
};

export const FactorMessages = (props: FactorMessagesProps) => {
  const { factor, getStatusIcon } = props;

  return (
    <div className={messagesDiv}>
      <Heading textAlign="center" className={messagesTitle}>
        {factor && factor.factorName} Test Criteria
      </Heading>
      <table className={tableStyles}>
        <thead>
          <tr>
            <th />
            <th>Test Criteria</th>
            <th>Actual</th>
          </tr>
        </thead>
        <tbody>
          {factor &&
            factor.tests.map((test, i) => {
              return (
                <tr key={i} className={cx(test.status === 'fail' ? redText : '')}>
                  <td>{getStatusIcon(test.status)}</td>
                  <td>{test.name}</td>
                  <td>{test.actual}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
