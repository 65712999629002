import { useEffect, useState } from 'react';
import { CustomAxios } from '../../../../redux/axios';
import { useAlert } from '@weave/alert-system';
import { REACT_APP_API_URL } from '../../../../config/app';

const validateAndParseJson = (text) => {
  try {
    return JSON.parse(text);
  } catch {
    return text;
  }
};

const clientURL = `${REACT_APP_API_URL}client-api/settings`;

export const useClientMapping = (formatForDisplay, keys) => {
  const [loading, setLoading] = useState(false);
  const alerts = useAlert();

  const formatSetting = (data) => {
    let value = validateAndParseJson(data);
    if (!value) {
      value = validateAndParseJson(data.replace(/'/g, '"'));
    }
    if (typeof value === 'boolean') {
      return value;
    }
    return value || {};
  };

  const formatString = (data) => {
    if (data) {
      return formatSetting(data);
    }
    return '';
  };

  const refresh = () => {
    let tmpMappingData = {};
    setLoading(true);
    //@ts-ignore
    Promise.allSettled(
      keys.map((key) => {
        return CustomAxios.get(`${clientURL}?key=${key}`).then((res) => {
          try {
            const data =
              key !== 'automated'
                ? formatSetting(res.data[0].value)
                : formatString(res.data[0].value);
            tmpMappingData = {
              ...tmpMappingData,
              [key]: data,
            };
          } catch {
            tmpMappingData = { ...tmpMappingData };
          }
        });
      })
    )
      .then(() => {
        setLoading(false);
        formatForDisplay(tmpMappingData);
      })
      .catch(() => {
        setLoading(false);
        tmpMappingData = { ...tmpMappingData };
        formatForDisplay(tmpMappingData);
        alerts.error(`Couldn't load all settings`);
      });
  };

  useEffect(() => refresh(), []);

  const saveClientMapping = (key, val, set = 'adapter') => {
    let value = JSON.stringify(val);
    if (set === 'email') {
      value = val;
    }
    const data = { set, key, value };
    CustomAxios.put(clientURL, data)
      .then(refresh)
      .catch((err) => console.error(err));
  };

  return { saveClientMapping, loading, refresh };
};
