import React from 'react';
import { useDropzone } from 'react-dropzone';
import { WeaveTheme } from '@weave/theme-original';
import { css } from '@emotion/core';
import { Text, TextLink } from '@weave/design-system';

interface Props {
  onFileDrop: (acceptedFiles: File[]) => void;
  canUploadMutipleFiles?: boolean;
}

const sectionStyles = (theme: WeaveTheme) => css`
  max-width: 100%;
`;

const fileUploaderStyles = (theme: WeaveTheme, isDragging) => css`
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  height: 100px;
  border-radius: ${theme.borderRadius.small};
  border: dashed ${!isDragging ? theme.colors.gray[400] : theme.colors.weaveBlue} 2px;
  background-color: ${theme.colors.gray[100]};
  cursor: pointer;
  display: block;
  img {
    width: 100%;
  }
`;

export const PortOrderUploadComponent = ({
  onFileDrop,
  canUploadMutipleFiles = false,
}: Props) => {
  const { getInputProps, getRootProps, open, isDragActive } = useDropzone({
    accept: '.pdf',
    noClick: true,
    noKeyboard: true,
    onDrop: onFileDrop,
    preventDropOnDocument: true,
    noDragEventsBubbling: true,
    maxSize: 3e6,
    multiple: canUploadMutipleFiles,
  });

  return (
    <section css={(theme) => sectionStyles(theme)}>
      <input
        {...getInputProps()}
        data-trackingid="insys-portal-phoneBillUploadStep-input-phoneBillUpload"
      />
      <div
        {...getRootProps()}
        css={() => css`
          width: 70%;
          position: relative;
        `}
      >
        <div css={(theme) => fileUploaderStyles(theme, isDragActive)}>
          <Text textAlign="center" size="medium">
            Drag file here or{' '}
            <TextLink
              onClick={open}
              data-trackingid="insys-portal-phoneBillUploadStep-link-phoneBillUpload"
            >
              click to upload
            </TextLink>{' '}
            <br />
            (PDF)
          </Text>
          <div
            css={(theme) => css`
              margin-top: ${theme.spacing(2)};
            `}
          >
            <Text textAlign="center" size="small">
              Note: File must be no larger than 3MB.
            </Text>
          </div>
        </div>
      </div>
    </section>
  );
};
