export type PortingRequest = {
  phone_number: string;
  port_type: 0;
  number_type: 0 | 1 | 2 | 3;
  requested_firm_order_commitment_date: string;
  id?: string;
  accepted_date?: string;
  carrier_history?: string;
  carrier_notes?: string;
  carrier_provisioned_date?: string;
  carrier_provisioned_date_set_by?: string;
  cnam_provisioned_by?: string;
  cnam_provisioned_date?: string;
  created_at?: string;
  desired_cnam?: string;
  emergency_desired?: string;
  emergency_provisioned_by?: string;
  emergency_provisioned_date?: string;
  firm_order_commitment_date?: string;
  pending_by?: string;
  pending_date?: string;
  phone_data_service_provisioned_date?: string;
  phone_data_service_provisioned_date_set_by?: string;
  port_order_number?: string;
  port_status?: string;
  porting_carrier?: string;
  porting_data_id?: string;
  porting_rejections?: null;
  porting_status?: number;
  porting_status_updated_at?: string;
  salesforce_batch_id?: string;
  submitted_by?: string;
  submitted_date?: string;
  updated_at?: string;
};

export enum MediaType {
  LOA = 'loa',
  phoneBill = 'phone_bill',
}

export enum RequestClient {
  WAM = 'WAM',
  adminPortal = 'admin-portal',
  desktop = 'desktop',
  mobile = 'mobile',
}

export type MediaData = {
  id?: string;
  media_id: string;
  file_name: string;
  media_type: MediaType;
  porting_data_id?: string;
  created_at?: string;
  updated_at?: string;
  provider_document_id?: string;
};

export type PortingData = {
  authorized_user_first_name: string;
  authorized_user_last_name: string;
  service_street1: string;
  service_city: string;
  service_state: string;
  service_country: string;
  service_zip: string;
  company_name: string;
  phone_service_account_number: string;
  porting_requests: PortingRequest[];
  billing_phone_number: string;
  current_phone_service_provider: string;
  office_email: string;
  request_client: string;
  account_pin: string;
  request_asap_port: boolean;
  customer_phone_bill_media: MediaData[];
  no_bill_available: boolean;
  reason_bill_not_available: string;
};

export type PortGetData = PortingData & {
  agreement_id: string;
  created_at: string;
  created_by: string;
  current_account_type: string;
  customer_phone_bill_media_id: null;
  customer_phone_bill_media_type: null;
  desired_carrier: null;
  emergency_city: string;
  emergency_country: string;
  emergency_state: string;
  emergency_street1: string;
  emergency_street2: string;
  emergency_zip: string;
  id: string;
  location_id: string;
  other_services_using_phone_lines: string;
  phone_and_internet_bundled: string;
  port_order_number: string;
  port_order_url: string;
  provider: number;
  request_client: RequestClient;
  salesforce_port_order_name: string;
  service_house_number: string;
  service_street2: string;
  sip_peer_id: string;
  updated_at: string;
};

export type PortCreationFileResponseData = {
  ID: string;
  Type: MediaType;
  LocationID: string;
  Name: string;
  Path: string;
  MediaLocation: string;
  UserID: string;
  DeletedAt: string;
  PublicURL: string;
  CreatedAt: string;
  EscapedURL: string;
  SecureURL: string;
};

export type PortViewFileUploadResponse = {
  media_data: MediaData;
};

export type PhoneBillDownloadPayload = {
  customer_phone_bill_media_id: string;
  customer_phone_bill_media_type: MediaType;
  location_id: string;
};

export type UploadLOAMediaType = {
  porting_data_id: string;
  media_id: string;
  file_name: string;
  media_type: string;
};

export type UploadLOAPayloadType = {
  content_type: string;
  media_data: UploadLOAMediaType;
};
