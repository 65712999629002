import React from 'react';
import {
  IconButton,
  XIcon,
  PhoneField,
  useForm,
  TextField,
  ConfirmationModal as NewConfirmationModal,
} from '@weave/design-system';
import { css } from '@emotion/core';

import { ForwardingNumber, ForwardingStatus } from '../../../models';
interface Props {
  deleteRow: (ForwardingNumber) => void;
  forwardingNumber: ForwardingNumber;
  forwardingStatus: ForwardingStatus;
  selectForwardingNumber: (forwardingNumber: string) => void;
  selected?: boolean;
  update: (ForwardingNumber) => void;
}

export const ForwardingNumberRow = (props: Props) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const { getFieldProps, values } = useForm({
    fields: {
      name: { type: 'text', required: true, value: props.forwardingNumber.name },
      number: { type: 'phone', required: true, value: props.forwardingNumber.number },
    },
  });

  const deleteRow = () => {
    props.deleteRow(props.forwardingNumber);
  };

  const update = () => {
    if (
      props.forwardingNumber.name !== values.name ||
      props.forwardingNumber.number !== values.number
    ) {
      const updatedNumber = {
        ...props.forwardingNumber,
        name: values.name,
        number: values.number,
      };

      props.update(updatedNumber);
    }
  };

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const handleRadioChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const numberId = target.value;

    props.selectForwardingNumber(numberId);
  };

  return (
    <>
      <tr>
        <td className="selected">
          <input
            type="radio"
            css={css`
              transform: translateY(calc(50% + 4px));
            `}
            className="form-control"
            name="forwardingNumberRadio"
            value={props.forwardingNumber.id}
            checked={props.selected}
            aria-label={`Select ${props.forwardingNumber.number} as the call-forwarding number.`}
            onChange={handleRadioChange}
          />
        </td>
        <td className="name">
          <TextField {...getFieldProps('name')} name="name" label="" onBlur={update} />
        </td>
        <td className="number">
          <PhoneField
            {...getFieldProps('number')}
            label=""
            name="number"
            onBlur={update}
          />
        </td>
        <td className="number-actions">
          <div
            css={css`
              opacity: 0.4;
              transform: scale(0.7);
            `}
          >
            <IconButton
              size="small"
              id="deleteBtn"
              label="Delete Forwarding Number"
              onClick={togglePopover}
            >
              <XIcon />
            </IconButton>
          </div>
        </td>
      </tr>
      <NewConfirmationModal
        title="Are you sure you want to delete this number?"
        onClose={togglePopover}
        onConfirm={deleteRow}
        show={popoverOpen}
        confirmLabel="Delete"
        cancelLabel="Cancel"
      />
    </>
  );
};
