import React from 'react';
import { rowStyle } from './auto-reminders-list.styles';

export const HeaderRow = () => {
  return (
    <div css={rowStyle}>
      <span className="bigCol headerStyle">Name</span>
      <span className="smallCol">ID</span>
      <span className="bigCol">Type - Send To</span>
      <span>Delivery Time</span>
      <span>Created - Updated</span>
      <span className="smallCol">Adv Opts</span>
      <span className="actionButtons" />
      <span />
    </div>
  );
};
