import { css } from '@emotion/core';
import {
  CheckboxField,
  Modal,
  ModalControlModalProps,
  useFormField,
  Text,
  Heading,
  Alert,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { ToggleState } from 'components/shared/multi-state-toggle/multi-state-toggle.component';
import { LocationFeature } from 'models/location-feature.model';
import React from 'react';

export type ModalData = { featureToToggle: LocationFeature; newState: ToggleState };
interface Props extends ModalControlModalProps {
  onAccept: (featureToToggle: LocationFeature, newState: ToggleState) => void;
  closeModal: () => void;
  modalData: ModalData;
}

export const WeaveVerifyCustomizationFlagModal = ({
  onAccept,
  closeModal,
  modalData,
  ...modalProps
}: Props) => {
  const acceptProps = useFormField({
    type: 'checkbox',
    value: false,
  });

  const confirmationHandler = () => {
    onAccept(modalData.featureToToggle, modalData.newState);
    closeModal();
  };

  return (
    <Modal {...modalProps} maxWidth={800}>
      <Modal.Header>ARE YOU SURE ?</Modal.Header>
      <Modal.Body>
        <Text>
          <Alert
            type="warning"
            css={css`
              margin: ${theme.spacing(1, 0)};
            `}
          >
            PLEASE READ ALL OF THE FOLLOWING TERMS AND CONDITIONS RELATED TO THE
            CANCELLATION OF THE SUBSCRIPTION FOR WEAVE INSURANCE VERIFICATION.
          </Alert>
          <Heading level={2}>You certify that</Heading>
          <ul css={ulCss}>
            <li>
              You are authorized to cancel the Weave Insurance Verification Agreement on
              behalf of the customer account
            </li>
            <li>
              You certify that the customers have offically assigned/appointed you to
              carry out the Weave Insurance Verification cancellation
            </li>
            <li>
              You also cerifty that the customers will no longer be able to use Weave
              Insurance Verification product for the purpose of verifying patient
              insurance eligibility based on this action
            </li>
            <li>
              You acknowledge that as a Weave representative, we have recorded all the
              necessary feedback/reason from our customer regarding the cancellation of
              the Weave Insurance Verification subscription
            </li>
          </ul>
          <Heading level={2}>Next Steps</Heading>
          <ul css={ulCss}>
            <li>
              Acknowledge the above terms by checking the box below and clicking{' '}
              <b>"Confirm"</b> button
            </li>
            <li>
              The <b>Feature/Customization Flag</b> value will be visible to Hide state.
              So once you see the Hide state, make sure that you click the <b>Save</b>{' '}
              button at the top right corner to update the Feature state <br />
              <b>
                Note: In order to reflect your action, it's important to save the latest
                state of the Feature/Customization Flag
              </b>
            </li>
            <li>
              Once the state of the Feature/Customization Flag is updated, you must make
              sure that a downgrade opportunity is created in Salesforce to cancel the
              service for the customer. Failure to do so will result in a customer paying
              for a service that they can't use
            </li>
          </ul>
        </Text>
      </Modal.Body>

      <CheckboxField
        css={css`
          padding: ${theme.spacing(2, 4, 0)};
          box-shadow: 0px -2px 4px rgb(0, 0, 0, 0.2);
        `}
        name="accept"
        {...acceptProps}
        label={
          <span>
            By checking this box and clicking "Confirm", you acknowledge that the Weave
            Insurance Verification product for the purpose of verifying patient insurance
            eligibility will be no longer available for the particular location
          </span>
        }
      />
      <Modal.Actions
        disablePrimary={!acceptProps.value}
        onPrimaryClick={confirmationHandler}
        primaryLabel="Confirm"
      />
    </Modal>
  );
};

const ulCss = css`
  margin: ${theme.spacing(1, 0)};
  > li {
    padding: ${theme.spacing(0.5, 0)};
  }
`;
