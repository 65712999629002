import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { cx } from 'emotion';
import {
  AudioScrubber,
  Heading,
  PrimaryButton,
  SearchField,
  Text,
  useFormField,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import matchSorter from 'match-sorter';

import * as styles from './form-select-media.styles';
import { PhoneMedia, phoneMediaSelector } from '../../../redux/actions';
import { css } from '@emotion/core';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  onMediaFileSelected: (mediaId: string) => void;
}

const defaultActiveMedia: PhoneMedia = {
  MediaID: '',
  LocationID: '',
  FilePath: '',
  FileName: '',
  MediaType: '',
  ContentType: '',
  CreatedAt: '',
  DeletedAt: '',
};

export const SelectMediaForm = (props: Props) => {
  const [activeMediaFile, setActiveMediaFile] = useState(defaultActiveMedia);
  const { locationId, token, phoneMedia } = useSelector(phoneMediaSelector);
  const searchFieldProps = useFormField({ type: 'text' });
  const selectFile = () => {
    props.onMediaFileSelected(activeMediaFile.MediaID);

    props.onRequestClose();
  };

  const onFileClick = (file: PhoneMedia) => {
    setActiveMediaFile(file);
  };

  const renderMediaFiles = (mediaFiles: PhoneMedia[]) => {
    let filteredMediaFiles = mediaFiles;

    if (searchFieldProps.value) {
      filteredMediaFiles = matchSorter(filteredMediaFiles, searchFieldProps.value, {
        keys: ['FileName'],
      });
    }

    return filteredMediaFiles.map((file) => {
      const fileName = file.FileName.replace(/.wav|.mp3|.mp4|.wma|.ogg|.m4a/, '');
      const isSelected = file.MediaID === activeMediaFile.MediaID;

      return (
        <div
          key={file.MediaID}
          className={cx(styles.mediaFileSelect, isSelected ? 'selected' : '')}
          onClick={() => onFileClick(file)}
        >
          <Text>{fileName.substring(fileName.length - 32, fileName.length)}</Text>
          <AudioScrubber
            src={`${file.FilePath}?token=${token}&location_id=${locationId}`}
            css={css`
              height: 35px;
            `}
          />
        </div>
      );
    });
  };

  return (
    <div>
      <div className={styles.mainSection}>
        <SearchField
          {...searchFieldProps}
          css={css`
            margin: ${theme.spacing(1, 0)};
          `}
          name={'searchMediaFiles'}
          value={searchFieldProps.value}
          label="Search"
        />
        <div className={styles.mediaFileContainer}>{renderMediaFiles(phoneMedia)}</div>
      </div>
      <hr className={styles.hr} />
      {!activeMediaFile.MediaID && (
        <>
          <Heading level={2} textAlign="center">
            Don't see your media file?
          </Heading>
          <div className={styles.instructions}>
            <Text>
              Dial *86 on your phone to record a new media file, then refresh this page to
              have it appear here for selection.
            </Text>
          </div>
        </>
      )}
      {activeMediaFile.MediaID && (
        <div className={styles.buttonContainer}>
          <PrimaryButton className={styles.submitButton} onClick={selectFile}>
            Use Selected File
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};
