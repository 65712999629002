export enum OnbaordingActionTypes {
  GET_ONBOARDING_STATUS = 'GET_ONBOARDING_STATUS',
  SET_ONBOARDING_STATUS = 'SET_ONBOARDING_STATUS',
}

export type OnboardingStatusGetAction = {
  type: typeof OnbaordingActionTypes.GET_ONBOARDING_STATUS;
  payload: any;
};

export type OnboardingStatusSetAction = {
  type: typeof OnbaordingActionTypes.SET_ONBOARDING_STATUS;
  payload: OnBoardingStatusState;
};

export type onBoardingStatusType = {
  id: string;
  name: string;
  userId: string | null;
  locationId: string;
  startedAt: string | null;
  completedAt: string | null;
  updatedAt: string | null;
  retry: string;
  // schema for data is unknown so currently defined the type as any
  data: any;
};

export type Modules = onBoardingStatusType & {
  tasks: Array<string>;
};

export type Tasks = onBoardingStatusType & {
  isStandAlone: boolean;
};

export type OnBoardingStatusState = {
  isLoading: boolean;
  error: string | null;
  modules: Modules[];
  tasks: Tasks[];
};
