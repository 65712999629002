import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';

import { ChilipiperScheduleEventModel } from './chilipiper-schedule-event.types';
import {
  getChilipiperScheduleEvent,
  getChilipiperScheduleEventSuccess,
  getChilipiperScheduleEventFailure,
  scheduleEvent,
  scheduleEventSuccess,
  scheduleEventFailure,
  updateScheduleEvent,
  updateScheduleEventSuccess,
  updateScheduleEventFailure,
  resetScheduleEventUpsertState,
  cancelScheduledEvent,
  cancelScheduledEventSuccess,
  cancelScheduledEventFailure,
  updatePreProvisionData,
} from './chilipiper-schedule-event.action';

export interface ChilipiperScheduleEventListStateModel {
  loading: boolean;
  data: ChilipiperScheduleEventModel[];
  error?: Error;
}

const defaultCollectionState: ChilipiperScheduleEventListStateModel = {
  loading: false,
  data: [],
};

export const retrieveChilipiperScheduleEventReducer = handleActions(
  {
    [getChilipiperScheduleEvent.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true, data: [], error: undefined }),
    [getChilipiperScheduleEventSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        data: action.payload,
        loading: false,
        error: undefined,
      }),
    [getChilipiperScheduleEventFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, loading: false, data: [] }),
  },
  defaultCollectionState
);

export interface ChilipiperScheduleEventStateModel {
  loading: boolean;
  cancelSuccess: boolean;
  data?: ChilipiperScheduleEventModel;
  error?: Error;
}

const defaultUpsertState: ChilipiperScheduleEventStateModel = {
  loading: false,
  data: undefined,
  cancelSuccess: false,
};

export const upsertChilipiperScheduleEventReducer = handleActions(
  {
    [scheduleEvent.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true, data: undefined, error: undefined }),
    [scheduleEventSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        data: action.payload,
        loading: false,
        error: undefined,
      }),
    [scheduleEventFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, loading: false }),
    [updateScheduleEvent.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true, data: undefined, error: undefined }),
    [updateScheduleEventSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        data: action.payload,
        loading: false,
        error: undefined,
      }),
    [updateScheduleEventFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, loading: false }),
    [resetScheduleEventUpsertState.toString()]: () => defaultUpsertState,
    [cancelScheduledEvent.toString()]: (state) => ({ ...state, loading: true }),
    [cancelScheduledEventSuccess.toString()]: (state) => ({
      ...state,
      loading: false,
      cancelSuccess: true,
    }),
    [cancelScheduledEventFailure.toString()]: (state) => ({ ...state, loading: false }),
  },
  defaultUpsertState
);

export const additionalChilipiperDataReducer = handleActions(
  {
    [updatePreProvisionData.toString()]: (state, action) => ({
      preProvisionData: action.payload,
    }),
  },
  {}
);

export const chilipiperScheduleEventReducer = combineReducers({
  retrieveScheduleEvents: retrieveChilipiperScheduleEventReducer,
  upsertScheduleEvents: upsertChilipiperScheduleEventReducer,
  additionalChilipiperData: additionalChilipiperDataReducer,
});
