import { formatCurrencyValue, formatPercentValue } from '../../../helpers/currency';
import { MerchantPlanTypes } from '../../../models/pricing.model';

export const standardPricing = {
  plan: { name: MerchantPlanTypes.Full },
  cardPresent: { rate: 240, transactionCost: 25 },
  cardNotPresent: { rate: 299, transactionCost: 30 },
};

export const getPercentValue = (rate?: number, isCardPresent = false) => {
  const standardRate = isCardPresent
    ? standardPricing.cardPresent.rate
    : standardPricing.cardNotPresent.rate;
  return rate ? formatPercentValue(rate) : formatPercentValue(standardRate);
};

export const getCurrencyValue = (cost?: number, isCardPresent = false) => {
  const standardCost = isCardPresent
    ? standardPricing.cardPresent.transactionCost
    : standardPricing.cardNotPresent.transactionCost;
  return cost ? formatCurrencyValue(cost) : formatCurrencyValue(standardCost);
};
