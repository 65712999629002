import { connect } from 'react-redux';

import { Leader, Leaderboard } from './leaderboard/leaderboard.component';
import { sentimentTrainingLeaderboard } from '../../../redux/actions/sentiment-training/get-sentiment-training-leaderboard/get-sentiment-training-leaderboard.action';

interface StateProps {
  leaders: Leader[];
  loading: boolean;
  error: string;
}

interface DispatchProps {
  getLeaders: () => void;
}

interface SentimentTraining {
  leaderboardData: any[];
  leaderboardLoading: boolean;
  leaderboardError: string;
}

export const LeaderboardContainer = connect<
  StateProps,
  DispatchProps,
  {},
  { sentimentTraining: SentimentTraining }
>(
  (state) => ({
    leaders: state.sentimentTraining.leaderboardData || [],
    loading: state.sentimentTraining.leaderboardLoading || false,
    error: state.sentimentTraining.leaderboardError || '',
  }),
  (dispatch) => ({
    getLeaders() {
      dispatch(sentimentTrainingLeaderboard());
    },
  })
)(Leaderboard);
