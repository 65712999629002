import React from 'react';
import { get, orderBy } from 'lodash';
import { css } from 'emotion';
import { useAlert } from '@weave/alert-system';
import {
  PhoneTreeModel,
  PhoneTreeInstruction,
  PhoneTreeEntryModel,
} from '../phone-tree.models';
import { PhoneTreeTextOption } from './phone-tree-entry-options/phone-tree-text-option.component';
import { PhoneTreeCallForwardingOption } from './phone-tree-entry-options/phone-tree-call-forwarding-option.component';
import { CustomAxios } from '../../../../redux/axios';
import { PhoneTreeInstructionsMap, PhoneTreeActions } from '../use-phone-tree-state';
import { CallForwardingNumber } from '../../../../redux/actions/call-forwarding/call-forwarding.types';

interface Props {
  entry: PhoneTreeEntryModel | null;
  dialNumber: string;
  instructions: PhoneTreeInstructionsMap;
  onPhoneTreeClick: (id: string) => void;
  phoneTreeDispatch: (action: any) => void;
  getPhoneTree: (phoneTreeId: string) => PhoneTreeModel | undefined;
}

export const PhoneTreeEntry = ({
  dialNumber,
  entry = null,
  onPhoneTreeClick,
  instructions,
  phoneTreeDispatch,
  getPhoneTree,
}: Props) => {
  const alerts = useAlert();
  const renderInstruction = (instruction: PhoneTreeInstruction) => {
    if (!instruction) {
      return <PhoneTreeTextOption text="Custom Configuration" />;
    }

    switch (instruction.InstructionType) {
      case 'bridge_call_group': {
        return (
          <>
            <PhoneTreeTextOption text="Forward to Call Group" />
            {instruction.CallerName && (
              <PhoneTreeTextOption text={instruction.CallerName} />
            )}
          </>
        );
      }
      case 'bridge_sip_profile': {
        return (
          <>
            <PhoneTreeTextOption text="Forward to Phone" />
            {instruction.CallerName && (
              <PhoneTreeTextOption text={instruction.CallerName} />
            )}
          </>
        );
      }
      case 'condition': {
        return <PhoneTreeTextOption text="Condition Check" />;
      }
      case 'play': {
        return <PhoneTreeTextOption text="Play Media File" />;
      }
      case 'voicemail_check': {
        return <PhoneTreeTextOption text="Check Voicemail Messages" />;
      }
      case 'voicemail_prompt': {
        return <PhoneTreeTextOption text="Leave a Message" />;
      }
      case 'hang_up': {
        return <PhoneTreeTextOption text="End Call" />;
      }
      case 'ivr_menu': {
        const menuId: string = instruction.IVRMenuID || '';
        const linkedPhoneTree = getPhoneTree(menuId);
        const menuName = get(linkedPhoneTree, 'MenuName', '');

        if (menuName) {
          return (
            <>
              <PhoneTreeTextOption text="Forward to Phone Tree" />
              <PhoneTreeTextOption
                text={menuName}
                onLinkClick={() => onPhoneTreeClick(menuId)}
              />
            </>
          );
        }

        return <PhoneTreeTextOption text="Forward to Phone Tree" />;
      }
      case 'bridge_forwarding_number': {
        const forwardNumberId = get(instruction, 'FwdNumber.ID');

        const onForwardingNumberChange = async (forwardNumber: CallForwardingNumber) => {
          const oldForwardingNumber = {
            ...instruction.FwdNumber,
          };

          // Optimistically update the phoneTree
          phoneTreeDispatch({
            type: PhoneTreeActions.updateInstructionFwdNumber,
            payload: {
              ...instruction,
              FwdNumber: {
                ID: forwardNumber.id,
                Name: forwardNumber.name,
                Number: forwardNumber.number,
              },
            },
          });

          try {
            await CustomAxios.put(`support/v1/instructions/${instruction.ID}`, {
              ID: instruction.ID,
              ForwardingNumberID: forwardNumber.id,
            });

            alerts.success('Call forwarding number updated successfully');
          } catch {
            // Roll back the phone tree update
            phoneTreeDispatch({
              type: PhoneTreeActions.updateInstructionFwdNumber,
              payload: {
                ...instruction,
                FwdNumber: oldForwardingNumber,
              },
            });
            alerts.error('Call forwarding number failed to update');
          }

          return;
        };

        return (
          <>
            <PhoneTreeTextOption text="Forward to Phone Number" />
            <PhoneTreeCallForwardingOption
              activeForwardingId={forwardNumberId}
              onForwardingNumberChange={onForwardingNumberChange}
            />
          </>
        );
      }
      case 'data_endpoint': {
        return <PhoneTreeTextOption text="Data Endpoint" />;
      }
      default: {
        return <PhoneTreeTextOption text="Custom Configuration" />;
      }
    }
  };

  const renderEntry = () => {
    switch (get(entry, 'Type')) {
      case 'back': {
        return <PhoneTreeTextOption text="Previous Menu" />;
      }
      case 'exit': {
        return <PhoneTreeTextOption text="Exit Phone Tree" />;
      }
      case 'ivr_menu': {
        const menuId = get(entry, 'MenuOptionID', '');
        const linkedPhoneTree = getPhoneTree(menuId);
        const menuName = get(linkedPhoneTree, 'MenuName', '');

        if (menuName) {
          return (
            <>
              <PhoneTreeTextOption text="Forward to Phone Tree" />
              <PhoneTreeTextOption
                text={menuName}
                onLinkClick={() => onPhoneTreeClick(menuId)}
              />
            </>
          );
        }

        return <PhoneTreeTextOption text="Forward to Phone Tree" />;
      }
      case 'instruction_set': {
        let entryInstructions: PhoneTreeInstruction[] = [];

        get(entry, 'Instructions', []).forEach((instructionId: string) => {
          entryInstructions.push(instructions[instructionId]);
        });

        // Sort Instructions by position since we're only rendering the first
        entryInstructions = orderBy(entryInstructions, ['Position'], ['asc']);

        return renderInstruction(entryInstructions[0]);
      }
      case 'top': {
        return <PhoneTreeTextOption text="Return to Main Menu" />;
      }
      default: {
        return <PhoneTreeTextOption text="None" />;
      }
    }
  };

  return (
    <div
      className={css`
        align-items: center;
        display: flex;
        height: 40px;
        margin: 8px;
      `}
    >
      <span
        className={css`
          display: inline-block;
          width: 75px;
        `}
      >
        Dial {dialNumber}
      </span>
      {!entry && <PhoneTreeTextOption text="None" />}
      {entry && renderEntry()}
    </div>
  );
};
