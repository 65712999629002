import React from 'react';
import moment from 'moment';
import { css, cx } from 'emotion';

import { dateTimeFormat } from '../../../helpers/utils';
import { getLossString, getFixedString } from './network-audits.helpers';

const pingWidth = css`
  width: 80px;
`;
const headerMargin = css`
  margin-top: 10px;
`;
const headerText = css`
  margin-bottom: 8px;
  font-weight: bold;
`;
const headerContainer = css`
  text-align: left;
  margin-left: 10px;
`;
const latencyHeader = css`
  margin-bottom: 8px;
  font-weight: bold;
  margin-left: 60px;
`;
const flex = css`
  display: flex;
`;
const beta = css`
  font-weight: normal;
  font-style: italic;
`;

const formatDate = (seconds: string | number): string => {
  if (!seconds) {
    return '';
  }

  return moment(seconds).format(dateTimeFormat);
};

export const tableColumns = [
  {
    Header: (props) => (
      <div className={headerMargin}>
        <div className={headerText}>Date / Time</div>
      </div>
    ),
    id: 'time',
    accessor: (data: any) => formatDate(data.start_at),
  },
  {
    Header: (props) => (
      <div className={headerMargin}>
        <div className={headerText}>Workstation</div>
      </div>
    ),
    accessor: 'username',
  },
  {
    Header: (props) => (
      <div className={headerContainer}>
        <div className={headerText}>
          Bandwidth - <span className={beta}>Beta Testing</span>
        </div>
        <div className={flex}>
          <div
            className={css`
              width: 100px;
            `}
          >
            Down
          </div>
          <div
            className={css`
              width: 100px;
            `}
          >
            Up
          </div>
        </div>
      </div>
    ),
    minWidth: 180,
    id: 'bandwidth',
    accessor: (audit) => {
      return audit.speed;
    },
    Cell: (props) => {
      const bandwidth = props.value;

      let download = '-';
      let upload = '-';
      if (bandwidth.download_mbps) {
        download = bandwidth.download_mbps.toFixed(1);
      }
      if (bandwidth.upload_mbps) {
        upload = bandwidth.upload_mbps.toFixed(1);
      }
      return (
        <div className={headerContainer}>
          <div className={flex}>
            <div
              className={css`
                min-width: 100px;
              `}
            >
              {download !== '-' ? `${download} mbps` : download}
            </div>
            <div
              className={css`
                min-width: 100px;
              `}
            >
              {upload !== '-' ? `${upload} mbps` : upload}
            </div>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <div className={headerContainer}>
        <div className={headerText}>Topology</div>
        <div className={flex}>
          <div className={pingWidth}>NAT</div>
          <div className={pingWidth}>Gateway </div>
        </div>
      </div>
    ),
    minWidth: 190,
    id: 'topology',
    accessor: (audit) => {
      return audit.local_network;
    },
    Cell: (props) => {
      const topology = props.value;
      let nat = '-';
      if (topology.nat_cnt === 1) {
        nat = 'Single';
      } else if (topology.nat_cnt > 1) {
        nat = 'Double';
      }
      let gateway = '-';
      if (topology.gateway_vendor) {
        gateway = topology.gateway_vendor;
      }

      return (
        <div className={headerContainer}>
          <div className={flex}>
            <div
              className={cx(
                css`
                  min-width: 80px;
                `,
                {
                  [css`
                    color: #dc3545;
                  `]: nat === 'Double',
                }
              )}
            >
              {nat}
            </div>
            <div
              className={cx(
                css`
                  min-width: 80px;
                `
              )}
            >
              {gateway}
            </div>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <div className={headerContainer}>
        <div className={flex}>
          <div className={headerText}>Packet Testing</div>
          <div className={latencyHeader}>Latency</div>
        </div>
        <div className={flex}>
          <div className={pingWidth}>Loss </div>
          <div className={pingWidth}>Jitter </div>
          <div className={pingWidth}>Avg </div>
          <div className={pingWidth}>Worst </div>
          <div className={pingWidth}>Data Center </div>
        </div>
      </div>
    ),
    minWidth: 350,
    id: 'ping',
    accessor: (audit) => {
      return audit.pings;
    },
    Cell: (props) => {
      const { dataCenterPingUrl } = props.tdProps.rest;

      const pings = props.value;
      let dataCenterPing = pings.find((ping) => ping.dest_name === dataCenterPingUrl);

      if (!dataCenterPing) {
        dataCenterPing = pings.reduce((acc, curr) => {
          if (curr.dest_name && curr.avg_latency < acc.avg_latency) {
            return curr;
          }
          return acc;
        });
      }

      const avgLatency = dataCenterPing.avg_latency ?? -1;
      const maxLatency = dataCenterPing.max_latency ?? -1;
      const jitter = dataCenterPing.jitter ?? -1;
      const sent = dataCenterPing.sent ?? -1;
      const returned = dataCenterPing.returned ?? -1;
      const loss = 100 - (sent / returned) * 100;

      return (
        <div className={headerContainer}>
          <div className={flex}>
            <div
              className={cx(
                css`
                  min-width: 80px;
                `,
                {
                  [css`
                    color: #dc3545;
                  `]: loss >= 1,
                }
              )}
            >
              {getLossString(loss)}
            </div>
            <div
              className={cx(
                css`
                  min-width: 80px;
                `,
                {
                  [css`
                    color: #dc3545;
                  `]: jitter > 40,
                }
              )}
            >
              {getFixedString(jitter)}
            </div>
            <div
              className={cx(
                css`
                  min-width: 80px;
                `,
                {
                  [css`
                    color: #dc3545;
                  `]: avgLatency > 120,
                }
              )}
            >
              {getFixedString(avgLatency)}
            </div>
            <div
              className={cx(
                css`
                  min-width: 80px;
                `,
                {
                  [css`
                    color: #dc3545;
                  `]: maxLatency > 150,
                }
              )}
            >
              {getFixedString(maxLatency)}
            </div>
            <div
              className={cx(
                css`
                  min-width: 80px;
                  text-align: center;
                `,
                {
                  [css`
                    color: #dc3545;
                  `]: maxLatency > 150,
                }
              )}
            >
              {dataCenterPing.dest_name &&
                dataCenterPing.dest_name.slice(5, 7).toUpperCase()}
            </div>
          </div>
        </div>
      );
    },
  },
];
