import axios from 'axios';
import { OktaAuth } from '@okta/okta-auth-js';

import { CustomAxios, getResponseData } from '../../axios';
import { SignInUser } from './auth.types';
import { REACT_APP_API_URL } from '../../../config/app';

const SIGN_IN_OKTA_URL = '/auth/oktaexchange';
const SIGN_IN_GOOGLE_URL = `/support/login`;
const OKTA_URL = 'https://auth.weaveconnect.com/api/v1/sessions/me';
const contentType = { 'Content-Type': 'application/json' };

export const AuthApi = {
  POST: {
    authenticateOkta: (token: string) =>
      axios
        .post(
          SIGN_IN_OKTA_URL,
          {
            idToken: token,
            exp: '120',
            source: 'wam',
          },
          { baseURL: REACT_APP_API_URL }
        )
        .then<{ token: string }>(getResponseData)
        .then((res) => res.token),

    authenticateGoogle: (credentials: SignInUser) =>
      CustomAxios.post(SIGN_IN_GOOGLE_URL, { ...credentials }).then(getResponseData),
  },
  GET: {
    session: () =>
      axios
        .get(OKTA_URL, {
          withCredentials: true,
          headers: contentType,
        })
        .then((res) => res.data.status === 'ACTIVE'),

    oktaToken: (oktaAuth: OktaAuth) =>
      oktaAuth.token
        .getWithoutPrompt()
        .then<{ id_token?: string; email?: string; access_token?: string }>(
          ({ tokens }) => ({
            email: tokens.idToken?.claims.email,
            id_token: tokens.idToken?.idToken,
            access_token: tokens.accessToken?.accessToken,
          })
        ),

    oktaTokenAlternative: (oktaAuth: OktaAuth) =>
      oktaAuth.token
        .parseFromUrl()
        .then<{ id_token?: string; email?: string; access_token?: string }>(
          ({ tokens }) => ({
            email: tokens.idToken?.claims.email,
            id_token: tokens.idToken?.idToken,
            access_token: tokens.accessToken?.accessToken,
          })
        ),
  },
  DELETE: {
    session: () =>
      axios
        .delete(OKTA_URL, {
          withCredentials: true,
          headers: contentType,
        })
        .then((res) => res),
  },
};
