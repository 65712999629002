import { omit } from 'lodash';
import { VerticalEnum } from '../../../../models/vertical-enum';

export enum DataCenters {
  CentralIA = 'central-ia',
  EasternVA = 'eastern-va',
  PacificCA = 'pacific-ca',
  MountainUT = 'mountain-ut',
  US1Central1 = 'us1-central1',
  None = '',
}

export interface LocationModel {
  LocationID: string;
  Name: string;
  Slug: string;
  Email: string;
  Timezone: string;
  Active: true;
  SalesforceID: string;
  Type: string;
  VerticalID: VerticalEnum;
  PhoneSystemTimezone: string;
  Phone: string;
  DataCenter: DataCenters;
  ParentID: string | null;
  MainDID: string;
  BannerMessages: any[];
  PhoneTenantID: string | null;

  //if you add any more entities here, be sure to add it to the omitter below
  deviceIds?: string[];
  voicemailBoxIds?: string[];
  fwdNumberIds?: string[];
  ringtoneIds?: string[];
  phoneNumberIds?: string[];
  departmentIds?: string[];
}

export type CreateLocationDTO = Omit<LocationModelWithPhoneData, 'LocationID'>;
export interface LocationModelWithPhoneData extends LocationModel {
  caller_number: string;
  primary_data_center: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  createPhoneTenant: boolean;
  salesforce_products?: any[];
  provisioned_from: string;
  salesforce_account_id: string;
}

export interface ProvisionLocationDTO {
  name: LocationModel['Name'];
  slug: LocationModel['Slug'];
  office_email: LocationModel['Email'];
  time_zone: LocationModel['Timezone'];
  vertical_id: LocationModel['VerticalID'];
  caller_number: LocationModelWithPhoneData['caller_number'];
  primary_data_center: LocationModelWithPhoneData['primary_data_center'];
  street1: LocationModelWithPhoneData['street1'];
  street2: LocationModelWithPhoneData['street2'];
  city: LocationModelWithPhoneData['city'];
  state: LocationModelWithPhoneData['state'];
  zip: LocationModelWithPhoneData['zip'];
  country: LocationModelWithPhoneData['country'];
  location_parent_id: LocationModel['ParentID'];
  location_type: LocationModel['Type'];
  salesforce_products?: any[];
  SalesforceID?: string;
  provisioned_from: string;
  salesforce_account_id: string;
}

export type LocationSummary = {
  Timezone: string;
  DataCenter: DataCenters;
  MainDID: string;
};

export type OfficeInfo = {
  businessName: string;
  businessPhone: string;
  businessEmail: string;
  timezone: string;
  industry: string;
};

export type LocationsState = { [locationId: string]: LocationModel };

export enum LocationsActionTypes {
  RequestCreateLocation = 'REQUEST_CREATE_LOCATION',
  RequestUpdateLocation = 'REQUEST_UPDATE_LOCATION',
  RequestSetLocationParent = 'REQUEST_SET_PARENT_LOCATION',
  RequestSetPhoneTenantID = 'REQUEST_SET_PHONE_TENANT_ID',
  AddLocations = 'ADD_LOCATION',
  UpdateLocation = 'UPDATE_LOCATION',
  RemoveLocation = 'REMOVE_LOCATION',
  UpdateLocationDataCenter = 'UPDATE_LOCATION_DATA_CENTER',
}

type Action<ActionType extends LocationsActionTypes, Payload = undefined> = {
  type: ActionType;
} & { payload: Payload };
export type RequestCreateLocationAction = Action<
  LocationsActionTypes.RequestCreateLocation,
  CreateLocationDTO
>;
export type RequestUpdateLocationAction = Action<
  LocationsActionTypes.RequestUpdateLocation,
  { locationId: string; location: Partial<LocationModel>; onFinish?: () => void }
>;
export type RequestSetLocationParentAction = Action<
  LocationsActionTypes.RequestSetLocationParent,
  { locationId: string; parentLocationId: string }
>;
export type RequestSetPhoneTenantID = Action<
  LocationsActionTypes.RequestSetPhoneTenantID,
  { phoneTenanatId: string }
>;
export type AddLocationsAction = Action<
  LocationsActionTypes.AddLocations,
  LocationModel[]
>;
export type UpdateLocationAction = Action<
  LocationsActionTypes.UpdateLocation,
  { locationId: string; location: Partial<LocationModel> }
>;
export type RemoveLocationAction = Action<LocationsActionTypes.RemoveLocation, string>;
export type UpdateLocationDataCenterAction = Action<
  LocationsActionTypes.UpdateLocationDataCenter,
  { locationId: string; dataCenter: string }
>;
export type LocationActions =
  | RequestCreateLocationAction
  | AddLocationsAction
  | UpdateLocationAction
  | RemoveLocationAction
  | UpdateLocationDataCenterAction;

export const stripLocationEntities = (location: Partial<LocationModelWithPhoneData>) =>
  omit(location, [
    'deviceIds',
    'voicemailBoxIds',
    'fwdNumberIds',
    'ringtoneIds',
    'phoneNumberIds',
    'departmentIds',
  ]);

export const mockLocation = (props: Partial<LocationModel> = {}): LocationModel => ({
  LocationID: '',
  PhoneTenantID: '',
  Name: '',
  Slug: '',
  Email: '',
  Timezone: '',
  Active: true,
  SalesforceID: '',
  Type: '',
  VerticalID: VerticalEnum.Dental,
  PhoneSystemTimezone: '',
  Phone: '',
  DataCenter: DataCenters.None,
  ParentID: null,
  MainDID: '',
  BannerMessages: [],
  ...props,
});

export const mockLocationWithPhoneData = (
  props: Partial<LocationModelWithPhoneData> = {}
): LocationModelWithPhoneData => {
  return {
    ...mockLocation(),
    caller_number: '',
    primary_data_center: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    createPhoneTenant: true,
    salesforce_products: [],
    provisioned_from: 'WAM',
    salesforce_account_id: '',
    ...props,
  };
};

export const mockProvisionLocationDTO = (
  props: Partial<ProvisionLocationDTO> = {}
): ProvisionLocationDTO => {
  return {
    ...convertLocationToProvisionLocationDTO(mockLocationWithPhoneData(props)),
    ...props,
  };
};

export const mockCreateLocationDTO = (
  props: Partial<CreateLocationDTO> = {}
): CreateLocationDTO => {
  const { LocationID, ...rest } = mockLocationWithPhoneData(props);
  return rest;
};

export const convertLocationToProvisionLocationDTO = (
  location: CreateLocationDTO
): ProvisionLocationDTO => ({
  name: location.Name,
  slug: location.Slug,
  office_email: location.Email,
  time_zone: location.Timezone,
  vertical_id: location.VerticalID,
  caller_number: location.caller_number,
  primary_data_center: location.primary_data_center,
  street1: location.street1,
  street2: location.street2,
  city: location.city,
  state: location.state,
  zip: location.zip,
  country: location.country,
  location_parent_id: location.ParentID,
  location_type: location.Type,
  ...(location.salesforce_products && {
    salesforce_products: location.salesforce_products,
  }),
  provisioned_from: 'WAM',
  SalesforceID: location.SalesforceID,
  salesforce_account_id: location.SalesforceID,
});

export const createDemoUserFromLocation = (location: LocationModelWithPhoneData) => {
  const emailAddress = location.Email.substring(0, location.Email.indexOf('@'));
  const names = emailAddress.split('.');
  const firstName = names?.[0] ?? '';
  const lastName = names?.[1] ?? '';
  const user = {
    LocationID: location.LocationID,
    FirstName: firstName,
    LastName: lastName,
    Password: `${firstName}123`,
    UserID: '',
    Username: location.Email,
    JobTitles: [
      {
        ID: '097f6f07-c7e2-40ff-80c0-04214a15dcdf',
        Name: 'Owner',
        Type: 'job_title',
        label: 'Owner',
        value: 'Owner',
      },
    ],
    Roles: [
      {
        ID: 4,
        Name: 'Admin',
        Type: 'practice',
        label: 'Admin',
        value: 'Admin',
      },
    ],
  };
  return user;
};
