import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { css, cx } from 'emotion';
import { weaveRed, gray9, Modal, Loader } from '@weave/platform-ui-components';
import {
  ChilipiperScheduleEventModel,
  ChilipiperScheduleEventTypes,
  PreProvisionDataModel,
} from '../../../redux/actions/chilipiper-schedule-event/chilipiper-schedule-event.types';

const iframeWidth = '550px';

const modalStyles = css`
  .modal-content {
    width: ${iframeWidth};
    background-color: white;
  }
`;
const iframeStyles = css`
  width: ${iframeWidth};
  height: 750px;
  background-color: white;
  border: none;
`;
const iframeSpinnerDiv = css`
  position: absolute;
  top: 300px;
  width: ${iframeWidth};
  display: flex;
  justify-content: center;
`;
const iframeTopDiv = css`
  width: ${iframeWidth};
  padding-right: 10px;
  padding-top: 10px;
  position: relative;
  height: 20px;
`;
const iframeWarningText = css`
  position: relative;
  color: ${weaveRed};
  left: 104px;
  font-size: 1rem;
`;
const iframeCloseButton = css`
  font-size: 14px;
  color: ${gray9};
  font-weight: bold;
  border: 2px solid ${gray9};
  padding: 0px 6px;
  float: right;
  cursor: pointer;
  &:hover {
    background-color: ${gray9};
    color: white;
  }
`;
const hideVisibility = css`
  visibility: hidden;
`;

enum RouterNames {
  debug = 'inbound-router1',
  phoneInstall = 'phone_installation',
  adminTraining = 'admin_training',
  onsiteInstall = 'onsite_install',
  softwareInstallation = 'software_installation',
  softwareInstallationMulti = 'software_installation_multi',
  phoneInstallationMulti = 'phone_customization_multi',
  phoneCustomizationSingle = 'phone_customization_single',
  phoneCustomizationSingleNoIvr = 'phone_customization_single_no_ivr',
}

export type SchedulingFrameProps = {
  debug: boolean;
  currentEvent?: ChilipiperScheduleEventModel;
  onModalClose: () => void;
  onScheduleSuccess: (data: any, currentEvent: ChilipiperScheduleEventModel) => void;
  preProvisionData: PreProvisionDataModel;
};

export const SchedulingFrame = (props: SchedulingFrameProps) => {
  const { debug, currentEvent, onScheduleSuccess } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookingUrl, setBookingUrl] = useState('');
  const [bookingEvent, setBookingEvent] = useState<MessageEvent>();

  /**
   * Triggers when the currentEvent prop changes. Handles setting the correct booking url
   * to be used for the iframe. Displays modal afterwards.
   */
  useEffect(() => {
    if (currentEvent) {
      setLoading(true);
      const router = getRouter(currentEvent.event_type);
      const requestBody = {
        form: {
          id: props.preProvisionData.contactId,
        },
        options: {
          router: router,
        },
      };

      /**
       * When scheduling a new event through chilipiper (CP), we first call their api
       * passing the Salesforce (SF) contact Id of the user who we will schedule the meeting
       * for and also the router name in order to get back the scheduling queue url. Then
       * we append the SF opportunityId as a query param in the url in order to avoid
       * scheduling the wrong SF lead when there are duplicates with the same SF contact Id.
       * The url can then be used in the iframe.
       */
      axios
        .post('https://api.chilipiper.com/marketing/getweave', requestBody)
        .then((response) => {
          const url = response.data.url;
          if (url) {
            let newUrl = url.replace('&marketing=true', '');
            newUrl += `&opportunityId=${props.preProvisionData.opportunityId}`;
            setBookingUrl(newUrl);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });

      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  }, [currentEvent]);

  /**
   * Sets up the event listener that will listen to the incoming Chilipiper messages.
   * Filters out the events to make sure we are only handling the ones coming from
   * chilipiper.
   */
  useEffect(() => {
    const receiveMessage = (event) => {
      if (event.origin !== 'https://getweave.chilipiper.com') {
        return;
      }
      // Delegate the event to the useEffect hook by storing the event.
      setBookingEvent(event);
    };
    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, []);

  /**
   * Triggered when a bookingEvent has been set. Currently, chilipiper's javascript api
   * sends two messages when scheduling through their calendar (displayed in an iframe).
   * Will first send a "booked" message. This first message contains limited data but the
   * CP calendar will change and seem as if everything is complete, so we will hide the
   * iframe at that point to display our own loader while waiting for the next message.
   * Next (after a slight delay), it will send a "booking-confirmed" message which
   * includes the event data needed to submit to the backend.
   */
  useEffect(() => {
    if (!bookingEvent || !currentEvent) {
      return;
    }

    if (bookingEvent.data.action === 'booked') {
      setLoading(true);
    }
    if (bookingEvent.data.action === 'booking-confirmed') {
      onScheduleSuccess(bookingEvent.data, currentEvent);
      setLoading(false);
      setModalIsOpen(false);
      setBookingUrl('');
      setBookingEvent(undefined);
    }
  }, [bookingEvent]);

  const getRouter = (type: ChilipiperScheduleEventTypes) => {
    if (debug) {
      return RouterNames.debug;
    }

    let router;

    switch (type) {
      case ChilipiperScheduleEventTypes.phoneInstallCall:
        router = RouterNames.phoneInstall;
        break;
      case ChilipiperScheduleEventTypes.adminTraining:
        router = RouterNames.adminTraining;
        break;
      case ChilipiperScheduleEventTypes.onsiteInstall:
        router = RouterNames.onsiteInstall;
        break;
      case ChilipiperScheduleEventTypes.softwareInstallCall:
        router = RouterNames.softwareInstallation;
        break;
      case ChilipiperScheduleEventTypes.softwareInstallationMulti:
        router = RouterNames.softwareInstallationMulti;
        break;
      case ChilipiperScheduleEventTypes.phoneInstallationMulti:
        router = RouterNames.phoneInstallationMulti;
        break;
      case ChilipiperScheduleEventTypes.phoneCustomizationSingle:
        router = RouterNames.phoneCustomizationSingle;
        break;
      case ChilipiperScheduleEventTypes.phoneCustomizationSingleNoIvr:
        router = RouterNames.phoneCustomizationSingleNoIvr;
        break;
      default:
        router = '';
    }

    return router;
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setLoading(false);
    props.onModalClose();
    setBookingUrl('');
    setBookingEvent(undefined);
  };

  return (
    <div>
      <Modal isOpen={modalIsOpen} className={modalStyles} onRequestClose={() => {}}>
        <div className={iframeTopDiv}>
          <span className={iframeWarningText}>
            Please do not close this window while loading.
          </span>
          <span className={iframeCloseButton} onClick={closeModal}>
            X
          </span>
        </div>
        {loading && (
          <div className={iframeSpinnerDiv}>
            <Loader />
          </div>
        )}
        {
          <iframe
            id="bookingIframe"
            title="bookingIframe"
            src={bookingUrl}
            className={cx(iframeStyles, cx(loading ? hideVisibility : ''))}
          />
        }
      </Modal>
    </div>
  );
};
