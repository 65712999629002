import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { Loader } from '../../shared/loader/loader.component';
import { LocationModel } from '../../../redux/actions/location';
import { formatDateFromUnixTimestamp } from '../../../helpers/utils';
import { Table } from 'reactstrap';
import { PortingCard } from '../porting-card/porting-card.component';
import { SuccessSVG } from './success-svg';
import { PhoneNumberLookup } from './phone-number-lookup';
import { portingHubStyles } from './porting-hub.styles';
import moment from 'moment';
import { PrimaryButton, SecondaryButton, Modal } from '@weave/design-system';
import { Provider } from '../porting-card/constants';
import { PortOrder } from '../port-order/port-order.component';
import { SalesforcePortOrderForm } from './salesforce-port-order-form.component';
import { PortStatusMapping } from '../utils/port-constants';

interface Props {
  ports: any[];
  loading: boolean;
  portingDetails: any;
  currentPortStatus: any;
  submitPortingDetails: any;
  portStatusLoading: boolean;
  splitPortingRequest: number;
  downloadLOALoading: boolean;
  downloadBillLoading: boolean;
  submitPortingLoading: boolean;
  splitPortingRequestsResults: any;
  updatePortingDetailsLoading: boolean;
  deletePortingRequestLoading: boolean;
  currentLocation: LocationModel | null;
  getPortingData: () => void;
  downloadLOA: (port) => void;
  downloadBill: (port) => void;
  submitPorting: (port) => void;
  clearPortingStatus: () => void;
  getPortingStatus: (port) => void;
  checkPortability: (port) => void;
  resetPortabilityCheck: () => void;
  updatePortingData: (port) => void;
  setPortingDetails: (port) => void;
  clearSubmitPortingDetails: () => void;
  deletePortingRequest: (id: string) => void;
  submitSplitPortingRequests: (port) => void;
}

export const PortingHub = (props: Props) => {
  const {
    ports,
    loading,
    portingDetails,
    currentLocation,
    currentPortStatus,
    portStatusLoading,
    downloadLOALoading,
    downloadBillLoading,
    splitPortingRequest,
    submitPortingDetails,
    submitPortingLoading,
    updatePortingDetailsLoading,
    splitPortingRequestsResults,
    deletePortingRequestLoading,
    downloadLOA,
    downloadBill,
    getPortingData,
    checkPortability,
    getPortingStatus,
    setPortingDetails,
    updatePortingData,
    clearPortingStatus,
    deletePortingRequest,
    resetPortabilityCheck,
    clearSubmitPortingDetails,
    submitSplitPortingRequests,
  } = props;

  const [currentPortStatusProvider, setCurrentPortStatusProvider] = useState<string>('');
  const [showPortOrderForm, setShowPortOrderForm] = useState<boolean>(false);

  useEffect(() => {
    getPortingData();
    setPortingDetails(null);
    if (portingDetails) {
      const resyncedPort = ports.find((port) => port.id === portingDetails.id);
      /* 
        then reset the portingDetails data with the synced version of it but only if it
        has porting_request data.
      */
      if (resyncedPort?.porting_requests?.length) {
        setPortingDetails(resyncedPort);
      } else {
        setPortingDetails(null);
      }
    }
  }, []);

  const getStatusColor = (portingRequest) => {
    if (portingRequest.porting_status) {
      const status = PortStatusMapping[portingRequest.porting_status];
      if (status === 'FOC' || status === 'Completed' || status === 'Accepted') {
        return '#2BD53C';
      } else if (status === 'Exception' || status === 'Rejected') {
        return '#FF6B59';
      }
    }
    return '#FFB92E';
  };

  const handlePortOrderBackClick = (): void => {
    setPortingDetails(null);
    getPortingData();
    setShowPortOrderForm(false);
  };

  if (!currentLocation) {
    return null;
  }
  if (loading) {
    return (
      <div className={portingHubStyles.loading}>
        <Loader />
      </div>
    );
  }
  if (portingDetails) {
    return (
      <PortingCard
        port={portingDetails}
        back={handlePortOrderBackClick}
        splitPortingRequest={splitPortingRequest}
        updatePortingDetails={updatePortingData}
        updatePortingDetailsLoading={updatePortingDetailsLoading}
        downloadLOALoading={downloadLOALoading}
        downloadBillLoading={downloadBillLoading}
        submitPortingLoading={submitPortingLoading}
        downloadBill={downloadBill}
        downloadLOA={downloadLOA}
        setPortingDetails={setPortingDetails}
        submitPortingDetails={submitPortingDetails}
        clearSubmitPortingDetails={clearSubmitPortingDetails}
        checkPortability={checkPortability}
        resetPortabilityCheck={resetPortabilityCheck}
        submitSplitPortingRequests={submitSplitPortingRequests}
        splitPortingRequestsResults={splitPortingRequestsResults}
        deletePortingRequest={deletePortingRequest}
        deletePortingRequestLoading={deletePortingRequestLoading}
        getStatusColor={getStatusColor}
      />
    );
  }

  const handleCreatePortOrder = (portingData) => {
    setShowPortOrderForm(false);
    setPortingDetails(portingData);
  };

  return showPortOrderForm ? (
    <PortOrder
      back={handlePortOrderBackClick}
      handleCreatePortOrder={handleCreatePortOrder}
      setShowPortOrderForm={(show) => setShowPortOrderForm(show)}
    />
  ) : (
    <div>
      <div className={portingHubStyles.portContainer}>
        <div>
          <div className={portingHubStyles.portTitle}>Port Orders</div>
          <div className={portingHubStyles.portingHubText}>
            View all port orders for this location below
          </div>
        </div>
        <div>
          <SalesforcePortOrderForm getPortingData={getPortingData} />
        </div>
        <div className={portingHubStyles.portContainerButtons}>
          <div className={portingHubStyles.newPortOrder}>
            <PrimaryButton color="blue" onClick={() => setShowPortOrderForm(true)}>
              New Port Order
            </PrimaryButton>
          </div>
          <div className={portingHubStyles.portRefresh}>
            <PrimaryButton color="blue" onClick={getPortingData}>
              Refresh
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div
        css={css`
          margin-right: 20px;
        `}
      >
        {/*// @ts-ignore */}
        <Table borderless="true" className={portingHubStyles.tableView}>
          <thead>
            <tr className={portingHubStyles.tableHeadRow}>
              <th
                css={css`
                  width: 375px;
                `}
              >
                Port Order Number
              </th>
              <th>Created Date</th>
              <th>Port Type</th>
              <th>Requested Date</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody className={portingHubStyles.tableBody}>
            {ports.map((port, index) => {
              const portingRequest = port.porting_requests?.[0];
              if (!portingRequest) {
                return null;
              }

              return (
                <tr key={index}>
                  <td
                    css={css`
                      width: 375px;
                    `}
                  >
                    {portingRequest.port_order_number}
                  </td>
                  <td>
                    {portingRequest.created_at &&
                      moment(portingRequest.created_at).utc().format('MM/DD/YY')}
                  </td>
                  <td>{portingRequest.number_type === 3 ? 'Voice/SMS' : 'Fax'}</td>
                  <td>
                    {portingRequest.requested_firm_order_commitment_date
                      ? moment(portingRequest.requested_firm_order_commitment_date)
                          .utc()
                          .format('MM/DD/YY')
                      : ''}
                  </td>
                  <td className={portingHubStyles.dotColumn}>
                    <div
                      css={(theme) => css`
                        width: 8px;
                        height: 8px;
                        margin: ${theme.spacing(1)};
                        border: 1px solid ${getStatusColor(portingRequest)};
                        background-color: ${getStatusColor(portingRequest)};
                        border-radius: 50%;
                      `}
                    />
                    <p>
                      {portingRequest.porting_status
                        ? PortStatusMapping[portingRequest.porting_status]
                        : 'Draft'}
                    </p>
                  </td>

                  <td>
                    <div className={portingHubStyles.tableActions}>
                      {portingRequest.porting_status ? (
                        <>
                          {portingRequest.porting_status !== 11 && (
                            <PrimaryButton
                              className={portingHubStyles.checkStatusButton}
                              css={css`
                                width: 140px;
                              `}
                              onClick={() => {
                                const provider: number = port?.provider || 0;
                                if (provider === 0 || !Provider.has(provider)) {
                                  setCurrentPortStatusProvider('Provider');
                                } else {
                                  setCurrentPortStatusProvider(
                                    Provider.get(provider) || 'Provider'
                                  );
                                }
                                getPortingStatus(port);
                              }}
                            >
                              Check Status
                            </PrimaryButton>
                          )}
                          <div
                            className={portingHubStyles.viewInfoButton}
                            onClick={() => {
                              setPortingDetails(port);
                            }}
                          >
                            View Info
                          </div>{' '}
                        </>
                      ) : (
                        <SecondaryButton
                          className={portingHubStyles.reviewButton}
                          color="gray"
                          css={css`
                            width: 140px;
                          `}
                          onClick={() => {
                            setPortingDetails(port);
                          }}
                        >
                          Review
                        </SecondaryButton>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {ports.length === 0 && (
        <div
          css={css`
            text-align: center;
            margin-bottom: 14px;
          `}
        >
          There are no active ports for this location.
        </div>
      )}
      <PhoneNumberLookup />
      <Modal
        onClose={clearPortingStatus}
        css={(theme) =>
          css`
            padding: ${theme.spacing(2)};
          `
        }
        show={portStatusLoading || (!portStatusLoading && !!currentPortStatus) || false}
      >
        {portStatusLoading ? (
          <div>
            <div className={portingHubStyles.loading}>
              <Loader size="large" />
            </div>
            <div className={portingHubStyles.pingingBandwidth}>
              Pinging {currentPortStatusProvider}...
            </div>
          </div>
        ) : (
          <div>
            <div className={portingHubStyles.loading}>
              <SuccessSVG />
            </div>
            <div className={portingHubStyles.statusComplete}>Status Check Complete!</div>
            {currentPortStatus && (
              <div className={portingHubStyles.progress}>
                {currentPortStatus.progress_status}
              </div>
            )}

            {currentPortStatus && (
              <div className={portingHubStyles.portStatusStyle}>
                {currentPortStatus.order_histories &&
                  currentPortStatus.order_histories.map((orderHistory, index) => {
                    if (
                      index === currentPortStatus.order_histories.length - 1 ||
                      index === currentPortStatus.order_histories.length - 2
                    ) {
                      return (
                        <div
                          key={index}
                          css={css`
                            margin-bottom: 10px;
                          `}
                        >
                          <span
                            css={css`
                              font-weight: bold;
                            `}
                          >
                            {formatDateFromUnixTimestamp(
                              orderHistory.order_date,
                              'MM/dd/yy'
                            )}
                            :
                          </span>{' '}
                          {orderHistory.note}
                        </div>
                      );
                    }
                    return null;
                  })}

                {currentPortStatus.order_notes &&
                  currentPortStatus.order_notes.map((orderNote, index) => {
                    if (
                      index === currentPortStatus.order_notes.length - 1 ||
                      index === currentPortStatus.order_notes.length - 2
                    ) {
                      return (
                        <div
                          key={index}
                          css={css`
                            margin-bottom: 10px;
                          `}
                        >
                          <span
                            css={css`
                              font-weight: bold;
                            `}
                          >
                            {formatDateFromUnixTimestamp(
                              orderNote.last_date_modifier,
                              'MM/dd/yy'
                            )}
                            :
                          </span>{' '}
                          {orderNote.description}
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            )}
            <PrimaryButton
              className={portingHubStyles.gotItButton}
              color="blue"
              size="large"
              onClick={() => {
                clearPortingStatus();
              }}
            >
              Got it
            </PrimaryButton>
          </div>
        )}
      </Modal>
    </div>
  );
};
