import { connect } from 'react-redux';

import { HandoffHub } from './handoff-hub/handoff-hub.component';
import {
  getHandoffHubData,
  submitHandoffData,
  saveHandoffNotesSnapshot,
  saveCSATData,
} from '../../redux/actions/handoff-hub';
import { getConnectedUsers } from '../../redux/actions/users';
import { getUsers } from '../../redux/actions/users/users-list.action';
import { runCustomerSatisfactionSurvey } from '../../redux/actions/pendo';

export const HandoffHubContainer = connect(
  (state: any) => ({
    snapshot: state.handoffHub.snapshot,
    phonesCategory: state.handoffHub.phonesCategory,
    dataSyncCategory: state.handoffHub.dataSyncCategory,
    softwareCategory: state.handoffHub.softwareCategory,
    premiumFeaturesCategory: state.handoffHub.premiumFeaturesCategory,
    connectedUsers: state.connectedUsers.users,
    users: state.users.data,
    loadingUsers: state.connectedUsers.loading,
    submitting: state.handoffHub.submitting,
    submitError: state.handoffHub.submitError,
  }),
  {
    getHandoffHubData,
    submitHandoffData,
    getConnectedUsers,
    saveHandoffNotesSnapshot,
    getUsers,
    runCustomerSatisfactionSurvey,
    saveCSATData,
  }
)(HandoffHub);
