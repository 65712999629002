import React from 'react';
import { css } from 'emotion';
import { CompletedIcon } from '../completed-icon/completed-icon.component';
import { OvalIcon } from '../oval-icon/oval-icon.component';
import { Task } from '../onboarding-task.model';

export type OnboardingTaskStatusProps = {
  status: string;
  updateTaskStatus: (task: Task, status: number) => void;
  task: Task;
};

export const OnboardingTaskStatus = (props: OnboardingTaskStatusProps) => {
  const getIcon = () => {
    if (props.status === 'WaitingOnCustomer') {
      return <OvalIcon color="#ED9825" />;
    } else if (props.status === 'WaitingOnWeave') {
      return <OvalIcon color="#37CFF1" />;
    } else if (props.status === 'WaitingOnPreviousTask') {
      return <OvalIcon color="rgba(0,0,0,0.5)" />;
    } else {
      return <CompletedIcon />;
    }
  };

  const handleChange = (event) => {
    const selection = event.target.value;
    let status = 0;
    if (selection === 'WaitingOnCustomer') {
      status = 0;
    } else if (selection === 'WaitingOnWeave') {
      status = 1;
    } else if (selection === 'Completed') {
      status = 2;
    } else {
      status = 3;
    }

    props.updateTaskStatus(props.task, status);
  };

  return (
    <div
      className={css`
        display: flex;
      `}
    >
      {getIcon()}

      {props.status === 'WaitingOnPreviousTask' ? (
        <div
          className={css`
            padding-left: 10px;
          `}
        >
          Complete other tasks first
        </div>
      ) : (
        <select
          className={css`
            margin-left: 10px;
          `}
          value={props.status}
          onChange={handleChange}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <option value="WaitingOnCustomer">Waiting on customer</option>
          <option value="WaitingOnWeave">Waiting on Weave</option>
          <option value="Completed">Completed</option>
        </select>
      )}
    </div>
  );
};
