import React from 'react';
import { css } from '@emotion/core';

interface Props {
  on: boolean;
}

export function OnOffIndicator({ on }: Props) {
  return (
    <div
      css={css`
        background-color: ${on ? '#1fc717' : '#98a7b0'};
        border-radius: 15px;
        color: white;
        display: inline-block;
        height: 23px;
        line-height: 23px;
        margin-right: 15px;
        text-align: center;
        width: 40px;
      `}
    >
      {on ? 'ON' : 'OFF'}
    </div>
  );
}
