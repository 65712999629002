import React, { useMemo } from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { templateTagExamples } from '../../auto-reminders-helpers/auto-reminder-helpers';
import { TextTemplateTags } from '../auto-reminder-template-tags';
import { getButtonContent } from './email-utils';
import { HtmlItem } from './html-item';

type EmailTypes =
  | 'emailButton'
  | 'emailHeader'
  | 'emailMessage'
  | 'emailFooter'
  | 'emailImage';

export type HtmlContent = {
  type: EmailTypes;
  content: string;
  src?: string;
  alt?: string;
};

const container = css`
  position: relative;
`;

const templateTagsContainer = (theme: WeaveTheme) => css`
  label: templateTags;
  background-color: #fff;
  position: sticky;
  top: 0px;
  padding: 8px;
  margin: 16px 0;
  z-index: 110;
  box-shadow: ${theme.shadows.light};
`;

export const EditEmailTemplate = ({ type, HTMLItems, setHTMLItems }) => {
  const buttonContent = useMemo(
    () => getButtonContent(HTMLItems, type),
    [HTMLItems.length, type]
  );

  const handleDrag = (tag) => (event) => {
    event.dataTransfer.setData('Text', tag);
  };

  const handleAddContent = (type, idx) => {
    const btnText = buttonContent || '(Disabled)';
    if ((type === 'emailButton' && btnText !== '(Disabled)') || type !== 'emailButton') {
      const content = type === 'emailButton' ? buttonContent : '';
      const element: HtmlContent = { type, content };
      const items = [...HTMLItems.slice(0, idx), element, ...HTMLItems.slice(idx)];
      setHTMLItems(items);
    }
  };

  const editContent = (idx, e) => {
    e.persist();
    setHTMLItems((itemArray) => {
      const items = [...itemArray];
      items[idx] = {
        ...itemArray[idx],
        content: e.target.value,
      };
      return items;
    });
  };

  const removeItem = (idx) => {
    setHTMLItems((itemArray) => itemArray.slice(0, idx).concat(itemArray.slice(idx + 1)));
  };

  return (
    <div css={container}>
      <div css={templateTagsContainer}>
        <TextTemplateTags
          handleDrag={handleDrag}
          templateTagExamples={templateTagExamples}
          templateType={'template_text'}
        />
      </div>
      {HTMLItems.map((item, idx) => (
        <HtmlItem
          key={`${item.type}-${idx}`}
          item={item}
          index={idx}
          handleAddContent={(type) => handleAddContent(type, idx)}
          removeItem={() => removeItem(idx)}
          editContent={(e) => editContent(idx, e)}
        />
      ))}
    </div>
  );
};
