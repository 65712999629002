import React from 'react';
import { WeaveTheme } from '@weave/theme-original';
import { Text, ForwardIcon } from '@weave/design-system';
import { Title } from '@weave/platform-ui-components';
import { css } from '@emotion/core';
import { StatusMapping } from './status-mapping';

export const statusMapContainer = (theme: WeaveTheme) => css`
  width: 600px;
  padding: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(4)};
  border: 1px solid ${theme.colors.gray[400]};
  border-radius: ${theme.borderRadius.medium};
  img {
    margin: ${theme.spacing(2, 0)};
  }
  p {
    margin: 0;
    margin-bottom: ${theme.spacing(1)};
  }
  dl {
    display: flex;
    svg {
      margin: auto;
      margin-left: 115px;
      margin-right: 95px;
      cursor: default;
    }
    p {
      margin: auto 0;
    }
  }
`;

export const AppointmentMapping = ({ sourceID }) => {
  return (
    <div css={statusMapContainer}>
      <Title size="large" weight="bold">
        Appointment Status Mapping
      </Title>
      <dl>
        <Text weight="bold">Weave Status</Text>
        <ForwardIcon />
        <Text weight="bold">Office Status</Text>
      </dl>

      <StatusMapping type="appointment" sourceID={sourceID} />
    </div>
  );
};
