import { css } from 'emotion';
import { gray5, weaveBlue, weaveBlueDark } from '@weave/platform-ui-components';

export const modalCard = css`
  .react-modal-card {
    padding: 40px;
    width: 475px;
  }

  label: modalCard;
`;

export const modalBody = css`
  text-align: center;

  label: modalBody;
`;

export const modalText = css`
  color: ${gray5};
  font-size: 15px;
  margin: 15px 0 25px;

  label: modalText;
`;

export const artistImage = css`
  border-radius: 50%;
  height: 140px;
  width: 140px;

  label: artistImage;
`;

export const artistNameTitle = css`
  display: block;
  margin-top: 16px;

  label: artistNameTitle;
`;

export const artistRole = css`
  color: ${gray5};
  display: block;

  label: artistRole;
`;

export const artistBio = css`
  display: block;
  margin-top: 16px;
  text-align: left;

  label: artistBio;
`;

export const section = css`
  margin-top: 24px;

  label: section;
`;

export const sectionRow = css`
  align-items: center;
  display: flex;
  margin-top: 8px;

  label: sectionRow;
`;

export const mediaData = css`
  display: inline-block;
  margin-left: 24px;
  min-width: 200px;

  label: mediaData;
`;

export const fileTitle = css`
  display: inline-block;
  font-weight: bold;

  label: fileTitle;
`;

export const link = css`
  color: ${weaveBlue};
  cursor: pointer;
  transition: color 300ms;

  :hover {
    color: ${weaveBlueDark};
  }

  label: link;
`;

export const explainerText = css`
  display: inline-block;
  margin: 8px 0 16px;
  max-width: 850px;

  label: explainerText;
`;

export const audio = css`
  height: 40px;
  margin: 0 30px;

  label: audio;
`;
