import React, { Component } from 'react';

export class CompletedIcon extends Component<any, any> {
  render() {
    return (
      <svg
        width="17px"
        height="13px"
        viewBox="0 0 17 13"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Completed Icon</title>
        <desc>Created with Sketch.</desc>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            id="Weave-Onboarding"
            transform="translate(-33.000000, -269.000000)"
            stroke="#27B523"
            strokeWidth="2.5"
          >
            <path
              d="M48.6435898,271.135769 C44.0341788,276.625931 40.9612116,279.611323 39.4246883,280.091945 C37.8881649,280.572567 36.4132688,279.00974 35,275.403463"
              id="Page-1"
            />
          </g>
        </g>
      </svg>
    );
  }
}
