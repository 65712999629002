import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Select,
  Option,
  Svg,
  Checkbox,
  Modal,
  Typography,
  Button,
} from '@weave/platform-ui-components';
import { useAlert } from '@weave/alert-system';
import { find, orderBy, isEmpty } from 'lodash';
import { cx } from 'emotion';

import { useResources } from '../../../helpers/useResources';
import { ConditionalLoader } from '../../shared/loader/conditional-loader.component';
import * as styles from './voicemail-override.styles';
import { CustomAxios } from '../../../redux/axios';
import { Page } from '../../shared/page/page.component';
import { phoneMediaSelector, PhoneMedia } from '../../../redux/actions';

interface VoicemailOverrideData {
  mediaID: string;
  enabled: boolean;
}

export const VoicemailOverride = () => {
  const { loadingPhoneMedia, locationId, phoneMedia, token } =
    useSelector(phoneMediaSelector);
  const [activationError, setActivationError] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const {
    data: vmo,
    loading: loadingVMO,
    setResources: setVMO,
  } = useResources<VoicemailOverrideData>('support/v1/ivrmenus/voicemailoverride', {
    deps: [locationId],
    resourceLabel: 'voicemail override',
    emptyState: {},
  });
  const currentToggleIndex = vmo.enabled ? 1 : 0;
  const alerts = useAlert();

  const onMediaChange = async (newMediaID: string) => {
    const oldMediaId = vmo.mediaID;

    if (newMediaID === oldMediaId) {
      return;
    }

    // Optimistically update the VMO mediaID
    setVMO({ ...vmo, mediaID: newMediaID });
    setActivationError(false);

    try {
      await CustomAxios.put(`support/v1/ivrmenus/voicemailoverride`, {
        Enabled: vmo.enabled,
        MediaID: newMediaID,
      });

      alerts.success('Voicemail override media updated successfully!');
    } catch {
      // Roll back the VMO mediaID since it failed to update
      setVMO({ ...vmo, mediaID: oldMediaId });
      alerts.error(
        `Error updating voicemail override media. Please refresh the page and try again.`
      );
    }
  };

  const onToggleClick = async () => {
    const newEnabledStatus = !vmo.enabled;

    setVMO({ ...vmo, enabled: newEnabledStatus });

    if (
      newEnabledStatus &&
      (!vmo.mediaID || vmo.mediaID === '00000000-0000-0000-0000-000000000000')
    ) {
      // No mediaId set so VMO can't be enabled
      return setTimeout(() => {
        setActivationError(true);
        setVMO({ ...vmo, enabled: false });
      }, 300);
    } else if (newEnabledStatus) {
      // Show the modal to confirm the user wants to override the whole phone system
      setShowActivationModal(true);
    } else if (!newEnabledStatus) {
      // Turn VMO off, no confirmation needed
      onChangeActivation(newEnabledStatus);
    }

    return;
  };

  const onChangeActivation = async (newEnabledStatus: boolean) => {
    const actionText = newEnabledStatus ? 'activated' : 'deactivated';

    setShowActivationModal(false);

    try {
      await CustomAxios.put(`support/v1/ivrmenus/voicemailoverride`, {
        enabled: newEnabledStatus,
      });

      alerts.success(`Voicemail Override ${actionText} successfully!`);
    } catch {
      // Roll back the enabled status since it failed to update
      setVMO({ ...vmo, enabled: !newEnabledStatus });
      alerts.error(
        `Error changing status to ${actionText}. Please refresh the page and try again.`
      );
    }
  };

  const onRequestModalClose = () => {
    setShowActivationModal(false);
    setVMO({ ...vmo, enabled: false });
  };

  const renderMediaFileOptions = (files: PhoneMedia[]) => {
    files = orderBy(files, ['Name'], ['asc']);

    return files.map((file) => {
      let fileName = file.FileName.replace(/.wav|.mp3|.mp4|.wma|.ogg|.m4a/, '');

      fileName = fileName.substring(fileName.length - 32, fileName.length);

      return (
        <Option key={file.MediaID} value={file.MediaID}>
          {fileName}
        </Option>
      );
    });
  };

  const renderAudio = (files: PhoneMedia[], activeId: string) => {
    const mediaFile = find(files, { MediaID: activeId });

    return (
      <audio
        controls
        src={`${mediaFile?.FilePath}?token=${token}&location_id=${locationId}`}
        className={styles.audio}
        preload="metadata"
      >
        Your browser does not support the audio tag.
      </audio>
    );
  };

  if (!locationId) {
    return <></>;
  }

  return (
    <Page
      title="Voicemail Override"
      subtitle="Activating Voicemail Overide (VMO) will close your office and play the recorded greeting selected below. "
      expandedSubtitle="This VMO greeting will remain active until manually toggled off, at which point your office will automatically return to its normal set office hours and voicemail greetings. Changes to your VMO settings can also be done via the Weave Desktop or Mobile apps."
      headerActions={
        <div className={styles.containToggle}>
          <Typography variant="Subtitle" className={styles.toggleText}>
            VMO Active
          </Typography>
          <Checkbox toggle onChange={onToggleClick} checked={vmo.enabled} />
        </div>
      }
    >
      <Modal
        onRequestClose={() => {
          return;
        }}
        isOpen={showActivationModal}
        className={styles.modalCard}
      >
        <div className={styles.modalBody}>
          <Typography variant="Title">Heads Up!</Typography>
          <div className={styles.modalText}>
            Voicemail override will override all other phone settings. All incoming calls
            will hit the override message.
          </div>
          <div>
            <Button
              className={styles.modalCancelButton}
              color="gray"
              fill="outline"
              onClick={onRequestModalClose}
            >
              Cancel
            </Button>
            <Button
              className={styles.modalContinueButton}
              onClick={() => onChangeActivation(!!currentToggleIndex)}
            >
              Continue
            </Button>
          </div>
        </div>
      </Modal>
      <ConditionalLoader loading={loadingVMO || loadingPhoneMedia || isEmpty(vmo)}>
        <div className={styles.eventBodyStyles}>
          <span className={styles.containIconStyles}>
            <Svg icon="PhoneCall" className="rg" color="gray5" />
          </span>
          <Select
            className={cx(styles.mediaDropdown, activationError && 'activationError')}
            placeholder="Select greeting"
            value={vmo.mediaID}
            onChange={(newMediaID: string) => onMediaChange(newMediaID)}
          >
            {renderMediaFileOptions(phoneMedia)}
          </Select>
          {renderAudio(phoneMedia, vmo.mediaID)}
        </div>
        {activationError && (
          <div className={styles.error}>
            A media file is required to turn on voicemail override
          </div>
        )}
      </ConditionalLoader>
    </Page>
  );
};
