import React from 'react';
import { Text, ForwardIcon } from '@weave/design-system';
import { Title } from '@weave/platform-ui-components';
import { StatusMapping } from './status-mapping';
import { statusMapContainer } from './appointment-mapping';

export const OrderMapping = ({ sourceID }) => {
  return (
    <div css={statusMapContainer}>
      <Title size="large" weight="bold">
        Eyewear Status Mapping
      </Title>
      <dl>
        <Text weight="bold">Weave Status</Text>
        <ForwardIcon />
        <Text weight="bold">Office Status</Text>
      </dl>

      <StatusMapping type="order" sourceID={sourceID} />
    </div>
  );
};
