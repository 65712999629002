import { useEffect, useMemo, useState } from 'react';
import { CustomAxios, getResponseData } from '../../../../redux/axios';
import { Document, DocumentType } from './auto-reminder-templates';
import { useSelector } from 'react-redux';
import { Store } from '../../../../redux/store/store.model';
import { selectCustomizationFeature } from '../../../../redux/actions/customization/customization.action';

export function useDocuments() {
  const documentLinksEnabled =
    useSelector((state: Store) => selectCustomizationFeature(state, 'Forms'))?.state ===
    'active';

  const [forms, setForms] = useState<Document[]>([]);
  const [packets, setPackets] = useState<Document[]>([]);
  const [loading, setLoading] = useState(true);

  const getDocuments = () => [
    ...forms.map((form) => ({ ...form, type: DocumentType.form })),
    ...packets.map((packet) => ({ ...packet, type: DocumentType.packet })),
  ];

  const getDocumentData = (type: 'forms' | 'packets'): Promise<void> => {
    const setDocuments = type === 'forms' ? setForms : setPackets;
    return CustomAxios.get(
      `https://forms-api.gke1-west3.wsf-prod-1.wstack.net/forms/v1/${type}`
    )
      .then(getResponseData)
      .then((response) => {
        if (response?.message) return;
        setDocuments(response);
      });
  };

  useEffect(() => {
    if (documentLinksEnabled) {
      Promise.all([getDocumentData('forms'), getDocumentData('packets')]).then(() =>
        setLoading(false)
      );
    }
  }, []);

  return {
    documentLinksEnabled,
    documents: useMemo(getDocuments, [forms, packets]),
    documentsLoading: loading,
  };
}
