import { CustomAxios } from '../../../redux/axios';
import {
  BillingInvoice,
  BillingAccount,
  ExtendedBillingInvoice,
  PaymentMethod,
  BillingAccountContact,
  BillingInvoiceDetails,
} from './billing.types';

export const mockPromise = <T>(
  data: any = {},
  time = 1000,
  action: 'resolve' | 'reject' = 'resolve'
): Promise<T> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      console.log('resolve: ', data);
      if (action === 'reject') {
        reject(data);
      } else {
        resolve(data);
      }
    }, time);
  });
};

//sample account response
// const mockAccount: BillingAccount = {
//   primaryContact: {
//     id: '1234',
//     address1: '2000 W. Ashton Blvd, Suite 100',
//     address2: 'Suite 400',
//     city: 'Lehi',
//     companyName: 'Test Dental Pratice - Location 2',
//     country: 'US',
//     email: 'jordan.thompson@getweave.com',
//     firstName: 'Jordan',
//     initial: '',
//     lastName: 'Thompson',
//     phone: '8018981487',
//     cellPhone: '',
//     postalCode: '84043',
//     state: 'Utah',
//   },
//   subscriberBillingNumber: '1234',
//   subscriberName: 'Test Dental Pratice - Location 2',
// };

// const mockPaymentMethodsList: PaymentMethod[] = [
//   {
//     id: 'cillum',
//     name: 'Dentist LLC',
//     accountHolder: 'sed',
//     type: 'creditCard',
//     hash: '**** **** 1454',
//     isDefault: false,
//     contact: {
//       id: 'Duis proident sint dolore laborum',
//       postalCode: 'laborum commodo Ut sed',
//     },
//   },
//   {
//     id: 'cillumx2',
//     name: 'Johnson Dentistry',
//     accountHolder: 'sed',
//     type: 'creditCard',
//     hash: '**** **** 1454',
//     isDefault: true,
//     contact: {
//       id: 'Duis proident sint dolore laborum',
//       postalCode: 'laborum commodo Ut sed',
//     },
//   },
//   {
//     id: 'cillumx3',
//     name: 'Opto LLC',
//     accountHolder: 'sed',
//     type: 'creditCard',
//     hash: '**** **** 1454',
//     isDefault: false,
//     contact: {
//       id: 'Duis proident sint dolore laborum',
//       postalCode: 'laborum commodo Ut sed',
//     },
//   },
// ];

// const mockInvoiceList = [
//   {
//     id: 'R-INV0107123',
//     amountDue: 0,
//     amountPaid: 8.57,
//     currency: 'USD',
//     createTime: '2019-05-08T00:32:04Z',
//     dueDate: '2019-05-07',
//     status: 'unpaid',
//   },
//   {
//     id: 'R-INV084638',
//     amountDue: 11.34,
//     amountPaid: 11.34,
//     currency: 'USD',
//     createTime: '2019-05-08T00:32:04Z',
//     dueDate: '2019-06-07',
//     status: 'paid',
//   },
//   {
//     id: 'R-INV0238474',
//     amountDue: 233.0,
//     amountPaid: 74.93,
//     currency: 'USD',
//     createTime: '2019-05-08T00:32:04Z',
//     dueDate: '2019-07-07',
//     status: 'unpaid',
//   },
// ] as BillingInvoice[];

// const mockInvoiceDetails: InvoiceDetails = {
//   invoice: {
//     id: 'R-INV0364074',
//     payment_terms: '',
//     amount: '499',
//     amount_due: '499',
//     amount_paid: '0',
//     currency: 'USD',
//     create_time: '2021-06-30T08:25:05Z',
//     due_date: '2021-06-30',
//     contact: {
//       id: '',
//       first_name: 'Chesley',
//       initial: '',
//       last_name: 'Maney',
//       company_name: 'Beechmont Smiles',
//       address_1: '2131 Beechmont Ave',
//       address_2: '',
//       city: 'Cincinnati',
//       state: 'OH',
//       country: 'United States',
//       postal_code: '45230',
//       email: 'maneydentistry@gmail.com',
//       phone: '',
//       cell_phone: '',
//     },
//     billing_contact: {
//       id: '',
//       first_name: 'Chesley',
//       initial: '',
//       last_name: 'Maney',
//       company_name: 'Beechmont Smiles',
//       address_1: '2131 Beechmont Ave',
//       address_2: '',
//       city: 'Cincinnati',
//       state: 'OH',
//       country: 'United States',
//       postal_code: '45230',
//       email: 'maneydentistry@gmail.com',
//       phone: '',
//       cell_phone: '',
//     },
//   },
//   line_items: [
//     {
//       id: '12569974',
//       code: 'R032',
//       quantity: '1',
//       description: 'MKTG Features',
//       unit_price: '50',
//       amount: '50',
//       item_group: 'Premium Features',
//     },
//     {
//       id: '12569976',
//       code: 'R030',
//       quantity: '1',
//       description: 'Reviews',
//       unit_price: '100',
//       amount: '100',
//       item_group: 'Reviews',
//     },
//     {
//       id: '12569978',
//       code: 'R020',
//       quantity: '1',
//       description: 'Phone Service and Software',
//       unit_price: '349',
//       amount: '349',
//       item_group: 'Software',
//     },
//   ],
// };

const baseUrl = '';

export const billingApi = {
  getAccountDetails: () =>
    CustomAxios.get(`${baseUrl}/finance/v1/finance/account`).then<BillingAccount>(
      (resp) => resp.data
    ),

  getInvoiceDetails: (invoiceId: string) =>
    CustomAxios.get(
      `${baseUrl}/finance/v1/finance/invoice/${invoiceId}`
    ).then<BillingInvoiceDetails>((resp) => resp.data),
  // getInvoiceDetails: (invoiceId: string) =>
  //   mockPromise<InvoiceDetails>(mockInvoiceDetails, undefined, 'resolve'),

  getInvoiceList: (pageSize = 5, nextPageToken?: string) =>
    CustomAxios.get<{ invoices: BillingInvoice[]; nextPageToken?: string }>(
      `${baseUrl}/finance/v1/finance/invoices?pageSize=${pageSize}` +
        (nextPageToken ? `&pageToken=${nextPageToken}` : '')
    ).then<{ invoices: ExtendedBillingInvoice[]; nextPageToken?: string }>(
      (resp: any) => {
        const billingInvoices = resp.data.invoices.map((invoice) => ({
          ...invoice,
          amount: JSON.parse(invoice.amount),
          amountDue: JSON.parse(invoice.amountDue),
          amountPaid: JSON.parse(invoice.amountPaid),
          status: invoice.amountDue === '0' ? 'paid' : 'unpaid',
        }));

        return { ...resp.data, invoices: billingInvoices };
      }
    ),
  // getInvoiceList: (pageSize = 5, nextPageToken?: string) =>
  //   CustomAxios.get<{ invoices: RawBillingInvoice[]; nextPageToken?: string }>(
  //     `${baseUrl}/finance/v1/finance/invoices?pageSize=${pageSize}` +
  //       (nextPageToken ? `&pageToken=${nextPageToken}` : '')
  //   ).then<{ invoices: BillingInvoice[]; nextPageToken?: string }>((resp) => {
  //     // Use Mock Data
  //     const billingInvoices = mockInvoiceList.map((invoice) => ({
  //       ...invoice,
  //       amountDue: JSON.parse(invoice.amountDue),
  //       amountPaid: JSON.parse(invoice.amountPaid),
  //       status: invoice.amountDue === invoice.amountPaid ? 'paid' : 'unpaid',
  //     }));

  //     return { ...resp.data, invoices: billingInvoices };
  //   }),

  getPaymentMethodsList: () =>
    CustomAxios.get(`${baseUrl}/finance/v1/finance/account/payment_methods`).then<
      PaymentMethod[]
    >((resp) => resp.data?.paymentMethods ?? []),
  // getPaymentMethodsList: () => mockPromise<PaymentMethod[]>(mockPaymentMethodsList),

  createPaymentMethod: (paymentMethodToken: string, paymentMethodType: string) =>
    CustomAxios.post(`${baseUrl}/finance/v1/finance/account/payment_methods`, {
      payment_method_token: paymentMethodToken,
      payment_method_type: paymentMethodType,
    }).then((resp) => resp?.data ?? {}),
  // createPaymentMethod: (token, type) => mockPromise({}, 2000),

  updateBillingAccountContact: (updates: Partial<BillingAccountContact>) =>
    CustomAxios.put(
      `${baseUrl}/finance/v1/finance/account/contact`,
      updates
    ).then<BillingAccount>((resp) => resp?.data),

  updateDefaultPaymentMethod: (id: string) =>
    CustomAxios.post(
      `${baseUrl}/finance/v1/finance/account/payment_methods/${id}/setasdefault`
    ),
  // updateDefaultPaymentMethod: (id: string) => mockPromise(),

  deletePaymentMethod: (id: string) =>
    CustomAxios.delete(`${baseUrl}/finance/v1/finance/account/payment_methods/${id}`),
  // deletePaymentMethod: (id: string) => mockPromise(),
};
