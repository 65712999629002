import { createAction } from 'redux-actions';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';
import { showError } from '@weave/alert-system';
import { handlePhonesLogic } from './phones/phones.action';
import { handleDataSyncLogic } from './data-sync/data-sync.action';
import { handleSoftwareLogic } from './software/software.action';
import { handlePremiumFeaturesLogic } from './premium-features/premium-features.action';
import { saveFeatureFlags } from '../feature-flags';
import { FactorStatus, FactorTest } from './handoff-hub.reducer';
import { selectCurrentLocation } from '../location/current-location';
import moment from 'moment';

export const getHandoffHubData = createAction('GET_HANDOFF_HUB_DATA');

export const getHandoffNotesDataSuccess = createAction('GET_HANDOFF_NOTES_DATA_SUCCESS');
export const getHandoffNotesDataFailure = createAction('GET_HANDOFF_NOTES_DATA_FAILURE');

export const saveHandoffDate = createAction('SAVE_HANDOFF_DATE');
export const saveCSATData = createAction('SAVE_CSAT_DATA');

export const saveHandoffNotesSnapshot = createAction('SAVE_HANDOFF_NOTES_SNAPSHOT');
export const saveHandoffNotesSnapshotSuccess = createAction(
  'SAVE_HANDOFF_NOTES_SNAPSHOT_SUCCESS'
);
export const saveHandoffNotesSnapshotFailure = createAction(
  'SAVE_HANDOFF_NOTES_SNAPSHOT_FAILURE'
);

export const factorLogic = createAction<any>('FACTOR_LOGIC');
export const factorLogicSuccess = createAction<any>('FACTOR_LOGIC_SUCCESS');
export const factorLogicFailure = createAction<any>('FACTOR_LOGIC_FAILURE');

export const submitHandoffData = createAction('SUBMIT_HANDOFF_DATA');
export const submitHandoffDataSuccess = createAction('SUBMIT_HANDOFF_DATA_SUCCESS');
export const submitHandoffDataFailure = createAction('SUBMIT_HANDOFF_DATA_FAILURE');

const HANDOFF_WEB_HOOK_URL = 'https://hooks.zapier.com/hooks/catch/883949/nkzyvl/';

/**
 * Helper method used in the handoff-hub action-helper files.
 */
export const getFactorTestResults = (
  currentFactorStatus: FactorTest['status'],
  name: FactorTest['name'],
  actual: FactorTest['actual'],
  passTest: () => boolean
): [FactorTest, FactorStatus] => {
  let newFactorStatus: FactorStatus = 'pass';
  let testStatus: FactorStatus = 'fail';

  if (passTest()) {
    testStatus = 'pass';
  }

  if (testStatus === 'fail' || currentFactorStatus === 'fail') {
    newFactorStatus = 'fail';
  }

  return [{ status: testStatus, name, actual }, newFactorStatus];
};

export const handleGetHandoffHubData = function* (action) {
  yield all([
    call(handlePhonesLogic),
    call(handleDataSyncLogic),
    call(handleSoftwareLogic),
    call(handlePremiumFeaturesLogic),
    call(handleGetHandoffNotesData),
  ]);
};

export const handleSaveHandoffNotesSnapshot = function* (action) {
  try {
    yield call(CustomAxios.post, `support/v1/onboarding/handoffsnapshot`, action.payload);
  } catch {
    yield put(saveHandoffNotesSnapshotFailure());
  }
};

export const handleSaveHandoffDate = function* (action) {
  try {
    const state = yield select();
    const handoffId = state.handoffHub.snapshot.onboarders_location_id;
    yield call(
      CustomAxios.post,
      `support/v1/onboarding/handoffsnapshot/${handoffId}/handoff`,
      {},
      {
        headers: { 'X-Weave-Debug-Id': 'handoff-tracer' },
      }
    );
    yield call(handleGetHandoffNotesData);
  } catch {
    yield put(showError(`Did not save handoff date.`));
  }
};

export const handleSaveCSATData = function* (action) {
  try {
    const state = yield select();
    const snapshot = state.handoffHub.snapshot;
    const handoffId = snapshot.onboarders_location_id;
    const { csat_recipient_user_email, locationInformation } = action.payload;

    const snapshotData: any = {
      point_of_contact_email: locationInformation.pointOfContact || null,
      reason_for_purchase: locationInformation.purchaseReason,
      customizations: locationInformation.hasCustomizations === 'false' ? null : true,
      customization_setup: locationInformation.customSetupNotes,
      router_type: locationInformation.routerType,
      disclaimer_type_sent: locationInformation.disclaimerType,
      router_make_and_model: locationInformation.routerInfo,
      network_decision: locationInformation.networkNotes,
      billing_notes: locationInformation.billingNotes,
      notes: locationInformation.officeExperience,
      onboarders_location_id: snapshot.onboarders_location_id,
    };
    if (snapshot.id) {
      snapshotData.id = snapshot.id;
    }
    if (snapshot.csat_recipient_user_email) {
      snapshotData.csat_recipient_user_email = snapshot.csat_recipient_user_email;
    }
    if (snapshot.csat_sent_at) {
      snapshotData.csat_sent_at = snapshot.csat_sent_at;
    }
    if (snapshot.created_at) {
      snapshotData.created_at = snapshot.created_at;
    }
    if (snapshot.updated_at) {
      snapshotData.updated_at = snapshot.updated_at;
    }

    snapshotData.point_of_contact_email = csat_recipient_user_email;
    yield put(saveHandoffNotesSnapshot(snapshotData));

    yield call(
      CustomAxios.post,
      `support/v1/onboarding/handoffsnapshot/${handoffId}/csat`,
      { csat_recipient_user_email }
    );
    yield call(handleGetHandoffNotesData);
  } catch {
    yield put(showError(`Did not save CSAT data.`));
  }
};

export const handleGetHandoffNotesData = function* () {
  const currentLocation: ReturnType<typeof selectCurrentLocation> = yield select(
    selectCurrentLocation
  );
  try {
    const onboardersResponse = yield call(
      CustomAxios.get,
      `support/v1/onboarderslocation/${currentLocation?.LocationID}`
    );
    const onboarderLocation = onboardersResponse.data.data;
    try {
      const notesResponse = yield call(
        CustomAxios.get,
        `support/v1/onboarding/handoffsnapshot/${onboarderLocation?.ID}`,
        {
          headers: { 'X-Weave-Debug-Id': 'handoff-get-tracer' },
        }
      );
      const notes = notesResponse.data.data;

      yield put(getHandoffNotesDataSuccess(notes));
    } catch {
      // no handoffsnapshot saved yet. Use a default object
      const defaultHandoffSnapshot = {
        billing_notes: null,
        csat_recipient_user_email: null,
        csat_sent_at: null,
        customization_setup: null,
        customizations: null,
        handed_off_at: null,
        network_decision: null,
        disclaimer_type_sent: null,
        notes: null,
        onboarders_location_id: onboarderLocation.ID,
        point_of_contact_email: null,
        reason_for_purchase: null,
        router_make_and_model: null,
        router_type: null,
      };
      yield put(getHandoffNotesDataFailure(defaultHandoffSnapshot));
    }
  } catch {
    // no onboarder has been assigned to onboard the location
    const defaultHandoffSnapshot = {
      hasNoOnboarder: true,
    };
    yield put(getHandoffNotesDataFailure(defaultHandoffSnapshot));
  }
};

export const handleSubmitHandoffData = function* (action) {
  try {
    yield put(saveHandoffDate());

    const currentLocation: ReturnType<typeof selectCurrentLocation> = yield select(
      selectCurrentLocation
    );
    const preProvisionResp = yield call(
      CustomAxios.get,
      `/support/v1/preprovisions/${currentLocation?.LocationID}`
    );

    const mostRecentPreProvisionData = preProvisionResp?.data?.data?.reduce(
      (mostRecent, preProvData) => {
        if (
          !!preProvData.salesforce_opportunity_id &&
          (!mostRecent ||
            moment(preProvData.updated_at).isAfter(moment(mostRecent.updated_at)))
        ) {
          return preProvData;
        } else {
          return mostRecent;
        }
      },
      undefined
    );

    const state = yield select();
    const snapshot = state.handoffHub.snapshot;
    const handoffData = {
      ...action.payload,
      location: currentLocation,
      csatRecipientUserEmail: snapshot?.csat_recipient_user_email,
      csatSentAt: snapshot?.csat_sent_at,
      preProvisionData: mostRecentPreProvisionData,
    };

    yield call(fetch, HANDOFF_WEB_HOOK_URL, {
      credentials: 'omit',
      body: JSON.stringify(handoffData),
      method: 'POST',
    });

    yield put(
      saveFeatureFlags({
        updatedFlags: [
          {
            DisplayName: 'OnboardingBetaEnabled',
            Name: 'onboardingBetaEnabled',
            Value: false,
          },
        ],
      })
    );

    yield put(submitHandoffDataSuccess());
  } catch (error: any) {
    yield put(submitHandoffDataFailure(error));
  }
};

export const getHandoffHubDataSaga = function* () {
  yield all([
    takeEvery(getHandoffHubData.toString(), handleGetHandoffHubData),
    takeLatest(submitHandoffData.toString(), handleSubmitHandoffData),
    takeLatest(saveHandoffNotesSnapshot.toString(), handleSaveHandoffNotesSnapshot),
    takeLatest(saveCSATData.toString(), handleSaveCSATData),
    takeLatest(saveHandoffDate.toString(), handleSaveHandoffDate),
  ]);
};
