import React from 'react';
import _ from 'lodash';
import { css, cx } from 'emotion';
import { Button } from '@weave/platform-ui-components';

import { AdvancedOptionsWrapper } from './auto-reminder-advanced-options/auto-reminder-advanced-options.component';
import { EditReminderDateTime } from './auto-reminder-date-time';
import { EditReminderSendToType } from './auto-reminder-edit-type';
import {
  AdvancedOption,
  Delivery,
  NotificationAdvancedOptions,
  OptionTypeInfo,
  ReminderDetails,
  ReminderUpdate,
  SendBefore,
  Template,
} from '../../../../redux/actions/notifications-settings/notification-settings.types';

export const button = css`
  position: absolute;
  top: 75px;
  right: 20px;
`;

interface Props {
  notificationAdvancedOptions: NotificationAdvancedOptions;
  selectedAutoReminder: ReminderDetails;
  changeLogDetails: ReminderDetails | { template: {} } | null;
  clearChangeLogSelection: () => void;
  updateNotificationSettings: (statusMapSettings: ReminderUpdate) => void;
  getNotificationAdvancedOptionsList: (optionType: string) => void;
}

interface State {
  send_before: SendBefore;
  type: string;
  delivery: Delivery;
  template: Template;
  optionTypeInfo: OptionTypeInfo;
  advanced_options: AdvancedOption[];
}

const Title = () => (
  <React.Fragment>
    <div>
      <h3>Advanced Options</h3>
    </div>
    <hr />
  </React.Fragment>
);

export class OptionsWrapper extends React.Component<Props> {
  state: State = {
    send_before: { ...this.props.selectedAutoReminder.send_before },
    type: this.props.selectedAutoReminder.type,
    template: { ...this.props.selectedAutoReminder.template },
    delivery: { ...this.props.selectedAutoReminder.delivery },
    optionTypeInfo: { ...this.props.selectedAutoReminder.optionTypeInfo },
    advanced_options: [...this.props.selectedAutoReminder.advanced_options],
  };

  updateAdvancedOptions = (advanced_options, cb) => {
    this.setState({ advanced_options }, cb);
  };

  updateOptionTypeInfo = (optionTypeInfo, cb) => {
    this.setState({ optionTypeInfo }, cb);
  };

  updateSendTo = (template) => {
    this.setState({ template });
  };

  updateSendBefore = (send_before) => {
    this.setState({ send_before });
  };

  updateDelivery = (delivery) => {
    this.setState({ delivery });
  };

  handleSave = () => {
    const updateData = {
      id: this.props.selectedAutoReminder.id,
      optionTypeInfo: this.state.optionTypeInfo,
      advanced_options: this.state.advanced_options,
      send_before: this.state.send_before,
      delivery: this.state.delivery,
      template: this.state.template,
    };

    this.props.updateNotificationSettings(updateData);
  };

  disableSaveButton = () => {
    const advancedOptionsIsProps = _.isEqual(
      this.state.advanced_options,
      this.props.selectedAutoReminder.advanced_options
    );
    const optionTypeInfoIsProps = _.isEqual(
      this.state.optionTypeInfo,
      this.props.selectedAutoReminder.optionTypeInfo
    );
    const send_beforeIsProps = _.isEqual(
      this.state.send_before,
      this.props.selectedAutoReminder.send_before
    );
    const deliveryIsProps = _.isEqual(
      this.state.delivery,
      this.props.selectedAutoReminder.delivery
    );
    const templateIsProps = _.isEqual(
      this.state.template,
      this.props.selectedAutoReminder.template
    );
    return (
      advancedOptionsIsProps &&
      optionTypeInfoIsProps &&
      send_beforeIsProps &&
      deliveryIsProps &&
      templateIsProps
    );
  };
  render() {
    return (
      <>
        <Title />
        {this.state.type !== 'birthday' && (
          <>
            <EditReminderSendToType
              template={this.state.template}
              updateSendTo={this.updateSendTo}
            />
            <EditReminderDateTime
              type={this.state.type}
              send_before={this.state.send_before}
              delivery={this.state.delivery}
              updateSendBefore={this.updateSendBefore}
              updateDelivery={this.updateDelivery}
            />
          </>
        )}
        <AdvancedOptionsWrapper
          type={this.state.type}
          notificationAdvancedOptions={this.props.notificationAdvancedOptions}
          selectedAutoReminder={this.props.selectedAutoReminder}
          optionTypeInfo={this.state.optionTypeInfo}
          advanced_options={this.state.advanced_options}
          getNotificationAdvancedOptionsList={
            this.props.getNotificationAdvancedOptionsList
          }
          updateAdvancedOptions={this.updateAdvancedOptions}
          updateOptionTypeInfo={this.updateOptionTypeInfo}
        />
        <Button
          className={cx(button, 'small')}
          disabled={this.disableSaveButton()}
          onClick={this.handleSave}
        >
          Save
        </Button>
      </>
    );
  }
}
