import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import queryString from 'query-string';

import { ReminderDetails } from '../../../../redux/actions/notifications-settings/notification-settings.types';
import { ReminderActionButtons } from '../auto-reminder-modal-container/auto-reminders-action-buttons';
import { EnabledToggle } from '../auto-reminders-helpers/auto-reminder-helpers';
import * as styles from './auto-reminders-list.styles';
import { updateNotificationToggle } from '../../../../redux/actions/notifications-settings/notifications-settings.action';

interface Props {
  reminderDetails: ReminderDetails;
  setModalContent: (reminderId: string, content: string) => void;
}

type ReminderActions =
  | 'advanced_options'
  | 'logs'
  | 'template'
  | 'changeLog'
  | 'goToReview';

export const RemindersListItem = ({ reminderDetails, setModalContent }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isFocused, setIsFocused] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleToggleSwitch = () => {
    const updateSettings = {
      id: reminderDetails.id,
      enabled: !reminderDetails.enabled,
    };
    dispatch(updateNotificationToggle(updateSettings));
  };

  const goToReview = () => {
    history.push(history.location.pathname.replace('auto-reminders', 'reviews'));
  };

  useEffect(() => {
    const parsed = queryString.parse(history.location.search);
    if (parsed.reminderId === reminderDetails.id && !isFocused) {
      setIsFocused(true);
    }
  }, [reminderDetails.id]);

  const actionRouter = (action: ReminderActions) => () => {
    const id = reminderDetails.id;
    switch (action) {
      case 'advanced_options':
        setModalContent(id, 'options');
        break;
      case 'logs':
        setModalContent(id, 'logs');
        break;
      case 'template':
        setModalContent(id, 'template');
        break;
      case 'changeLog':
        setModalContent(id, 'changeLog');
        break;
      case 'goToReview':
        goToReview();
        break;
      default:
        break;
    }
  };

  const updateCopied = useCallback(() => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, []);

  const {
    name,
    type,
    delivery_type,
    modified_at = 'N/A',
    created_at = 'N/A',
    delivery_time,
    send_schedule,
    enabled,
    id,
    advanced_options,
    template,
  } = reminderDetails;
  const modifiedAt = moment(modified_at).format('MM/DD/YYYY ');
  const createdAt = moment(created_at).format('MM/DD/YYYY ');

  return (
    <div css={[styles.rowStyle, ...(isFocused ? [styles.focusedRow] : [])]}>
      <span className="bigCol" onClick={actionRouter('template')}>
        {name}
      </span>
      <span className="smallCol">
        <CopyToClipboard text={id} onCopy={updateCopied}>
          <div css={styles.copyToClipboard} title="Copy to clipboard">
            <div>
              <span>{`${id.slice(0, 4)}...`}</span>
              {copied ? <span css={styles.copied}>Copied!</span> : null}
            </div>
          </div>
        </CopyToClipboard>
      </span>
      <span className="bigCol">
        {delivery_type} - {template.type}
      </span>
      <span> {`${delivery_time} ${send_schedule}`} </span>
      <span>{`${createdAt} - ${modifiedAt}`}</span>
      <span className="smallCol">{!advanced_options.length ? 'False' : 'True'}</span>
      <ReminderActionButtons
        id={id}
        type={type}
        css={styles.actionButtons}
        actionRouter={actionRouter}
      />
      <span className="reminder-toggle">
        {type !== 'review' && (
          <EnabledToggle enabled={enabled} handleToggleSwitch={handleToggleSwitch} />
        )}
      </span>
    </div>
  );
};
