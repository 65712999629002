import React from 'react';
import _ from 'lodash';

import { EditAdvancedOptions } from './auto-reminder-edit-advanced-options.component';
import { AutoScrollDiv } from './auto-reminder-selected-advanced-options.component';

import {
  AdvancedOption,
  NotificationAdvancedOptions,
  OptionTypeInfo,
  ReminderDetails,
  AdvancedFilterKeys,
} from '../../../../../redux/actions/notifications-settings/notification-settings.types';

import * as styles from './auto-reminder-advanced-options.styles';

interface Props {
  type: string;
  notificationAdvancedOptions: NotificationAdvancedOptions;
  selectedAutoReminder: ReminderDetails;
  updateAdvancedOptions: (advanced_options: AdvancedOption, cb: Function) => void;
  updateOptionTypeInfo: (optionTypeInfo: OptionTypeInfo, cb: Function) => void;
  optionTypeInfo: OptionTypeInfo;
  advanced_options: AdvancedOption[];
  getNotificationAdvancedOptionsList: (optionType: string) => void;
}

interface State {
  consolidatedOptions: {
    [key: string]: {
      excludeOptions: boolean;
      values: string[];
    };
  };
  viewOptionList: AdvancedFilterKeys | '';
}

const advancedOptionMap = (type) => {
  switch (type) {
    case 'recall':
      return ['recall-type', 'location'];
    case 'birthday':
      return ['location'];
    case 'missedcall':
      return ['appointment-type', 'practitioner', 'operatory'];
    case 'schedule-reactivation':
      return ['appointment-type', 'practitioner', 'operatory'];
    default:
      return ['appointment-type', 'practitioner', 'operatory', 'location', 'data-source'];
  }
};

export class AdvancedOptionsWrapper extends React.Component<Props> {
  state: State = {
    consolidatedOptions: {},
    viewOptionList: '',
  };

  componentDidMount() {
    this.consolidateOptionInfo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedAutoReminder.id !== this.props.selectedAutoReminder.id) {
      this.consolidateOptionInfo();
    }
  }

  consolidateOptionInfo = () => {
    const consolidatedOptions = _.cloneDeep(this.props.optionTypeInfo);
    Object.keys(consolidatedOptions).forEach((key) => {
      consolidatedOptions[key].values = this.props.advanced_options
        .filter((option) => option.key === key)
        .map((value) => value.value);
    });
    this.setState({ consolidatedOptions });
  };

  toggleOptionTypeList = (type) => () => {
    this.props.getNotificationAdvancedOptionsList(type);
    this.setState({ viewOptionList: type }, this.scrollToBottom);
  };

  handleCheckBox = (type, optionValue) => {
    let advanced_options;
    const optionExists = !!this.props.advanced_options.find(
      (option) => option.key === type && option.value === optionValue
    );
    if (optionExists) {
      advanced_options = this.props.advanced_options.filter(
        (option) =>
          (option.key === type && option.value !== optionValue) || option.key !== type
      );
    } else {
      advanced_options = [
        ...this.props.advanced_options,
        { key: type, value: optionValue },
      ];
    }
    this.props.updateAdvancedOptions(advanced_options, this.refreshOptionList);
  };

  toggleExcludeOption = (type, bool) => {
    const optionTypeInfo = _.cloneDeep(this.props.optionTypeInfo);
    optionTypeInfo[type].excludeOptions = bool;
    this.props.updateOptionTypeInfo(optionTypeInfo, this.refreshOptionList);
  };

  refreshOptionList = () => {
    this.consolidateOptionInfo();
    this.scrollToBottom();
  };

  scrollToBottom = () => {
    if (!this.state.viewOptionList) {
      return;
    }
    const scrollHeight = this[this.state.viewOptionList].scrollHeight;
    const height = this[this.state.viewOptionList].clientHeight;
    const maxScrollTop = scrollHeight - height;
    this[this.state.viewOptionList].scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  setRef = (key, ref) => {
    this[key] = ref;
  };

  render() {
    const allOptsInfo =
      this.props.notificationAdvancedOptions?.[this.state.viewOptionList] ?? [];
    const selectedOpts = this.state.consolidatedOptions[this.state.viewOptionList];

    const selectedValues = selectedOpts?.values ?? [];
    const selectedOptions = [
      ...(allOptsInfo.filter((opt) => selectedValues.includes(opt.value)) ?? []),
    ];
    const notSelectedOptions = [
      ...(allOptsInfo.filter((opt) => !selectedValues.includes(opt.value)) ?? []),
    ];

    const sortedOptions = [...selectedOptions, ...notSelectedOptions];
    return (
      <React.Fragment>
        <div className={styles.columns}>
          <div>
            {advancedOptionMap(this.props.type).map((key, index) => (
              <AutoScrollDiv
                key={`${key}-${index}`}
                optionKey={key}
                setRef={this.setRef}
                viewOptionList={this.state.viewOptionList}
                toggleOptionTypeList={this.toggleOptionTypeList}
                index={index}
                info={this.state.consolidatedOptions[key]}
              />
            ))}
          </div>
          <EditAdvancedOptions
            type={this.state.viewOptionList}
            consolidatedOption={selectedOpts}
            list={sortedOptions}
            handleCheckBox={this.handleCheckBox}
            toggleExcludeOption={this.toggleExcludeOption}
          />
        </div>
      </React.Fragment>
    );
  }
}
