import React from 'react';
import { css, cx } from 'emotion';
import { noop } from 'lodash';

export const contentWrapper = css`
  height: 200px;
  width: 450px;

  background: #f5f6f5;
  border-radius: 6px;
  box-shadow: 1px 1px 5px lightgrey;

  label: contentWrapper;
`;

export const body = css`
  padding: 0 10px;

  label: body;
`;

export const title = css`
  text-align: center;
  line-height: 1.3em;

  label: title;
`;

const flexButtons = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  bottom: 10px;
  right: 5px;
  left: 5px;

  label: flexButtons;
`;

export const buttonStyle = css`
  margin: 2px 4px;
  padding: 6px 14px;
  border-radius: 4px;
  color: white;

  label: buttonStyle;
`;
const one = css`
  background-color: #bfbfbf;
`;
const two = css`
  background-color: #79c6d2;
`;
const three = css`
  background-color: #ee2375;
`;
const four = css`
  background-color: #a92070;
`;
const five = css`
  background-color: #e4ac34;
`;
const six = css`
  background-color: #407c85;
`;
const messageStyle = css`
  margin: 10px auto;
  font-size: 0.875rem;
  color: #909090;
  height: 72px;
  overflow: auto;
  text-align: center;

  label: messageStyle;
`;

interface Props {
  message: string;
  messageId: string;
  classifications: string[];
  getStatement: () => void;
  updateSentiment: (label: string, id: string) => void;
}

export class Labeler extends React.Component<Props, {}> {
  componentDidMount() {
    if (typeof this.props.getStatement === 'function') {
      this.props.getStatement();
    }
  }

  render() {
    const buttonStyles = [three, five, four, six, two, one];
    const { message, classifications = [], updateSentiment = noop } = this.props;
    return (
      <div className={cx('labeler', contentWrapper)}>
        <h4 className={title}>Apply the most appropriate label</h4>
        <div className={body}>
          <div className={messageStyle}>{message}</div>
          <div className={flexButtons}>
            {classifications.map((label, idx) => {
              return (
                <button
                  key={`${label}${idx}`}
                  className={cx(
                    buttonStyle,
                    buttonStyles[(idx + buttonStyles.length) % buttonStyles.length]
                  )}
                  onClick={() => updateSentiment(label, this.props.messageId)}
                >
                  {label}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
