import { createAction } from 'redux-actions';
import { call, put } from 'redux-saga/effects';
import { CustomAxios } from '../../../axios';

export const sentimentTrainingRank = createAction('SENTIMENT_TRAINING_RANK');
export const sentimentTrainingRankSuccess = createAction(
  'SENTIMENT_TRAINING_RANK_SUCCESS'
);
export const sentimentTrainingRankFailure = createAction<Error>(
  'SENTIMENT_TRAINING_RANK_FAILURE'
);

export const sentimentTrainingRankUrl = '/support/v1/sentimenttraining/rank';

export const handleSentimentTrainingRank = function* (action) {
  try {
    const response = yield call(CustomAxios.get, sentimentTrainingRankUrl);
    yield put(sentimentTrainingRankSuccess(response.data.data));
  } catch (error: any) {
    yield put(sentimentTrainingRankFailure(error));
  }
};
