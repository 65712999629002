import { Reducer } from 'redux';
import { Store } from '../../../store/store.model';

// export interface LocationCreationResponseModel {
//   activated_packages_successfully?: boolean;
//   customization_flags_setup_successfully: boolean;
//   e911_setup_successfully: boolean;
//   location_created_successfully: boolean;
//   phone_data_tenant_created_successfully: boolean;
//   software_integration_setup_successfully: boolean;
//   temp_number_setup_successfully: boolean;
//   location_id: string;
// }

export interface LocationCreationResponseModel {
  activated_packages_error: string;
  activated_packages_successfully: boolean;
  customization_flags_error: string;
  customization_flags_setup_successfully: boolean;
  e911_setup_error: string;
  e911_setup_successfully: boolean;
  location_created_error: string;
  location_created_successfully: boolean;
  location_id: string;
  phone_data_tenant_created_error: string;
  phone_data_tenant_created_successfully: boolean;
  software_integration_error: string;
  software_integration_setup_successfully: boolean;
  temp_number_setup_error: string;
  temp_number_setup_successfully: boolean;
  user_created_error: string;
  user_created_successfully: boolean;
}

export enum LocationStatusActionTypes {
  CreateLocationSuccess = 'CREATE_LOCATION_SUCCESS',
  CreateLocationFailure = 'CREATE_LOCATION_FAILURE',
  CreateLocationLoading = 'CREATE_LOCATION_LOADING',
  ResetLocationCreationStatus = 'RESET_LOCATION_CREATION_STATUS',
}

type Action<ActionType extends LocationStatusActionTypes, Payload = undefined> = {
  type: ActionType;
} & { payload: Payload };
export type CreateLocationSuccessAction = Action<
  LocationStatusActionTypes.CreateLocationSuccess,
  LocationCreationResponseModel
>;
export type CreateLocationFailureAction =
  Action<LocationStatusActionTypes.CreateLocationFailure>;
export type CreateLocationLoadingAction =
  Action<LocationStatusActionTypes.CreateLocationLoading>;
export type ResetLocationCreationStatusAction =
  Action<LocationStatusActionTypes.ResetLocationCreationStatus>;

export type LocationStatusActions =
  | CreateLocationSuccessAction
  | CreateLocationFailureAction
  | CreateLocationLoadingAction
  | ResetLocationCreationStatusAction;

export type LocationStatusState = {
  loading: boolean;
  creationStatus: LocationCreationResponseModel | null;
};

export const createLocationSuccess = (
  res: LocationCreationResponseModel
): LocationStatusActions => ({
  type: LocationStatusActionTypes.CreateLocationSuccess,
  payload: res,
});
export const createLocationFailure = (): LocationStatusActions => ({
  type: LocationStatusActionTypes.CreateLocationFailure,
  payload: undefined,
});
export const createLocationLoading = (): LocationStatusActions => ({
  type: LocationStatusActionTypes.CreateLocationLoading,
  payload: undefined,
});
export const resetLocationCreationStatus = (): LocationStatusActions => ({
  type: LocationStatusActionTypes.ResetLocationCreationStatus,
  payload: undefined,
});

export const locationStatusReducer: Reducer<LocationStatusState, LocationStatusActions> =
  (state = { loading: false, creationStatus: null }, action) => {
    switch (action.type) {
      case LocationStatusActionTypes.CreateLocationLoading:
        return { ...state, loading: true, creationStatus: null };
      case LocationStatusActionTypes.CreateLocationSuccess:
        return { ...state, loading: false, creationStatus: action.payload };
      case LocationStatusActionTypes.CreateLocationFailure:
        return {
          ...state,
          loading: false,
          creationStatus: {
            activated_packages_error: '',
            activated_packages_successfully: false,
            customization_flags_error: '',
            customization_flags_setup_successfully: false,
            e911_setup_error: '',
            e911_setup_successfully: false,
            location_created_error: '',
            location_created_successfully: false,
            location_id: '',
            phone_data_tenant_created_error: '',
            phone_data_tenant_created_successfully: false,
            software_integration_error: '',
            software_integration_setup_successfully: false,
            temp_number_setup_error: '',
            temp_number_setup_successfully: false,
            user_created_error: '',
            user_created_successfully: false,
          },
        };
      case LocationStatusActionTypes.ResetLocationCreationStatus:
        return { ...state, loading: false, creationStatus: null };
      default:
        return state;
    }
  };

export const selectLocationCreationStatus = (store: Store) => store.locationStatus;
