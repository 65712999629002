import React from 'react';

import { AddNumber } from '../add-number/add-number.component';
import { ForwardingNumber, ForwardingStatus } from '../../../models';
import { ForwardingNumberRow } from '../forwarding-number-row/forwarding-number-row.component';
import { ToggleSwitch } from '../../../../../shared/toggle-switch/toggle-switch.component';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { Heading, Info } from '@weave/design-system';
interface Props {
  addForwardingNumber: (ForwardingNumber) => void;
  updateForwardingNumber: (ForwardingNumber) => void;
  deleteForwardingNumber: (ForwardingNumber) => void;
  forwardingNumbers: ForwardingNumber[];
  forwardingStatus: ForwardingStatus;
  selectForwardingNumber: (forwardingNumber: string) => void;
  toggleCallForwarding: () => void;
}

export const CallForwarding = (props: Props) => {
  const [popoverOpen, setPopoverOpen] = React.useState<boolean>(false);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  return (
    <div className="settings-container">
      <Heading level={2} className="settings-title">
        Call Forwarding
        <Info className="settings-subtitle">
          To activate Forwarding, toggle the switch on, choose a number, and press 'Save'.
        </Info>
      </Heading>
      <ToggleSwitch
        on={props.forwardingStatus.enabled}
        label="Call Forwarding"
        onChange={props.toggleCallForwarding}
      />
      {props.forwardingStatus.enabled && (
        <div>
          <span
            css={css`
              margin: ${theme.spacing(1, 0)};
              font-size: ${theme.fontSize(16)};
              font-weight: 600;
            `}
            className="settings-subtitle"
          >
            Forwarding Numbers:
          </span>
          <table
            css={css`
              padding: 0;
              & tr td {
                padding: ${theme.spacing(0.5)};
              }
            `}
            className="table table-borderless forwarding-numbers-table"
          >
            <tbody>
              {props.forwardingNumbers.map((forwardingNumber: ForwardingNumber) => {
                return (
                  <ForwardingNumberRow
                    key={forwardingNumber.id}
                    selected={props.forwardingStatus.id === forwardingNumber.id}
                    deleteRow={props.deleteForwardingNumber}
                    forwardingNumber={forwardingNumber}
                    forwardingStatus={props.forwardingStatus}
                    selectForwardingNumber={props.selectForwardingNumber}
                    update={props.updateForwardingNumber}
                  />
                );
              })}
            </tbody>
          </table>
          <AddNumber
            isOpen={popoverOpen}
            toggle={togglePopover}
            addNew={props.addForwardingNumber}
            forwardingNumbers={props.forwardingNumbers}
          >
            Add Forwarding Number
          </AddNumber>
        </div>
      )}
    </div>
  );
};
