import React from 'react';
import { css } from 'emotion';
import { RadioGroup, Radio, Label, Button } from '@weave/platform-ui-components';
import { withRouter } from 'react-router-dom';
import { LOCATION } from '../../../constants/paths';

interface State {
  locationType: string;
}

class LocationCreateWizardComponent extends React.Component<any, State> {
  constructor(props) {
    super(props);
    this.state = {
      locationType: 'dev',
    };
  }

  render() {
    return (
      <div
        className={css`
          margin: 30px;
        `}
      >
        <div>What kind of location would you like to create: </div>
        <RadioGroup>
          <div>
            <Radio
              id="dev"
              value="dev"
              name="locationcreate"
              onClick={(event: any) => {
                this.setState({ locationType: event.target.value });
              }}
              checked={this.state.locationType === 'dev'}
              readOnly
            />
            <Label htmlFor="dev">A dev location and phone tenant</Label>
          </div>
          <div>
            <Radio
              id="demo"
              value="demo"
              name="locationcreate"
              onClick={(event: any) => {
                this.setState({ locationType: event.target.value });
              }}
              checked={this.state.locationType === 'demo'}
              readOnly
            />
            <Label htmlFor="demo">
              A demo location and phone tenant (for sales, onboarding, etc.)
            </Label>
          </div>
          <div>
            <Radio
              id="newcustomer"
              value="newcustomer"
              name="locationcreate"
              onClick={(event: any) => {
                this.setState({ locationType: event.target.value });
              }}
              checked={this.state.locationType === 'newcustomer'}
              readOnly
            />
            <Label htmlFor="newcustomer">
              A new customer and phone tenant (not recommended - do it through Salesforce)
            </Label>
          </div>
        </RadioGroup>

        <Button
          className={css`
            margin-top: 14px;
          `}
          onClick={() => {
            if (this.state.locationType === 'demo') {
              this.props.history.push(LOCATION.demo);
            } else {
              this.props.history.push(LOCATION.new);
            }
          }}
        >
          Next
        </Button>
      </div>
    );
  }
}

export const LocationCreateWizard = withRouter(LocationCreateWizardComponent);
