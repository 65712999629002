import { useEffect, useState } from 'react';
import { Mapping, defaultMapping } from './status-mapping.config';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../../redux/actions/location/current-location';
import { CustomAxios } from '../../../../redux/axios';
import { useResource } from '../../../shared/hooks/use-resource.hook';

const url = `/support/v1/notifications/status-mappings`;

export const useStatusMapping = (type, sourceID) => {
  const locationID = useSelector(selectCurrentLocationId);
  const [externalValues, setExternalValues] = useState<string[]>([]);
  const [mapping, setMapping] = useState<Mapping>(defaultMapping[type] || {});
  const [mapUsage, setMapUsage] = useState<string[]>([]);
  const [originalMapping, setOriginalMapping] = useState({ ...mapping });

  const { data: statusMappingData }: any = useResource({
    url: `${url}/${type}`,
    deps: [type, locationID],
    emptyState: [],
  });

  useEffect(() => {
    if (statusMappingData.length) getSource();
  }, [sourceID, statusMappingData.length]);

  useEffect(() => {
    saveMapping();
  }, [mapUsage.toString()]);

  const getSource = () => {
    let statusMapping = {
      ExternalValues: externalValues,
      Mapping: mapping,
    };
    if (!sourceID && statusMappingData.length) {
      console.warn(`The PMS settings don't match the source ID.`);
      statusMapping = statusMappingData[0];
    } else {
      statusMapping = statusMappingData.find((item) => {
        return item.SourceID === sourceID;
      }) || {
        ExternalValues: [],
        Mapping: {},
      };
    }
    const mappingObj = sanitizeStatusMapping(statusMapping?.Mapping ?? {});
    setExternalValues(statusMapping?.ExternalValues ?? []);
    setMapping(mappingObj);
    setOriginalMapping(mappingObj);
    setMapUsage(updateMapUsage(mappingObj));
  };

  const sanitizeStatusMapping = (mapping: Mapping) => {
    Object.keys(mapping).forEach((key) => {
      mapping[key] = mapping[key].filter((e) => e);
    });
    return mapping;
  };

  const updateMapUsage = (data: Mapping) => {
    return Object.values(data)?.reduce<string[]>(
      (previous: string[], value: string[]) => {
        return [...previous, ...value];
      },
      []
    );
  };

  const updateMapping = (evt, key) => {
    let value = evt?.target?.value ?? evt;
    // NOTE when value === string: handling for confirmation mapping
    if (typeof value === 'string') {
      value = [value];
    }
    const data = { ...mapping, [key]: value.filter((e) => e) };
    setMapping(data);
    setMapUsage(updateMapUsage(data));
  };

  const saveMapping = () => {
    const mappingChanged = Object.entries(mapping)?.reduce((previous, [key, value]) => {
      return !previous ? !(originalMapping[key] === value) : previous;
    }, false);

    if (mappingChanged) {
      const data = {
        Mapping: mapping,
        SourceID: sourceID,
      };
      CustomAxios.post(`${url}/${type}`, data)
        .then((res) => {
          setOriginalMapping(mapping);
        })
        .catch((err) => console.error(err));
    }
  };

  return {
    mapUsage,
    externalValues,
    mapping,
    setMapping,
    updateMapping,
  };
};
