import { CustomAxios, getResponseData } from '../../redux/axios';
import {
  MediaType,
  PhoneBillDownloadPayload,
  PortCreationFileResponseData,
  PortingData,
  PortViewFileUploadResponse,
  UploadLOAMediaType,
  UploadLOAPayloadType,
} from './porting.types';

export const postPortingData = (body: PortingData): Promise<PortingData> =>
  CustomAxios.post('/support/v1/portingdata', body).then(getResponseData);

export const sendToSalesforce = (batchId: string): Promise<any> =>
  CustomAxios.post(
    `/support/v1/portingdata/portins/${batchId}/salesforce/create`,
    {}
  ).then(getResponseData);

export const uploadPhoneFile = (data: FormData) =>
  CustomAxios.post('/support/v1/media', data).then<PortCreationFileResponseData>(
    getResponseData
  );

export const deleteFile = (type: MediaType, mediaID: string) =>
  CustomAxios.delete(`/support/v1/media/${type}/${mediaID}`).then<unknown>(
    getResponseData
  );

//api for upload loa
export const portViewLoaUpload = (portingDataID: string, data: UploadLOAMediaType) => {
  const payloadData: UploadLOAPayloadType = {
    content_type: 'application/pdf',
    media_data: data,
  };
  return CustomAxios.put(
    `/support/v1/portingdata/${portingDataID}/loa`,
    payloadData
  ).then<PortViewFileUploadResponse>(getResponseData);
};

//api for upload bills and delete file from porting view page
export const portViewBillsUpload = (portingDataID: string, data: UploadLOAMediaType) => {
  const payloadData: UploadLOAPayloadType = {
    content_type: 'application/pdf',
    media_data: data,
  };
  return CustomAxios.post(
    `/support/v1/portingdata/${portingDataID}/media`,
    payloadData
  ).then<PortViewFileUploadResponse>(getResponseData);
};

export const portViewFileDelete = (portID: string, mediaID: string) =>
  CustomAxios.delete(`/support/v1/portingdata/${portID}/media/${mediaID}`).then<unknown>(
    getResponseData
  );

//download endpoints for view page
export const portViewLoaDownload = (portingID: string) =>
  CustomAxios.get(`/support/v1/portingdata/loa/${portingID}`, {
    responseType: 'blob',
  });

export const portViewBillDownload = (phoneBillMediaObj: PhoneBillDownloadPayload) => {
  const { location_id, customer_phone_bill_media_id, customer_phone_bill_media_type } =
    phoneBillMediaObj;
  return CustomAxios.get(
    `/support/v1/media/${location_id}/${customer_phone_bill_media_id}/${customer_phone_bill_media_type}`,
    {
      responseType: 'blob',
    }
  );
};

export const clonePortingData = (portingDataId: string): Promise<PortingData> =>
  CustomAxios.post(`/support/v1/portingdata/${portingDataId}/clone`).then<PortingData>(
    getResponseData
  );

export const cancelPortOrder = (portingDataId: string) =>
  CustomAxios.post(
    `/support/v1/portingdata/portins/${portingDataId}/cancel`
  ).then<PortingData>(getResponseData);

export const portingApi = {
  postPortingData,
  uploadPhoneFile,
  deleteFile,
  portViewBillsUpload,
  portViewFileDelete,
  portViewLoaDownload,
  clonePortingData,
};
