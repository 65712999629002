import React from 'react';
import { Col, Row } from 'reactstrap';
import { css } from 'emotion';
import { HandoffCard } from '../handoff-card/handoff-card.component';
import { Categories, KeyFactor } from '../../handoff-hub.types';

const cardColumn = css`
  margin-bottom: 10px;
`;

export type HandoffCardsSectionProps = {
  categories: Categories;
  calculateHealthScore: (keyFactors: KeyFactor[]) => any;
};

export const HandoffCardsSection = ({
  categories,
  calculateHealthScore,
}: HandoffCardsSectionProps) => {
  return (
    <Row>
      {Object.values(categories).map((category, index) => (
        <Col xl="6" className={cardColumn} key={index}>
          <HandoffCard
            categoryId={category.categoryId}
            categoryName={category.label}
            keyFactors={category.factors}
            healthScore={calculateHealthScore(category.factors)}
          />
        </Col>
      ))}
    </Row>
  );
};
