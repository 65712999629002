import { CustomAxios, getResponseData } from '../../../axios';
import {
  LocationModel,
  OfficeInfo,
  LocationSummary,
  stripLocationEntities,
  convertLocationToProvisionLocationDTO,
  CreateLocationDTO,
} from './locations.types';
import { LocationCreationResponseModel } from '../location-status';

export const getLocation = (locationId: string): Promise<LocationModel> =>
  CustomAxios.get(`support/v1/locations/${locationId}`).then(getResponseData);

export const getLocationSummary = (locationId: string): Promise<LocationSummary> =>
  CustomAxios.get(`support/v1/locations/${locationId}/summary`).then(getResponseData);

export const provisionLocation = (
  location: CreateLocationDTO
): Promise<LocationCreationResponseModel> => {
  const provisionData = convertLocationToProvisionLocationDTO(location);
  return CustomAxios.post(`support/v1/initialprovision`, provisionData).then(
    getResponseData
  );
};

export const createLocation = (location: CreateLocationDTO): Promise<string> =>
  CustomAxios.post(`support/v1/locations`, location).then(getResponseData);

export const updateLocation = (
  locationId: string,
  location: LocationModel
): Promise<null> =>
  CustomAxios.put(
    `support/v1/locations/${locationId}`,
    stripLocationEntities(location)
  ).then(getResponseData);

export const setLocationParent = (
  locationId: string,
  parentLocationId: string
): Promise<null> =>
  CustomAxios.put(
    `support/v1/locations/${locationId}/parent`,
    parentLocationId ? { ParentID: parentLocationId } : {}
  ).then(getResponseData);

export const setPhoneTenantId = (
  locationId: string,
  phoneTenanatId: string
): Promise<null> =>
  CustomAxios.put(
    `support/v1/locations/${locationId}/phone-tenant`,
    phoneTenanatId ? { PhoneTenantID: phoneTenanatId } : {}
  ).then(getResponseData);

export const updateOfficeInfo = (info: OfficeInfo): Promise<boolean> =>
  CustomAxios.post(`support/v1/officeinfo`, info).then(getResponseData);
