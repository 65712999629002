import { CustomAxios, getResponseData } from '../../../src/redux/axios/axios';
import {
  MediaMigrationStatusRes,
  NumberingMigrationStatusRes,
  TenantMigrationNumberingReq,
} from './tenant-migration.types';

export const tenantMigrationApi = {
  getMediaStatus: () =>
    CustomAxios.get(
      `support/v1/phone/migrate/media/status`
    ).then<MediaMigrationStatusRes>(getResponseData),
  getNumberingStatus: () =>
    CustomAxios.get(
      `support/v1/phone/migrate/tenant/status`
    ).then<NumberingMigrationStatusRes>(getResponseData),
  migrateMedia: () =>
    CustomAxios.post(`support/v1/phone/migrate/media`).then<unknown>(getResponseData),
  migrateNumbering: (map: TenantMigrationNumberingReq) =>
    CustomAxios.post(`support/v1/phone/migrate/tenant`, map).then<unknown>(
      getResponseData
    ),
};

export const mockTenantMigrationApi = {
  getMediaStatus: () =>
    //GET `support/v1/phone/migrate/media/status`
    Promise.resolve<MediaMigrationStatusRes>({
      srcCallRecordingCount: 8743,
      dstCallRecordingCount: 1762,
      srcMediaCount: 7645,
      dstMediaCount: 1543,
    }),
  getNumberingStatus: () =>
    //GET `support/v1/phone/migrate/tenant/status`
    Promise.resolve<NumberingMigrationStatusRes>({
      migrationCompleted: false,
      destinationTenantNumbering: [
        {
          number: 9000,
          extensionName: 'General VM Check',
          extensionInstructionSetName: 'General VM Check',
          voicemailBoxName: 'General',
        },
      ],
      sourceTenantNumbering: [
        {
          number: 101,
          extensionName: 'Phone 101',
          extensionInstructionSetName: 'Phone 101 Direct',
          voicemailBoxName: 'Phone 101',
        },
        {
          number: 104,
          extensionName: 'Phone 104',
          extensionInstructionSetName: 'Phone 104 Direct',
          voicemailBoxName: 'Phone 104',
        },
        {
          number: 9000,
          extensionName: 'General VM Check',
          extensionInstructionSetName: 'General VM Check',
          voicemailBoxName: 'General',
        },
        {
          number: 9002,
          extensionName: '',
          extensionInstructionSetName: '',
          voicemailBoxName: 'Emergency 1',
        },
      ],
    }),
  // POST `support/v1/phone/migrate/media`
  migrateMedia: () => Promise.resolve(),

  //POST `support/v1/phone/migrate/tenant`
  migrateNumbering: (map: TenantMigrationNumberingReq) => Promise.resolve(),
};
