import { css } from 'emotion';

export const container = css`
  display: flex;
`;
export const packageContainer = css`
  border: 1px solid #d4dbdf;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b7b7b7;
  font-weight: bold;
  text-align: center;
  path {
    stroke: #b7b7b7;
  }

  .no-hover:hover {
    color: inherit;
    background-color: inherit;
    path {
      fill: inherit;
      stroke: inherit;
    }
    rect {
      stroke: inherit;
    }
  }
`;
export const packageNameStyle = css`
  cursor: pointer;
  position: relative;
  border-right: 1px solid #d4dbdf;
  width: 150px;
  height: 150px;
  padding-top: 30px;
  &:hover {
    background: #79e7ff;
    color: white;
    path {
      stroke: white;
    }
  }
`;
export const subPackagesContainer = css`
  font-style: italics;
  height: 150px;
  width: 100px;
  font-size: 12px;
`;
export const subPackageStyles = css`
  cursor: pointer;
  height: 75px;
  border-bottom: 1px solid #d4dbdf;
  padding-top: 13px;
  &:hover {
    background: #79e7ff;
    color: white;
    path {
      stroke: white;
    }
  }
`;
export const softwareOnly = css`
  padding-top: 13px;
  path {
    fill: #b7b7b7;
    stroke: transparent;
  }
  &:hover {
    background: #79e7ff;
    color: white;
    path {
      fill: white;
      stroke: transparent;
    }
    rect {
      fill: #79e7ff;
      stroke: white;
    }
  }
`;
export const selectedStyleStroke = css`
  &:hover {
    background: #37cff1;
  }
  background: #37cff1;
  color: white;
  path {
    stroke: white;
  }
`;
export const selectedStyleFill = css`
  &:hover {
    background: #37cff1;
  }
  background: #37cff1;
  color: white;
  svg {
    path {
      fill: white;
    }
  }
`;
export const selectedSubPackage = css`
  &:hover {
    background: #37cff1;
    rect {
      fill: #37cff1;
      stroke: white;
    }
  }
  background: #37cff1;
  color: white;
  svg {
    path {
      fill: white;
      stroke: transparent;
    }
    rect {
      fill: #37cff1;
      stroke: white;
    }
  }
`;
export const noCSVStyle = css`
  text-decoration: line-through;
  font-size: 25px;
  line-height: 37px;
  margin-top: -5px;
`;
export const packageNamePositioning = css`
  position: absolute;
  bottom: 12px;
  width: 100%;
  margin: 0 auto;
`;
export const weaveIconStyling = css`
  width: 68px;
  color: #b7b7b7;
  path {
    fill: #b7b7b7;
    stroke: white;
  }
  rect {
    stroke: white;
  }
`;
export const weaveContainerStyling = css`
  padding-top: 40px;
  &:hover {
    color: white;
    path {
      fill: white;
      stroke: white;
    }
    rect {
      stroke: white;
    }
  }
`;
export const loader = css`
  margin: 20px auto;
`;
export const subPackageText = css`
  font-size: 11px;
  margin-top: 3px;
`;
export const plusIconStyles = css`
  font-size: 32px;
  line-height: 37px;
  margin-top: -5px;
`;

export const phoneIconStyles = css`
  /* Had to use a specific class in combination with !important to apply these styles
  since the shared-ui library icons are using !important styles */
  &.packageIcon {
    width: 68px !important;
    height: 68px !important;
    stroke-width: 0%;
  }
`;
