import React, {
  useEffect,
  createContext,
  useMemo,
  useContext,
  useState,
  useCallback,
} from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { AuthApi } from '../redux/actions/auth/auth.api';
import { logoutFirebase } from '../redux/actions/firebase-token';
import { REACT_APP_API_URL, REACT_APP_OKTA_CLIENT_ID } from '../config/app';
import { setLoading, unsetLoading, useAlert } from '@weave/alert-system';
import {
  handleLocalStorage,
  handleAuthFromLocalStorage,
  setUpAuthInterceptor,
  authInterceptor,
} from '../redux/actions/auth/auth.helpers';
import { axiosInstance } from '../redux/axios';
import * as authTypes from '../redux/actions/auth/auth.types';
import * as authActions from '../redux/actions/auth/auth.action';

const authContext = createContext<authTypes.AuthContextValue>(
  {} as authTypes.AuthContextValue
);

export const AuthProvider: React.FC<authTypes.AuthProviderProps> = ({
  oktaAuth,
  googleAuth,
  children,
}) => {
  const midway = handleLocalStorage.get(authTypes.AuthStorage.midway);

  const [signInMode, setSignInMode] = useState<string>(
    (['test', 'development'].includes(process.env.NODE_ENV) && !midway) ||
      REACT_APP_API_URL.includes('dev')
      ? authTypes.SignInMode.google
      : authTypes.SignInMode.okta
  );
  const alerts = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const localToken = handleLocalStorage.get(authTypes.AuthStorage.weave_token);

  const handleSignOut = async (expired?: boolean) => {
    await dispatch(authActions.signOut());
    await dispatch(logoutFirebase());
    axiosInstance.interceptors.request.eject(authInterceptor);
    /* If sessions on either Google or Okta are expired we don't want to call the endpoints */
    if (expired) return;

    /* Okta Sign Out */
    if (signInMode === authTypes.SignInMode.okta) {
      try {
        await AuthApi.DELETE.session();
      } catch (err: any) {
        console.log(err.message || 'Sighing you in with alternative method. ');
        const okta_id_token = handleLocalStorage.get(authTypes.AuthStorage.okta_id_token);
        window.location.replace(
          `https://auth.weaveconnect.com/oauth2/default/v1/logout?client_id=${REACT_APP_OKTA_CLIENT_ID}&id_token_hint=${okta_id_token}&post_logout_redirect_uri=${window.location.origin}`
        );
      }
    } else {
      /* Google Sign Out */
      window.location.replace(
        `https://getweave.auth0.com/v2/logout?client_id=qaxIDSGsXuBypfF8d1qsFhMVQY0ZdCHQ&returnTo=${window.location.origin}`
      );
    }
  };

  const handleGoogleAuthentication = (initRoute: string) => {
    googleAuth.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        handleLocalStorage.create(
          authTypes.AuthStorage.user_email,
          authResult.idTokenPayload.email
        );

        const user = new authTypes.SignInUser();
        user.username = '';
        user.password = '';
        //@ts-ignore
        user.auth0 = authResult;

        dispatch(
          authActions.handleGoogleAuth({
            credentials: user,
            redirectPath: initRoute || '/',
          })
        );
        /* Set Up the Axios Interceptor */
        setUpAuthInterceptor(dispatch, handleSignOut, alerts);
      } else if (err) {
        //@ts-ignore
        console.error(err.message || 'Problem parsing url for google authentication.');
      }
    });
  };

  const handleOktaAuthentication = async (alternativeMethod: boolean) => {
    const originalPath = oktaAuth.getOriginalUri();
    dispatch(setLoading('SIGN_IN'));
    try {
      const { email, id_token, access_token } = alternativeMethod
        ? await AuthApi.GET.oktaTokenAlternative(oktaAuth)
        : await AuthApi.GET.oktaToken(oktaAuth);

      if (!email && !id_token && !access_token)
        throw new Error("Couldn't retrieve user information for Okta auth.");

      dispatch(
        authActions.handleOktaAuth({
          tokens: { oktaAccessToken: access_token!, oktaIdToken: id_token! },
          redirectPath: originalPath || '/',
          email: email!,
        })
      );
      /* Set Up the Axios Interceptor */
      setUpAuthInterceptor(dispatch, handleSignOut, alerts);
      oktaAuth.removeOriginalUri();
    } catch (err: any) {
      alerts.error(err?.message || 'Failed to retrieve tokens.');
      setSignInMode(authTypes.SignInMode.google);
      dispatch(unsetLoading('SIGN_IN'));
    }
  };

  const handleOktaAuthFlow = useCallback(async () => {
    const isAuthenticated = await handleAuthFromLocalStorage(
      dispatch,
      handleSignOut,
      alerts
    );

    if (!oktaAuth || isAuthenticated) return;

    try {
      const session: boolean = await AuthApi.GET.session();
      if (!session) return;
      handleOktaAuthentication(false);
    } catch (err: any) {
      console.error(err.message ? err.message : 'Failed to retrieve session.');
      dispatch(unsetLoading('SIGN_IN'));
      const code = new URLSearchParams(history.location.search).get('code');
      if (!code) return;
      /* This means they have third party cookies blocked so we need to sign in  a different way */
      handleOktaAuthentication(true);
    }
  }, [oktaAuth]);

  const handleGoogleAuthFlow = useCallback(async () => {
    const isAuthenticated = await handleAuthFromLocalStorage(
      dispatch,
      handleSignOut,
      alerts
    );

    if (isAuthenticated) return;

    /* Sign In redirect */
    if (/access_token|id_token|error/.test(history.location.hash)) {
      const initRoute = handleLocalStorage.get(authTypes.AuthStorage.redirect_path);
      handleGoogleAuthentication(initRoute || '');
    }
  }, [localToken]);

  /* Setting Redirect URL for nested links*/
  useEffect(() => {
    if (
      history.location.pathname !== '/sign-in' &&
      history.location.pathname !== '/login/callback' &&
      history.location.pathname !== '/' &&
      history.location.pathname !== '/wam/'
    ) {
      handleLocalStorage.create(
        authTypes.AuthStorage.redirect_path,
        `${history.location.pathname}${history.location.search}`
      );
    }
  }, []);

  useEffect(() => {
    if (signInMode === authTypes.SignInMode.okta) handleOktaAuthFlow();
  }, [handleOktaAuthFlow, signInMode]);

  useEffect(() => {
    if (signInMode === authTypes.SignInMode.google) handleGoogleAuthFlow();
  }, [handleGoogleAuthFlow, signInMode]);

  const value: authTypes.AuthContextValue = useMemo(
    () => ({
      oktaAuth,
      signInMode,
      googleAuth,
      handleSignOut,
      setSignInMode,
      handleGoogleAuthentication,
    }),
    [oktaAuth, googleAuth, signInMode]
  );

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};

export const useAuth = () => useContext(authContext);
