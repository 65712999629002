import React from 'react';
import { cx } from 'emotion';
import * as styles from './auto-reminder-template.styles';
import { useResource } from '../../../shared/hooks';
import { useEffect, useState } from 'react';

const availableOptionsMap = (templateType) => {
  switch (templateType) {
    case 'single':
    case 'template_text':
      return [
        '{practice phone}',
        '{practice name}',
        '{first name}',
        '{last name}',
        '{preferred name}',
        '{date and time}',
        '{day}',
        '{day of month}',
        '{month}',
        '{time}',
        '{time early}',
        '{wellness form}',
        '{service provider}',
      ];
    case 'multi':
    case 'multi_template_text':
      return [
        '{practice phone}',
        '{practice name}',
        '[{first name}]',
        '[{preferred name}]',
        '[{time}]',
        '{day}',
        '{day of month}',
        '{month}',
        '{time}',
        '{time early}',
        '{wellness form}',
        '{service provider}',
      ];
    default:
      return [];
  }
};

const getLabelName = (tag) => {
  let labelName = tag.replace(/[^a-zA-Z ]/g, '');
  if (tag.indexOf('[') > -1) {
    labelName += 's';
  }
  return labelName;
};

export const TextTemplateTags = (props) => {
  const { templateTagExamples, handleDrag, handleClick, templateType } = props;
  const [availableOptions, setAvailableOptions] = useState(
    availableOptionsMap(templateType)
  );

  const { data: isVet } = useResource<boolean>({
    url: '/support/v1/notifications/pet-templatable',
    emptyState: false,
  });

  useEffect(() => {
    if (isVet) {
      let petOptions = ['{pet name}'];
      if (templateType === 'multi' || templateType === 'multi_template_text') {
        petOptions = [...petOptions, '[{pet name}]'];
      }
      setAvailableOptions([...availableOptions, ...petOptions]);
    }
  }, [isVet, templateType]);

  return (
    <div className={styles.tags}>
      {Object.keys(templateTagExamples).map((tag) => {
        return availableOptions.includes(tag) ? (
          <span
            className={cx(styles.templateTag)}
            key={`template-tag-${tag}`}
            draggable={availableOptions.includes(tag)}
            onDragStart={handleDrag(tag)}
            onClick={() => handleClick(tag)}
          >
            {` ${getLabelName(tag)} `}
          </span>
        ) : null;
      })}
    </div>
  );
};
