import React from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';

type Props = {
  minValue: number;
  maxValue: number;
  percent: number;
  value: number;
  id: string;
  getHandleProps: (id: string) => void;
};

export const SliderHandle = ({
  percent,
  value,
  id,
  minValue,
  maxValue,
  getHandleProps,
}: Props) => {
  return (
    <>
      {value > minValue && value < maxValue && (
        <div
          css={(theme: WeaveTheme) => css`
            color: ${theme.colors.gray[500]};
            font-size: 14px;
            left: ${percent}%;
            position: absolute;
            margin-left: -16px;
            margin-top: -27px;
            text-align: center;
            width: 30px;
          `}
        >
          {value}s
        </div>
      )}
      <div
        key={id}
        role="slider"
        aria-valuemin={minValue}
        aria-valuemax={maxValue}
        aria-valuenow={value}
        css={(theme: WeaveTheme) => css`
          left: ${percent}%;
          position: absolute;
          margin-left: -11px;
          margin-top: -6px;
          z-index: 2;
          width: 16px;
          height: 16px;
          cursor: pointer;
          border-radius: 50%;
          background-color: ${theme.colors.weaveBlue};
        `}
        {...getHandleProps(id)}
      />
    </>
  );
};
