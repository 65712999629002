import React, { useState, useEffect, FC } from 'react';
import { css } from 'emotion';
import { weaveRed, weaveGreen } from '@weave/platform-ui-components';
import { useAlert } from '@weave/alert-system';
import { NakedUl } from '@weave/design-system';
import { isEmpty, omit } from 'lodash';
import { DeviceSection } from './device-section.component';
import { DeviceRowReadOnly } from './device-row-read-only.component';
import { DeviceRowText } from './device-row-text.component';
import {
  DeviceOptionModel,
  DevicePendingChangesModel,
} from '../../../redux/actions/devices/devices.types';
import { useResources } from '../../../helpers/useResources';
import { DeviceRowSelect } from './device-row-select.component';

interface Props {
  isRegistered: boolean;
  locationId: string;
  name: string;
  macAddress: string;
  make: string;
  region: string;
  signalingProtocol: string;
  sipDisabled: boolean;
  updating: boolean;
  updateDevice: (changes: DevicePendingChangesModel) => void;
}

interface PendingChanges {
  signalingProtocol?: string;
  sipProfile: {
    name?: string;
  };
}

export const DeviceSectionGeneral: FC<Props> = ({
  isRegistered,
  locationId,
  name,
  macAddress,
  make,
  region,
  signalingProtocol,
  sipDisabled,
  updating,
  updateDevice,
}) => {
  const [nameError, setNameError] = useState<boolean>(false);
  const [pendingChanges, setPendingChanges] = useState<PendingChanges>({
    sipProfile: {},
  });
  const alerts = useAlert();

  useEffect(() => {
    // When the editable device values update, clear pending changes
    setPendingChanges({ sipProfile: {} });
  }, [name, signalingProtocol]);

  const onSave = async () => {
    if (nameError) {
      alerts.error('Invalid device name');
      return;
    }

    updateDevice(pendingChanges);
  };

  const { data: signalingProtocolOptions } = useResources<DeviceOptionModel[]>(
    'support/v1/phone/devices/options/signaling-protocols',
    {
      deps: [locationId],
      suppressFetch: !locationId,
      resourceLabel: 'signaling protocol options',
    }
  );

  return (
    <DeviceSection
      name="General Settings"
      showSaveButton
      hasRowChanges={
        (!isEmpty(pendingChanges.sipProfile) || !!pendingChanges.signalingProtocol) &&
        !updating
      }
      onSaveClick={onSave}
    >
      <NakedUl
        className={css`
          margin: 16px 0 0;
          max-width: 600px;
        `}
      >
        <DeviceRowText
          name="Display Name"
          error={nameError}
          helpText="Device names cannot be blank"
          value={pendingChanges.sipProfile.name ?? name}
          onChange={(value: string) => {
            if (!value) {
              setNameError(true);
            } else {
              setNameError(false);
            }

            if (value === name) {
              // Clear pending changes
              setPendingChanges({
                ...pendingChanges,
                sipProfile: omit(pendingChanges.sipProfile, 'name'),
              });
            } else {
              setPendingChanges({
                ...pendingChanges,
                sipProfile: { ...pendingChanges.sipProfile, name: value },
              });
            }
          }}
        />
        <DeviceRowReadOnly
          name="Registration Status"
          value={
            <>
              <span
                className={css`
                  display: inline-block;
                  border-radius: 50%;
                  height: 8px;
                  width: 8px;
                  background-color: ${isRegistered ? weaveGreen : weaveRed};
                  margin-right: 8px;
                `}
              />
              {isRegistered ? 'Registered' : 'Not Registered'}
            </>
          }
        />
        <DeviceRowReadOnly
          name="SIP Status"
          value={
            <>
              <span
                className={css`
                  display: inline-block;
                  border-radius: 50%;
                  height: 8px;
                  width: 8px;
                  background-color: ${sipDisabled ? weaveRed : weaveGreen};
                  margin-right: 8px;
                `}
              />
              {sipDisabled ? 'Disabled' : 'Enabled'}
            </>
          }
        />
        <DeviceRowReadOnly name="Region" value={region} />
        <DeviceRowReadOnly name="MAC Address" value={macAddress} />
        <DeviceRowReadOnly name="Make" value={make} />
        <DeviceRowSelect
          id="signaling-protocol"
          label="Signaling Protocol"
          value={pendingChanges.signalingProtocol ?? signalingProtocol}
          onChange={(newValue: string) => {
            if (newValue === signalingProtocol) {
              // Clear pending changes
              setPendingChanges(omit(pendingChanges, 'signalingProtocol'));
            } else {
              setPendingChanges({ ...pendingChanges, signalingProtocol: newValue });
            }
          }}
          options={signalingProtocolOptions}
        />
      </NakedUl>
    </DeviceSection>
  );
};
