import React from 'react';
import { cx } from 'emotion';
import {
  noCSVStyle,
  subPackageStyles,
  selectedSubPackage,
  softwareOnly,
  subPackageText,
  plusIconStyles,
} from '../customization.styles';
import { SubPackage, SubPackageNames, IconNames } from '../customization.constant';

interface CustomizationSubPackageProps {
  subPackage: SubPackage;
  isSelected: boolean;
  subPackageIndex: number;
  onSubPackageClicked: (subPackageIndex: number) => void;
}

export const CustomizationSubPackage = (props: CustomizationSubPackageProps) => {
  const { subPackage, isSelected, subPackageIndex, onSubPackageClicked } = props;
  const getIcon = (icon?: React.FunctionComponent | keyof typeof IconNames) => {
    if (!icon) {
      return null;
    }

    // Return any custom components here if a string was passed as the argument to the
    // icon parameter rather than a JSX.Element
    if (typeof icon === 'string') {
      switch (icon) {
        case IconNames.noCSV:
          return <div className={noCSVStyle}> .csv </div>;
        case IconNames.plus:
          return <div className={plusIconStyles}> + </div>;
        default:
          return null;
      }
    }

    const Icon = icon;
    return <Icon />;
  };

  return (
    <div
      className={cx(
        {
          [selectedSubPackage]: isSelected,
          [softwareOnly]: subPackage.name === SubPackageNames.softwareOnly,
        },
        subPackageStyles
      )}
      onClick={() => subPackage && onSubPackageClicked(subPackageIndex)}
    >
      {getIcon(subPackage.icon)}
      <div className={subPackageText}>{subPackage.label}</div>
    </div>
  );
};
