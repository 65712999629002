import React, { useEffect, useState } from 'react';
import {
  useForm,
  ValidatorFieldState,
  TextField,
  PrimaryButton,
  useModalControl,
} from '@weave/design-system';
import { css } from '@emotion/core';
import { dashboardApi as API } from '../../../apis/porting/dashboard.api';
import { LoaderModal as Loader } from '../utils/loader.component';
import { MessageDialogue } from '../utils/message-dialogue.component';
import { getErrorMessage } from '../../../redux/axios';

const supportKeyRegex = /sr_[A-Za-z\d]{6}\b/;

interface Props {
  getPortingData: () => void;
}

type MessageModal = {
  header: string;
  body: string;
  success: boolean;
};

export const SalesforcePortOrderForm = ({ getPortingData }: Props) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [submissionPortModal, setSubmissionPortModal] = useState<MessageModal>({
    header: 'Successfully submitted',
    body: 'Port order successfully imported from salesforce',
    success: true,
  });
  const {
    modalProps: messageModalProps,
    closeModal: closeMessageModal,
    triggerProps: messageTriggerModalProps,
  } = useModalControl();

  useEffect(() => {
    initValues();
    return () => {};
  }, []);

  const initValues = () => {
    seedValues({
      salesforcePortOrderId: '',
      supportKey: '',
    });
  };

  const {
    formProps: createSalesforcePortOrderProps,
    getFieldProps,
    seedValues,
    isComplete: isSalesforcePortOrderFormComplete,
  } = useForm({
    fields: {
      salesforcePortOrderId: {
        type: 'text',
        required: true,
        validator: ({ value }: ValidatorFieldState<'text'>) =>
          value.length === 18 ? '' : 'Must be of 18 characters.',
      },
      supportKey: {
        type: 'text',
        validator: ({ value }: ValidatorFieldState<'text'>) => {
          if (value.trim() && !supportKeyRegex.test(value)) {
            return 'Must be of format: sr_{6characters}';
          }
          return '';
        },
      },
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async (values: any) => {
    const error = await submitDashboardSubmission(values);
    if (error) {
      setSubmissionPortModal({
        header: 'Something Went Wrong',
        body: error,
        success: false,
      });
    } else {
      setSubmissionPortModal({
        header: 'Successfully submitted',
        body: 'Port order successfully imported from salesforce',
        success: true,
      });
    }
    messageTriggerModalProps.onClick();
  };

  const submitDashboardSubmission = async (values: any): Promise<string> => {
    const { salesforcePortOrderId, supportKey } = values;

    setShowLoader(true);

    try {
      await API.postDashboardSubmission({
        SalesforcePortOrderId: salesforcePortOrderId,
        SupportKey: supportKey,
      });
      return '';
    } catch (error) {
      console.error(error);
      return getErrorMessage(error);
    } finally {
      setShowLoader(false);
    }
  };

  const closeModal = () => {
    closeMessageModal();
    if (submissionPortModal.success) {
      getPortingData();
    }
  };

  return (
    <>
      <form
        {...createSalesforcePortOrderProps}
        css={css`
          margin-top: 10px;
          display: flex;
          justify-content: center;
          flex-direction: row;
          flex-wrap: wrap;
        `}
      >
        <div
          css={css`
            width: 213px;
            margin-right: 10px;
          `}
        >
          <TextField
            {...getFieldProps('salesforcePortOrderId')}
            name="salesforcePortOrderId"
            label="Salesforce PO id"
            helperText="Ex: a0X8H000000942BUAQ"
          />
        </div>

        <div
          css={css`
            width: 140px;
            margin-right: 10px;
          `}
        >
          <TextField
            {...getFieldProps('supportKey')}
            name="supportKey"
            label="Support Key"
            helperText="Ex: sr_ab1234"
          />
        </div>

        <PrimaryButton
          color="blue"
          size="small"
          type="submit"
          css={css`
            width: 130px;
            margin-bottom: 10px;
          `}
          disabled={!isSalesforcePortOrderFormComplete}
        >
          Submit
        </PrimaryButton>
      </form>

      <Loader showLoading={showLoader} message="Submitting..." />

      <MessageDialogue
        header={submissionPortModal.header}
        body={submissionPortModal.body}
        messageModalProps={messageModalProps}
        closeMessageModal={closeModal}
        actionLabel="Okay"
        onActionLabelClick={closeModal}
      />
    </>
  );
};
