import { PrimaryButton, useModalControl } from '@weave/design-system';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMerchant } from 'redux/actions/merchant/merchant.action';
import { DestructiveConfirmationModal } from '../shared/confirmation-modal/destructive-confirmation-modal.component';
import { useDisconnectStripe } from './hooks/use-disconnect-stripe';

export const DisconnectStripeBtn = () => {
  const { modalProps, triggerProps } = useModalControl();
  const { disconnectStripeAccount, loading, disconnecting, completed } =
    useDisconnectStripe();
  const dispatch = useDispatch();

  useEffect(() => {
    if (completed) {
      modalProps.onClose();
      dispatch(getMerchant());
    }
  }, [completed]);

  return (
    <div>
      <PrimaryButton
        {...triggerProps}
        destructive
        disabled={loading || disconnecting}
        title="Disconnect stripe account from this location"
      >
        Disconnect Account
      </PrimaryButton>
      <DestructiveConfirmationModal
        {...modalProps}
        title="Disconnect Stripe Account"
        message="Are you sure you want to disconnect the stripe account? You need to create a new stripe account if you decide to go back."
        onConfirm={() => disconnectStripeAccount()}
        loading={loading || disconnecting}
        loadingLabel="Disconnecting stripe account"
        confirmLabel="Disconnect"
      />
    </div>
  );
};
