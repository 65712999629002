import { noop } from 'lodash';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../redux/actions/location';
import { BillingRoutes } from './billing-routes';
import { selectHasBillingReadPermission } from './billing.helpers';
import { BillingInvoiceDetails } from './billing.types';
import { history } from '../../../redux/store/history';

export type InvoiceDetailsMap = {
  [invoiceId: string]: BillingInvoiceDetails;
};

export const InvoicesDetailsContext = React.createContext<{
  invoiceDetailsMap: InvoiceDetailsMap;
  addInvoiceDetails: (invoiceId: string, invoiceDetails: BillingInvoiceDetails) => void;
}>({
  invoiceDetailsMap: {},
  addInvoiceDetails: noop,
});

export const BillingPage = () => {
  const [invoiceDetailsMap, setInvoiceDetailsMap] = useState<InvoiceDetailsMap>({});
  const locationId = useSelector(selectCurrentLocationId);

  const hasBillingReadPermission = useSelector(selectHasBillingReadPermission);

  useEffect(() => {
    if (hasBillingReadPermission) {
      setInvoiceDetailsMap({});
    } else {
      history.replace(`/`);
    }
  }, [locationId, hasBillingReadPermission]);

  const handleAddInvoiceDetails = (
    invoiceId: string,
    invoiceDetails: BillingInvoiceDetails
  ) => {
    setInvoiceDetailsMap({ ...invoiceDetailsMap, [invoiceId]: invoiceDetails });
  };

  return (
    <InvoicesDetailsContext.Provider
      value={{ invoiceDetailsMap, addInvoiceDetails: handleAddInvoiceDetails }}
    >
      <BillingRoutes />
    </InvoicesDetailsContext.Provider>
  );
};
