import React from 'react';
import { cx } from 'emotion';
import {
  packageContainer,
  packageNamePositioning,
  packageNameStyle,
  subPackagesContainer,
  selectedStyleFill,
  selectedStyleStroke,
  weaveContainerStyling,
  weaveIconStyling,
  phoneIconStyles,
} from '../customization.styles';
import { Package, PackageNames } from '../customization.constant';
import { CustomizationSubPackage } from '../custotmization-subpackage/customization-subpackage';

interface Props {
  package: Package;
  isSelectedPackage: boolean;
  selectedSubPkgIndices: number[];
  onPackageClicked: (pkg: Package, selectedSubPkgIndices: number[]) => void;
}

export const LegacyCustomizationPackage = (props: Props) => {
  const { package: pkg, isSelectedPackage, selectedSubPkgIndices } = props;

  const getIcon = (icon?: any) => {
    if (!icon) {
      return null;
    }

    const Icon = icon;
    const iconStyles = cx(
      {
        [weaveIconStyling]: pkg.name === PackageNames.weaveCore,
        [phoneIconStyles]: pkg.name === PackageNames.starterPromo,
      },
      'packageIcon'
    );

    return <Icon className={iconStyles} />;
  };

  const handleSubPackageClicked = (subPackageIndex: number) => {
    let newIndices;
    if (!isSelectedPackage) {
      // If user clicked on a sub package that belongs to a newly selected base package,
      // then newIndices should be a new array with only that sub package index.
      newIndices = [subPackageIndex];
    } else {
      // Use a Set to only keep unique sub package index values since the same sub package
      // may be clicked multiple times.
      newIndices = Array.from(new Set([...selectedSubPkgIndices, subPackageIndex]));

      // If the user clicked on a sub package that was already selected, then remove its
      // index from the array.
      if (selectedSubPkgIndices.includes(subPackageIndex)) {
        newIndices = selectedSubPkgIndices.filter((el) => el !== subPackageIndex);
      }
    }

    props.onPackageClicked(pkg, newIndices);
  };

  const handleBasePackageClicked = () => {
    props.onPackageClicked(pkg, []);
  };

  return (
    <div className={packageContainer}>
      <div
        className={cx(
          {
            [selectedStyleStroke]: isSelectedPackage,
            [weaveContainerStyling]: pkg.name === PackageNames.weaveCore,
            [selectedStyleFill]: isSelectedPackage && pkg.name === PackageNames.weaveCore,
          },
          packageNameStyle
        )}
        onClick={() => handleBasePackageClicked()}
      >
        {getIcon(pkg.icon)}
        <div className={packageNamePositioning}>{pkg.label}</div>
      </div>

      <div className={subPackagesContainer}>
        {pkg.subPackages?.[0] && (
          <CustomizationSubPackage
            subPackage={pkg.subPackages[0]}
            subPackageIndex={0}
            isSelected={isSelectedPackage && selectedSubPkgIndices.includes(0)}
            onSubPackageClicked={handleSubPackageClicked}
          />
        )}
        {pkg.subPackages?.[1] && (
          <CustomizationSubPackage
            subPackage={pkg.subPackages[1]}
            subPackageIndex={1}
            isSelected={isSelectedPackage && selectedSubPkgIndices.includes(1)}
            onSubPackageClicked={handleSubPackageClicked}
          />
        )}
      </div>
    </div>
  );
};
