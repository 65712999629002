import React, { useState } from 'react';
import { Text } from '@weave/design-system';
import { Checkbox } from '@weave/platform-ui-components';
import { css } from '@emotion/core';
import { useClientMapping } from './client-mapping.hook';

const container = (theme) => css`
  width: 600px;
  height: 92px;
  border: 1px solid ${theme.colors.gray[400]};
  border-radius: 3px;
  padding: 16px;
  margin-bottom: 32px;
  > p {
    width: 85%;
  }
`;

const toggleContainer = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  p {
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

const clientKey = 'auto_confirmed_mark_read';

export const MarkAsRead = () => {
  const [markAsRead, setMarkAsRead] = useState(false);
  const { saveClientMapping } = useClientMapping(
    (data) => setMarkAsRead(data[clientKey]),
    [clientKey]
  );

  const saveMarkAsRead = () => {
    setMarkAsRead(!markAsRead);
    saveClientMapping(clientKey, !markAsRead, 'notifications');
  };

  return (
    <div css={container}>
      <div css={toggleContainer}>
        <Text>Mark As Read</Text>
        <Checkbox toggle checked={markAsRead} onChange={saveMarkAsRead} />
      </div>
      <Text color="light" size="small">
        Toggle this setting if you would like responses confirming appointments to
        automatically be marked as read.
      </Text>
    </div>
  );
};
