import { css } from 'emotion';
import { weaveBlue } from '../../shared/styles/colors';

export const hubsSection = css`
  width: 100%;
`;
export const hubContainer = css`
  margin-top: 10px;
  margin-left: 20px;
`;
export const trackerContainer = css`
  display: flex;
  background: #fafcfc;
  min-height: 600px;
  border: 1px solid #d4dbdf;
  overflow: hidden;
`;
export const trackerHeader = css`
  width: 350px;
  margin: 15px auto;
`;
export const trackerTitle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const trackerText = css`
  font-weight: bold;
  font-size: 16px;
`;
export const alertContainer = css`
  text-align: center;
  margin-right: 6px;
  margin-top: 20px;
`;
export const enableTrackerButton = css`
  cursor: pointer;
  color: ${weaveBlue};
  margin-left: 5px;
  &:hover {
    color: #004c9e;
  }
`;
export const progressBarContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;
export const filterContainer = css`
  margin-right: 1px;
  margin-top: 10px;
  line-height: 14px;
`;
export const displayFlexCenter = css`
  display: flex;
  justify-content: center;
`;
