import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { CustomAxios, getResponseData } from '../../axios/axios';
import { createRequestSaga, showSuccess } from '@weave/alert-system';
import {
  DeviceModel,
  DeviceModelNoId,
  DevicesActionTypes,
  PartialDevice,
  SipProfile,
} from './devices.types';
import { selectDevice, selectDevicesArray } from './devices.selectors';
import { ResolvedPromise } from '../../store/store-utils';
import {
  addDevicesToLocation,
  removeDevicesFromLocation,
} from '../location/location-entities/location-devices';
import { selectCurrentLocationId } from '../location/current-location';

type Action<ActionType extends DevicesActionTypes, Payload = undefined> = {
  type: ActionType;
} & { payload: Payload };
type RequestListAction = Action<DevicesActionTypes.RequestList>;
type RequestGetAction = Action<DevicesActionTypes.RequestGet, string>;
type RequestUpdateAction = Action<
  DevicesActionTypes.RequestUpdate,
  { id: string; update: PartialDevice }
>;
type RequestDeleteAction = Action<DevicesActionTypes.RequestDelete, string>;
type RequestCreateAction = Action<DevicesActionTypes.RequestCreate, DeviceModelNoId>;
type RequestDisableAction = Action<DevicesActionTypes.RequestDisable, string>;
type RequestDisableAllAction = Action<DevicesActionTypes.RequestDisableAll>;
type RequestEnableAction = Action<DevicesActionTypes.RequestEnable, string>;
type RequestEnableAllAction = Action<DevicesActionTypes.RequestEnableAll>;
type AddAction = Action<DevicesActionTypes.Add, DeviceModel[]>;
type RemoveAction = Action<DevicesActionTypes.Remove, string[]>;
type UpdateAction = Action<DevicesActionTypes.Update, DeviceModel>;
type UpdateMultipleAction = Action<DevicesActionTypes.UpdateMultiple, DeviceModel[]>;
type RebootDeviceAction = Action<DevicesActionTypes.Reboot, string>;
type RebootAllDevicesAction = Action<DevicesActionTypes.RebootAll>;

export type DevicesActions =
  | RequestListAction
  | RequestGetAction
  | RequestUpdateAction
  | RequestDeleteAction
  | RequestCreateAction
  | AddAction
  | RemoveAction
  | UpdateAction
  | UpdateMultipleAction
  | RebootDeviceAction
  | RebootAllDevicesAction;

export const requestListDevices = (): RequestListAction => ({
  type: DevicesActionTypes.RequestList,
  payload: undefined,
});
export const requestGetDevice = (deviceId: string): RequestGetAction => ({
  type: DevicesActionTypes.RequestGet,
  payload: deviceId,
});
export const requestUpdateDevice = (
  id: string,
  update: PartialDevice
): RequestUpdateAction => ({
  type: DevicesActionTypes.RequestUpdate,
  payload: { id, update },
});
export const requestDeleteDevice = (deviceId: string): RequestDeleteAction => ({
  type: DevicesActionTypes.RequestDelete,
  payload: deviceId,
});
export const requestCreateDevice = (device: DeviceModelNoId): RequestCreateAction => ({
  type: DevicesActionTypes.RequestCreate,
  payload: device,
});
export const requestDisableDevice = (deviceId: string): RequestDisableAction => ({
  type: DevicesActionTypes.RequestDisable,
  payload: deviceId,
});
export const requestDisableAllDevices = (): RequestDisableAllAction => ({
  type: DevicesActionTypes.RequestDisableAll,
  payload: undefined,
});
export const requestEnableDevice = (deviceId: string): RequestEnableAction => ({
  type: DevicesActionTypes.RequestEnable,
  payload: deviceId,
});
export const requestEnableAllDevices = (): RequestEnableAllAction => ({
  type: DevicesActionTypes.RequestEnableAll,
  payload: undefined,
});
export const requestRebootDevice = (deviceId): RebootDeviceAction => ({
  type: DevicesActionTypes.Reboot,
  payload: deviceId,
});
export const requestRebootAllDevices = (): RebootAllDevicesAction => ({
  type: DevicesActionTypes.RebootAll,
  payload: undefined,
});

export const addDevices = (devices: DeviceModel[]): AddAction => ({
  type: DevicesActionTypes.Add,
  payload: devices,
});
export const removeDevices = (deviceIds: string[]): RemoveAction => ({
  type: DevicesActionTypes.Remove,
  payload: deviceIds,
});
export const updateDevice = (device: DeviceModel): UpdateAction => ({
  type: DevicesActionTypes.Update,
  payload: device,
});
export const updateMultipleDevices = (devices: DeviceModel[]): UpdateMultipleAction => ({
  type: DevicesActionTypes.UpdateMultiple,
  payload: devices,
});

const api = {
  getDevices: (): Promise<DeviceModel[]> =>
    CustomAxios.get(`support/v1/phone/devices?newRegistration=true`).then(
      getResponseData
    ),
  getDevice: (deviceId: string): Promise<DeviceModel> =>
    CustomAxios.get(`support/v1/phone/devices/${deviceId}?newRegistration=true`).then(
      getResponseData
    ),
  updateDevice: (id: string, update: DeviceModel): Promise<DeviceModel> =>
    CustomAxios.put(`support/v1/phone/devices/${id}`, update).then(getResponseData),
  deleteDevice: (deviceId: string): Promise<DeviceModel> =>
    CustomAxios.delete(`support/v1/phone/devices/${deviceId}`).then(getResponseData),
  createDevice: (device: DeviceModelNoId): Promise<DeviceModel> =>
    CustomAxios.post(`support/v1/phone/devices/`, device).then(getResponseData),
  disableDevices: (locationId): Promise<any> =>
    CustomAxios.put(`support/v1/fraud/${locationId}/disable`),
  enableDevices: (locationId): Promise<any> =>
    CustomAxios.put(`support/v1/fraud/${locationId}/enable`),
  rebootDevice: (sipUsername: string): Promise<any> =>
    CustomAxios.post(`support/v1/phone/reboot`, { Sipusername: sipUsername }),
  rebootAllDevices: (): Promise<any> => CustomAxios.post(`support/v1/phone/rebootAll`),
};

const listDevicesSaga = createRequestSaga<RequestListAction>({
  key: DevicesActionTypes.RequestList,
  displayErrors: true,
  onError: (err) => `Failed to load device: ${err.message}`,
  saga: function* () {
    const devices: ResolvedPromise<ReturnType<typeof api.getDevices>> = yield call(
      api.getDevices
    );
    const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    yield put(addDevices(devices));
    yield put(
      addDevicesToLocation(locationId, devices?.map((device) => device.ID) ?? [])
    );
  },
});
const getDeviceSaga = createRequestSaga<RequestGetAction>({
  key: DevicesActionTypes.RequestGet,
  displayErrors: true,
  onError: (err) => `Failed to load device: ${err.message}`,
  saga: function* ({ payload }) {
    const device: ResolvedPromise<ReturnType<typeof api.getDevice>> = yield call(
      api.getDevice,
      payload
    );
    yield put(addDevices([device]));
  },
});

const updateDeviceSaga = createRequestSaga<RequestUpdateAction>({
  key: DevicesActionTypes.RequestUpdate,
  displayErrors: true,
  onError: (err) => `Failed to load device: ${err.message}`,
  saga: function* ({ payload }) {
    const { device }: ReturnType<ReturnType<typeof selectDevice>> = yield select(
      selectDevice(payload.id)
    );
    const updated = { ...device, ...payload.update };
    yield call(api.updateDevice, payload.id, updated);
    yield put(updateDevice(updated));
  },
});

const deleteDeviceSaga = createRequestSaga<RequestDeleteAction>({
  key: DevicesActionTypes.RequestDelete,
  displayErrors: true,
  onError: (err) => `Failed to load device: ${err.message}`,
  saga: function* ({ payload }) {
    const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    yield call(api.deleteDevice, payload);
    yield put(removeDevices([payload]));
    yield put(removeDevicesFromLocation(locationId, [payload]));
  },
});
const createDeviceSaga = createRequestSaga<RequestCreateAction>({
  key: DevicesActionTypes.RequestCreate,
  displayErrors: true,
  onError: (err) => `Failed to load device: ${err.message}`,
  saga: function* ({ payload }) {
    const device: ResolvedPromise<ReturnType<typeof api.createDevice>> = yield call(
      api.createDevice,
      payload
    );
    const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    yield put(addDevices([device]));
    yield put(addDevicesToLocation(locationId, [device.ID]));
  },
});
const rebootDeviceSaga = createRequestSaga<RebootDeviceAction>({
  key: DevicesActionTypes.Reboot,
  displayErrors: true,
  onError: (err) => `Failed to reboot device: ${err.message}`,
  saga: function* ({ payload }) {
    yield call(api.rebootDevice, payload);
    showSuccess("Device Resyncing! Last connected time won't update immediately.");
  },
});
const rebootAllDevicesSaga = createRequestSaga<RebootDeviceAction>({
  key: DevicesActionTypes.Reboot,
  displayErrors: true,
  onError: (err) => `Failed to reboot device: ${err.message}`,
  saga: function* ({ payload }) {
    yield call(api.rebootAllDevices);
    showSuccess("All Devices Resyncing! Last connected times won't update immediately.");
  },
});
const disableAllDevicesSaga = createRequestSaga<RequestDisableAction>({
  key: DevicesActionTypes.RequestDisable,
  displayErrors: true,
  onError: (err) => `Failed to disable all devices: ${err.message}`,
  saga: function* ({ payload }) {
    yield call(api.disableDevices, payload);
    const { devices }: ReturnType<typeof selectDevicesArray> = yield select(
      selectDevicesArray
    );
    const updatedDevices = devices
      .filter((device) => !!device.sipProfile)
      .map((device) => ({
        ...device,
        sipProfile: { ...(device.sipProfile as SipProfile), disabled: true },
      }));
    yield put(updateMultipleDevices(updatedDevices));
  },
});

const enableAllDevicesSaga = createRequestSaga<RequestDisableAction>({
  key: DevicesActionTypes.RequestDisable,
  displayErrors: true,
  onError: (err) => `Failed to disable all devices: ${err.message}`,
  saga: function* ({ payload }) {
    yield call(api.enableDevices, payload);
    const { devices }: ReturnType<typeof selectDevicesArray> = yield select(
      selectDevicesArray
    );
    const updatedDevices = devices
      .filter((device) => !!device.sipProfile)
      .map((device) => ({
        ...device,
        sipProfile: { ...(device.sipProfile as SipProfile), disabled: false },
      }));
    yield put(updateMultipleDevices(updatedDevices));
  },
});

export const devicesSaga = function* () {
  yield all([
    takeLatest(DevicesActionTypes.RequestList, listDevicesSaga),
    takeLatest(DevicesActionTypes.RequestGet, getDeviceSaga),
    takeLatest(DevicesActionTypes.RequestUpdate, updateDeviceSaga),
    takeLatest(DevicesActionTypes.RequestDelete, deleteDeviceSaga),
    takeLatest(DevicesActionTypes.RequestCreate, createDeviceSaga),
    takeLatest(DevicesActionTypes.RequestDisableAll, disableAllDevicesSaga),
    takeLatest(DevicesActionTypes.RequestEnableAll, enableAllDevicesSaga),
    takeLatest(DevicesActionTypes.Reboot, rebootDeviceSaga),
    takeLatest(DevicesActionTypes.RebootAll, rebootAllDevicesSaga),
  ]);
};
