import React from 'react';
import { css } from 'emotion';
import moment from 'moment';

import {
  Card,
  Secondary,
  Svg,
  gray7,
  weaveBlueDark,
  weaveBlue,
} from '@weave/platform-ui-components';
import {
  ChilipiperScheduleEventModel,
  ChilipiperScheduleEventTypes,
  ChilipiperCancelEventModel,
} from '../../../redux/actions/chilipiper-schedule-event/chilipiper-schedule-event.types';
import { defaultEvent, eventNameMap } from '../scheduling-hub.component';

const cardStyles = css`
  width: 215px;
  margin: 12px;
`;
const cardTitle = css`
  font-size: 18px;
  font-weight: bold;
`;
const cardSubtitle = css`
  font-size: 14px;
  display: block;
  margin-top: 4px;
  color: ${gray7};
`;
const svgAndDateDiv = css`
  display: flex;
  margin-top: 16px;
`;
const calendarSVG = css`
  width: 32px;
  position: relative;
  top: 10px;
`;
const dateTimeText = css`
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 1.4em;
  color: ${gray7};
`;
const dateAndRescheduleDiv = css`
  margin-left: 16px;
`;
const scheduleLink = css`
  color: ${weaveBlue};
  font-size: 14px;

  &:hover {
    color: ${weaveBlueDark};
    cursor: pointer;
  }
`;
const eventBody = css`
  display: flex;
  flex-direction: column;
`;
const actionsDiv = css`
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
`;

export type EventCardProps = {
  scheduleEvent?: ChilipiperScheduleEventModel;
  onboarders?: any[];
  eventType: ChilipiperScheduleEventTypes;
  onScheduleButtonClick: (
    rescheduleEvent: ChilipiperScheduleEventModel,
    reschedule?: boolean
  ) => void;
  onCancelButtonClick: (cancelDetails: ChilipiperCancelEventModel) => void;
};

export const EventCard = (props: EventCardProps) => {
  const { scheduleEvent, eventType } = props;

  /**
   * Formats the snake cased event type string into separate capitalized words.
   * E.g. "software_install_call" ==> "Software Install Call"
   */
  const formattedEventType = () => {
    if (typeof eventType !== 'string') {
      return 'unknown';
    }

    const formatted = eventType
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ');

    return formatted;
  };

  const eventName = eventNameMap[eventType] || formattedEventType();

  const getOnboarder = (event?: ChilipiperScheduleEventModel) => {
    if (!props.onboarders) {
      return { name: '', email: '' };
    }

    if (event) {
      const eventOnboarder = props.onboarders.find(
        (onboarder) => onboarder.salesforceUserID === event.assignee_id
      );
      if (eventOnboarder && eventOnboarder.salesforceUserID) {
        return eventOnboarder;
      }
    }

    return { name: 'unknown', email: '' };
  };

  const formatDateTime = (dateTime: string) => {
    const momentDate = moment(dateTime);
    return `${momentDate.format('dddd, MMM D')} at ${momentDate.format('h:mm A')}`;
  };

  const handleScheduleNewEvent = () => {
    const newEvent = {
      ...defaultEvent,
      event_type: eventType,
    };
    props.onScheduleButtonClick(newEvent);
  };
  const onBoarder = getOnboarder(scheduleEvent);

  return (
    <Card className={cardStyles}>
      {scheduleEvent && props.onboarders && (
        <>
          <div className={cardTitle}>
            <span>{eventName}</span>
          </div>
          <Secondary className={cardSubtitle}>with {onBoarder.name}</Secondary>
          <div className={eventBody}>
            <div className={svgAndDateDiv}>
              <Svg icon="Reminders" color="green" className={calendarSVG} />
              <div className={dateAndRescheduleDiv}>
                <div className={dateTimeText}>
                  <span>{formatDateTime(scheduleEvent.start_at)}</span>
                </div>
              </div>
            </div>
            <div className={actionsDiv}>
              <div
                className={scheduleLink}
                onClick={() => props.onScheduleButtonClick(scheduleEvent, true)}
              >
                <span>Reschedule</span>
              </div>
              {onBoarder.email && moment().isBefore(scheduleEvent.start_at) && (
                <div
                  className={scheduleLink}
                  onClick={() =>
                    props.onCancelButtonClick({
                      ...scheduleEvent,
                      assignee_email: onBoarder.email,
                      event_title: eventName,
                    })
                  }
                >
                  <span>Cancel</span>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {(!scheduleEvent || !props.onboarders) && (
        <>
          <div className={cardTitle}>{eventName}</div>
          <Secondary className={cardSubtitle}>with - - -</Secondary>
          <div className={eventBody}>
            <div className={svgAndDateDiv}>
              <Svg icon="Calendar" color="gray5" className={calendarSVG} />
              <div className={dateAndRescheduleDiv}>
                <div className={dateTimeText}>
                  <span>No Scheduled Appointment</span>
                </div>
                {/* For now, we do not allow scheduling new "next_steps_call" events. */}
              </div>
            </div>
            {!(eventType === 'next_steps_call') && (
              <div className={actionsDiv}>
                <div className={scheduleLink} onClick={handleScheduleNewEvent}>
                  <span>Schedule</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </Card>
  );
};
