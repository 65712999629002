import { theme } from '@weave/theme-original';
import { Heading, PrimaryButton, Text } from '@weave/design-system';
import { css } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import { tenantMigrationApi } from '../../../apis/tenant-migration/tenant-migration.api';
import { MediaMigrationStatusRes } from '../../../apis/tenant-migration/tenant-migration.types';
import { Loader } from '../../shared/loader/loader.component';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../redux/actions/location';

export const CopyMedia: React.FC = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const [mediaStatus, setMediaStatus] = useState<MediaMigrationStatusRes>();

  useEffect(() => {
    tenantMigrationApi.getMediaStatus().then(setMediaStatus);
  }, [locationId]);

  const copyMedia = () => tenantMigrationApi.migrateMedia();

  if (!mediaStatus) {
    return (
      <>
        <Heading
          css={css`
            margin-top: ${theme.spacing(2)};
          `}
        >
          Step 1: Copy Media
        </Heading>
        <Loader />
      </>
    );
  }

  const { dstMediaCount, srcMediaCount } = mediaStatus;

  return (
    <>
      <Heading
        css={css`
          margin-top: ${theme.spacing(2)};
        `}
      >
        Step 1: Copy Media
      </Heading>
      <Text size="small">
        This will copy all media files related to voicemail, call recordings, phone trees,
        and media library to the parent PDS tenant. Copying media can be performed
        multiple times if needed. <br />
        Note: Copying the media files can take minutes depending on media volume.
      </Text>
      <Text
        weight={'bold'}
        css={css`
          color: ${dstMediaCount === srcMediaCount
            ? theme.colors.success
            : theme.colors.pizazz};
        `}
      >
        {dstMediaCount.toLocaleString()} out of {srcMediaCount.toLocaleString()} have been
        copied
      </Text>
      <PrimaryButton
        onClick={copyMedia}
        disabled={srcMediaCount === dstMediaCount}
        css={css`
          width: 250px;
        `}
      >
        Copy Remaining {(srcMediaCount - dstMediaCount).toLocaleString()} Files
      </PrimaryButton>
    </>
  );
};
