import { connect } from 'react-redux';

import { Labeler } from './labeler/labeler.component';
import { sentimentTrainingClassifying } from '../../../redux/actions/sentiment-training/get-sentiment-training-classifying/get-sentiment-training-classifying.action';
import { postSentimentTrainingClassifying } from '../../../redux/actions/sentiment-training/post-sentiment-training-classifying/post-sentiment-training-classifying.action';
import { SentimentTraining } from '../../../redux/actions/sentiment-training/sentiment-training.reducer';

interface StateProps {
  message: string;
  messageId: string;
  classifications: string[];
}

interface DispatchProps {
  getStatement: () => void;
  updateSentiment: (label: string, id: string) => void;
}

export const LabelerContainer = connect<
  StateProps,
  DispatchProps,
  {},
  { sentimentTraining: SentimentTraining }
>(
  (state) => ({
    message: state.sentimentTraining.messageData.MessageText,
    messageId: state.sentimentTraining.messageData.MessageID,
    classifications: state.sentimentTraining.messageData.AvailableClassifications,
  }),
  (dispatch) => ({
    getStatement() {
      dispatch(sentimentTrainingClassifying());
    },
    updateSentiment(label: string, id: string) {
      dispatch(postSentimentTrainingClassifying({ label, id }));
    },
  })
)(Labeler);
