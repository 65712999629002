import React, { useRef } from 'react';
import { showError, showSuccess } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import {
  FormRow,
  Modal,
  SwitchField,
  TextField,
  useForm,
  Text,
} from '@weave/design-system';
import { css } from '@emotion/core';
import { CustomAxios } from '../../redux/axios';
import { useDispatch } from 'react-redux';
import { FeatureFlag } from './feature-flag.types';

type EditFlagModalProps = ModalContentProps & {
  show: boolean;
};

type ModalContentProps = {
  flag: FeatureFlag;
  close: Function;
};
const ModalContent = (props: ModalContentProps) => {
  const dispatch = useDispatch();

  const initDefaultValue = useRef(props.flag.defaultValue);
  const { getFieldProps, formProps, values, isComplete } = useForm({
    computeChangedValues: true,
    fields: {
      flagName: { type: 'text', required: true, value: props.flag.name },
      description: { type: 'text', required: true, value: props.flag.description },
      defaultValue: { type: 'switch', value: initDefaultValue.current },
    },
    onSubmit: function () {
      CustomAxios.patch(`/feature-flags/v1/flags/${props.flag.name}`, {
        description: values.description,
        default_value: values.defaultValue,
      })
        .then((res) => {
          dispatch(showSuccess('Flag updated!'));
          props.close(res.data);
        })
        .catch((err) => {
          dispatch(showError('Error updating feature flag'));
        });
    },
  });

  const changedDefaultValue = initDefaultValue.current !== values.defaultValue;

  return (
    <>
      <Modal.Header>Global Flag Update</Modal.Header>
      <Text color="warn" weight="bold" style={{ textAlign: 'center' }}>
        This will update for all locations
      </Text>
      <Modal.Body>
        <form
          css={(theme: WeaveTheme) =>
            css`
              padding-top: ${theme.spacing(1)};
            `
          }
        >
          <FormRow>
            <TextField {...getFieldProps('flagName')} label="Name" disabled={true} />
          </FormRow>
          <FormRow>
            <TextField {...getFieldProps('description')} label="Description" />
          </FormRow>
          <FormRow>
            <SwitchField {...getFieldProps('defaultValue')} label="Enabled by default" />
          </FormRow>
        </form>
        {changedDefaultValue && (
          <Text style={{ margin: 0 }}>
            Will update default for all locations, are you sure?
          </Text>
        )}
        <Modal.Actions
          // @ts-ignore It's the right type, Typescript just doesn't recognize that.
          onPrimaryClick={formProps.onSubmit}
          onSecondaryClick={() => {
            props.close();
          }}
          disablePrimary={!isComplete}
          primaryLabel="Update"
          secondaryLabel="Cancel"
          destructive={changedDefaultValue}
        />
      </Modal.Body>
    </>
  );
};

export const EditFlagModal = ({ show, ...props }: EditFlagModalProps) => {
  return (
    <Modal
      show={show}
      onClose={() => {
        props.close();
      }}
    >
      <ModalContent {...props} />
    </Modal>
  );
};
