import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

export const headerContainer = css`
  display: flex;
  justify-content: end;
  padding: ${theme.spacing(0, 1)};
`;

export const onBoardingHubContainer = css`
  margin-top: ${theme.spacing(3)};
  text-align: center;
`;

export const cardStyles = css`
  border: 1px solid ${theme.colors.gray400};
  border-radius: ${theme.borderRadius.medium};
  background-color: ${theme.colors.white};
  box-shadow: none;
  margin: ${theme.spacing(0, 0, 3, 0)};
  padding: ${theme.spacing(3)};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
