import React from 'react';
import { Button, Table } from 'reactstrap';
import { css } from 'emotion';

import UserListModel from '../../../users/UserListModel';
import { Loader } from '../../../shared/loader/loader.component';
import { LocationInfo } from '../../handoff-hub.types';

const containerStyles = css`
  padding: 15px;
  color: #555;
`;

const titleStyles = css`
  padding: 15px;
  text-align: center;
`;

const subtitleStyles = css`
  font-weight: 300;
`;

export type GuideTriggerProps = {
  users: UserListModel[];
  locationInformation: LocationInfo;
  loading: boolean;
  runGuide: (userId: string) => void;
  saveGuideRun: (data) => void;
  toggleModal: () => void;
};

export const GuideTrigger = (props: GuideTriggerProps) => {
  const { users, loading, runGuide, toggleModal, saveGuideRun, locationInformation } =
    props;

  const handleRunGuide = (user) => {
    runGuide(user.UserID);
    saveGuideRun({ csat_recipient_user_email: user.Username, locationInformation });
    toggleModal();
  };

  return (
    <div className={containerStyles}>
      <h1 className={titleStyles}>Trigger Client Guide</h1>
      <h3 className={subtitleStyles}>Select a user</h3>
      {loading ? (
        <Loader />
      ) : (
        <Table striped>
          <thead>
            <tr>
              <th />
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>
                  {user.FirstName} {user.LastName}
                </td>
                <td>{user.Username}</td>
                <td>
                  <Button color="info" onClick={() => handleRunGuide(user)}>
                    Run
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {users.length === 0 && (
        <div>No user is currently logged in to the client for this location. </div>
      )}
    </div>
  );
};
