import { createAction } from 'redux-actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios, getErrorMessage } from '../../axios';
import { showError, showSuccess } from '@weave/alert-system';

export const getManualTemplates = createAction('GET_MANUAL_TEMPLATES');
export const getManualTemplatesSuccess = createAction('GET_MANUAL_TEMPLATES_SUCCESS');
export const postManualTemplates = createAction('POST_MANUAL_TEMPLATES');
export const postManualTemplatesSuccess = createAction('POST_MANUAL_TEMPLATES_SUCCESS');

export const getManualTemplatesSaga = function* () {
  const handleGetTemplates = function* () {
    try {
      const response = yield call(CustomAxios.get, '/support/v1/manualtemplates');
      yield put(getManualTemplatesSuccess(response.data.data));
    } catch (error: any) {
      yield put(showError(getErrorMessage(error)));
    }
  };
  yield takeEvery(getManualTemplates.toString(), handleGetTemplates);
};

export const postManualTemplatesSaga = function* () {
  const handlePostTemplates = function* (action) {
    try {
      yield call(CustomAxios.post, '/support/v1/manualtemplates', action.payload);
      yield put(showSuccess('Save Successful'));
      yield put(postManualTemplatesSuccess(action.payload));
    } catch (error: any) {
      yield put(showError(getErrorMessage(error)));
    }
  };

  yield takeEvery(postManualTemplates.toString(), handlePostTemplates);
};
