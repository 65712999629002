import { connect } from 'react-redux';

import { NetworkAudits } from './network-audits/network-audits.component';
import { getNetworkAudits, runNetworkAudit } from '../../redux/actions/network-audits';
import { getConnectedUsers } from '../../redux/actions/users';
import {
  selectCurrentLocationId,
  selectCurrentLocation,
} from '../../redux/actions/location/current-location';
import { Store } from '../../redux/store/store.model';

export const NetworkAuditsContainer = connect(
  (state: Store) => ({
    networkAudits: state.networkAudits.audits,
    loading: state.networkAudits.loading,
    locationId: selectCurrentLocationId(state),
    currentLocation: selectCurrentLocation(state),
    users: state.connectedUsers.users,
    loadingUsers: state.connectedUsers.loading,
  }),
  { getNetworkAudits, runNetworkAudit, getConnectedUsers }
)(NetworkAudits);
