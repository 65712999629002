import { css } from '@emotion/core';
import React from 'react';
import { theme } from '@weave/theme-original';
import { Heading, Text } from '@weave/design-system';
import { formatNumber, formatSubscriberInvoiceDateForDisplay } from '../billing.helpers';
import { BillingInvoiceDetails } from '../billing.types';

type Props = {
  invoiceDetails: BillingInvoiceDetails | undefined;
  currency: string;
};

export const InvoiceDetails = ({ invoiceDetails, currency, ...rest }: Props) => {
  return (
    <section {...rest}>
      <div css={topSectionStyles}>
        <header>
          {invoiceDetails?.invoice?.createTime && (
            <Text weight="bold" css={invoiceDateHeader}>
              {formatSubscriberInvoiceDateForDisplay(invoiceDetails?.invoice?.createTime)}
            </Text>
          )}
          <Heading css={invoiceIdHeader}>{invoiceDetails?.invoice.id}</Heading>
        </header>

        <dl css={dlStyle}>
          <div css={dataItem}>
            <Text as="dt" color="light">
              Amount Paid
            </Text>
            <Text as="dd" weight="bold">
              {formatNumber(+(invoiceDetails?.invoice?.amountPaid ?? ''), currency)}
            </Text>
          </div>

          <div css={dataItem}>
            <Text as="dt" color="light">
              Amount Due
            </Text>
            <Text as="dd" weight="bold">
              {formatNumber(+(invoiceDetails?.invoice?.amountDue ?? ''), currency)}
            </Text>
          </div>

          <div css={dataItem}>
            <Text as="dt" color="light">
              Payment Terms
            </Text>
            <Text as="dd" weight="bold">
              Due on receipt
            </Text>
          </div>

          <div css={dataItem}>
            <Text as="dt" color="light">
              Due Date
            </Text>
            <Text as="dd" weight="bold">
              {invoiceDetails?.invoice.dueDate}
            </Text>
          </div>
        </dl>
      </div>

      <table css={tableStyles}>
        <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
            <th>Qty</th>
            <th>Unit Price</th>
            <th>Extended Price</th>
          </tr>
        </thead>
        <tbody>
          {invoiceDetails?.lineItems?.map((lineItem) => (
            <tr key={lineItem.id}>
              <td>{lineItem.code}</td>
              <td>{lineItem.description}</td>
              <td>{lineItem.quantity}</td>
              <td>{formatNumber(+lineItem.unitPrice, currency)}</td>
              <td>{formatNumber(+lineItem.amount, currency)}</td>
            </tr>
          ))}
          <tr className="billing--totals-row">
            <td colSpan={6}>
              <Text weight="bold" css={totalTextStyles}>
                {formatNumber(+(invoiceDetails?.invoice.amount ?? ''), currency)} Total
              </Text>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

const dlStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  dt {
    margin: ${theme.spacing(2, 0)};
    font-size: ${theme.fontSize(21)};
    color: #6f7780;
  }

  dd {
    word-break: break-word;
    font-size: ${theme.fontSize(21)};
    color: #32373e;
  }

  @media only screen and (min-width: 1400px) {
    flex-basis: 40%;
    margin-bottom: 0;
  }
`;

const dataItem = css`
  display: flex;
  flex-direction: column;
`;

const topSectionStyles = css`
  padding: ${theme.spacing(3)};
  margin-bottom: 100px;
`;
const invoiceDateHeader = css`
  font-size: ${theme.fontSize(24)};
  color: #757b7c;
  margin-bottom: 0;
`;
const invoiceIdHeader = css`
  font-size: ${theme.fontSize(36)};

  color: #333b3d;
`;
const tableStyles = css`
  width: 100%;

  thead {
    border-bottom: 1px solid #ced3da;
  }

  th {
    font-size: ${theme.fontSize(14)};
    color: #a1a8b0;
    padding-bottom: ${theme.spacing(3)};
  }

  td {
    font-size: ${theme.fontSize(16)};
    color: #6f7780;
    padding: ${theme.spacing(2, 0)};
  }

  tr:nth-child(even):not(:last-child) {
    background: #e5e8ea;
  }

  .billing--totals-row {
    background: #32373e;
  }
  .billing--totals-row td {
    text-align: right;
    padding: ${theme.spacing(2, 5)};
  }
`;

const totalTextStyles = css`
  color: white;
  margin: 0;
  font-size: ${theme.fontSize(21)};
`;
