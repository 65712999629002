import React from 'react';
import { Button, Input, Modal } from '@weave/platform-ui-components';
import { get } from 'lodash';

import * as styles from './media-upload-modal.styles';
import { getFileExtensionFromFileName } from '../../../../helpers/utils';

interface Props {
  isOpen: boolean;
  maxFileNameLength: number;
  onRequestClose: () => void;
  uploadMediaFile: (payload: { phoneMedia: File; fileName: string }) => void;
}

interface State {
  error: string;
  mediaFile: File | null;
  fileExt: string;
  fileName: string;
}

export class MediaUploadModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      fileExt: '',
      mediaFile: null,
      fileName: '',
    };
  }

  handleCloseModal = () => {
    this.setState({
      error: '',
      mediaFile: null,
      fileName: '',
    });

    this.props.onRequestClose();
  };

  handleFileChange = (selectorFiles: FileList | null) => {
    if (selectorFiles && selectorFiles[0]) {
      const mediaFile = get(selectorFiles, '[0]');
      const fileExt = getFileExtensionFromFileName(mediaFile.name);

      this.setState({
        error: '',
        fileExt,
        mediaFile,
      });
    } else {
      this.setState({
        error: 'Please select an audio file',
        fileExt: '',
        mediaFile: null,
      });
    }
  };

  handleFileNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;

    if (target.value.length <= this.props.maxFileNameLength) {
      this.setState({ fileName: target.value });
    }
  };

  uploadMediaFile = () => {
    if (!this.state.mediaFile) {
      return this.setState({ error: 'Please select an audio file' });
    }

    this.props.uploadMediaFile({
      phoneMedia: this.state.mediaFile,
      fileName: this.state.fileName ? `${this.state.fileName}.${this.state.fileExt}` : '',
    });

    this.handleCloseModal();
  };

  render() {
    const fileName = get(this.state.mediaFile, 'name');

    return (
      <Modal
        className={styles.modal}
        title="Upload Media File"
        onRequestClose={this.handleCloseModal}
        isOpen={this.props.isOpen}
      >
        {this.state.error && <div className={styles.error}>{this.state.error}</div>}
        <label className={styles.fileInputLabel}>
          <input
            className={styles.fileInput}
            type="file"
            onChange={(e) => this.handleFileChange(e.target.files)}
            accept="audio/wav, audio/mpeg, video/mp4"
          />
          {fileName && `Selected file: ${fileName}`}
          {!fileName && 'Choose a file to upload...'}
        </label>
        <Input
          placeholder="Enter media name"
          value={this.state.fileName}
          name="fileName"
          onChange={(e) => this.handleFileNameChange(e)}
        />
        <div className={styles.buttonContainer}>
          <Button
            fill="outline"
            color="gray"
            className={styles.button}
            onClick={this.handleCloseModal}
          >
            Cancel
          </Button>
          <Button className={styles.button} onClick={this.uploadMediaFile}>
            Save
          </Button>
        </div>
      </Modal>
    );
  }
}
