import React from 'react';
import { Modal, TextField, useFormField } from '@weave/design-system';

type CompleteFormModalProps = {
  onProceed: (code: string) => void;
};

export const CompleteFormModal = ({ onProceed }: CompleteFormModalProps) => {
  const fieldProps = useFormField({
    type: 'text',
    required: true,
  });

  return (
    <>
      <Modal.Header>Enter Code</Modal.Header>
      <Modal.Body>
        <TextField {...fieldProps} label={'Code'} name="completeFormCode" />
      </Modal.Body>
      <Modal.Actions
        primaryLabel="Proceed"
        onPrimaryClick={() => onProceed(fieldProps.value)}
      />
    </>
  );
};
