import React from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { Text } from '@weave/design-system';
import { Package } from '../customization.constant';

interface Props {
  package: Package;
  isSelectedPackage: boolean;
  onPackageClicked: (pkg: Package) => void;
}

export const CustomizationPackage = (props: Props) => {
  const { package: pkg, isSelectedPackage } = props;

  const handlePackageClicked = () => {
    props.onPackageClicked(pkg);
  };

  return (
    <div
      css={(theme: WeaveTheme) => cardStyles(theme, isSelectedPackage)}
      onClick={handlePackageClicked}
    >
      <pkg.icon css={svgIconStyles} />
      <Text>{pkg.label}</Text>
    </div>
  );
};

const cardStyles = (theme: WeaveTheme, isSelectedPackage: boolean) => css`
  width: 232px;
  height: 50px;
  display: flex;
  padding: 14px 18px;
  cursor: pointer;
  box-shadow: 0 4px 10px 0 rgba(49, 53, 110, 0.13);
  border-radius: 10px;
  margin-bottom: 5%;
  ${isSelectedPackage ? `border: 2px solid ${theme.colors.weaveBlue}` : ''}
`;

const svgIconStyles = css`
  margin-right: 16px;
`;
