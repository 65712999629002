import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Modal,
  Title,
  Headline,
  Subtitle,
  Body,
  RadioGroup,
  Radio,
  Audio,
} from '@weave/platform-ui-components';
import { useAlert } from '@weave/alert-system';
import { get, find } from 'lodash';
import { cx } from 'emotion';
import { useResources, UseResourcesResponse } from '../../../helpers/useResources';
import { ConditionalLoader } from '../../shared/loader/conditional-loader.component';
import * as styles from './hold-music.styles';
import { CustomAxios } from '../../../redux/axios';
import { SelectMediaModal } from '../select-media-modal/select-media-modal.component';
import { Page } from '../../shared/page/page.component';
import { phoneMediaSelector, PhoneMedia } from '../../../redux/actions';
import { TextLink, useModalControl } from '@weave/design-system';

interface HoldMusicFile extends PhoneMedia {
  Artist?: string;
  ArtistRole?: string;
  Bio?: string;
  Image?: string;
  Title: string;
}

interface FetchHoldMusicListResponse extends UseResourcesResponse {
  data: {
    Defaults: HoldMusicFile[];
    Originals: HoldMusicFile[];
  };
}

interface FetchSelectedFileResponse extends UseResourcesResponse {
  data: {
    MediaID: string;
  };
}

export const HoldMusic = () => {
  const { loadingPhoneMedia, locationId, token, phoneMedia } =
    useSelector(phoneMediaSelector);
  const [showArtistModal, setShowArtistModal] = useState<boolean>(false);
  const [activeArtistImageSrc, setActiveArtistImageSrc] = useState<string>('');
  const [activeArtistRole, setActiveArtistRole] = useState<string>('');
  const [activeArtistBio, setActiveArtistBio] = useState<string>('');
  const [activeArtistName, setActiveArtistName] = useState<string>('');
  const { modalProps, triggerProps } = useModalControl();
  const alerts = useAlert();

  const {
    data: holdMusicList,
    loading: loadingHoldMusicList,
  }: FetchHoldMusicListResponse = useResources('/support/v1/phonemedia/hold-music', {
    deps: [locationId],
    resourceLabel: 'hold music list',
    suppressFetch: !locationId,
  });

  const {
    data: selectedFile,
    loading: loadingSelectedFile,
    setResources: setSelectedFile,
  }: FetchSelectedFileResponse = useResources(
    `/support/v1/locations/${locationId}/hold-music`,
    {
      deps: [locationId],
      resourceLabel: 'hold music list',
      suppressFetch: !locationId,
      emptyState: {},
    }
  );

  const selectedMediaId = selectedFile.MediaID;
  const customMediaFile = find(phoneMedia, { MediaID: selectedMediaId });

  const onArtistClick = (
    imageSrc?: string,
    role?: string,
    bio?: string,
    name?: string
  ) => {
    if (imageSrc && role && bio && name) {
      setActiveArtistImageSrc(imageSrc);
      setActiveArtistRole(role);
      setActiveArtistBio(bio);
      setActiveArtistName(name);
      setShowArtistModal(true);
    }
  };

  const getActiveMediaFileName = () => {
    const file = find(phoneMedia, { MediaID: selectedMediaId });
    let fileName = get(file, 'FileName', '');

    fileName = fileName.replace(/.wav|.mp3|.mp4|.wma|.ogg|.m4a/, '').substring(0, 30);

    return fileName || 'Select custom media file...';
  };

  const updateSelectedMediaId = (id: string, delay = 0) => {
    const previousSelectedId = selectedMediaId;

    // Optimistically update the selected media id
    setSelectedFile({ MediaID: id });
    setTimeout(async () => {
      try {
        await CustomAxios.post(`/support/v1/locations/${locationId}/hold-music`, {
          MediaID: id,
        });
      } catch {
        // Need to roll back the optimistic update
        setSelectedFile({ MediaID: previousSelectedId });
        alerts.error('Error updating hold music');
      }
    }, delay);
  };

  const renderOptions = (files: HoldMusicFile[] = []) => {
    return files?.map((file) => {
      return (
        <div key={file.MediaID} className={styles.sectionRow}>
          <Radio
            value={file.MediaID}
            name={file.Title}
            onClick={() => {
              updateSelectedMediaId(file.MediaID);
            }}
            checked={selectedMediaId === file.MediaID}
          />
          <div className={styles.mediaData}>
            <div className={styles.fileTitle}>
              <Subtitle>{file.Title}</Subtitle>
            </div>
            {file.Artist && (
              <div>
                <Body>
                  Artist:
                  <span
                    className={styles.link}
                    onClick={() =>
                      onArtistClick(file.Image, file.ArtistRole, file.Bio, file.Artist)
                    }
                  >
                    {` ${file.Artist}`}
                  </span>
                </Body>
              </div>
            )}
          </div>
          <Audio
            src={`${file.FilePath}?token=${token}&location_id=${locationId}`}
            className={styles.audio}
          />
        </div>
      );
    });
  };

  return (
    <Page
      title="Hold Music"
      subtitle="When office customers are on hold this system will play hold music for their
      listening enjoyment. "
      expandedSubtitle="Below you can choose from Weave’s default music option,
      Weave Employee Originals, or a file from the Media Library. The selected
      option will continue to repeat while the customer is on hold."
    >
      <Modal
        onRequestClose={() => setShowArtistModal(false)}
        isOpen={showArtistModal}
        className={styles.modalCard}
      >
        <div className={styles.modalBody}>
          <img src={activeArtistImageSrc} className={styles.artistImage} alt="Artist" />
          <Title className={styles.artistNameTitle}>{activeArtistName}</Title>
          <Subtitle className={styles.artistRole}>{activeArtistRole}, Weave</Subtitle>
          <Subtitle className={styles.artistBio}>{activeArtistBio}</Subtitle>
        </div>
      </Modal>
      <SelectMediaModal
        onMediaFileSelected={(fileId: string) => updateSelectedMediaId(fileId, 1000)}
        modalProps={modalProps}
      />
      <ConditionalLoader
        loading={
          loadingPhoneMedia || !locationId || loadingHoldMusicList || loadingSelectedFile
        }
      >
        <RadioGroup>
          <section className={styles.section}>
            <Headline>Default</Headline>
            <Subtitle className={styles.explainerText}>
              This is the default hold music that will play if nothing else is chosen.
            </Subtitle>
            {renderOptions(holdMusicList.Defaults)}
          </section>
          <section className={styles.section}>
            <Headline>Weave Employee Originals</Headline>
            <Subtitle className={styles.explainerText}>
              These hold music options were created by Weave employees.
            </Subtitle>
            {renderOptions(holdMusicList.Originals)}
          </section>
          <section className={styles.section}>
            <Headline>Custom</Headline>
            <Subtitle className={styles.explainerText}>
              If a custom hold music or office advertisment file has been uploaded to the
              Media Library, you can select it here.
            </Subtitle>
            <div className={styles.sectionRow}>
              <Radio
                value={selectedMediaId}
                name="customHoldMusicRadio"
                checked={!!customMediaFile}
              />
              {customMediaFile && (
                <>
                  <div className={styles.mediaData}>
                    <Subtitle className={styles.fileTitle}>
                      {getActiveMediaFileName()}
                    </Subtitle>
                    <div>
                      <TextLink className={styles.link} {...triggerProps}>
                        Select Media File
                      </TextLink>
                    </div>
                  </div>
                  <Audio
                    src={`${customMediaFile.FilePath}?token=${token}&location_id=${locationId}`}
                    className={styles.audio}
                  />
                </>
              )}
              {!customMediaFile && (
                <span className={styles.mediaData}>
                  <TextLink
                    className={cx(styles.fileTitle, styles.link)}
                    {...triggerProps}
                  >
                    Select Media File
                  </TextLink>
                </span>
              )}
            </div>
          </section>
        </RadioGroup>
      </ConditionalLoader>
    </Page>
  );
};
