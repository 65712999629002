import { createAction } from 'redux-actions';
import { call, put } from 'redux-saga/effects';
import { CustomAxios } from '../../../axios';
import { sentimentTrainingRank } from '../get-sentiment-training-rank/get-sentiment-training-rank.action';
import { sentimentTrainingClassifying } from '../get-sentiment-training-classifying/get-sentiment-training-classifying.action';

export const postSentimentTrainingClassifying = createAction(
  'POST_SENTIMENT_TRAINING_CLASSIFYING'
);
export const postSentimentTrainingClassifyingSuccess = createAction(
  'POST_SENTIMENT_TRAINING_CLASSIFYING_SUCCESS'
);
export const postSentimentTrainingClassifyingFailure = createAction<Error>(
  'POST_SENTIMENT_TRAINING_CLASSIFYING_FAILURE'
);

export const sentimentUrl = `/support/v1/sentimenttraining/:id/classification/:label`;

export const handlePostSentimentTrainingClassifying = function* (action) {
  try {
    const { id } = action.payload;
    const label = action.payload.label.replace(/\s/, '');
    const url = sentimentUrl.replace(':id', id).replace(':label', label);

    yield call(CustomAxios.post, url);
    yield put(postSentimentTrainingClassifyingSuccess());
  } catch (error: any) {
    yield put(postSentimentTrainingClassifyingFailure(error));
  }

  yield put(sentimentTrainingRank());
  yield put(sentimentTrainingClassifying());
};
