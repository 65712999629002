import { css } from 'emotion';
import { weaveBlue, weaveBlueDark, gray1 } from '@weave/platform-ui-components';

export const schedulingHubStyles = css`
  width: 98%;
`;
export const topBar = css`
  display: flex;
  justify-content: space-between;
  margin-left: 12px;
`;
export const titlesDiv = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;
export const topTitleMargin = css`
  margin-top: 20px;
`;
export const schedulingTitle = css`
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  margin-bottom: 8px;
`;
export const refreshButtonDiv = css`
  margin-top: 9px;
`;
export const buttonStyles = css`
  width: 125px;
  height: 35px;
  font-size: 15px;
  letter-spacing: 0.16px;
`;
export const cardsSection = css`
  display: flex;
  flex-wrap: wrap;
`;
export const additionalEventsSection = css`
  display: flex;
  flex-wrap: wrap;
  width: 800px;
`;
export const additionalSectionSubtitle = css`
  margin: 24px 0px 4px 12px;
`;
export const scheduleAdditionalEventDiv = css`
  margin: 24px 12px;
`;
export const scheduleLink = css`
  color: ${weaveBlue} !important;

  &:hover {
    color: ${weaveBlueDark} !important;
    cursor: pointer !important;
  }
`;
export const scheduleAdditionalEventText = css`
  font-size: 16px;
`;
export const additionalEventDiv = css`
  &:hover {
    background-color: ${gray1};
    cursor: pointer;
  }
`;
export const additionalEventModal = css`
  padding: 15px;
  color: #555;
`;

export const cancelEventModal = css`
  color: #555;
  width: 550px;
  padding: 10px;
`;

export const modalTitleStyles = css`
  padding: 15px;
  text-align: center;
`;

export const modalSubtitleStyles = css`
  font-weight: 300;
`;

export const cancelModalSubtitleStyles = css`
  font-weight: 300;
  text-align: center;
`;

export const loaderSubtitleStyles = css`
  font-weight: 300;
  padding: 25px 0 0 15px;
`;

export const actionsContainer = css`
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  padding: 0 100px;
`;

export const cancelEventModalLoader = css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
`;

export const successImageStyle = css`
  height: 180px;
  width: 180px;
`;

export const successContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: space-apart;
  align-items: center;
`;

export const successSubtitleStyles = css`
  font-weight: 300;
  text-align: center;
  margin: 15px;
`;
