import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { WeaveTheme } from '@weave/theme-original';
import {
  Modal,
  PrimaryButton,
  useFormField,
  FormRow,
  Text,
  TextField,
} from '@weave/design-system';
import { css } from '@emotion/core';
import { LocationSearchContainer } from '../location-search-container/location-search.container';
import { LocationSearchModel } from '../../../models/location-search.model';
import {
  requestSetLocationParent,
  requestSetPhoneTenantID,
} from '../../../redux/actions/location/locations';

interface Props {
  locationID: string;
  parentLocationID: string;
  phoneTenantID: string;
  onClose: () => void;
}

export const LocationParentageModal = ({
  locationID,
  parentLocationID,
  phoneTenantID,
  onClose,
}: Props) => {
  const dispatch = useDispatch();
  const [newParent, setNewParent] = useState<LocationSearchModel>();
  const newPhoneTenantID = useFormField({ type: 'text' });

  useEffect(() => {
    if (phoneTenantID) {
      newPhoneTenantID.onChange({ name: 'tenantID', value: phoneTenantID });
    }
  }, []);

  const handleSubmit = () => {
    if (parentLocationID.trim() !== newParent?.LocationID.trim()) {
      dispatch(requestSetLocationParent(locationID, newParent?.LocationID ?? ''));
    }
    if (phoneTenantID.trim() !== newPhoneTenantID.value.trim()) {
      dispatch(requestSetPhoneTenantID(newPhoneTenantID.value ?? ''));
    }
    onClose();
  };

  /**
   * @desc: Function is defined to get primary button verbiages
   * @returns string
   */
  const getPrimaryLabel = () => {
    if (!parentLocationID && newPhoneTenantID.value) {
      return 'Set Parent & Tenant';
    } else if (!parentLocationID) {
      return 'Set Parent';
    } else if (parentLocationID && !newPhoneTenantID.value) {
      return 'Cleat Parent & Tenant';
    } else {
      return 'Clear Parent';
    }
  };

  return (
    <>
      <Modal.Header>Location Parentage</Modal.Header>
      <Text textAlign="center">(DO NOT USE UNLESS ABSOLUTELY SURE)</Text>
      <Modal.Body
        css={(theme: WeaveTheme) => css`
          padding-top: ${theme.spacing(1)};
          padding-left: ${theme.spacing(1)};
          min-height: 150px;
        `}
      >
        {!parentLocationID ? (
          <div>
            <label htmlFor="ParentID">Set Parent Location:</label>
            <div>
              {!newParent ? (
                <LocationSearchContainer
                  css={css`
                    width: 100%;
                    margin-left: 1px;
                  `}
                  onSelect={(selection) => {
                    setNewParent(selection);
                  }}
                />
              ) : (
                <div>
                  Selected:{' '}
                  <strong>
                    {newParent.Name} - {newParent.LocationID}
                  </strong>
                  <div>
                    <PrimaryButton size="tiny" onClick={() => setNewParent(undefined)}>
                      Clear Selected
                    </PrimaryButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <label htmlFor="ParentID">Clear Parent Location</label>
          </div>
        )}
        {!phoneTenantID ? (
          <div
            css={(theme: WeaveTheme) => css`
              margin-top: ${theme.spacing(2)};
            `}
          >
            <Text as="label">Set Phone Tenant ID:</Text>
            <FormRow>
              <TextField
                {...newPhoneTenantID}
                clearable
                label="Phone Tenant ID"
                placeholder=""
                name="tenantID"
              />
            </FormRow>
          </div>
        ) : (
          <div
            css={(theme: WeaveTheme) => css`
              margin-top: ${theme.spacing(2)};
            `}
          >
            <FormRow>
              <TextField
                {...newPhoneTenantID}
                clearable
                label="Phone Tenant ID"
                placeholder=""
                name="tenantID"
              />
            </FormRow>
          </div>
        )}
      </Modal.Body>
      <Modal.Actions
        primaryLabel={getPrimaryLabel()}
        disablePrimary={!parentLocationID && !newParent}
        onPrimaryClick={handleSubmit}
        onSecondaryClick={onClose}
      />
    </>
  );
};
