import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  EditIcon,
  FormRow,
  IconButton,
  SpinningLoader,
  SyncIcon,
  TextLink,
  TrashIcon,
  CheckboxField,
  TextareaField,
  useForm,
  PrimaryButton,
} from '@weave/design-system';
import {
  MediaData,
  MediaType,
  PhoneBillDownloadPayload,
  PortCreationFileResponseData,
  PortGetData,
  PortViewFileUploadResponse,
  RequestClient,
  UploadLOAMediaType,
} from 'apis/porting/porting.types';
import { portingCardStyles } from './porting-card.styles';
import { theme } from '@weave/theme-original';
import { useAlert } from '@weave/alert-system';
import { PortOrderUploadComponent } from '../port-order/port-order-upload.component';
import {
  portingApi as API,
  portViewBillDownload,
  portViewBillsUpload,
  portViewLoaDownload,
  portViewLoaUpload,
} from 'apis/porting/porting.api';
import fileDownload from 'js-file-download';
import { LoaderModal } from '../utils/loader.component';
import { FileButton } from '../port-order/port-order.component';
import { useDropzone } from 'react-dropzone';
import { cloneDeep } from 'lodash';
import { css } from '@emotion/core';
import { PortStatus } from '../utils/port-constants';
import { downloadLOA } from 'redux/actions/porting';
import { useDispatch } from 'react-redux';

type SortedFiles = { accepted: File[]; rejected: File[]; duplicates: File[] };

interface Props {
  port: PortGetData;
  canEditAttachments: boolean;
  setCanEditAttachments: React.Dispatch<React.SetStateAction<boolean>>;
  handleDrawerToggle: (
    drawer: string,
    toggleState: boolean,
    disableToggle: boolean
  ) => void;
  updatePortingDetails: (port) => void;
}

export const PortingCardAttachments = (props: Props) => {
  const {
    port,
    canEditAttachments,
    setCanEditAttachments,
    handleDrawerToggle,
    updatePortingDetails,
  } = props;
  const portingRequest = port?.porting_requests[0];
  const alert = useAlert();

  useEffect(() => {
    if (port?.customer_phone_bill_media) {
      const loaFiles = port?.customer_phone_bill_media
        ?.filter((item) => item.media_type === 'loa')
        .sort((file1, file2) => {
          if (!!file1.updated_at && !!file2.updated_at)
            return new Date(file1.updated_at) < new Date(file2.updated_at) ? 1 : -1;
          return 0;
        });
      setAttachedLOA(loaFiles ?? []);
      const bills = port?.customer_phone_bill_media?.filter(
        (item) => item.media_type === 'phone_bill'
      );
      setAttachedBills(bills ?? []);
    }
  }, [port.customer_phone_bill_media]);

  const [loaLoader, setLoaLoader] = useState<boolean>(false);
  const [billLoader, setBillLoader] = useState<boolean>(false);
  const [attachedLOA, setAttachedLOA] = useState<MediaData[]>([]);
  const [attachedBills, setAttachedBills] = useState<MediaData[]>([]);
  const [downloadLOALoading, setDownloadLOALoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const {
    getFieldProps,
    values: fields,
    isComplete,
  } = useForm({
    fields: {
      noBillAvailable: { type: 'checkbox', value: attachedBills.length === 0 },
      noBillReason: {
        type: 'text',
        value: port.reason_bill_not_available,
        required: true,
      },
    },
  });
  const noBillProps = getFieldProps('noBillAvailable');

  const isSameFileAlreadyUploaded = (file: File, currentFiles: MediaData[]) => {
    return currentFiles.some((item) => item.file_name === file.name);
  };

  const validateFile = (acceptedFiles: File[], currentFiles: MediaData[]) => {
    if (acceptedFiles.length > 0) {
      const sortedFiles = acceptedFiles.reduce(
        (acc, file) => {
          const isDuplicate = isSameFileAlreadyUploaded(file, currentFiles);
          if (file.size <= 3e6 && !isDuplicate) {
            return { ...acc, accepted: [...acc.accepted, file] };
          } else if (isDuplicate) {
            return { ...acc, duplicates: [...acc.duplicates, file] };
          } else {
            return { ...acc, rejected: [...acc.rejected, file] };
          }
        },
        { accepted: [], rejected: [], duplicates: [] } as SortedFiles
      );
      return sortedFiles;
    }
    return { accepted: [], rejected: [], duplicates: [] } as SortedFiles;
  };

  const showAlerts = (sortedFile: SortedFiles) => {
    sortedFile.rejected.forEach((file) => {
      alert.error(`Error uploading file: ${file.name}`);
    });
    sortedFile.duplicates.forEach((file) => {
      alert.error(
        `The file with name ${file.name} has been already uploaded. Please upload with different name.`
      );
    });
  };

  const handleFileUpload = async (file: File, type: MediaType) => {
    if (file !== undefined) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', type);
        const data = await API.uploadPhoneFile(formData);
        alert.success(`Successfully submitted file: ${file.name}`);
        return data;
      } catch {
        alert.error('Error uploading file.');
        return null;
      }
    }
    return null;
  };

  const onLoaDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setLoaLoader(true);
      const sortedFile = validateFile(acceptedFiles, attachedLOA);
      if (sortedFile.accepted.length > 0) {
        try {
          const responseData: PortCreationFileResponseData | null =
            await handleFileUpload(sortedFile?.accepted?.[0], MediaType.LOA);
          if (responseData?.ID) {
            const payload: UploadLOAMediaType = {
              porting_data_id: port.id,
              media_id: responseData?.ID,
              file_name: responseData?.Name,
              media_type: MediaType.LOA,
            };
            const response: PortViewFileUploadResponse | null = await portViewLoaUpload(
              port.id,
              payload
            );
            const mediaData: MediaData = response.media_data;
            if (mediaData?.id) {
              const acceptedFiles: MediaData[] = [];
              acceptedFiles.push(mediaData);
              setAttachedLOA([...acceptedFiles]);
            }
          }
        } catch (err) {
          alert.error(`Error in uplaoding file: ${err.message}`);
        }
      }
      setLoaLoader(false);
      showAlerts(sortedFile);
    },
    [attachedLOA]
  );

  const removeLOA = (file: MediaData) => {
    setAttachedLOA((prevFiles) => {
      const tempIndex = prevFiles.findIndex((item) => item.media_id === file.media_id);
      const replacedFile = cloneDeep(prevFiles);
      if (tempIndex >= 0) replacedFile.splice(tempIndex, 1);
      return replacedFile;
    });
  };

  const removeBills = (file: MediaData) => {
    const tempIndex = attachedBills.findIndex((item) => item.media_id === file.media_id);
    attachedBills.splice(tempIndex, 1);
    setAttachedBills([...attachedBills]);
  };

  const onRemoveFile = async (file: MediaData) => {
    if (!file.id) {
      return;
    }
    try {
      await API.portViewFileDelete(port.id, file.id);
      if (file.media_type === MediaType.LOA) {
        removeLOA(file);
      } else {
        removeBills(file);
      }
    } catch {
      alert.error(`deletion failed: ${file.file_name}`);
    }
  };

  const { getInputProps, open } = useDropzone({
    accept: '.pdf',
    noClick: true,
    noKeyboard: true,
    onDrop: onLoaDrop,
    preventDropOnDocument: true,
    noDragEventsBubbling: true,
    maxSize: 3e6,
    multiple: false,
  });

  const onBillsDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length + attachedBills.length > 5) {
        alert.error('You can add a maximum of 5 previous bills');
        return;
      }
      setBillLoader(true);
      const sortedFile = validateFile(acceptedFiles, attachedBills);
      sortedFile.accepted.forEach(async (file) => {
        try {
          const responseData: PortCreationFileResponseData | null =
            await handleFileUpload(file, MediaType.phoneBill);
          if (responseData?.ID) {
            const payload: UploadLOAMediaType = {
              porting_data_id: port.id,
              media_id: responseData?.ID,
              file_name: responseData?.Name,
              media_type: MediaType.phoneBill,
            };
            const response: PortViewFileUploadResponse | null = await portViewBillsUpload(
              port.id,
              payload
            );
            const mediaData: MediaData = response.media_data;
            if (mediaData?.id) {
              const acceptedFiles: MediaData[] = [];
              acceptedFiles.push(mediaData);
              setAttachedBills((prevBills) => [...prevBills, ...acceptedFiles]);
            }
          }
        } catch (err) {
          alert.error(err.message);
        }
      });
      setBillLoader(false);
      showAlerts(sortedFile);
    },
    [attachedBills.length]
  );

  const handleDownloadLOA = async (filename: string) => {
    const { id } = port;
    setDownloadLOALoading(true);
    try {
      const response = await portViewLoaDownload(id);
      const file = response.data;
      fileDownload(file, filename, 'application/pdf');
    } catch (err) {
      alert.error(err.message);
    } finally {
      setDownloadLOALoading(false);
    }
  };

  const handleDownloadBill = async (mediaObj: MediaData) => {
    const { location_id } = port;
    const payload: PhoneBillDownloadPayload = {
      customer_phone_bill_media_id: mediaObj.media_id,
      customer_phone_bill_media_type: mediaObj.media_type,
      location_id,
    };
    setDownloadLOALoading(true);
    try {
      const response = await portViewBillDownload(payload);
      const file = response.data;
      fileDownload(file, mediaObj.file_name, 'application/pdf');
    } catch (err) {
      alert.error(err.message);
    } finally {
      setDownloadLOALoading(false);
    }
  };

  const handleUpdatePortingDetails = () => {
    const portCopy = cloneDeep(port);
    portCopy.no_bill_available = attachedBills.length === 0 ? true : false;
    portCopy.reason_bill_not_available =
      attachedBills.length === 0 ? fields.noBillReason ?? '' : '';
    portCopy.customer_phone_bill_media = [...attachedLOA, ...attachedBills];
    updatePortingDetails(portCopy);
  };

  const haveEditAccess = useMemo(() => {
    const noEditAccessStatuses = [
      PortStatus.cancelPending,
      PortStatus.cancelled,
      PortStatus.accepted,
      PortStatus.requestedFOC,
      PortStatus.requestedCancel,
      PortStatus.activationInProgress,
    ];
    return !noEditAccessStatuses.includes(portingRequest.porting_status ?? 0);
  }, [portingRequest]);

  const disableEditIcon =
    canEditAttachments &&
    (!port.reason_bill_not_available || !fields.noBillReason) &&
    attachedBills.length === 0;

  return (
    <div>
      <div className={portingCardStyles.portingInfoContainer}>
        <div className={portingCardStyles.title}>Documents</div>
        {(!portingRequest.port_order_number || haveEditAccess) && (
          <div>
            <EditIcon
              css={css`
                color: ${!canEditAttachments && theme.colors.weaveBlue};
              `}
              onClick={() =>
                handleDrawerToggle('attachments', canEditAttachments, disableEditIcon)
              }
            />
          </div>
        )}
      </div>

      <div className={portingCardStyles.officeCont}>
        <div
          css={css`
            display: flex;
          `}
        >
          <div
            css={css`
              width: 250px;
              margin-bottom: 10px;
            `}
          >
            LOA
          </div>
          <div>
            {canEditAttachments && !!attachedLOA && (
              <FormRow>
                {attachedLOA.length <= 0 && (
                  <PortOrderUploadComponent onFileDrop={onLoaDrop} />
                )}
                {attachedLOA.length > 0 && (
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                    `}
                  >
                    {attachedLOA.map((item, index) => {
                      return (
                        <div
                          key={item.file_name}
                          css={css`
                            display: flex;
                            align-items: center;
                          `}
                        >
                          <TextLink
                            css={css`
                              padding-right: 10px;
                              margin: 0;
                            `}
                            onClick={() => {
                              handleDownloadLOA(item.file_name);
                            }}
                          >
                            {item.file_name}
                          </TextLink>
                          {attachedLOA.length === 1 ? (
                            <IconButton
                              label="Replace file"
                              size="small"
                              showLabelOnHover
                            >
                              <SyncIcon
                                onClick={() => open()}
                                css={css`
                                  cursor: pointer;
                                `}
                                color="light"
                              />
                            </IconButton>
                          ) : (
                            <IconButton label="Delete file" size="small">
                              <TrashIcon
                                onClick={() => onRemoveFile(item)}
                                css={css`
                                  cursor: pointer;
                                `}
                                color="light"
                              />
                            </IconButton>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
                {!!loaLoader && <SpinningLoader size="small" />}
              </FormRow>
            )}
            {!canEditAttachments && !!attachedLOA && attachedLOA.length > 0 && (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                `}
              >
                {attachedLOA.map((item, index) => {
                  return (
                    <div
                      key={item.file_name}
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <TextLink
                        css={css`
                          padding-right: 10px;
                          margin: 0;
                        `}
                        onClick={() => {
                          handleDownloadLOA(item.file_name);
                        }}
                      >
                        {item.file_name}
                      </TextLink>
                    </div>
                  );
                })}
              </div>
            )}
            {!canEditAttachments &&
              attachedLOA.length === 0 &&
              (port.request_client !== RequestClient.WAM ? (
                <TextLink
                  css={css`
                    padding-right: 10px;
                    margin: 0;
                  `}
                  onClick={() => {
                    dispatch(downloadLOA(port));
                  }}
                >
                  Download LOA
                </TextLink>
              ) : (
                <div>No files attached</div>
              ))}
          </div>
        </div>

        <div
          css={css`
            display: flex;
          `}
        >
          <div
            css={css`
              width: 250px;
              margin-bottom: 10px;
            `}
          >
            Previous Bill
          </div>
          <div>
            {canEditAttachments && (
              <FormRow>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      flex-direction: row;
                    `}
                  >
                    <PortOrderUploadComponent
                      onFileDrop={onBillsDrop}
                      canUploadMutipleFiles={true}
                    />
                    {!!billLoader && <SpinningLoader size="small" />}
                  </div>
                  {attachedBills.map((item, index) => {
                    return (
                      <FileButton
                        key={item.file_name}
                        file={item}
                        onRemove={onRemoveFile}
                        onDownload={handleDownloadBill}
                      ></FileButton>
                    );
                  })}
                </div>
              </FormRow>
            )}
            {!canEditAttachments && attachedBills.length > 0 && (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 10px;
                `}
              >
                {attachedBills.map((item, index) => {
                  return (
                    <div
                      key={item.file_name}
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <TextLink
                        css={css`
                          padding-right: 10px;
                          margin: 0;
                        `}
                        onClick={() => {
                          handleDownloadBill(item);
                        }}
                      >
                        {item.file_name}
                      </TextLink>
                    </div>
                  );
                })}
              </div>
            )}
            {attachedBills.length === 0 && (
              <>
                <CheckboxField
                  {...noBillProps}
                  name="noBillAvailable"
                  disabled={true}
                  label="No Bill Available"
                ></CheckboxField>
                {!!noBillProps.value && (
                  <TextareaField
                    {...getFieldProps('noBillReason')}
                    name="noBillReason"
                    disabled={!canEditAttachments}
                    label="Why isn't there a bill?"
                    css={css`
                      margin-top: 16px;
                      height: 80px;
                    `}
                  ></TextareaField>
                )}
              </>
            )}
          </div>
        </div>

        {canEditAttachments && attachedBills.length === 0 && (
          <div className={portingCardStyles.saveOfficeCont}>
            <PrimaryButton
              className={portingCardStyles.saveOffice}
              color="blue"
              size="large"
              onClick={() => {
                setCanEditAttachments(false);
                handleUpdatePortingDetails();
              }}
              disabled={!isComplete}
            >
              Save
            </PrimaryButton>
          </div>
        )}
      </div>

      <input
        {...getInputProps()}
        data-trackingid="insys-portal-phoneBillUploadStep-input-phoneBillUpload"
      />

      <LoaderModal showLoading={downloadLOALoading} message={'downloading...'} />
    </div>
  );
};
