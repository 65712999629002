import React from 'react';
import { Fragment, useState } from 'react';
import { Button, Input } from '@weave/platform-ui-components';
import { css } from 'emotion';

const style = css`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 10px;
  align-items: stretch;
  justify-content: center;
`;

const noMargin = css`
  position: relative;
  bottom: 13px;
`;
const height = css`
  height: 40px;
`;
export const AddNewAdvancedOption = ({ handleCheckBox, type }) => {
  const [newLocation, setNewLocation] = useState('');
  const handleNewInputChange = (event) => {
    setNewLocation(event.target.value);
  };

  const handleButtonClick = () => {
    setNewLocation('');
    handleCheckBox(type, newLocation);
  };
  return (
    <Fragment>
      <br />
      <div className={style}>
        <div className={height}>
          <Input
            className={noMargin}
            onChange={handleNewInputChange}
            name={'newLocation'}
            value={newLocation}
            placeholder="Add ClientLocationID"
          />
        </div>
        <div>
          <Button onClick={handleButtonClick}>+</Button>
        </div>
      </div>
    </Fragment>
  );
};
