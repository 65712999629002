import React from 'react';
import { css } from '@emotion/core';
import { Modal } from '@weave/platform-ui-components';
import { EditSimpleIcon } from '@weave/design-system';

import { OfficeHoursCardEditContainer } from './office-hours-card.container';

interface Props {
  open: any;
  title: string;
  close: string;
  lunchStart: string;
  lunchStop: string;
  breakStart: string;
  breakStop: string;
  day: string;
}

interface State {
  modalOpen: boolean;
}

export class OfficeHoursCard extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  handleModalOpenToggle = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  hoursOpenClosed = (title, timeBlock) => {
    return (
      <div className="office-hours-row">
        <div className="title">{title}:</div>
        <div className="hour">{timeBlock === '00:00:00' ? 'N/A' : timeBlock}</div>
      </div>
    );
  };

  render() {
    return (
      <div
        className="office-hours-card"
        css={css`
          border: 1px solid rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          padding: 0 25px 10px;
          width: 150px;

          .office-hours-title {
            color: #6c757d;
            display: flex;
            font-size: 1rem;
            justify-content: space-between;
            margin: 0.75rem 0 2rem;
          }

          .office-hours-section {
            padding: 15px 0;
            position: relative;

            &:after {
              background: #d4dbdf;
              content: '';
              height: 1px;
              left: 0;
              position: absolute;
              top: 0;
              width: 25%;
            }

            .title {
              font-size: 0.875rem;
            }
          }

          .office-hours-row {
            margin: 15px 0;

            [class*='-time'] {
              font-weight: bold;
            }
          }
        `}
      >
        <div
          className="ribbon-container"
          css={css`
            padding-bottom: 20px;
          `}
        >
          {this.props.open !== this.props.close && (
            <div
              className="ribbon"
              css={css`
                border: 8px solid #37cff1;
                border-bottom: 3px solid rgba(0, 0, 0, 0);
                border-top: 0 solid;
                box-sizing: content-box;
                color: black;
                font: normal 100% normal Arial, Helvetica, sans-serif;
                height: 15px;
                position: absolute;
                text-overflow: clip;
                width: 0;
              `}
            />
          )}
        </div>
        <div className="office-hours-title">
          {this.props.title}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          <a onClick={this.handleModalOpenToggle}>
            <EditSimpleIcon color="primary" />
          </a>
        </div>
        <div>
          <div className="open-hours office-hours-section">
            {this.hoursOpenClosed('Open', this.props.open)}
            {this.hoursOpenClosed('Close', this.props.close)}
          </div>
          <div className="lunch-hours office-hours-section">
            {this.hoursOpenClosed('Lunch Start:', this.props.lunchStart)}
            {this.hoursOpenClosed('Lunch Stop:', this.props.lunchStop)}
          </div>
          <div className="break-hours office-hours-section">
            {this.hoursOpenClosed('Break Start', this.props.breakStart)}
            {this.hoursOpenClosed('Break Stop', this.props.breakStop)}
          </div>
        </div>
        {
          <Modal
            onRequestClose={this.handleModalOpenToggle}
            isOpen={this.state.modalOpen}
            title={this.props.day}
            css={css`
              .react-modal-card {
                overflow: visible;
              }
            `}
          >
            <OfficeHoursCardEditContainer
              handleModalOpenToggle={this.handleModalOpenToggle}
              dayOfWeek={this.props.day}
            />
          </Modal>
        }
      </div>
    );
  }
}
