import { css } from 'emotion';
import { weaveTheme } from '@weave/theme-original/dist/weaveTheme';
export const messagesDiv = css``;
export const messagesTitle = css`
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 16px;
`;
export const tableStyles = css`
  width: 100%;
  margin-bottom: 20px;

  tr > *:not(:first-child) {
    font-size: 16px;
  }
  th {
    height: 40px;
    padding: 8px;
    background-color: #f6f7f7;
    color: ${weaveTheme.colors.gray400};
  }
  th:first-child {
    border-radius: 10px 0 0 10px;
  }
  th:last-child {
    border-radius: 0 10px 10px 0;
  }
  td {
    padding: 8px;
    color: ${weaveTheme.colors.gray600};
  }
`;
export const redText = css`
  td {
    color: ${weaveTheme.colors.error};
  }
`;
