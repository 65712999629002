import { connect } from 'react-redux';

import { FeatureFlagsManager } from './feature-flags-manager.component';
import { selectHasWeaveAcl } from '../../redux/actions/auth/auth.selectors';
import { CoreACLs } from '../../redux/actions/auth/auth.types';

export const FeatureFlagsManagerContainer = connect(
  (state: any) => ({
    canEditFeatureFlags: selectHasWeaveAcl(state, CoreACLs.FEATUREFLAGWRITE),
    canCreateFeatureFlags: selectHasWeaveAcl(state, CoreACLs.FEATUREFLAGCREATE),
    loading: state.featureFlags.loading,
  }),
  {}
)(FeatureFlagsManager);
