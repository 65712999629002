import React, { useState } from 'react';
import {
  FormRow,
  Modal,
  SwitchField,
  TextareaField,
  useForm,
} from '@weave/design-system';
import { WeaveTheme } from '@weave/theme-original';
import { showError, showSuccess } from '@weave/alert-system';
import { css } from '@emotion/core';
import { CustomAxios } from '../../redux/axios';
import { useDispatch } from 'react-redux';

interface Props {
  flagName: string;
  close: Function;
}

export const BatchSetFlagModal = (props: Props) => {
  const dispatch = useDispatch();

  const [allLocations, setAllLocations] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const [skipBadRecords, setSkipBadRecords] = useState(false);
  const uuidRegex =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  const { getFieldProps, formProps, values, isComplete } = useForm({
    allowInvalidSubmission: true,
    fields: {
      locationIDs: { type: 'text', required: true, value: '' },
      newValue: { type: 'switch', value: false },
    },
    onSubmit: function () {
      switch (true) {
        // if using all locations make a request to set all
        case allLocations:
          CustomAxios.post('/feature-flags/v1/locations/flags:all', {
            flagName: props.flagName,
            value: values.newValue,
          })
            .then((res) => {
              dispatch(showSuccess('All locations set'));
              props.close(values.newValue);
            })
            .catch((err) => {
              dispatch(showError('Failed to set all locations'));
            });
          break;

        // if a percentage of locations have been set use that
        case percentage > 0:
          CustomAxios.post('/feature-flags/v1/locations/flags:percentage', {
            flagName: props.flagName,
            value: values.newValue,
            percentage: percentage,
          });
          break;

        // default to just using the IDs of the locations provided
        default:
          // if no locations have been added do nothing. The form validation should prevent this anyways but do it just in case.
          if (!values.locationIDs) {
            return;
          }

          CustomAxios.post('/feature-flags/v1/locations/flags:batchCreate', {
            flagName: props.flagName,
            locationIds: skipBadRecords
              ? values.locationIDs.split('\n').filter(function (value) {
                  return uuidRegex.test(value);
                })
              : values.locationIDs.split('\n'),
            value: values.newValue,
          })
            .then((res) => {
              dispatch(showSuccess('Locations Set!'));
              props.close();
            })
            .catch((err) => {
              dispatch(showError('Error setting flag for locations'));
            });
          break;
      }
    },
  });

  return (
    <Modal
      show={true}
      onClose={() => {
        props.close();
      }}
    >
      <Modal.Header>Batch Set - {props.flagName}</Modal.Header>
      <Modal.Body>
        <p>Place LocationIDs here 1 per line</p>
        <form
          css={(theme: WeaveTheme) =>
            css`
              padding-top: ${theme.spacing(1)};
            `
          }
        >
          <TextareaField
            height={250}
            style={{ width: '100%' }}
            {...getFieldProps('locationIDs')}
            label="LocationIDs"
            placeholder={'Ex. 00000000-0000-0000-0000-000000000000'}
          />
          <br />
          <FormRow>
            <SwitchField {...getFieldProps('newValue')} label="Enabled" />
          </FormRow>
        </form>
        <h4>
          Total Locations:{' '}
          {values.locationIDs ? values.locationIDs.split('\n').length : 0}
        </h4>
        <div className="custom-control custom-switch">
          <input
            className="custom-control-input"
            id="skipBadRecords"
            type="checkbox"
            checked={skipBadRecords}
            onChange={(e) => setSkipBadRecords(e.target.checked)}
          />
          <label className="custom-control-label" htmlFor="skipBadRecords">
            Skip Bad Records
          </label>
        </div>
        <div className="custom-control custom-switch">
          <input
            className="custom-control-input"
            id="allLocations"
            type="checkbox"
            checked={allLocations}
            onChange={(e) => setAllLocations(e.target.checked)}
          />
          <label className="custom-control-label text-danger" htmlFor="allLocations">
            Set All Locations
          </label>
        </div>
        <label htmlFor="customRange1" className="form-label">
          Percentage of all locations {percentage}%
        </label>
        <input
          type="range"
          value={percentage}
          className="form-range"
          id="customRange1"
          onChange={(e) => setPercentage(e.target.valueAsNumber)}
        />
        <Modal.Actions
          // @ts-ignore It's the right type, Typescript just doesn't recognize that.
          onPrimaryClick={formProps.onSubmit}
          onSecondaryClick={() => {
            props.close();
          }}
          disablePrimary={!allLocations && !isComplete && percentage === 0}
          primaryLabel="Set"
          secondaryLabel="Cancel"
        />
      </Modal.Body>
    </Modal>
  );
};
