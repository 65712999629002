import { connect } from 'react-redux';

import { Onboarding } from './onboarding/onboarding.component';
import {
  getOnboarderSchedulingLinks,
  markTaskAsComplete,
  onboardingGetTasks,
  onboardingSetCurrentTask,
  saveExplanationOnTask,
  saveOnboarderSchedulingLinks,
  getOnboarders,
} from '../../redux/actions/onboarding-tasks';
import { saveFeatureFlags, selectFeatureFlags } from '../../redux/actions/feature-flags';
import FeatureFlagModel from '../settings/feature-flags-container/feature-flag.model';
import {
  selectCurrentLocation,
  selectCurrentLocationId,
  selectCurrentLocationVerticalID,
} from '../../redux/actions/location/current-location';
import { setPortingDetails, getPortingData } from '../../redux/actions/porting';
import { Store } from '../../redux/store/store.model';

const getUserId = (state) => {
  if (state.auth && state.auth.user) {
    return state.auth.user.user_id;
  }
  return '';
};

export const OnboardingContainer = connect(
  (state: Store) => ({
    onboarders: state.onboarding.onboarders,
    getOnboardersLoading: state.onboarding.getOnboardersLoading,
    currentLocation: selectCurrentLocation(state),
    tasks: state.onboarding.tasks,
    loading:
      state.onboarding.loading ||
      state.featureFlags.loading ||
      state.customization.loading,
    currentTask: state.onboarding.currentTask,
    schedulingLinks: state.onboarding.schedulingLinks,
    userId: getUserId(state),
    locationId: selectCurrentLocationId(state),
    featureFlags: selectFeatureFlags(state),
    customizations: state.customization.features,
    verticalId: selectCurrentLocationVerticalID(state),
    syncApp: state.syncApp.data.SyncApps,
  }),
  (dispatch) => ({
    onboardingGetTasks: () => dispatch(onboardingGetTasks()),
    getOnboarders: () => dispatch(getOnboarders()),
    markTaskAsComplete: (task, status) => dispatch(markTaskAsComplete({ task, status })),
    onboardingSetCurrentTask: (task) => dispatch(onboardingSetCurrentTask(task)),
    saveExplanationOnTask: (task, explanation) =>
      dispatch(saveExplanationOnTask({ task, explanation })),
    getOnboarderSchedulingLinks: (user) => dispatch(getOnboarderSchedulingLinks(user)),
    backToPortingHubContainer: (): void => {
      dispatch(setPortingDetails(null));
      dispatch(getPortingData());
    },
    saveOnboarderSchedulingLinks: (
      locationId,
      userId,
      schedulingLinks,
      installsAlreadyScheduled
    ) =>
      dispatch(
        saveOnboarderSchedulingLinks({
          location: { id: locationId },
          user: { id: userId },
          schedulingLinks,
          installsAlreadyScheduled,
        })
      ),
    saveFeatureFlags: (updatedFlags: FeatureFlagModel[]) =>
      dispatch(
        saveFeatureFlags({
          updatedFlags,
        })
      ),
  })
)(Onboarding);
