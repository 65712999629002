import React, { useEffect, useState } from 'react';
import { TableStyles } from '../../../styles/table-style';
import { css } from '@emotion/core';
import { RouteComponentProps } from 'react-router';
import ReactTable from 'react-table';
import { Page } from '../../shared/page/page.component';
import { CopyMedia } from './copy-media.component';
import { TenantConfiguration } from './tenant-configuration.component';
import { useSelector } from 'react-redux';
import {
  LocationModel,
  selectCurrentLocationParentId,
} from '../../../redux/actions/location';
import { getLocation } from '../../../redux/actions/location/locations/api';

export const TenantMigration: React.FC<RouteComponentProps> = (props) => {
  const parentLocationId = useSelector(selectCurrentLocationParentId);
  const [parentLocation, setParentLocation] = useState<LocationModel>();
  useEffect(() => {
    if (parentLocationId) {
      getLocation(parentLocationId).then(setParentLocation);
    }
  }, [parentLocationId]);

  if (!parentLocation) {
    return <>No Parent Location</>;
  }

  return (
    <Page title={'Merge to Parent Tenant'}>
      <ReactTable
        columns={[
          {
            Header: 'Parent Tenant Location Id',
            id: 'slug',
            accessor: (info) => info?.LocationID,
          },
          { Header: 'Parent Tenant Name', id: 'name', accessor: (info) => info?.Name },
        ]}
        data={[parentLocation]}
        showPagination={false}
        loading={!parentLocation}
        pageSize={1}
        css={[
          TableStyles,
          css`
            .rt-tr-group:last-child {
              border-bottom: none !important ;
            }
          `,
        ]}
      />

      <CopyMedia />
      <TenantConfiguration />
    </Page>
  );
};
