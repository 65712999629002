import { Heading, PrimaryButton, SpinningLoader, Text } from '@weave/design-system';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import {
  getOnboardingStatus,
  selectOnBoardingStatusDataSyncData,
  selectOnBoardingStatusError,
  selectOnBoardingStatusLoading,
} from '../../redux/actions/onboarding-hub';
import { isEmpty } from 'lodash';
import { useAlert } from '@weave/alert-system';
import {
  cardStyles,
  headerContainer,
  onBoardingHubContainer,
} from './onboarding-hub.styles';
import { onboardingIntakeFormApi } from './onboarding.api';
import { IntakeForm, OperationStatus } from './onboarding.types';
import { IntakeFormStatus } from './intake-form-status/intake-form-status';

type OnBoardingProps = {
  locationId: string;
};

export const OnboardingHub = ({ locationId }: OnBoardingProps) => {
  const dispatch = useDispatch();
  const alerts = useAlert();
  const [intakeFormData, setIntakeFormData] = useState<IntakeForm>();
  const [operationsStatuses, setOperationsStatuses] = useState<OperationStatus[]>([]);
  const [fetchIntakeFormLoading, setFetchIntakeFormLoading] = useState(false);
  const onBoardingStatusLoading = useSelector(selectOnBoardingStatusLoading);
  const onBoardingHubStatusDataSync = useSelector(selectOnBoardingStatusDataSyncData);
  const onBoardingHubStatusError = useSelector(selectOnBoardingStatusError);
  const selectedIntegration =
    onBoardingHubStatusDataSync &&
    !isEmpty(onBoardingHubStatusDataSync) &&
    onBoardingHubStatusDataSync?.data?.selectedIntegration
      ? onBoardingHubStatusDataSync?.data?.selectedIntegration
      : null;

  const fetchIntakeFormData = async () => {
    setIntakeFormData(undefined);
    setOperationsStatuses([]);
    setFetchIntakeFormLoading(true);

    try {
      const data = await onboardingIntakeFormApi.getOnboardingIntakeForm();
      setIntakeFormData(data);

      const operationsResponse = await onboardingIntakeFormApi.getOperationsStatus();
      setOperationsStatuses(operationsResponse?.operations ?? []);

      return data;
    } catch (error: any) {
      console.error(error);
      alerts.error('Error retrieving reimagine form data');
    } finally {
      setFetchIntakeFormLoading(false);
    }
  };

  useEffect(() => {
    if (locationId) {
      fetchOnboardingStatus();
    }
  }, [locationId]);

  /**
   * @desc : Function is defined to fetch onboarding status data
   */
  const fetchOnboardingStatus = () => {
    fetchIntakeFormData();
    dispatch(getOnboardingStatus());
  };

  /**
   * @desc: Function is defined to get the neccessary jsx component based on some checks and validation
   */
  const getIntegrationSoftwareDetails = () => {
    return (
      <>
        <div>
          <div>
            <Heading>Modular Onboarding</Heading>
            <Text as="span" color="light">
              Data Sync Module Integration Software
            </Text>
          </div>
        </div>

        {selectedIntegration ? (
          <>
            <div css={cardStyles}>
              <Text as="span">
                Selected Integration Software : <b>{selectedIntegration}</b>
              </Text>
            </div>
            {selectedIntegration === 'Other' ? (
              <Alert color="warning">
                <Text weight="bold" as="span">
                  User selected "Other" as integration software cannot complete an
                  independent data sync. Onboarder action is required.
                </Text>
              </Alert>
            ) : null}
          </>
        ) : (
          <Alert color="danger">
            {onBoardingHubStatusError
              ? 'Something Went Wrong!'
              : 'Integration Software Not Yet Selected'}
          </Alert>
        )}
      </>
    );
  };

  return (
    <div>
      <div css={headerContainer}>
        <div>
          <PrimaryButton color="blue" onClick={fetchOnboardingStatus}>
            Refresh
          </PrimaryButton>
        </div>
      </div>
      <br />

      <IntakeFormStatus
        intakeFormData={intakeFormData}
        fetchIntakeFormData={fetchIntakeFormData}
        fetchIntakeFormLoading={fetchIntakeFormLoading}
        operationsStatuses={operationsStatuses}
      />

      <hr />

      <div css={onBoardingHubContainer}>
        {onBoardingStatusLoading ? <SpinningLoader /> : getIntegrationSoftwareDetails()}
      </div>
    </div>
  );
};
