export interface ApptMapping {
  Attempted: string[];
  Canceled: string[];
  Completed: string[];
  Confirmed: string[];
  'In Office': string[];
  Unconfirmed: string[];
}

export interface OrderMapping {
  Dispensed: string[];
  Received: string[];
  Unknown: string[];
}

export interface WritebackMapping {
  Confirmed: string[];
}

export type Mapping = ApptMapping & OrderMapping & WritebackMapping;

interface DefaultMapping {
  appointment: ApptMapping;
  order: OrderMapping;
  writeback: WritebackMapping;
}

export const defaultMapping: DefaultMapping = {
  appointment: {
    Attempted: [],
    Canceled: [],
    Completed: [],
    Confirmed: [],
    'In Office': [],
    Unconfirmed: [],
  },
  order: {
    Dispensed: [],
    Received: [],
    Unknown: [],
  },
  writeback: {
    Confirmed: [],
  },
};
