import { useState, useEffect, useCallback, useMemo } from 'react';
import { useAlert } from '@weave/alert-system';

import { CustomAxios } from '../../../../redux/axios';
import { useResources } from '../../../../helpers/useResources';
import { DocumentType, Template } from './auto-reminder-templates';
import { NotificationSetting } from '../../recall-reminders/recall-settings.hook';

interface TemplateParams {
  notifiableType: string;
  deliveryMethod: string;
  leadValue: number;
}

const defaultTemplatesUrl = (params: TemplateParams) => {
  const paramString = Object.entries(params).reduce((prev, [key, value]) => {
    return `${prev}&${key}=${value}`;
  }, '');
  return `desktop/v1/notifications/default-templates?${paramString}`;
};

export const useTemplates = (settings: NotificationSetting) => {
  const id = settings.id;
  const [templates, setTemplates] = useState<Template[]>([]);
  const [original, setOriginal] = useState<Template[]>([]);
  const alerts = useAlert();

  const { data: templatesData, refresh } = useResources<any>(
    `notifications/settings/${id}/templates`,
    { deps: [id], emptyState: [], suppressFetch: !id }
  );

  const params: TemplateParams = useMemo(
    () => ({
      notifiableType: settings?.type,
      deliveryMethod: settings?.delivery.method,
      leadValue: settings?.send_before.value,
    }),
    [settings?.type, settings?.delivery.method, settings?.send_before.value]
  );

  const { data: defaultTemplatesData } = useResources<any>(defaultTemplatesUrl(params), {
    deps: [id, settings?.type, settings?.delivery?.method, settings?.send_before?.value],
    emptyState: [],
    suppressFetch: !!id,
  });

  useEffect(() => {
    if (id) {
      setTemplates(templatesData);
      setOriginal(templatesData);
    } else {
      const templates = defaultTemplatesData?.Templates ?? [];
      setTemplates(templates);
      setOriginal(templates);
    }
  }, [templatesData.length, defaultTemplatesData.length]);

  const saveTemplates = useCallback(() => {
    Promise.all(
      templates.map((item) => {
        if (item.language !== 'Unknown') {
          return CustomAxios.put(`notifications/settings/${id}/template`, item);
        } else {
          return CustomAxios.put(`support/v1/notifications/settings/${id}`, {
            ...settings,
            template: {
              ...settings.template,
              document_id: item.document_id,
              text: item.single,
              multi_template_text: item.multi,
            },
          });
        }
      })
    )
      .then(() => {
        setOriginal(templates);
        alerts.success('Saved Templates!');
      })
      .catch((error) => {
        console.error(error);
        alerts.error('Failed to save templates.');
      })
      .finally(refresh);
  }, [templates]);

  const prepareTemplates = useCallback((templateIndex, key, value) => {
    setTemplates((templates) =>
      templates.map((template, index) => {
        if (index === templateIndex) return { ...template, [key]: value };
        return template;
      })
    );
  }, []);

  const updateTemplateDocument = useCallback(
    (templateIndex: number, documentId: string, documentType: DocumentType) => {
      setTemplates((templates) =>
        templates.map((template, i) =>
          i === templateIndex
            ? { ...template, document_id: documentId, document_type: documentType }
            : template
        )
      );
    },
    []
  );

  return useMemo(
    () => ({
      original,
      prepareTemplates,
      refresh,
      saveTemplates,
      templates,
      updateTemplateDocument,
    }),
    [templates, original]
  );
};
