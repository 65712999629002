type RowState = {
  expanded: boolean;
  error?: boolean;
  loading: boolean;
};

type RowsStatesReducerState = {
  [rowIndex: number]: RowState;
};

/*-------------------------------- Action Creators -------------------------------------*/
export const toggleRowExpanded = (rowIndex: number): rowsStatesReducerAction => ({
  type: 'toggleExpanded',
  index: rowIndex,
});

export const updateRowLoading = (
  rowIndex: number,
  isLoading: boolean
): rowsStatesReducerAction => ({
  type: 'updateLoading',
  payload: {
    index: rowIndex,
    isLoading,
  },
});

export const setRowErred = (rowIndex: number): rowsStatesReducerAction => ({
  type: 'setError',
  index: rowIndex,
});

export const clearState = (): rowsStatesReducerAction => ({ type: 'clearState' });

/*------------------------------------ Actions -----------------------------------------*/
type rowsStatesReducerAction =
  | { type: 'toggleExpanded'; index: number }
  | { type: 'updateLoading'; payload: { index: number; isLoading: boolean } }
  | { type: 'setError'; index: number }
  | { type: 'clearState' };

/*------------------------------------ Reducer -----------------------------------------*/
export const rowsStatesReducer = (
  state: RowsStatesReducerState,
  action: rowsStatesReducerAction
): RowsStatesReducerState => {
  switch (action.type) {
    case 'toggleExpanded':
      return {
        ...state,
        [action.index]: {
          ...state[action.index],
          expanded: !state[action.index]?.expanded,
        },
      };
    case 'updateLoading':
      return {
        ...state,
        [action.payload.index]: {
          ...state[action.payload.index],
          loading: action.payload.isLoading,
          // Clear error if loading.
          error: action.payload.isLoading ? false : state[action.payload.index]?.error,
        },
      };
    case 'setError':
      return {
        ...state,
        [action.index]: { ...state[action.index], error: true },
      };
    case 'clearState':
      return {};
  }
};
