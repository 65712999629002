export enum VerticalEnum {
  Dental = 1,
  Optometry = 2,
  Wellness = 3,
  Other = 4,
  Vet = 5,
  Ortho = 6,
}

// Converts the enum to an array of objects
export const getVerticalList = () => {
  const list = Object.entries(VerticalEnum)
    .filter(([, value]) => isNaN(Number(value)))
    .map(([key, value]) => {
      return { key: Number(key), value: value as string };
    });

  return list;
};
